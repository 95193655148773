import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Suspense as _Suspense, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7d7f16b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "flex col g24" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex align-center g10 grow" }
const _hoisted_5 = {
  style: {"white-space":"nowrap"},
  class: "text-muted"
}
const _hoisted_6 = { class: "flex row align-center g10" }
const _hoisted_7 = {
  style: {"white-space":"nowrap"},
  class: "text-muted"
}
const _hoisted_8 = { class: "flex row align-start g24" }
const _hoisted_9 = { class: "flex row align-start g24" }
const _hoisted_10 = { class: "flex align-center g10" }
const _hoisted_11 = {
  style: {"white-space":"nowrap"},
  class: "text-muted"
}
const _hoisted_12 = { class: "container" }
const _hoisted_13 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeCountryDropdown = _resolveComponent("SeCountryDropdown")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_StoreList = _resolveComponent("StoreList")!
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('stores')
    }, null, 8, ["title"]),
    _createVNode(_component_SearchBar, {
      class: "sticky",
      ref: "searchBar",
      searchChange: _ctx.searchChange,
      newCallback: _ctx.openStoreModal,
      btnText: _ctx.canCreateStore() ? _ctx.Lang.getI18N('new') : undefined,
      modelValue: _ctx.state.displayUnactives,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.displayUnactives) = $event))
    }, null, 8, ["searchChange", "newCallback", "btnText", "modelValue"]),
    _createVNode(_component_SeModal, {
      title: _ctx.Lang.getI18N('new'),
      ref: "storeModal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Lang.getI18N("distributor")) + " : ", 1),
              _createVNode(_component_SeDropdown, {
                onSelectionChanged: _ctx.onDistributorChange,
                style: {"width":"100%"},
                ref: "distributorSelect",
                titleFunc: (distributor) => distributor.name,
                modelValue: _ctx.state.selectedDistributor,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.selectedDistributor) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.distributors, (distributor) => {
                    return (_openBlock(), _createBlock(_component_SeDropdownItem, {
                      key: distributor.ref?.id,
                      value: distributor
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(distributor.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["onSelectionChanged", "titleFunc", "modelValue"])
            ], 512), [
              [_vShow, _ctx.canSetDistributor()]
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Lang.getI18N("database")) + ": ", 1),
              _createVNode(_component_SeDropdown, {
                titleFunc: (database) => database.name,
                style: {"width":"100%"},
                modelValue: _ctx.state.selectedDatabase,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.selectedDatabase) = $event)),
                ref: "databaseSelect",
                nullable: true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.databases, (database) => {
                    return (_openBlock(), _createBlock(_component_SeDropdownItem, {
                      value: database,
                      key: database.ref?.id
                    }, null, 8, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["titleFunc", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_SeInput, {
              ref: "name",
              placeholder: _ctx.Lang.getI18N('name')
            }, null, 8, ["placeholder"]),
            _createVNode(_component_SeInput, {
              ref: "address",
              placeholder: _ctx.Lang.getI18N('address')
            }, null, 8, ["placeholder"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_SeInput, {
              ref: "postalCode",
              placeholder: _ctx.Lang.getI18N('postal-code')
            }, null, 8, ["placeholder"]),
            _createVNode(_component_SeInput, {
              ref: "city",
              placeholder: _ctx.Lang.getI18N('city')
            }, null, 8, ["placeholder"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.Lang.getI18N("country")) + " : ", 1),
            _createVNode(_component_SeCountryDropdown, { ref: "countrySelect" }, null, 512),
            _createVNode(_component_SeDropdown, {
              titleFunc: (utc) => utc,
              style: {"width":"100%"},
              search: true,
              modelValue: _ctx.state.selectedUtc,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.selectedUtc) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.utcs, (utc) => {
                  return (_openBlock(), _createBlock(_component_SeDropdownItem, {
                    value: utc,
                    key: utc
                  }, null, 8, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["titleFunc", "modelValue"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_SeButton, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addStore())),
              class: "modal-valid w100",
              icon: "/ico/plus.svg"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("create")), 1)
              ]),
              _: 1
            }),
            _createElementVNode("span", {
              ref: "formCheckLabel",
              style: {"display":"none"},
              class: "text-alert"
            }, _toDisplayString(_ctx.Lang.getI18N("form-invalid")), 513),
            _createElementVNode("span", {
              ref: "formCheckLabelExists",
              style: {"display":"none"},
              class: "text-alert"
            }, _toDisplayString(_ctx.Lang.getI18N("form.store.invalid.exists")), 513)
          ])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_HalfCircleSpinner, {
              "animation-duration": 1000,
              size: 60,
              color: "#454eff"
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_StoreList, {
            unactives: _ctx.state.displayUnactives,
            stores: _ctx.manager.listener?.items!
          }, null, 8, ["unactives", "stores"])
        ]),
        _: 1
      }))
    ])
  ]))
}