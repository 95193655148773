import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5adf13a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeButton = _resolveComponent("SeButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      title: "Infos",
      ref: "modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.modalMessage), 1)
      ]),
      _: 1
    }, 512),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.Lang.getI18N("login.recovery.help")), 1),
    _createVNode(_component_SeInput, {
      style: {"width":"auto","min-width":"300px"},
      ref: "email",
      placeholder: "Email",
      value: ""
    }, null, 512),
    _createVNode(_component_SeButton, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.recover())),
      ref: "loginBtn"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("login.recovery.send-mail")), 1)
      ]),
      _: 1
    }, 512)
  ], 64))
}