export enum AllergenEnum {
  glutenGrains = "A",
  crustaceans = "B",
  egg = "C",
  fish = "D",
  peanut = "E",
  soy = "F",
  milkOrLactose = "G",
  edibleNuts = "H",
  celery = "L",
  mustard = "M",
  sesame = "N",
  sulphites = "O",
  lupines = "P",
  molluscs = "R",
}
