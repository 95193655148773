
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import * as Device from "@/utils/Device";
export default defineComponent({
  props: ["result"],
  data() {
    return {
      Lang: Lang,
    };
  },
  methods: {
    displayPicture() {
      if (Device.isMobile()) {
        window.open(this.result.element.getIllustrationUrl(), "_blank");
        return;
      }

      let refs = <any>this.$refs;
      refs.pictureModal.open();
    },
  },
});
