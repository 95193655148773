import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1e104134"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-icon flex align-center justify-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
      class: "img",
      src: _ctx.value ? _ctx.on : _ctx.off
    }, null, 8, _hoisted_2)
  ]))
}