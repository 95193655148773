import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex align-center justify-around" }
const _hoisted_2 = { class: "flex align-center justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeNumberPicker = _resolveComponent("SeNumberPicker")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeForm = _resolveComponent("SeForm")!
  const _component_SeModal = _resolveComponent("SeModal")!

  return (_openBlock(), _createBlock(_component_SeModal, {
    title: _ctx.Lang.getI18N('edit'),
    ref: "rangePickerModal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SeForm, {
        ref: "submitForm",
        class: "flex col grow",
        style: {"padding-top":"10px","width":"350px"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("min")), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("max")), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_component_SeNumberPicker, {
              variant: "white",
              min: _ctx.rangeMin,
              modelValue: _ctx.numberMin,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.numberMin) = $event)),
              key: 'min-rating' + _ctx.componentKey,
              inputWidth: "55px"
            }, null, 8, ["min", "modelValue"])),
            (_openBlock(), _createBlock(_component_SeNumberPicker, {
              variant: "white",
              modelValue: _ctx.numberMax,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.numberMax) = $event)),
              key: 'max-rating' + _ctx.componentKey,
              inputWidth: "55px"
            }, null, 8, ["modelValue"]))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(['warning-box', _ctx.showWarning ? 'show' : ''])
          }, _toDisplayString(_ctx.Lang.getI18N("range-picker-invalid")), 3),
          _createVNode(_component_SeButton, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleAction())),
            class: "w100",
            icon: "ico/edit.svg"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("save")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["title"]))
}