import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1d632632"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between w100 align-center" }
const _hoisted_2 = { style: {"width":"90%"} }
const _hoisted_3 = {
  key: 0,
  class: "text-missing"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = {
  class: "flex g24",
  style: {"width":"25%","justify-content":"flex-end"}
}
const _hoisted_6 = { class: "flex align-center" }
const _hoisted_7 = {
  key: 0,
  class: "flex align-center"
}
const _hoisted_8 = {
  key: 1,
  class: "button-warning"
}
const _hoisted_9 = { class: "flex align-center" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!
  const _component_FileUploadResultRow = _resolveComponent("FileUploadResultRow")!
  const _component_SeTable = _resolveComponent("SeTable")!
  const _component_YesOrNoResultRow = _resolveComponent("YesOrNoResultRow")!
  const _component_MultipleChoiceResultRow = _resolveComponent("MultipleChoiceResultRow")!
  const _component_AuditResultRow = _resolveComponent("AuditResultRow")!
  const _component_SeExpendable = _resolveComponent("SeExpendable")!

  return (_openBlock(), _createBlock(_component_SeExpendable, { headerSize: 20 }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.question.text === '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("question-missing")), 1))
            : (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "header-text",
                title: _ctx.question.text
              }, _toDisplayString(_ctx.truncateText(_ctx.question.text, 180)), 9, _hoisted_4))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (
                _ctx.question.type === 'yes_or_no' ||
                _ctx.question.type === 'multiple_choice'
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_ToggleSwitch, {
                    ref: "isPercentageShownToggle",
                    variant: "transparent",
                    modelValue: _ctx.isPercentageShown,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isPercentageShown) = $event)),
                    auto: "false",
                    toggleIconText: "%"
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.question.required)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("required")), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: `/ico/form-${_ctx.question.type}.svg`,
              style: { filter: 'brightness(40%) saturate(10%) contrast(50%)' }
            }, null, 8, _hoisted_10),
            _createElementVNode("span", null, _toDisplayString(`(${_ctx.question.countProvidedAnswers()}/${
                  _ctx.state.selectedResults?.length
                })`), 1)
          ])
        ])
      ])
    ]),
    default: _withCtx(() => [
      (
        _ctx.question.type === 'file_upload' ||
        _ctx.question.type === 'yes_or_no' ||
        _ctx.question.type === 'multiple_choice'
      )
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.question.type === 'file_upload')
              ? (_openBlock(), _createBlock(_component_SeTable, {
                  key: 0,
                  widths: [20, 75, 5],
                  columns: [
            _ctx.Lang.getI18N('files'),
            _ctx.Lang.getI18N('column-action'),
            _ctx.hasComments ? _ctx.Lang.getI18N('comment') : '',
            _ctx.question.score !== null ? _ctx.Lang.getI18N('score') : '',
          ],
                  style: {"padding":"10px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FileUploadResultRow, {
                      answers: _ctx.question.answers!,
                      question: _ctx.question
                    }, null, 8, ["answers", "question"])
                  ]),
                  _: 1
                }, 8, ["columns"]))
              : _createCommentVNode("", true),
            (
          _ctx.question.type === 'yes_or_no' || _ctx.question.type === 'multiple_choice'
        )
              ? (_openBlock(), _createBlock(_component_SeTable, {
                  key: 1,
                  widths: [20, 75, 5],
                  columns: [
            _ctx.Lang.getI18N('answer'),
            _ctx.isPercentageShown ? _ctx.Lang.getI18N('percentage') : '',
            _ctx.hasComments && !_ctx.isPercentageShown ? _ctx.Lang.getI18N('comment') : '',
            _ctx.question.score !== null
              ? _ctx.isPercentageShown
                ? ''
                : _ctx.Lang.getI18N('score')
              : '',
          ],
                  style: {"padding":"10px"}
                }, {
                  default: _withCtx(() => [
                    (_ctx.question.type === 'yes_or_no')
                      ? (_openBlock(), _createBlock(_component_YesOrNoResultRow, {
                          key: 0,
                          answers: _ctx.question.answers!,
                          question: _ctx.question,
                          isPercentageShown: _ctx.isPercentageShown
                        }, null, 8, ["answers", "question", "isPercentageShown"]))
                      : (_openBlock(), _createBlock(_component_MultipleChoiceResultRow, {
                          key: 1,
                          answers: _ctx.question.answers!,
                          question: _ctx.question,
                          isPercentageShown: _ctx.isPercentageShown
                        }, null, 8, ["answers", "question", "isPercentageShown"]))
                  ]),
                  _: 1
                }, 8, ["columns"]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(_component_SeTable, {
            key: 1,
            widths: [95, 10, 5],
            columns: [
          _ctx.Lang.getI18N('answer'),
          _ctx.hasComments ? _ctx.Lang.getI18N('comment') : '',
          _ctx.question.score !== null ? _ctx.Lang.getI18N('score') : '',
        ],
            style: {"padding":"10px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AuditResultRow, {
                answers: _ctx.question.answers!,
                question: _ctx.question
              }, null, 8, ["answers", "question"])
            ]),
            _: 1
          }, 8, ["columns"]))
    ]),
    _: 1
  }))
}