export var data = {
  labels: ["1", "2", "3", "4", "5", "6"] as string[],
  datasets: [
    {
      label: "Views",
      backgroundColor: "rgb(69, 78, 255,0.3)",
      data: [40, 39, 10, 40, 39, 80, 40, 10, 10, 40, 45],
      hoverBackgroundColor: "rgb(69, 78, 255,0.5)",
      pointRadius: 0,
      borderWidth: 2,
      borderColor: "rgb(69, 78, 255,0.8)",
    },
  ],
};
export const options = {
  responsive: true,
  maintainAspectRatio: false,
};
