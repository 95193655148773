
import NavHeader from "../nav/NavHeader.vue";
import SeButton from "../components/global/SeButton.vue";
import SeInput from "../components/global/SeInput.vue";
import * as DateUtils from "@/utils/DateUtils";
import dateFormat from "dateformat";
import SeFadeButton from "../components/global/SeFadeButton.vue";
import { getElementManager } from "@/firebase/ElementManager";
import { defineComponent } from "@vue/runtime-core";
import Element from "@/model/Elements/Element";

import RRuleInput from "@/components/databases/task_configurations/RRuleInput.vue";
import ElementSelectList from "@/components/databases/task_configurations/ElementSelectList.vue";
import { RRule, rrulestr } from "rrule";
import * as Lang from "@/i18n/lang";
import TaskActionList from "@/components/databases/TaskActionList.vue";
import { DocumentReference, collection } from "firebase/firestore";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import LockedField from "@/components/global/LockedField.vue";
import { ModuleEnum } from "@/model/ModuleEnum";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import * as ModuleManager from "@/firebase/ModuleManager";
import TaskAction from "@/model/Tasks/TaskAction";
import { getStoreTaskConfigurationState } from "@/pinia/tasks/StoreTaskConfigurationState";
import { getState } from "@/pinia/NavigationState";
import * as Modals from "@/utils/Modals";
import { compareObjects } from "@/utils/ObjectUtils";
import * as Snackbars from "@/utils/Snackbars";
import TaskType from "@/model/Tasks/TaskType";
import * as TaskTypeManager from "@/firebase/TaskTypeManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import Attachment from "@/model/Filesystem/Attachment";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as DOMUtils from "@/utils/DOMUtils";
import { doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import * as Storage from "@/firebase/Storage";
import { nextTick } from "vue";
import TaskModuleConfigurationTemperature from "@/model/Tasks/config/TaskModuleConfigurationTemperature";
import TaskModuleConfigurationOils from "@/model/Tasks/config/TaskModuleConfigurationOils";
import TaskModuleConfigurationAction from "@/model/Tasks/config/TaskModuleConfigurationAction";
import FirebaseUploadStrategy from "@/model/Uploads/FirebaseUploadStrategy";
import * as StringUtils from "@/utils/StringUtils";

let isEqual = require("lodash/isEqual");

export default defineComponent({
  components: {
    RRuleInput,
    ElementSelectList,
    TaskActionList,
    LockedField,
    ReadonlyField,
  },
  data() {
    let rruleText = Lang.getI18N("modify");

    return {
      StringUtils: StringUtils,
      actionAttachments: [] as Attachment[],
      rruleText: rruleText,
      schedules: [] as any[],
      scheduleStart: new Date(0, 0, 0, 0, 0, 0, 0),
      scheduleEnd: new Date(0, 0, 0, 0, 0, 0, 0),
      ModuleEnum: ModuleEnum,
      dateFormat: dateFormat,
      DateUtils: DateUtils,
      selectedElements: [] as Element[],
      validElements: [] as Element[],
      taskActions: [] as TaskAction[],
      Lang: Lang,
      elementManager: getElementManager(),
      manager: getTaskConfigurationManager(),
    };
  },
  async setup() {
    var state = getStoreTaskConfigurationState();

    await state.setup();

    return {
      state: state,
    };
  },
  async mounted() {
    let refs = <any>this.$refs;

    let ruleStr = rrulestr(this.state.taskConfiguration!.getRecurrence()!);

    this.rruleText = DateUtils.getRRuleFrequenceText(ruleStr);

    let rrule = rrulestr(this.state.taskConfiguration!.getRecurrence()!);
    refs.rruleInput.fromRRule(rrule);

    if (!this.state.creation) {
      let configuration = <any>this.state.taskConfiguration!.getConfiguration();

      refs.elementSelect.automatic =
        configuration.automatic == undefined ? false : configuration.automatic;

      if (this.state.taskConfiguration!.hasScheduleOverride()) {
        for (
          let i = 0;
          i < this.state.taskConfiguration!.getStarts()!.length;
          i++
        ) {
          let start = this.state.taskConfiguration!.getStarts()![i];
          let duration = this.state.taskConfiguration!.getDurations()![i];

          this.schedules.push({
            start: start,
            end: start + duration,
          });
        }

        this.schedules.sort((a, b) => a.start - b.start);
      }

      refs.switch.toggle(this.state.taskConfiguration!.getActive());

      this.state.selectedModule = ModuleManager.getModuleFromRef(
        this.state.taskConfiguration?.getModule()!
      )?.toEnum()!;

      if (this.state.selectedModule == ModuleEnum.Temperatures) {
        for (let i = 0; i < configuration.temperature_elements.length; i++) {
          let element = await this.elementManager.getElementByReference(
            configuration.temperature_elements[i],
            this.state.store!
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule == ModuleEnum.Oils) {
        for (let i = 0; i < configuration.oil_elements.length; i++) {
          let element = await this.elementManager.getElementByReference(
            configuration.oil_elements[i],
            this.state.store!
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule! == ModuleEnum.Traceability) {
        for (let i = 0; i < configuration.traceability.length; i++) {
          let element = await this.elementManager.getElementByReference(
            configuration.traceability[i],
            this.state.store!
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule! == ModuleEnum.Labels) {
        for (let i = 0; i < configuration.labels.length; i++) {
          let element = await this.elementManager.getElementByReference(
            configuration.labels[i],
            this.state.store!
          );
          this.selectedElements.push(element!);
        }
      } else if (this.state.selectedModule! == ModuleEnum.Action) {
        this.taskActions = configuration.actions.map((x: any) =>
          TaskAction.fromFirestore(x)
        );

        if (this.taskActions == null || this.taskActions == undefined) {
          this.taskActions = [];
        }
      }
    }

    if (this.state.selectedModule != null) {
      this.validElements = await this.elementManager.getStoreElementsByModule(
        this.state.selectedModule!,
        this.state.store!
      );
    }

    this.state.taskTypes = await TaskTypeManager.getAllTaskTypes(
      this.state.store!
    );

    if (this.state.taskConfiguration!.getType() != null) {
      var type = this.state.taskTypes.find(
        (x) => x.ref!.id == this.state.taskConfiguration?.getType()?.id!
      );

      if (type != undefined) {
        this.state.selectedTaskType = type!;
      }
    }
  },
  methods: {
    moveDownAttachement(attachment: Attachment) {
      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var index = action.attachments?.indexOf(attachment)!;

      if (index <= 0 || index >= action.attachments!.length) {
        return;
      }

      // Swap the element at the given index with the previous one
      let temp = action.attachments![index];
      action.attachments![index] = action.attachments![index - 1];
      action.attachments![index - 1] = temp;
    },
    moveUpAttachement(attachment: Attachment) {
      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var index = action.attachments?.indexOf(attachment)!;

      // Check if the index is within the bounds of the array
      if (index < 0 || index >= action.attachments!.length - 1) {
        return;
      }

      // Swap the element at the given index with the next one
      let temp = action.attachments![index];
      action.attachments![index] = action.attachments![index + 1];
      action.attachments![index + 1] = temp;
    },
    uploadAttachment() {
      DOMUtils.attachementFileDialog(this.onAttachmentUploaded);
    },
    async onAttachmentUploaded(e: any) {
      let file = e.target.files[0];

      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var path =
        "/stores/" +
        this.state.store?.ref?.id +
        "/task_configurations_attachments/" +
        this.state.taskConfiguration?.ref?.id!;

      var strategy = await UploadManager.createStrategy(file, path);

      UploadManager.addFileToUpload(strategy);

      action.attachments.push(strategy.generateAttachment()!);
    },
    async downloadAttachement(attachment: Attachment) {
      await attachment.download();
    },
    async deleteAttachement(attachment: Attachment) {
      var refs = <any>this.$refs;

      var modal = refs.modifyActionModal;

      var action = <TaskAction>modal.context;

      var index = action.attachments.indexOf(attachment);

      action.attachments.splice(index, 1);

      UploadManager.addFileToDelete(attachment);
    },
    openEditScheduleModal(schedule: any) {
      this.scheduleStart = DateUtils.getDateFromMinutes(schedule.start - 60);
      this.scheduleEnd = DateUtils.getDateFromMinutes(schedule.end - 60);

      let refs = <any>this.$refs;
      refs.scheduleModal.open(schedule);
    },

    openEditTaskTypeModal(taskType: TaskType) {
      var refs = <any>this.$refs;

      refs.taskTypeName.setValue(taskType.name);
      refs.colorPicker.setValue(taskType.color);
      refs.taskTypeModal.open(taskType);
    },
    openTaskTypeModal() {
      var refs = <any>this.$refs;
      refs.taskTypeName.setValue("");
      refs.colorPicker.setValue("#454eff");
      refs.taskTypeModal.open();
    },
    async modifyOrCreateTaskType() {
      var refs = <any>this.$refs;

      var name = refs.taskTypeName.getValue();

      var color = refs.colorPicker.getValue();

      if (name.trim() == "") {
        return;
      }
      if (refs.taskTypeModal.context == null) {
        var type = new TaskType(
          doc(
            collection(
              Firebase.firestore,
              this.state.store!.ref.path,
              "task_types"
            )
          ),
          name,
          color,
          true
        );

        await TaskTypeManager.createStoreTaskType(this.state.store!, type);

        this.state.taskTypes.push(type);
        this.state.selectedTaskType = type;
      } else {
        var taskType = <TaskType>refs.taskTypeModal.context;

        taskType.name = name;
        taskType.color = color;
        await TaskTypeManager.modifyTaskType(taskType);
      }

      refs.taskTypeModal.close();
    },
    async duplicate() {
      this.apply();

      let refs = <any>this.$refs;

      if (refs.duplicateBtn.isLoading()) {
        return;
      }
      refs.duplicateBtn.toggleLoading(true);

      let cloned = StoreTaskConfiguration.duplicate(
        this.state.taskConfiguration!,
        this.state.store!
      );

      cloned.name += " (Copy)";

      await cloned.set();

      let state = getState();
      state.goBack();
    },

    openRRuleModal() {
      let refs = <any>this.$refs;
      refs.rruleModal.open();

      let rrule = rrulestr(this.state.taskConfiguration!.getRecurrence()!);
      refs.rruleInput.fromRRule(rrule);
    },
    closeRRuleModal() {
      let refs = <any>this.$refs;
      refs.rruleModal.close();
      let rule = refs.rruleInput.computeRRule();
      this.state.taskConfiguration!.recurrence = rule.toString();
      this.rruleText = DateUtils.getRRuleFrequenceText(
        refs.rruleInput.computeRRule()
      );
    },
    modifyAction() {
      let refs = <any>this.$refs;
      let action = refs.modifyActionModal.context;

      action.action = refs.actionNameMod.getValue();

      refs.modifyActionModal.close();
      this.applyConfiguration();
    },
    openAddElementModal() {
      let refs = <any>this.$refs;

      refs.elementSelect.update();
      refs.addElementModal.open();
    },
    openAddScheduleModal() {
      let refs = <any>this.$refs;
      refs.scheduleModal.open();
    },
    openModifyActionModal(action: TaskAction) {
      let refs = <any>this.$refs;
      refs.actionNameMod.setValue(action.action);
      refs.modifyActionModal.open(action);

      this.actionAttachments = action.attachments;
    },
    openAddActionModal(): any {
      let refs = <any>this.$refs;
      refs.actionName.setValue("");
      refs.addActionModal.open();
    },
    removeAction(action: TaskAction) {
      const index = this.taskActions.indexOf(action);
      if (index > -1) {
        this.taskActions.splice(index, 1);
      }
      this.applyConfiguration();
    },
    addAction() {
      let refs = <any>this.$refs;
      let actionName = refs.actionName.getValue();

      if (actionName == "") {
        return;
      }

      let action: TaskAction = new TaskAction(actionName, false);

      this.taskActions.push(action);

      refs.addActionModal.close();

      this.applyConfiguration();
    },
    applyConfiguration() {
      let userConfiguration = this.computeConfiguration();

      if (
        !isEqual(
          userConfiguration,
          this.state.taskConfiguration!.originValue?.configuration
        ) ||
        !isEqual(userConfiguration, this.state.taskConfiguration!.configuration)
      ) {
        this.state.taskConfiguration!.configuration = userConfiguration;
      }
    },
    applySchedules() {
      let userStarts: number[] = [];
      let userDurations: number[] = [];

      this.schedules.forEach((schedule: any) => {
        userStarts.push(schedule.start);
        userDurations.push(schedule.end - schedule.start);
      });

      if (
        !isEqual(
          userStarts,
          this.state.taskConfiguration!.originValue?.starts
        ) ||
        !isEqual(userStarts, this.state.taskConfiguration!.starts)
      ) {
        this.state.taskConfiguration!.starts = userStarts;
      }

      if (
        !isEqual(
          userDurations,
          this.state.taskConfiguration!.originValue?.durations
        ) ||
        !isEqual(userDurations, this.state.taskConfiguration!.durations)
      ) {
        this.state.taskConfiguration!.durations = userDurations;
      }

      this.schedules.sort((a, b) => a.start - b.start);
    },
    async onModuleChange(module: ModuleEnum) {
      if (module == ModuleEnum.Temperatures || module == ModuleEnum.Oils) {
        this.validElements = await this.elementManager.getStoreElementsByModule(
          module,
          this.state.store!
        );
        this.selectedElements = [];
      }
    },

    addElements() {
      (<any>this.$refs.addElementModal).close();
      this.selectedElements = (<any>(
        this.$refs.elementSelect
      )).getSelectedElements();

      this.$forceUpdate();
    },

    computeConfiguration(): any {
      var refs = <any>this.$refs;

      let configuration: any = {};

      let elementRefs: DocumentReference[] = [];
      this.selectedElements.forEach((element) => {
        elementRefs.push(element.ref!);
      });

      if (this.state.selectedModule! == ModuleEnum.Temperatures) {
        configuration = new TaskModuleConfigurationTemperature(
          elementRefs,
          false,
          refs.elementSelect.automatic
        );
      } else if (this.state.selectedModule! == ModuleEnum.Oils) {
        configuration = new TaskModuleConfigurationOils(elementRefs);
      } else if (this.state.selectedModule! == ModuleEnum.Action) {
        configuration = new TaskModuleConfigurationAction(this.taskActions);
      }

      return configuration;
    },
    async apply() {
      let refs = <any>this.$refs;
      if (refs.applyBtn.isLoading()) {
        return;
      }

      if (!refs.submitForm.verify()) {
        return;
      }

      if (
        this.state.selectedModule! == ModuleEnum.Action &&
        this.taskActions.length == 0
      ) {
        refs.modal.open();
        return;
      }

      if (this.state.selectedModule == null) {
        Modals.open(Lang.getI18N("task-configuration.select-module"));
        return;
      }

      try {
        refs.applyBtn.toggleLoading(true);
        let rule: RRule = refs.rruleInput.computeRRule();

        let newRecurrence = rule.toString();

        if (
          newRecurrence !=
            this.state.taskConfiguration!.originValue?.recurrence! ||
          newRecurrence != this.state.taskConfiguration!.recurrence
        ) {
          this.state.taskConfiguration!.recurrence = newRecurrence;
        }

        var module = ModuleManager.getModuleFromEnum(this.state.selectedModule);

        if (
          this.state.taskConfiguration!.originValue?.module != module.ref ||
          module.ref != this.state.taskConfiguration!.module
        ) {
          this.state.taskConfiguration!.module = module.ref;
        }

        if (this.state.selectedTaskType != null) {
          this.state.taskConfiguration!.setType(
            this.state.selectedTaskType?.ref!
          );
        } else {
          this.state.taskConfiguration?.setType(null);
        }

        this.applyConfiguration();

        this.applySchedules();

        await UploadManager.applyUploads();

        if (
          ModuleManager.getModuleFromRef(
            this.state.taskConfiguration?.module!
          )?.toEnum() == ModuleEnum.Action
        ) {
          var config = <TaskModuleConfigurationAction>(
            this.state.taskConfiguration?.getConfiguration()!
          );

          for (let action of config.actions) {
            for (let attachment of action.attachments) {
              await attachment.generateUrl();
            }
          }
        }
        await this.state.taskConfiguration!.set();

        await UploadManager.applyDeletions();

        this.state.changeSaved = true;

        Snackbars.display(Lang.getI18N("saved-successfully"));

        refs.applyBtn.toggleLoading(false);
      } catch (e: any) {
        Snackbars.display(Lang.getI18N("save-error"), 3000, "error");
        refs.applyBtn.toggleLoading(false);
      }
    },
    removeSchedule(schedule: any) {
      const index = this.schedules.indexOf(schedule);
      if (index > -1) {
        this.schedules.splice(index, 1);
      }
      this.applySchedules();
    },
    addOrEditSchedule() {
      if (this.scheduleStart > this.scheduleEnd) {
        return;
      }
      let refs = <any>this.$refs;

      let start =
        this.scheduleStart.getHours() * 60 + this.scheduleStart.getMinutes();

      let end =
        this.scheduleEnd.getHours() * 60 + this.scheduleEnd.getMinutes();

      if (refs.scheduleModal.context != null) {
        let schedule: any = refs.scheduleModal.context;
        schedule.start = start;
        schedule.end = end;
      } else {
        this.schedules.push({
          start: start,
          end: end,
        });
      }

      this.applySchedules();

      refs.scheduleModal.close();
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.state.changeSaved) {
      next();
      return;
    }
    if (
      this.state.creation ||
      !compareObjects(
        this.state.initialTaskConfiguration,
        this.state.taskConfiguration
      )
    ) {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        "Oui",
        "Non"
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
});
