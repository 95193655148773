import { generateUniqueId } from "../utils/uniqueIdGenerator";

export class Choice {
  id: string;
  text: string;
  score: number;
  archived_at: Date | null;

  constructor(
    text: string,
    score: number = 1,
    id: string = generateUniqueId(),
    archived_at: Date | null = null
  ) {
    this.id = id;
    this.text = text;
    this.score = score;
    this.archived_at = archived_at;
  }

  toMap(): Record<string, any> {
    return {
      id: this.id,
      text: this.text,
      score: this.score,
      archived_at: this.archived_at,
    };
  }

  clone(): Choice {
    return new Choice(this.text, this.score, this.id, this.archived_at);
  }
}
