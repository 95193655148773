import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-panel visible" }
const _hoisted_2 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!
  const _component_NavContent = _resolveComponent("NavContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Suspense, null, {
      fallback: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HalfCircleSpinner, {
            "animation-duration": 1000,
            size: 60,
            color: "#454eff"
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NavContent)
      ]),
      _: 1
    }))
  ]))
}