import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4d154583"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex center g24" }
const _hoisted_3 = { class: "text-delete" }
const _hoisted_4 = { class: "vertical-tab-layout grow" }
const _hoisted_5 = { class: "vertical-tabs" }
const _hoisted_6 = { class: "structure" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "text-blue p6 f14" }
const _hoisted_9 = { class: "section-container" }
const _hoisted_10 = { class: "section-header" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "subtitle" }
const _hoisted_13 = { class: "question-text p6 f14" }
const _hoisted_14 = { class: "text-blue p6 f14" }
const _hoisted_15 = { class: "tab-container grow" }
const _hoisted_16 = { class: "tab-header" }
const _hoisted_17 = { class: "content-grid" }
const _hoisted_18 = { class: "options-container" }
const _hoisted_19 = { class: "card options" }
const _hoisted_20 = {
  class: "bold",
  style: {"padding":"12px 10px"}
}
const _hoisted_21 = { class: "tab-option" }
const _hoisted_22 = {
  key: 0,
  class: "tab-option-col"
}
const _hoisted_23 = {
  class: "flex center justify-between",
  style: {"padding":"1rem 0rem"}
}
const _hoisted_24 = { class: "choices-container" }
const _hoisted_25 = { class: "grid single" }
const _hoisted_26 = { class: "flex center g24" }
const _hoisted_27 = { class: "tab-option-row" }
const _hoisted_28 = {
  key: 0,
  class: "tab-option-row"
}
const _hoisted_29 = { class: "tab-option-row" }
const _hoisted_30 = { class: "flex align-center" }
const _hoisted_31 = { class: "bold" }
const _hoisted_32 = { class: "bold" }
const _hoisted_33 = { class: "tab-option-row" }
const _hoisted_34 = { class: "tab-option-row" }
const _hoisted_35 = { class: "tab-option-col" }
const _hoisted_36 = { class: "flex center justify-between" }
const _hoisted_37 = { class: "text-blue p6 f14" }
const _hoisted_38 = { class: "choices-container" }
const _hoisted_39 = { class: "grid single" }
const _hoisted_40 = { class: "flex center g24" }
const _hoisted_41 = { class: "flex center" }
const _hoisted_42 = {
  key: 4,
  class: "tab-option-row"
}
const _hoisted_43 = {
  key: 5,
  class: "tab-option-row"
}
const _hoisted_44 = {
  key: 6,
  class: "tab-option-row"
}
const _hoisted_45 = { class: "flex align-center" }
const _hoisted_46 = { class: "bold" }
const _hoisted_47 = { class: "bold" }
const _hoisted_48 = { class: "card footer" }
const _hoisted_49 = { class: "score" }
const _hoisted_50 = { class: "flex center g6" }
const _hoisted_51 = { class: "flex center" }
const _hoisted_52 = {
  class: "bold",
  style: {"margin-right":"-10px"}
}
const _hoisted_53 = { class: "flex align-center g0" }
const _hoisted_54 = { class: "bold" }
const _hoisted_55 = {
  key: 0,
  class: "text-muted",
  style: {"margin-left":"-5px"}
}
const _hoisted_56 = { class: "flex center g6" }
const _hoisted_57 = { class: "purple-container" }
const _hoisted_58 = { class: "text-add" }
const _hoisted_59 = {
  key: 1,
  class: "content-empty"
}
const _hoisted_60 = { class: "title-empty" }
const _hoisted_61 = { class: "text-blue p6 f14" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeRangePickerModal = _resolveComponent("SeRangePickerModal")!
  const _component_SeInputModal = _resolveComponent("SeInputModal")!
  const _component_SeDeleteConfirmModal = _resolveComponent("SeDeleteConfirmModal")!
  const _component_ConditionsModal = _resolveComponent("ConditionsModal")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_SeForm = _resolveComponent("SeForm")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SeNumberPicker = _resolveComponent("SeNumberPicker")!
  const _component_WideCard = _resolveComponent("WideCard")!
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!
  const _component_SeDropdownFilter = _resolveComponent("SeDropdownFilter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeRangePickerModal, { ref: "rangePickerModal" }, null, 512),
    _createVNode(_component_SeInputModal, { ref: "inputModal" }, null, 512),
    _createVNode(_component_SeDeleteConfirmModal, { ref: "deleteConfirmModal" }, null, 512),
    _createVNode(_component_ConditionsModal, {
      ref: "conditionsModal",
      localAudit: _ctx.localAudit,
      selectedQuestion: _ctx.selectedQuestion
    }, null, 8, ["localAudit", "selectedQuestion"]),
    _createVNode(_component_SeModal, {
      title: _ctx.Lang.getI18N('edit'),
      ref: "editSectionModal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SeForm, {
          ref: "editSectionForm",
          class: "flex col g30 grow",
          style: {"width":"550px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SeInput, {
              ref: "sectionTextEdit",
              placeholder: _ctx.Lang.getI18N('name'),
              required: ""
            }, null, 8, ["placeholder"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_SeButton, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal(_ctx.$refs.editSectionModal))),
                style: {"width":"100px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("cancel")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_SeFadeButton, {
                  icon: "/ico/trash.svg",
                  iconRight: true,
                  style: {"width":"100px"},
                  onClick: _ctx.confirmDeleteSection
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("delete")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_SeButton, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editSection())),
                  style: {"width":"100px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("edit")), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Lang.getI18N("structure")), 1),
          _createVNode(_component_SeFadeButton, {
            icon: "/ico/plus-blue.svg",
            iconRight: true,
            style: {"width":"fit-content"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openCreateSectionModal(_ctx.$refs.inputModal)))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.Lang.getI18N("new-section")), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_draggable, {
            modelValue: _ctx.localAudit.sections,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localAudit.sections) = $event)),
            group: "sections",
            "item-key": "sections-id"
          }, {
            item: _withCtx(({ element: section }) => [
              (section.archived_at === null)
                ? (_openBlock(), _createElementBlock("div", {
                    class: "section",
                    key: section.id
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_SeFadeButton, {
                        icon: "/ico/edit-blue.svg",
                        iconRight: true,
                        style: {"padding":"4px","margin":"0px 4px","width":"fit-content"},
                        onClick: ($event: any) => (_ctx.openEditSectionModal(section)),
                        iconSize: 21
                      }, null, 8, ["onClick"]),
                      _createElementVNode("div", {
                        class: "flex center justify-between w100",
                        onClick: ($event: any) => (_ctx.toggleSection(section.id))
                      }, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(section.title), 1),
                        _createVNode(_component_SeFadeButton, {
                          icon: "/ico/arrow-minimalistic-down.svg",
                          iconRight: true,
                          class: _normalizeClass({
                      rotate: section.isVisible,
                      rotateBack: !section.isVisible,
                    })
                        }, null, 8, ["class"])
                      ], 8, _hoisted_11)
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createVNode(_component_draggable, {
                        modelValue: section.questions,
                        "onUpdate:modelValue": ($event: any) => ((section.questions) = $event),
                        group: "questions",
                        "item-key": "questions-id"
                      }, {
                        item: _withCtx(({ element: question }) => [
                          (question.archived_at === null)
                            ? (_openBlock(), _createBlock(_component_SeFadeButton, {
                                icon: `/ico/form-${question.type}.svg`,
                                iconRight: true,
                                class: _normalizeClass({
                        w100: true,
                        questionSelected: _ctx.selectedQuestion.id === question.id,
                      }),
                                style: {"justify-content":"start","margin-top":"5px"},
                                key: question.id,
                                onClick: ($event: any) => (_ctx.onQuestionSelect(question))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.truncateText(question.text, 35)), 1)
                                ]),
                                _: 2
                              }, 1032, ["icon", "class", "onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue"]),
                      _createVNode(_component_SeFadeButton, {
                        class: "border w100",
                        icon: "/ico/plus-blue.svg",
                        iconRight: true,
                        onClick: ($event: any) => (_ctx.addQuestion(section.id))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.Lang.getI18N("new-question")), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ], 512), [
                      [_vShow, section.isVisible]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        (_ctx.localAudit.sections.length > 0 && _ctx.findFirstQuestion)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_SeInput, {
                  placeholder: _ctx.Lang.getI18N('question'),
                  ref: "question",
                  modelValue: _ctx.selectedQuestion.text,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedQuestion.text) = $event))
                }, null, 8, ["placeholder", "modelValue"]),
                _createVNode(_component_SeDropdown, {
                  titleFunc: (questionType) => _ctx.Lang.getI18N(`question.${questionType}`),
                  onSelectionChanged: _ctx.onQuestionTypeChange,
                  style: {"width":"190px"},
                  modelValue: _ctx.selectedQuestion.type,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedQuestion.type) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionTypes, (questionType) => {
                      return (_openBlock(), _createBlock(_component_SeDropdownItem, {
                        value: questionType,
                        key: questionType
                      }, null, 8, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["titleFunc", "onSelectionChanged", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.Lang.getI18N("options")), 1),
                    _createElementVNode("div", _hoisted_21, [
                      (_ctx.selectedQuestion.type === 'yes_or_no')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("choices")), 1)
                            ]),
                            _createElementVNode("div", _hoisted_24, [
                              _createElementVNode("div", _hoisted_25, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedQuestionChoices, (choice) => {
                                  return (_openBlock(), _createBlock(_component_WideCard, {
                                    class: "shadow",
                                    key: choice.id,
                                    obj: choice
                                  }, _createSlots({ _: 2 }, [
                                    (choice.archived_at === null)
                                      ? {
                                          name: "body",
                                          fn: _withCtx(() => [
                                            _createElementVNode("span", null, _toDisplayString(choice.text === "true"
                                ? _ctx.Lang.getI18N("yes")
                                : _ctx.Lang.getI18N("no")), 1)
                                          ]),
                                          key: "0"
                                        }
                                      : undefined,
                                    (choice.archived_at === null)
                                      ? {
                                          name: "footer",
                                          fn: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_26, [
                                              (_ctx.toggleQuestionScore)
                                                ? (_openBlock(), _createBlock(_component_SeNumberPicker, {
                                                    onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"])),
                                                    modelValue: choice.score,
                                                    "onUpdate:modelValue": ($event: any) => ((choice.score) = $event),
                                                    min: 0,
                                                    key: 'question-score' + _ctx.selectedQuestion.id
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                : _createCommentVNode("", true)
                                            ])
                                          ]),
                                          key: "1"
                                        }
                                      : undefined
                                  ]), 1032, ["obj"]))
                                }), 128))
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.selectedQuestion.type === 'text_input')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("limit-characters")), 1),
                              _createVNode(_component_ToggleSwitch, {
                                auto: "false",
                                ref: "limitCharacters",
                                variant: "transparent",
                                modelValue: _ctx.toggleLimitCharacters,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.toggleLimitCharacters) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            (_ctx.toggleLimitCharacters)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("max-characters")), 1),
                                  (_openBlock(), _createBlock(_component_SeNumberPicker, {
                                    variant: "white",
                                    min: 1,
                                    modelValue: (_ctx.selectedQuestion.options as TextQuestionOptions).max_characters,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (((_ctx.selectedQuestion.options as TextQuestionOptions).max_characters) = $event)),
                                    key: 'max-characters' + _ctx.selectedQuestion.id
                                  }, null, 8, ["modelValue"]))
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.selectedQuestion.type === 'rating')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            _createElementVNode("div", _hoisted_29, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("range")), 1),
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("from")), 1),
                                _createElementVNode("span", _hoisted_31, _toDisplayString((_ctx.selectedQuestion.options as RatingQuestionOptions)
                          .min_rating), 1),
                                _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("to")), 1),
                                _createElementVNode("span", _hoisted_32, _toDisplayString((_ctx.selectedQuestion.options as RatingQuestionOptions)
                          .max_rating), 1),
                                _createVNode(_component_SeButton, {
                                  onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openRangePickerModal(_ctx.$refs.rangePickerModal))),
                                  icon: "ico/edit.svg",
                                  style: {"margin-left":"20px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("edit")), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_33, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("rating-precision")), 1),
                              _createVNode(_component_SeDropdown, {
                                titleFunc: (precision) => precision,
                                style: {"width":"100px","padding":"5px"},
                                modelValue: (_ctx.selectedQuestion.options as RatingQuestionOptions).precision,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (((_ctx.selectedQuestion.options as RatingQuestionOptions).precision) = $event))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 0.5, 0.1, 0.01], (precision) => {
                                    return _createVNode(_component_SeDropdownItem, {
                                      value: precision,
                                      key: precision
                                    }, null, 8, ["value"])
                                  }), 64))
                                ]),
                                _: 1
                              }, 8, ["titleFunc", "modelValue"])
                            ])
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.selectedQuestion.type === 'multiple_choice')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("multiple-selection")), 1),
                              _createVNode(_component_ToggleSwitch, {
                                variant: "transparent",
                                modelValue: (_ctx.selectedQuestion.options as MultipleQuestionOptions).multiple_selection,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (((_ctx.selectedQuestion.options as MultipleQuestionOptions).multiple_selection) = $event)),
                                auto: "true",
                                ref: "multipleSelection"
                              }, null, 8, ["modelValue"])
                            ]),
                            _createElementVNode("div", _hoisted_35, [
                              _createElementVNode("div", _hoisted_36, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("choices")), 1),
                                _createVNode(_component_SeFadeButton, {
                                  icon: "/ico/plus-blue.svg",
                                  iconRight: true,
                                  style: {"width":"fit-content"},
                                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openCreateChoiceModal(_ctx.$refs.inputModal)))
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.Lang.getI18N("new-choice")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _createElementVNode("div", _hoisted_38, [
                                _createElementVNode("div", _hoisted_39, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedQuestionChoices, (choice) => {
                                    return (_openBlock(), _createBlock(_component_WideCard, {
                                      class: "shadow",
                                      key: choice.id,
                                      obj: choice,
                                      onClick: ($event: any) => (_ctx.openEditChoiceModal(_ctx.$refs.inputModal, choice))
                                    }, _createSlots({ _: 2 }, [
                                      (choice.archived_at === null)
                                        ? {
                                            name: "body",
                                            fn: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(choice.text), 1)
                                            ]),
                                            key: "0"
                                          }
                                        : undefined,
                                      (choice.archived_at === null)
                                        ? {
                                            name: "footer",
                                            fn: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_40, [
                                                (_ctx.toggleQuestionScore)
                                                  ? (_openBlock(), _createBlock(_component_SeNumberPicker, {
                                                      onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"])),
                                                      modelValue: choice.score,
                                                      "onUpdate:modelValue": ($event: any) => ((choice.score) = $event),
                                                      min: 0,
                                                      key: 'question-score' + _ctx.selectedQuestion.id
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                                  : _createCommentVNode("", true),
                                                _createElementVNode("div", _hoisted_41, [
                                                  _createVNode(_component_SeFadeButton, {
                                                    onClick: [
                                                      _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"])),
                                                      ($event: any) => (_ctx.deleteChoice(choice.id))
                                                    ],
                                                    icon: "/ico/trash.svg"
                                                  }, null, 8, ["onClick"])
                                                ])
                                              ])
                                            ]),
                                            key: "1"
                                          }
                                        : undefined
                                    ]), 1032, ["obj", "onClick"]))
                                  }), 128))
                                ])
                              ])
                            ])
                          ], 64))
                        : _createCommentVNode("", true),
                      (_ctx.selectedQuestion.type === 'file_upload')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("allowed-extensions")) + ": ", 1),
                            _createVNode(_component_SeDropdownFilter, {
                              title: "Extensions",
                              ref: "supportedExtensions",
                              items: _ctx.supportedExtensions,
                              style: {"padding":"5px"}
                            }, null, 8, ["items"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.selectedQuestion.type === 'date')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("limit-current-date")) + ": ", 1),
                            _createVNode(_component_SeDropdown, {
                              titleFunc: (dateConfigurationLimit) => _ctx.Lang.getI18N(`date.${dateConfigurationLimit}`),
                              style: {"width":"190px","padding":"5px"},
                              modelValue:  (_ctx.selectedQuestion.options as DateQuestionOptions).date_limit,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (( (_ctx.selectedQuestion.options as DateQuestionOptions).date_limit) = $event))
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateConfigurationLimits, (dateConfigurationLimit) => {
                                  return (_openBlock(), _createBlock(_component_SeDropdownItem, {
                                    key: dateConfigurationLimit,
                                    value: dateConfigurationLimit
                                  }, null, 8, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["titleFunc", "modelValue"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.selectedQuestion.type === 'number')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("range")), 1),
                            _createElementVNode("div", _hoisted_45, [
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("from")), 1),
                              _createElementVNode("span", _hoisted_46, _toDisplayString((_ctx.selectedQuestion.options as NumberQuestionOptions)
                          .min_number), 1),
                              _createElementVNode("span", null, _toDisplayString(_ctx.Lang.getI18N("to")), 1),
                              _createElementVNode("span", _hoisted_47, _toDisplayString((_ctx.selectedQuestion.options as NumberQuestionOptions)
                          .max_number), 1),
                              _createVNode(_component_SeButton, {
                                onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.openRangePickerModal(_ctx.$refs.rangePickerModal))),
                                icon: "ico/edit.svg",
                                style: {"margin-left":"20px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("edit")), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_48, [
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("div", _hoisted_51, [
                      _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.Lang.getI18N("required")), 1),
                      _createVNode(_component_ToggleSwitch, {
                        ref: "questionRequired",
                        variant: "transparent",
                        modelValue: _ctx.selectedQuestion.required,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.selectedQuestion.required) = $event)),
                        auto: "false"
                      }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_53, [
                      _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.Lang.getI18N("score")), 1),
                      _createVNode(_component_ToggleSwitch, {
                        ref: "toggleQuestionScore",
                        modelValue: _ctx.toggleQuestionScore,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.toggleQuestionScore) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    (_ctx.toggleQuestionScore)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (
                    _ctx.selectedQuestion.type === 'multiple_choice' ||
                    _ctx.selectedQuestion.type === 'yes_or_no'
                  )
                            ? (_openBlock(), _createElementBlock("span", _hoisted_55, _toDisplayString(`${_ctx.Lang.getI18N("total-score")}: ${_ctx.selectedQuestion.score}`), 1))
                            : (_openBlock(), _createBlock(_component_SeNumberPicker, {
                                min: 1,
                                modelValue: _ctx.selectedQuestion.score,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.selectedQuestion.score) = $event)),
                                key: 'score-multiple' + _ctx.selectedQuestion.id
                              }, null, 8, ["modelValue"]))
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("div", _hoisted_57, [
                    _createVNode(_component_SeFadeButton, {
                      icon: 
                  _ctx.selectedQuestion.conditions &&
                  _ctx.selectedQuestion.conditions.length > 0
                    ? '/ico/edit-blue.svg'
                    : '/ico/plus-blue.svg'
                ,
                      style: {"width":"fit-content","gap":"10px"},
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.openConditionsModal(_ctx.$refs.conditionsModal)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.Lang.getI18N("add-condition")), 1)
                      ]),
                      _: 1
                    }, 8, ["icon"])
                  ]),
                  _createVNode(_component_SeFadeButton, {
                    icon: "/ico/trash.svg",
                    iconRight: true,
                    onClick: _cache[21] || (_cache[21] = ($event: any) => (
                _ctx.confirmDeleteQuestion(
                  _ctx.$refs.deleteConfirmModal,
                  _ctx.selectedQuestion.id
                )
              ))
                  })
                ])
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_59, [
              _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.Lang.getI18N("questions-empty")), 1),
              _createVNode(_component_SeFadeButton, {
                style: {"width":"fit-content"},
                onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.openCreateSectionModal(_ctx.$refs.inputModal)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.Lang.getI18N("add-new-section")), 1)
                ]),
                _: 1
              })
            ]))
      ])
    ])
  ], 64))
}