
import { defineComponent } from "@vue/runtime-core";
import { getState } from "@/pinia/AppState";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";

export default defineComponent({
  components: { SeDropdownItem },
  expose: [
    "stores",
    "getStart",
    "getEnd",
    "getSelectedStore",
    "resetTextSearch",
  ],

  props: ["onStoreChange"],

  mounted() {
    let state = getState();

    if (state.customStoreId != null) {
      this.forceStoreSelection(state.customStoreId);
    }
  },
  methods: {
    storeChanged(store: Store) {
      if (this.onStoreChange) {
        this.onStoreChange(store);
      }
    },
    forceStoreSelection(storeId: string) {
      let refs = <any>this.$refs;

      let store = this.stores.find((x) => x.ref?.id == storeId);

      if (store != null) {
        refs.storeSelect.$el.style.display = "none";
        refs.storeSelect.setValue(store);
      }
    },
    async searchClick() {
      let refs = <any>this.$refs;

      refs.searchBtn.toggleLoading(true);
      let parent = <any>this.$parent;

      await parent.search();
      refs.searchBtn.toggleLoading(false);
    },
    searchTextChanged(text: string) {
      let parent = <any>this.$parent;
      parent.onSearchTextChange(text);
    },
    resetTextSearch() {
      let refs = <any>this.$refs;
      refs.searchBar.setSearch("");
    },
    getSelectedStore() {
      let refs = <any>this.$refs;
      let value = refs.storeSelect.getValue();
      return value;
    },

    getStart(): Date {
      return (<any>this.$refs).calendar.getStart();
    },
    getEnd(): Date {
      return (<any>this.$refs).calendar.getEnd();
    },
  },

  async setup() {
    let stores = getStoreManager().getAvailableStoreOnHistory();

    return {
      stores: stores,
    };
  },
});
