
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 100,
    },
  },
});
