import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import Probe from "@/model/Probe";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import DatabaseShopItem from "@/model/Shop/DatabaseShopItem";

interface ShopManager {}

export const getShopManager = defineStore("ShopManager", {
  state: (): ShopManager => {
    return {};
  },
  actions: {
    async getDatabaseShop(database: Database): Promise<DatabaseShopItem[]> {
      if (database.distributor == null) {
        console.warn(
          "This database dont have distributor assigned. Unable to access shop."
        );
        return [];
      }
      let distributorItems = await this.getShop(database.distributor!);

      const q = query(
        collection(Firebase.firestore, database.ref.path + "/shop")
      );

      let snapshot = await getDocs(q);
      var databaseShopItems = snapshot.docs.map((x) =>
        DatabaseShopItem.fromFirestore(x)
      );

      let results = [];

      for (let item of distributorItems) {
        if (
          databaseShopItems.some(
            (x) => x.origin != null && x.origin!.id == item.ref.id
          )
        ) {
          continue;
        }
        results.push(item.toDatabaseShopItem(database.ref!));
      }

      for (let item of databaseShopItems) {
        if (item.origin != null) {
          var originValue = distributorItems.find(
            (x) => x.ref.id == item.origin?.id
          );
          item.setOriginValue(originValue!);
        }
        results.push(item);
      }

      return results;
    },
    async getShop(
      distributorRef: DocumentReference
    ): Promise<DistributorShopItem[]> {
      const q = query(
        collection(Firebase.firestore, distributorRef.path + "/shop")
      );
      let snapshot = await getDocs(q);

      let results = snapshot.docs.map((x) =>
        DistributorShopItem.fromFirestore(x)
      );
      results.sort((a, b) => a.index - b.index);

      return results;
    },
  },
});
