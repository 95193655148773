import { defineStore } from "pinia";
import { Role } from "@/model/User";
import { getStoreManager } from "@/firebase/StoreManager";
import Distributor from "@/model/Distributor";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import { getDistributorManager } from "@/firebase/DistributorManager";
import Probe from "@/model/Probe";
import { getProbesManager } from "@/firebase/ProbeManager";

interface ProbesState {
  visibleProbes: Probe[];
  probes: Probe[];
  filterIndex: number;
  displayUnactives: boolean;
}

export const getProbesState = defineStore("ProbesState", {
  state: (): ProbesState => {
    return {
      visibleProbes: [],
      probes: [],
      filterIndex: 0,
      displayUnactives: false,
    };
  },
  actions: {
    async setup() {
      this.probes = await getProbesManager().getProbes();
      this.visibleProbes = this.probes;
    },
  },
});
