import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import Audit from "@/model/Auditing/Audit";
import * as StringUtils from "@/utils/StringUtils";
import { Role } from "@/model/User";
import { getAuditManager } from "@/firebase/AuditManager";

interface AuditsState {
  audits: Audit[];
  databases: Database[];
  selectedDatabase: Database | null;
}

export const getAuditsState = defineStore("auditstate", {
  state: (): AuditsState => {
    return {
      audits: [],
      databases: [],
      selectedDatabase: null,
    };
  },
  actions: {
    async setup() {
      await this.reloadDatabases();

      this.databases = this.databases.sort((a, b) =>
        StringUtils.removeEmojis(a.name).localeCompare(
          StringUtils.removeEmojis(b.name)
        )
      );

      if (this.databases.length > 0) {
        if (!this.selectedDatabase) {
          this.selectedDatabase = this.databases[0];
        }
      }

      if (this.selectedDatabase?.ref?.id) {
        let role = getState().userData!.role;
        if (
          role === Role.Admin ||
          role === Role.Manager ||
          role === Role.Distributor
        ) {
          let auditManager = getAuditManager();

          await auditManager.ensureAuditListener(
            this.selectedDatabase?.ref?.id
          );

          auditManager.getAuditListener(
            this.selectedDatabase?.ref?.id
          ).updated = (audits: Audit[]) => {
            this.audits = audits;
          };

          this.audits = await auditManager.getAudits(
            role,
            this.selectedDatabase?.ref?.id
          );
        }
      }
    },
    async reloadDatabases() {
      await getDatabaseManager().initialize();
      this.databases = getDatabaseManager().getDatabases();
    },
  },
});
