
import { defineComponent } from "vue";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  props: {},
  data() {
    return {
      callback: Function,
      Lang: Lang,
      context: "",
    };
  },
  emits: ["close", "action"],
  expose: ["openModal"],
  methods: {
    handleAction(modalRef: any) {
      if (this.context !== "") {
        this.callback(this.context);
      } else {
        this.callback();
      }
      modalRef.close();
    },
    openModal(callbackFunction: any, context?: any) {
      let refs = <any>this.$refs;
      if (context) {
        this.context = context;
      }
      this.callback = callbackFunction;
      refs.confirmDeleteModal.open();
    },
    closeModal(modalRef: any) {
      modalRef.close();
    },
  },
});
