
import { defineComponent, ref } from "vue";
import { languageMappings } from "@/i18n/lang";
import { getState } from "../../pinia/AppState";

export default defineComponent({
  setup() {
    const state = getState();
    const selectedLanguage = ref(state.lang);

    const handleLanguageChange = (event: Event) => {
      const selectedLang = (event.target as HTMLSelectElement).value;
      selectedLanguage.value = selectedLang;
      state.lang = selectedLang;
    };

    return {
      selectedLanguage,
      handleLanguageChange,
      languageMappings,
    };
  },
});
