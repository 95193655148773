import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_DatabaseList = _resolveComponent("DatabaseList")!
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('databases')
    }, null, 8, ["title"]),
    _createVNode(_component_SearchBar, {
      class: "sticky",
      ref: "searchBar",
      searchChange: _ctx.searchChange,
      newCallback: _ctx.openDatabaseModal,
      btnText: _ctx.isDistributor() ? _ctx.Lang.getI18N('new') : undefined
    }, null, 8, ["searchChange", "newCallback", "btnText"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeModal, {
        title: _ctx.Lang.getI18N('new'),
        ref: "databaseModal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeInput, {
            style: {"width":"80vw","max-width":"350px"},
            ref: "name",
            placeholder: _ctx.Lang.getI18N('name')
          }, null, 8, ["placeholder"]),
          _createVNode(_component_SeButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createDatabase())),
            icon: "ico/plus.svg",
            class: "modal-valid w100"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("add")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]),
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_HalfCircleSpinner, {
              "animation-duration": 1000,
              size: 60,
              color: "#454eff"
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DatabaseList, {
            databases: _ctx.sortDatabases(_ctx.databaseManager.listener?.items!),
            ref: "dbList"
          }, null, 8, ["databases"])
        ]),
        _: 1
      }))
    ])
  ]))
}