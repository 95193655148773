import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  Firestore,
} from "firebase/firestore";
import { vModelDynamic } from "vue";
import SecureatSnapshot from "./SecureatSnapshot";

export default class ElementType extends SecureatSnapshot {
  name: string = "";
  active: boolean = false;
  storeSide: boolean = false;

  constructor(
    ref: DocumentReference,
    active: boolean,
    name: string,
    storeSide: boolean
  ) {
    super(ref);
    this.active = active;
    this.name = name;
    this.storeSide = storeSide;
  }

  public static fromFirestore(
    snapshot: DocumentSnapshot,
    storeSide: boolean
  ): ElementType {
    const data = snapshot.data()!;

    let result = new ElementType(
      snapshot.ref,
      data.active,
      data.name,
      storeSide
    );

    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      name: this.name,
    };
  }

  public toJson(): Record<string, any> {
    return {
      ref: this.ref ? this.ref.path : null,
      active: this.active,
      name: this.name,
    };
  }

  public static fromJson(
    json: Record<string, any>,
    db: Firestore
  ): ElementType {
    const documentRef: DocumentReference = doc(db, json.ref);
    return new ElementType(documentRef, json.active, json.name, false);
  }
}
