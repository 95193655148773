import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeChip = _resolveComponent("SeChip")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label) => {
    return (_openBlock(), _createBlock(_component_SeChip, {
      selected: _ctx.selection == label,
      text: label,
      onClick: () => _ctx.selectChip(label)
    }, null, 8, ["selected", "text", "onClick"]))
  }), 256))
}