import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6d0c05ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "stock-image-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SePlaceholder = _resolveComponent("SePlaceholder")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.searching)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.images, (image) => {
            return (_openBlock(), _createBlock(_component_SeImage, {
              click: _ctx.onImageClick,
              src: image,
              class: "stock-image"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SePlaceholder, { class: "stock-image" })
              ]),
              _: 2
            }, 1032, ["click", "src"]))
          }), 256))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.count, (id) => {
            return (_openBlock(), _createBlock(_component_SePlaceholder, { class: "stock-image" }))
          }), 256))
    ])
  ], 512)), [
    [_vShow, false]
  ])
}