import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  collection,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";
import * as DateUtils from "@/utils/DateUtils";
import DatabaseShopItem from "./DatabaseShopItem";
import * as Firebase from "@/firebase/Firebase";

export default class DistributorShopItem extends SecureatSnapshot {
  active: boolean;
  name: string;
  description: string;
  price: number;
  illustration: string | null;
  // transcient
  illustration_path: string | null = null;
  index: number;

  constructor(
    ref: DocumentReference,
    active: boolean,
    name: string,
    description: string,
    price: number,
    illustration: string | null,
    index: number
  ) {
    super(ref);
    this.active = active;
    this.name = name;
    this.description = description;
    this.price = price;
    this.illustration = illustration;
    this.index = index;
  }

  toDatabaseShopItem(databaseReference: DocumentReference): DatabaseShopItem {
    var databaseShopItem = new DatabaseShopItem(
      doc(collection(Firebase.firestore, databaseReference.path, "shop")),
      null,
      null,
      null,
      null,
      null,
      this.ref
    );

    databaseShopItem.setOriginValue(this);
    return databaseShopItem;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): DistributorShopItem {
    const data = snapshot.data()!;

    let result = new DistributorShopItem(
      snapshot.ref,
      data.active,
      data.name,
      data.description,
      data.price,
      data.illustration,
      data.index
    );
    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      name: this.name,
      description: this.description,
      price: this.price,
      illustration: this.illustration,
      index: this.index,
    };
  }
}
