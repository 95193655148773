import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import ElementType from "@/model/ElementType";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { DocumentReference, collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";

interface DatabaseElementState {
  initialElement: DatabaseElement | null;
  element: DatabaseElement | null;
  database: Database | null;
  selectedType: ElementType | null;
  creation: boolean;
  types: ElementType[];
  changeSaved: boolean;
}

export const getDatabaseElementState = defineStore("databaseElementState", {
  state: (): DatabaseElementState => {
    return {
      initialElement: null,
      element: null,
      database: null,
      selectedType: null,
      creation: false,
      types: [],
      changeSaved: false,
    };
  },
  actions: {
    async setup() {
      this.types = await ElementTypeManager.getDatabaseElementTypes(
        this.getDatabaseId()
      );
    },
    getDatabaseId() {
      return this.database?.ref?.id!;
    },
    set(element: DatabaseElement | null, database: Database) {
      this.selectedType = null;
      this.initialElement = element;
      this.database = database;
      this.creation = element == null;
      this.element = null;
      this.changeSaved = false;

      if (element !== null && element instanceof DatabaseElement) {
        this.element = DatabaseElement.clone(element, true);
        this.element.ref = element.ref;
      }

      if (this.creation) {
        this.element = new DatabaseElement(
          doc(collection(Firebase.firestore, database.ref.path, "elements"))
        );
      }

      console.log(this.element);
    },
  },
});
