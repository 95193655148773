
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  expose: ["getValue", "setValue", "getLineCount"],

  emits: ["update:modelValue"],
  name: "SeInput",

  props: {
    inputChange: null,
    placeholder: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    numberOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modelValue: null,
    };
  },

  methods: {
    getLineCount() {
      return this.getValue().split("\n").length;
    },
    onInput($event: any) {
      if (this.numberOnly) {
        let input = $event.target.value;

        let res = Number(input);

        if (isNaN(res)) {
          $event.target.value = $event.target.value.substring(
            0,
            $event.target.value.length - 1
          );
        }
      }
      this.$emit("update:modelValue", $event.target.value);

      if (this.inputChange != null) {
        this.inputChange(this);
      }
    },
    getValue(): string {
      return this.$el.value;
    },
    setValue(value: string): void {
      this.$el.value = value;
    },
  },
});
