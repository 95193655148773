import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import Store from "@/model/Store";
import * as DateUtils from "@/utils/DateUtils";

export default class Probe {
  probeUID: string;
  active: boolean;
  name: string;
  batteryLevel: number;
  maximumTemperature: number;
  minimumTemperature: number;
  criticalThreshold: number;
  notifyTemperature: boolean;
  postUrl: string;
  postingInterval: number;
  pushNotification: boolean;
  restartHooks: string;
  lastTemperature: number;
  lastTemperatureTimestamp: Date;
  storeId: string | null;

  constructor(
    probeUID: string,
    active: boolean,
    name: string,
    batteryLevel: number,
    maximumTemperature: number,
    minimumTemperature: number,
    criticalThreshold: number,
    notifyTemperature: boolean,
    postUrl: string,
    postingInterval: number,
    pushNotification: boolean,
    restartHooks: string,
    lastTemperature: number,
    lastTemperatureTimestamp: Date,
    storeId: string | null
  ) {
    this.active = active;
    this.probeUID = probeUID;
    this.name = name;
    this.batteryLevel = batteryLevel;
    this.maximumTemperature = maximumTemperature;
    this.minimumTemperature = minimumTemperature;
    this.criticalThreshold = criticalThreshold;
    this.notifyTemperature = notifyTemperature;
    this.postUrl = postUrl;
    this.postingInterval = postingInterval;
    this.pushNotification = pushNotification;
    this.restartHooks = restartHooks;
    this.lastTemperature = lastTemperature;
    this.lastTemperatureTimestamp = lastTemperatureTimestamp;
    this.storeId = storeId;
  }
  getProbeLastTimestamp() {
    return this.lastTemperature != null
      ? DateUtils.formatDateWithTime(this.lastTemperatureTimestamp)
      : "?";
  }
  getProbeLastTemperature() {
    return this.lastTemperature != null
      ? Math.round(this.lastTemperature) + " °C"
      : "?";
  }
  public isProbeWorking() {
    if (this.lastTemperature == null) {
      return false;
    }
    var now = new Date();

    let nowUtc = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );

    var lastTemp = new Date(this.lastTemperatureTimestamp);

    let diffInMilliseconds = nowUtc.getTime() - lastTemp.getTime();

    // Convert milliseconds to hours
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return diffInHours < 2;
  }

  static fromJson(data: any): Probe {
    return new Probe(
      data.probeUID,
      data.active,
      data.name,
      data.batteryLevel,
      data.maximumTemperature,
      data.minimumTemperature,
      data.criticalThreshold,
      data.notifyTemperature,
      data.postUrl,
      data.postingInterval,
      data.pushNotification,
      data.restartHooks,
      data.lastTemperature,
      data.lastTemperatureTimestamp,
      data.storeId
    );
  }
}
