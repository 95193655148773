import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import ElementType from "@/model/ElementType";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { DocumentReference, collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { getElementManager } from "@/firebase/ElementManager";
import * as ObjectUtils from "@/utils/ObjectUtils";

interface DatabaseElementState {
  database: Database | null;

  targetDatabase: Database | null;
  databases: Database[];

  elements: DatabaseElement[];
  displayedElements: DatabaseElement[];
  baseElements: DatabaseElement[];
  displayColumns: string[];
  types: ElementType[];
  search: string;
  preventReload: boolean;
  displayUnactives: boolean;
}

export const getBulkElementState = defineStore("BulkElementState", {
  state: (): DatabaseElementState => {
    return {
      targetDatabase: null,
      database: null,
      displayedElements: [],
      baseElements: [],
      databases: [],
      elements: [] as DatabaseElement[],
      displayColumns: [],
      types: [],
      search: "",
      preventReload: false,
      displayUnactives: false,
    };
  },
  actions: {
    hasChanges() {
      for (let i = 0; i < this.elements.length; i++) {
        if (i >= this.baseElements.length) {
          break;
        }
        this.baseElements[i].ref = this.elements[i].ref;
        if (
          !ObjectUtils.compareObjects(this.elements[i], this.baseElements[i])
        ) {
          return true;
        }
      }

      return false;
    },

    getTypeName(ref: DocumentReference) {
      let type = this.types.find((x) => x.ref.id == ref.id);

      if (type != undefined) {
        return type.name;
      }
      return "?";
    },
    async setup() {
      if (this.preventReload) {
        this.preventReload = false;
        return;
      }
      this.elements = await getElementManager()
        .getDatabaseElements(this.database.ref!.id)
        .map((x) => DatabaseElement.clone(x, true, true));

      this.baseElements = this.elements.map((x) =>
        DatabaseElement.clone(x, true, true)
      );

      await getDatabaseManager().initialize();

      this.databases = getDatabaseManager().getDatabases();

      this.types = await ElementTypeManager.getDatabaseElementTypes(
        this.database.ref.id!
      );
      this.displayedElements = this.elements;
    },

    set(database: Database) {
      this.database = database;
    },
  },
});
