
import { defineComponent } from "vue";
import NavHeader from "../../components/nav/NavHeader.vue";
import SearchBar from "../../components/global/SearchBar.vue";
import * as Lang from "@/i18n/lang";
import AuditingList from "../../components/auditing/AuditingList.vue";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getAuditManager } from "@/firebase/AuditManager";
import { Role } from "@/model/User";
import { getState } from "@/pinia/AppState";
import Database from "@/model/Database";
import { getAuditsState } from "@/pinia/audits/AuditsState";
import { getAuditConfigurationState } from "@/pinia/audits/AuditConfigurationState";

export default defineComponent({
  components: {
    AuditingList,
    NavHeader,
  },
  async setup() {
    var state = getAuditsState();

    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return { Lang: Lang };
  },
  methods: {
    openAuditingConfigurationPage() {
      getAuditConfigurationState().set(
        null,
        this.state.selectedDatabase?.ref?.id!
      );
      getNavigationState().navigate("auditingConfiguration");
    },
    isDistributor(): boolean {
      let role = getState().userData?.role;
      return (
        role == Role.Manager || role == Role.Admin || role == Role.Distributor
      );
    },
    async fetchAudits() {
      let role = getState().userData?.role;
      if (this.state.selectedDatabase?.ref?.id) {
        await this.state.setup();
        let audits = await getAuditManager().getAudits(
          role,
          this.state.selectedDatabase?.ref.id
        );
        getAuditConfigurationState().set(
          null,
          this.state.selectedDatabase?.ref?.id!
        );
        this.state.audits = audits;
      }
    },
  },
});
