import { defineStore } from "pinia";
import { Role } from "@/model/User";
import { getStoreManager } from "@/firebase/StoreManager";
import Distributor from "@/model/Distributor";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import { getDistributorManager } from "@/firebase/DistributorManager";
import Probe from "@/model/Probe";
import { getProbesManager } from "@/firebase/ProbeManager";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import { getShopManager } from "@/firebase/ShopManager";
import DatabaseShopItem from "@/model/Shop/DatabaseShopItem";

interface ShopState {
  distributor: Distributor | null;
  shopItems: DistributorShopItem[];
  databaseShopItems: Map<Database, DatabaseShopItem[]> | null;
  displayUnactives: boolean;
}

export const getShopState = defineStore("ShopState", {
  state: (): ShopState => {
    return {
      distributor: null,
      shopItems: [],
      databaseShopItems: null,
      displayUnactives: false,
    };
  },
  actions: {
    async initDatabaseShops() {
      this.databaseShopItems = new Map();

      for (let database of getDatabaseManager().getDatabases()) {
        let databaseShopItem = await getShopManager().getDatabaseShop(database);
        this.databaseShopItems.set(database, databaseShopItem);
      }
    },
    async setup() {
      await getDatabaseManager().initialize();
      await getDistributorManager().initialize();

      this.distributor =
        await getDistributorManager().getDistributorByReference(
          getState().userData.distributor
        );

      this.shopItems = await getShopManager().getShop(this.distributor!.ref!);
    },
  },
});
