import ElementType from "@/model/ElementType";
import Store from "@/model/Store";
import { getState } from "@/pinia/AppState";
import {
  DocumentReference,
  WriteBatch,
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";

export async function updateElementType(type: ElementType) {
  await setDoc(type.ref!, type.toFirestore());
}
export async function createDatabaseElementType(
  databaseId: string,
  typeName: string
) {
  let col = collection(
    Firebase.firestore,
    "databases/" + databaseId + "/element_types"
  );

  let type: ElementType = new ElementType(doc(col), true, typeName, false);

  let ref = await addDoc(col, type.toFirestore());

  type.ref = ref;
  return type;
}

export async function createStoreElementType(
  store: Store,
  typeName: string,
  batch: WriteBatch | null = null
) {
  let path = "stores/" + store.ref!.id + "/element_types";

  let col = collection(Firebase.firestore, path);

  let type: ElementType = new ElementType(doc(col), true, typeName, true);
  let ref: DocumentReference;

  if (batch === null) {
    ref = await addDoc(col, type.toFirestore());
  } else {
    ref = doc(col);
    await batch.set(ref, type.toFirestore());
  }
  type.ref = ref;
  return type;
}

export async function getAllElementsTypes(store: Store, databaseId: string) {
  let storeTypes = await getStoreElementTypes(store);

  let databaseTypes = await getDatabaseElementTypes(databaseId);

  let results = storeTypes.concat(databaseTypes);

  return results.sort((a, b) => a.name.localeCompare(b.name));
}

export async function getStoreElementTypes(store: Store) {
  let q = query(
    collection(Firebase.firestore, "stores/" + store.ref!.id + "/element_types")
  );

  const snapshot = await getDocs(q);

  let storeTypes = snapshot.docs.map((x) => ElementType.fromFirestore(x, true));

  return storeTypes.sort((a, b) => a.name.localeCompare(b.name));
}

export async function getDatabaseElementTypes(databaseId: string) {
  let q = query(
    collection(Firebase.firestore, "databases/" + databaseId + "/element_types")
  );

  const snapshot = await getDocs(q);

  let databaseTypes = snapshot.docs.map((x) =>
    ElementType.fromFirestore(x, false)
  );
  return databaseTypes.sort((a, b) => a.name.localeCompare(b.name));
}
