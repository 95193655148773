
import { defineComponent } from "@vue/runtime-core";
import { nextTick } from "vue";

export default defineComponent({
  expose: [
    "getValue",
    "getSelectedIndex",
    "setIndex",
    "hideIndex",
    "unhideIndex",
    "selectFirstIndex",
  ],
  props: ["labels", "onSwitch", "icons"],

  methods: {
    getSelectedIndex() {
      return this.selectedIndex;
    },
    getValue() {
      return this.labels[this.selectedIndex];
    },

    setIndex(index: number) {
      var label = this.labels[index];
      this.changeTab(label);
    },

    unhideIndex(index: number) {
      let indexOf = this.hiddenIndexes.indexOf(index);

      if (indexOf != -1) {
        this.hiddenIndexes.splice(indexOf, 1);
      }
      this.selectFirstIndex();
    },
    hideIndex(index: number) {
      if (!this.hiddenIndexes.includes(index)) {
        this.hiddenIndexes.push(index);
      }

      this.selectFirstIndex();
    },

    resizeIndicator() {
      const tabs = document.querySelectorAll(".tab");
      let element: any = tabs[this.selectedIndex];
      const indicator: HTMLElement = document.querySelector(".indicator");
      indicator.style.width = `${element.offsetWidth}px`;
      indicator.style.left = `${element.offsetLeft}px`;
    },

    selectFirstIndex() {
      for (let i = 0; i < this.labels.length; i++) {
        if (!this.hiddenIndexes.includes(i)) {
          this.setIndex(i);
          break;
        }
      }
    },

    changeTab(label: string) {
      var refs = <any>this.$refs;

      let index = this.labels.indexOf(label);
      this.selectedIndex = index;

      const tabs = document.querySelectorAll(".tab");

      let element: any = tabs[index];

      tabs.forEach((tab: any) => tab.classList.remove("active"));
      element.classList.add("active");

      this.resizeIndicator();

      const containerWidth = refs.tabContainer.offsetWidth;
      const elementWidth = element.offsetWidth;
      const elementOffsetLeft = element.offsetLeft;

      const scrollPosition =
        elementOffsetLeft - containerWidth / 2 + elementWidth / 2;

      refs.tabContainer.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      if (this.onSwitch != undefined) {
        this.onSwitch(index);
      }
    },
  },

  data() {
    return {
      hiddenIndexes: [] as Number[],
      selectedIndex: 0,
    };
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeIndicator);
  },

  mounted() {
    this.selectFirstIndex();
    window.addEventListener("resize", this.resizeIndicator);
  },
});
