import {
  collection,
  query,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  documentId,
  orderBy,
  updateDoc,
  DocumentReference,
  WriteBatch,
} from "firebase/firestore";

import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import Task from "@/model/Task";
import * as DateUtils from "@/utils/DateUtils";

import { getState } from "../pinia/AppState";
import Employee from "@/model/Employee";
import Store from "@/model/Store";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import TaskResult from "@/model/TaskResults/TaskResult";
import TemperatureResult from "@/model/TaskResults/TemperatureResult";
import { getElementManager } from "@/firebase/ElementManager";
import TraceabilityResult from "@/model/TaskResults/TraceabilityResult";
import { ModuleEnum } from "@/model/ModuleEnum";
import CleaningResult from "@/model/TaskResults/CleaningResult";
import OilResult from "@/model/TaskResults/OilResult";
import * as StringUtils from "@/utils/StringUtils";
import dateFormat from "dateformat";
import * as ModuleManager from "@/firebase/ModuleManager";
import TemperatureControlResult from "@/model/TaskResults/TemperatureControlResult";
import TaskType from "@/model/Tasks/TaskType";
import * as Firebase from "@/firebase/Firebase";
import PictureResult from "@/model/TaskResults/PictureResult";

//stage
export async function getTasksByDay(store: Store, date: Date): Promise<Task[]> {
  const begin = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0
  );
  const end = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59
  );

  return await getTasksBetweenDate(store, begin, end);
}

export async function getTasksBetweenDate(
  store: Store,
  begin: Date,
  end: Date
): Promise<Task[]> {
  let results: Task[] = [];

  const tasksCollectionRef = collection(
    Firebase.firestore,
    "/stores/" + store.ref!.id + "/tasks"
  );

  const q = query(
    tasksCollectionRef,
    where("start", ">=", begin),
    where("start", "<=", end)
  );

  let querySnapshot = await getDocs(q);

  let tasks = querySnapshot!.docs.map((x) => Task.fromFirestore(x));

  for (let task of tasks) {
    task.store = store;
  }
  results = results.concat(tasks);

  return results;
}

export function getTaskDateString(task: Task) {
  if (task.completed_timestamp != null) {
    return dateFormat(
      DateUtils.getDateFromSeconds(task.completed_timestamp.seconds),
      "dd/mm/yyyy HH:MM"
    );
  } else {
    return getTaskStartString(task);
  }
}
export function getTaskStartString(task: Task) {
  return dateFormat(
    DateUtils.getDateFromSeconds(task.start.seconds),
    "dd/mm/yyyy HH:MM"
  );
}

export async function getTask(ref: DocumentReference) {
  const snapshot = await getDoc(ref);
  let task: Task = Task.fromFirestore(snapshot);
  return task;
}
export async function getTaskResults(
  task: Task,
  store: Store
): Promise<TaskResult[]> {
  let results: TaskResult[] = [];

  if (task.result == undefined || task.result == null) {
    return results;
  }

  if (task.module == ModuleEnum.Temperatures) {
    for (let temperature of task.result.temperatures) {
      let element = getElementManager().getElementByReference(
        temperature.element,
        store
      );
      let temperatureResult: TemperatureResult = new TemperatureResult(
        task.result.details,
        task.result.status,
        task.result.timestamp,
        element,
        temperature.temperature,
        temperature.first_temperature,
        temperature.comment,
        temperature.pictures == undefined
          ? []
          : temperature.pictures.map((x: any) => PictureResult.fromFirebase(x))
      );

      results.push(temperatureResult);
    }
  } else if (task.module == ModuleEnum.Traceability) {
    for (let traceability of task.result.traceability) {
      let element = await getElementManager().getElementByReference(
        traceability.element,
        store
      );

      let traceabilityResult: TraceabilityResult = new TraceabilityResult(
        task.result.details,
        task.result.status,
        task.result.timestamp,
        element,
        traceability.pictures.map((x: any) => PictureResult.fromFirebase(x))
      );

      results.push(traceabilityResult);
    }
  } else if (task.module == ModuleEnum.Action) {
    for (let action of task.result.actions) {
      let actionResult: CleaningResult = new CleaningResult(
        action.action,
        action.details,
        action.done,
        action.employee,
        action.firebase_path,
        action.local_path,
        action.requires_picture,
        action.status,
        action.timestamp,
        action.uploadTimestamp,
        action.url
      );

      results.push(actionResult);
    }
  } else if (task.module == ModuleEnum.Oils) {
    for (let result of task.result.oils) {
      let element = await getElementManager().getElementByReference(
        result.element,
        store
      );

      let oilResult: OilResult = new OilResult(
        result.cleaned,
        element,
        result.oil_changed,
        result.oil_filtered,
        result.filter_changed,
        result.polarity,
        result.temperature
      );

      results.push(oilResult);
    }
  } else if (task.module == ModuleEnum.TemperatureControl) {
    for (let res of task.result.elements) {
      let element = await getElementManager().getElementByReference(
        res.element,
        store
      );
      let result = new TemperatureControlResult(
        element,
        res.target_temperature == undefined ? null : res.target_temperature,
        res.start_temperature == undefined ? null : res.start_temperature,
        res.start_timestamp == undefined ? null : res.start_timestamp,
        res.start_employee == undefined ? null : res.start_employee,
        res.end_temperature == undefined ? null : res.end_temperature,
        res.end_timestamp == undefined ? null : res.end_timestamp,
        res.end_employee == undefined ? null : res.end_employee,
        res.comment == undefined ? null : res.comment,
        task.result.mode
      );
      results.push(result);
    }
  }

  return results;
}

export async function searchTasks(
  ingredientSearch: string,
  start: Date,
  end: Date,
  stores: Store[],
  modules: ModuleEnum[]
): Promise<Task[]> {
  if (modules.length == 0) {
    return [];
  }

  var moduleRefs = modules.map((x) => ModuleManager.getModuleFromEnum(x).ref);

  let results: Task[] = [];

  start.setHours(0);
  start.setMinutes(0);

  end.setHours(23);
  end.setMinutes(59);

  for (let store of stores) {
    await getElementManager().ensureDatabaseElementListener(
      store.database?.id!
    );

    await getElementManager().ensureStoreElementListener(store.ref?.id!);

    let taskQuery = query(
      collection(Firebase.firestore, "stores/" + store.ref?.id + "/tasks"),
      where("completed_timestamp", "!=", null),
      where("completed_timestamp", ">=", start),
      where("completed_timestamp", "<=", end),
      where("module", "in", moduleRefs)
    );

    let snapshot = await getDocs(taskQuery);

    let tasks = snapshot!.docs.map((x) => Task.fromFirestore(x));

    for (let task of tasks) {
      task.store = store;
    }
    results = results.concat(tasks);
  }

  if (ingredientSearch != "" && ingredientSearch != null) {
    let filterResults: Task[] = [];

    for (let task of results) {
      if (task.module == ModuleEnum.Traceability) {
        for (let result of task.result.traceability) {
          let element = getElementManager().getElementByReference(
            result.element!,
            task.store!
          );

          if (
            ingredientSearch
              .toLowerCase()
              .includes(element.getName()!.toLowerCase())
          ) {
            filterResults.push(task);
          }
        }
      }
    }
    return filterResults;
  }

  return results;
}

export function getExpectedCompletionDate(task: Task) {
  let start = DateUtils.getDateFromSeconds(task.start.seconds);
  let end = new Date(start.getTime() + task.duration * 60000);
  return end;
}

export async function getEmployeeFromTask(
  task: Task
): Promise<Employee | null> {
  if (task.completed_employee == null) {
    return null;
  }

  let document = await getDoc(task.completed_employee!);
  return Employee.fromFirestore(document);
}
export async function getEmployee(ref: DocumentReference) {
  let document = await getDoc(ref);
  return Employee.fromFirestore(document);
}
export function isTaskDoneLate(task: Task) {
  let endDate = DateUtils.getDateFromSeconds(
    task.start.seconds + task.duration * 60
  );

  let completionDate = DateUtils.getDateFromSeconds(
    task.completed_timestamp!.seconds
  );

  return completionDate > endDate;
}

export function isCleaningStepDone(task: Task): boolean {
  if (task.result == undefined) {
    return false;
  }
  for (let step of task.result.actions) {
    if (step.done == false) {
      return false;
    }
  }
  return true;
}
export function getTaskStatus(task: Task) {
  if (task.module == ModuleEnum.Action && isCleaningStepDone(task)) {
    return "done";
  }
  if (
    task.module != ModuleEnum.Action &&
    task.completed_timestamp != undefined
  ) {
    return "done";
  }
  let end = getExpectedCompletionDate(task);
  if (new Date() > end) {
    return "late";
  }

  return "on-time";
}
