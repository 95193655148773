
import { PropType, defineComponent, nextTick } from "vue";
import Communication from "@/model/Communication/Communication";
import { CommunicationState } from "@/model/Communication/CommunicationState";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  data() {
    return {
      Lang: Lang,
      CommunicationState: CommunicationState,
    };
  },
  props: {
    communication: {
      type: Communication,
      required: true,
    },
  },
  methods: {},
});
