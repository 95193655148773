export default class ProbeTemperature {
  probeUId: string;
  timestamp: Date;
  temperature: string;
  batteryLevel: string;

  constructor(
    probeUId: string,
    timestamp: Date,
    temperature: string,
    batteryLevel: string
  ) {
    this.probeUId = probeUId;
    this.timestamp = timestamp;
    this.temperature = temperature;
    this.batteryLevel = batteryLevel;
  }

  static fromJson(data: any): ProbeTemperature {
    return new ProbeTemperature(
      data.probeUId,
      new Date(data.timestamp),
      data.temperature,
      data.batteryLevel
    );
  }
}
