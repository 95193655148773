import { Ref, ref } from "vue";
import Attachment from "../Filesystem/Attachment";

export default class UploadStrategy {
  public progress: Ref<number> = ref(0);

  public file: File;

  constructor(file: File) {
    this.file = file;
  }
  public async prepare(): Promise<void> {}

  public upload(done: Function): void {}

  public getFileName(): string | null {
    return null;
  }

  public generateAttachment(): Attachment | null {
    return null;
  }
}
