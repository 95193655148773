import dateFormat from "dateformat";
import {
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import { Answer } from "./Answer";

export class Result extends SecureatSnapshot {
  public toFirestore() {
    throw new Error("Method not implemented.");
  }
  auditor: any;
  start_date: Timestamp;
  answers: Answer[];
  end_date: Timestamp;
  store: DocumentReference | null;

  constructor(
    ref: DocumentReference,
    auditor: string,
    start_date: Timestamp,
    answers: Answer[] = [],
    end_date: Timestamp,
    store: DocumentReference | null
  ) {
    super(ref);
    this.auditor = auditor;
    this.start_date = start_date;
    this.answers = answers;
    this.end_date = end_date;
    this.store = store;
  }

  static fromFirestore(snapshot: DocumentSnapshot): Result {
    const data = snapshot.data()!;

    let answers = data.answers.map((answer: any) => new Answer(answer));
    let store = null;
    if (data.store) {
      store = data.store;
    }

    let result = new Result(
      snapshot.ref,
      data.auditor,
      data.start_date,
      answers,
      data.end_date,
      store
    );

    return result;
  }

  calculateAnswersAmount(): number {
    let totalAnswers = 0;

    this.answers.forEach((answer) => {
      if (answer.toPDF() !== "") totalAnswers++;
    });

    return totalAnswers;
  }

  getEndDateFormat(seconds: boolean = false) {
    if (seconds) {
      return dateFormat(this.end_date.toDate(), "dd/mm/yyyy HH:MM:ss");
    } else {
      return dateFormat(this.end_date.toDate(), "dd/mm/yyyy HH:MM");
    }
  }
}
