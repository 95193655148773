import {
  collection,
  query,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  documentId,
  updateDoc,
  DocumentReference,
  orderBy,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";
import Module from "@/model/Module";
import { ModuleEnum } from "@/model/ModuleEnum";
import Store from "@/model/Store";
import Database from "@/model/Database";
import * as Firebase from "@/firebase/Firebase";

let modules: Map<ModuleEnum, Module> = new Map();

export function getModules() {
  return modules.values();
}
export async function loadModules() {
  let state = getState();
  const query = await getDocs(collection(Firebase.firestore, "modules"));
  let datas = query.docs.map((x) => Module.fromFirestore(x));

  for (let moduleEnum of Object.values(ModuleEnum)) {
    var data = datas.find((x) => x.key == moduleEnum);

    if (moduleEnum == ModuleEnum.Action) {
      var cleaningData = datas.find((x) => x.key == "cleaning");
      if (cleaningData != undefined) {
        cleaningData.key = "actions";
        cleaningData.name = "Actions";
        modules.set(ModuleEnum.Action, cleaningData);
        continue;
      }
    }
    if (data) {
      modules.set(<ModuleEnum>moduleEnum, data);
    } else {
      console.log("Unable to retreive module " + moduleEnum);
    }
  }
}

export function getModuleFromRef(ref: DocumentReference): Module | null {
  for (const [moduleEnum, module] of modules.entries()) {
    if (module.ref?.path === ref.path) {
      return module;
    }
  }
  return null;
}

export function getModuleFromIndex(index: number): Module {
  var i = 0;
  for (let module of modules) {
    if (i == index) {
      return module[1];
    }
    i++;
  }

  throw Error("Invalid module index. Unable to get module enum");
}
export function getModuleFromEnum(moduleEnum: ModuleEnum): Module {
  return modules.get(moduleEnum)!;
}

export function isModuleActivated(store: Store, module: ModuleEnum) {
  for (let hiddenModule of store.hidden_modules) {
    let mod = getModuleFromRef(hiddenModule);
    if (mod?.key == module.toString()) {
      return false;
    }
  }

  return true;
}
export function isModuleActivatedDatabase(
  database: Database,
  module: ModuleEnum
) {
  for (let hiddenModule of database.hidden_modules) {
    let mod = getModuleFromRef(hiddenModule);
    if (mod?.key == module.toString()) {
      return false;
    }
  }

  return true;
}

export function getModuleEnums(): any {
  let results: ModuleEnum[] = [];

  for (let module of modules) {
    results.push(module[1].toEnum());
  }

  return results;
}
