
import { getStoreManager } from "../firebase/StoreManager";
import { getState } from "../pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { defineComponent } from "@vue/runtime-core";
import NavPanel from "../components/nav/NavPanel.vue";
import NavHeader from "../components/nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import StoreList from "../components/stores/StoreList.vue";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import Store from "../model/Store";
import * as Lang from "@/i18n/lang";
import { getDistributorManager } from "@/firebase/DistributorManager";
import Database from "@/model/Database";
import { Role } from "@/model/User";
import * as TimezoneUtils from "@/utils/TimezoneUtils";
import { doc, DocumentReference, collection, getDoc } from "firebase/firestore";
import Distributor from "@/model/Distributor";
import SeCountryDropdown from "@/components/global/SeCountryDropdown.vue";
import { getStoresState } from "@/pinia/stores/StoresState";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import SeDropdownItem from "@/components/global/dropdown/SeDropdownItem.vue";
import * as Firebase from "@/firebase/Firebase";

export default defineComponent({
  components: {
    StoreList,
    SeCountryDropdown,
    SeDropdown,
    SeDropdownItem,
  },
  mounted() {
    let refs = this.$refs as any;
    refs.searchBar.setSearch(this.state.search);
    (this.$refs.formCheckLabelExists as any).style.display = "none";
    (this.$refs.formCheckLabel as any).style.display = "none";
  },
  async setup() {
    let state = getStoresState();

    await state.setup();

    return {
      state: state,
    };
  },
  methods: {
    displayUnactivesChanged(flag: boolean) {
      this.state.displayUnactives = flag;
    },
    async onDistributorChange(distributor: Distributor) {
      var refs = <any>this.$refs;

      if (distributor != null && distributor.countries.length > 0) {
        refs.countrySelect.selectCountryCode(distributor.countries[0]);
      } else {
        refs.countrySelect.clear();
      }

      if (distributor != null) {
        this.state.databases =
          await getDatabaseManager().getDistributorDatabases(distributor.ref!);
      }

      refs.databaseSelect.clear();
    },
    searchChange(value: string) {
      this.state.search = value;
    },
    canSetDistributor() {
      return getState().userData!.role == Role.Admin;
    },

    canCreateStore() {
      let userRole = getState().userData!.role;
      let flag = userRole == Role.Distributor || userRole == Role.Admin;
      return flag;
    },
    openStoreModal() {
      let refs = <any>this.$refs;
      this.state.selectedDistributor = null;
      this.state.selectedDatabase = null;
      refs.name.setValue("");
      refs.address.setValue("");
      refs.postalCode.setValue("");
      refs.city.setValue("");

      ((this as any).$refs.storeModal as any).open();
    },
    async addStore() {
      let refs = this.$refs as any;

      let timezone = refs.countrySelect.getTimezone();
      let name: string = refs.name.getValue();
      let address: string = refs.address.getValue();
      let postalCode: string = refs.postalCode.getValue();
      let city: string = refs.city.getValue();
      let creationDate: Date = new Date();

      let utcTimezone = this.state.selectedUtc;

      var country = refs.countrySelect.getCountry();
      if (
        name == "" ||
        address == "" ||
        postalCode == "" ||
        city == "" ||
        country == null ||
        (this.state.selectedDistributor == null && this.canSetDistributor())
      ) {
        (this.$refs.formCheckLabel as any).style.display = "";
        (this.$refs.formCheckLabelExists as any).style.display = "none";
        return;
      }
      let canCreateResult = !this.manager.listener!.items!.some((store) => {
        let isSameAddress =
          address == store.address &&
          postalCode == store.postal_code &&
          city == store.city &&
          country.code == store.country;

        if (store.name === name || isSameAddress) {
          (this.$refs.formCheckLabelExists as any).style.display = "";
          (this.$refs.formCheckLabel as any).style.display = "none";
          return true;
        }

        return false;
      });

      if (!canCreateResult) return;

      let distributor: Distributor | null = null;

      if (this.canSetDistributor()) {
        distributor = this.state.selectedDistributor;
      } else {
        distributor = await getDistributorManager().getDistributorByReference(
          getState().userData!.distributor!
        );
      }

      let store: Store = new Store(
        doc(collection(Firebase.firestore, "stores")),
        true,
        address,
        city,
        creationDate,
        this.state.selectedDatabase == null
          ? null
          : this.state.selectedDatabase.ref,
        distributor!.ref!,
        name,
        null,
        postalCode,
        false,
        timezone,
        0,
        [],
        false,
        false,
        country.code,
        null,
        null,
        utcTimezone,
        []
      );

      await store.set();

      (this.$refs.formCheckLabelExists as any).style.display = "none";
      (this.$refs.formCheckLabel as any).style.display = "none";

      refs.storeModal.close();
    },
  },
  data() {
    let manager = getStoreManager();

    return {
      getDistributorManager: getDistributorManager,
      Lang: Lang,
      manager,
      utcs: TimezoneUtils.UTCS,
    };
  },
});
