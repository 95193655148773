import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "text-muted",
  style: {"font-weight":"bold"}
}
const _hoisted_2 = {
  class: "text-muted",
  style: {"font-weight":"bold"}
}
const _hoisted_3 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.postponedDate), 1),
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.period), 1),
    (_ctx.task.employee != null)
      ? (_openBlock(), _createElementBlock("td", _hoisted_3, _toDisplayString(_ctx.task.employee?.name) + " " + _toDisplayString(_ctx.task.employee?.surname), 1))
      : _createCommentVNode("", true)
  ]))
}