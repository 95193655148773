import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-410c1ee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "se-inputfile bold" }
const _hoisted_2 = ["accept"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      accept: _ctx.accept,
      type: "file"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", { src: _ctx.icon }, null, 8, _hoisted_3),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}