
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: ["on", "off", "modelValue", "defaultValue"],
  expose: ["toggle", "toggled"],
  emits: ["update:modelValue"],

  data() {
    return {
      value: this.modelValue,
    };
  },
  methods: {
    onClick() {
      this.toggle(!this.toggled());
    },
    toggled() {
      return this.value;
    },
    toggle(active: boolean) {
      this.value = active;
      this.$emit("update:modelValue", active);
    },
  },
});
