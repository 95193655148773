
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Question, QuestionType } from "@/model/Auditing/Question";
import { QuestionCondition } from "@/model/Auditing/QuestionCondition";
import Audit from "@/model/Auditing/Audit";
import { truncateText } from "@/utils/StringUtils";

export default defineComponent({
  props: {
    localAudit: {
      type: Audit,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Lang: Lang,
      selectedConditionalQuestion: {} as Question | null,
      selectedCondition: {} as QuestionCondition,
      toggleQuestionCondition: false,
      truncateText: truncateText,
    };
  },
  emits: ["close", "submit"],
  expose: ["openModal"],
  computed: {
    booleanQuestions(): Question[] {
      const booleanQuestions = [];
      let conditionIds = [] as string[];
      if (
        this.selectedQuestion.conditions &&
        this.selectedQuestion.conditions.length > 0
      ) {
        conditionIds = this.selectedQuestion.conditions.map(
          (condition: QuestionCondition) => condition.question_id
        );
      }

      for (const section of this.localAudit.sections) {
        for (const question of section.questions) {
          if (
            question.type === QuestionType.YesOrNo &&
            !conditionIds.includes(question.id)
          ) {
            booleanQuestions.push(question);
          }
        }
      }
      if (booleanQuestions.length <= 0) {
        this.selectedConditionalQuestion = null;
      }
      return booleanQuestions;
    },
  },
  methods: {
    onQuestionSelect(question: Question) {
      this.selectedConditionalQuestion = question;
      let refs = <any>this.$refs;
      refs.addQuestionModal.close();
      this.addCondition();
    },

    selectFirstCondition() {
      if (this.selectedQuestion.hasConditions()) {
        this.selectedCondition = this.selectedQuestion.conditions[0];
        this.toggleQuestionCondition =
          this.selectedQuestion.conditions[0].value;
      } else {
        this.selectedCondition = {} as QuestionCondition;
        this.toggleQuestionCondition = false;
      }
    },

    openModal() {
      let refs = <any>this.$refs;
      refs.addConditionModal.open();
      this.selectedConditionalQuestion = null;
      this.selectFirstCondition();
    },

    openQuestionModal() {
      let refs = <any>this.$refs;
      refs.addQuestionModal.open();
    },

    closeModal(modalRef: any) {
      modalRef.close();
    },

    addCondition() {
      this.toggleQuestionCondition = false;

      let newCondition = this.selectedQuestion.addCondition(
        this.selectedConditionalQuestion!.id,
        false
      );

      this.selectedCondition = newCondition;
      this.selectedConditionalQuestion = null;
    },

    deleteCondition(conditionId: string) {
      this.selectedQuestion.deleteCondition(conditionId);
      this.selectFirstCondition();
    },

    onToggleConditionValue() {
      let newState = !this.toggleQuestionCondition;
      this.toggleQuestionCondition = newState;
      this.selectedCondition.value = newState;
    },

    onSelectedConditionChange(condition: QuestionCondition) {
      this.selectedCondition = condition;
      this.toggleQuestionCondition = condition.value;
    },
  },
});
