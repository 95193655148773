
import { defineComponent } from "@vue/runtime-core";
import "vue3-carousel/dist/carousel.css";
import * as Modals from "@/utils/Modals";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import * as Device from "@/utils/Device";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: ["result"],
  methods: {
    displayPictures() {
      Modals.openCarousel(this.result.pictures.map((x) => x.url));
    },
  },
});
