
import * as UserManager from "../firebase/UserManager";
import { defineComponent } from "vue";
import NavHeader from "../components/nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import * as Lang from "@/i18n/lang";
import AccountList from "../components/accounts/AccountList.vue";
import { getState } from "@/pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import Distributor from "@/model/Distributor";
import User from "@/model/User";
import DocumentExplorer from "@/components/documents/DocumentExplorer.vue";
import { getStoreManager } from "@/firebase/StoreManager";
import * as Filesystem from "@/firebase/Filesystem";
import { stringify } from "querystring";
import CloudItem from "@/model/Filesystem/CloudItem";
import * as StoreManager from "@/firebase/StoreManager";
import { CloudItemType } from "@/model/Filesystem/CloudItemType";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { Role } from "@/model/User";
import Database from "@/model/Database";
import { getDistributorManager } from "@/firebase/DistributorManager";

export default defineComponent({
  data() {
    return {
      Lang: Lang,
    };
  },

  async mounted() {
    let refs = <any>this.$refs;

    refs.documentExplorer.updateDocuments();
  },

  async setup() {
    let state = getState();

    let role = state.userData?.role;

    let databaseDocs = [];

    let storeDocs = [];

    let storeManager = getStoreManager();

    let databaseManager = getDatabaseManager();

    await storeManager.initialize();

    await databaseManager.initialize();
    let databases = databaseManager.getDatabases();

    let distributorManager = getDistributorManager();

    await distributorManager.initialize();

    if (
      role == Role.Distributor ||
      role == Role.Admin ||
      role == Role.Manager
    ) {
      if (role != Role.Distributor) {
        for (let database of databases) {
          let item = new CloudItem(
            database.name,
            CloudItemType.Folder,
            "secureat_cloud/databases/" + database.ref?.id!,
            database?.illustration_url != null
              ? database.illustration_url
              : "/ico/database.svg",
            1
          );

          item.forceReadonly = true;
          databaseDocs.push(item);
        }
      }

      for (let distributor of distributorManager.getDistributors()) {
        let itemDistributor = new CloudItem(
          distributor.name,
          CloudItemType.Folder,
          "secureat_cloud/distributors/" + distributor.ref!.id,
          distributor.logo != null
            ? distributor.logo
            : "/ico/distributors2.svg",
          1,
          []
        );
        itemDistributor.forceReadonly = true;
        databaseDocs.push(itemDistributor);
      }
    }

    if (role == Role.Customer || role == Role.Admin) {
      let stores = storeManager.getStores(false);
      for (let store of stores) {
        let additional: CloudItem[] = [];

        if (store.database != null) {
          var database = databaseManager.getDatabaseByReferenceFromCache(
            store.database!
          );

          let item = new CloudItem(
            database?.name!,
            CloudItemType.Folder,
            "secureat_cloud/databases/" + store.database.id,
            database?.illustration_url != null
              ? database.illustration_url
              : "/ico/database.svg",
            1,
            []
          );

          let distributor = await distributorManager.getDistributorByReference(
            store.distributor
          );

          item.forceReadonly = true;
          additional.push(item);

          let itemDistributor = new CloudItem(
            distributor.name,
            CloudItemType.Folder,
            "secureat_cloud/distributors/" + store.distributor.id,
            distributor.logo != null
              ? distributor.logo
              : "/ico/distributor.svg",
            1,
            []
          );

          itemDistributor.forceReadonly = true;

          additional.push(itemDistributor);
        }
        let doc = new CloudItem(
          store.name,
          CloudItemType.Folder,
          "secureat_cloud/stores/" + store.ref?.id!,
          store.illustration_url != null
            ? store.illustration_url
            : "/ico/store.svg",
          1,
          additional
        );

        doc.forceReadonly = true;

        storeDocs.push(doc);
      }
    }

    return {
      rootDocuments: storeDocs.concat(databaseDocs),
    };
  },
  methods: {},
  components: {
    DocumentExplorer,
    SearchBar,
  },
});
