
import { defineComponent } from "@vue/runtime-core";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Lang from "@/i18n/lang";
import ElementSelectList from "../databases/task_configurations/ElementSelectList.vue";

export default defineComponent({
  props: {
    items: null as any,
    labelFunction: null as any,
    search: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
      required: false,
    },
    addCallback: {
      type: Function,
      default: null,
      required: false,
    },
  },
  expose: [
    "getSelectedItems",
    "setSelectedIndexes",
    "getSelectedIndexes",
    "setSelectedItems",
  ],
  data() {
    return {
      Lang: Lang,
      selectedIndexes: [] as number[],
    };
  },

  mounted() {
    let selected = this.getSelected();

    this.updateTitle();

    let select = this.getSelect();
    let caret = this.getCaret();
    let menu = this.getMenu();

    document.addEventListener("click", (event: MouseEvent) => {
      let target = <HTMLElement>event.target;

      if (!DOMUtils.isDescendantOf(target, this.$el)) {
        select.classList.remove("selected-clicked");
        caret.classList.remove("caret-rotate");
        menu.classList.remove("menu-open");
      }
    });

    if (menu.offsetWidth < this.$el.offsetWidth) {
      menu.style.width = this.$el.offsetWidth + "px";
    }

    let dropdown = this.$el;
    const dropdownRect = dropdown.getBoundingClientRect();

    const spaceBelow = Math.round(window.innerHeight - dropdownRect.bottom);

    this.$el.querySelector(".menudf").style.maxHeight = spaceBelow + "px";
  },
  methods: {
    toggleSelectAll() {
      if (this.items.length == this.selectedIndexes.length) {
        this.setSelectedIndexes([]);
      } else {
        this.setSelectedItems(this.items);
      }
    },
    onSearchChange(value: string) {
      var menuContent = this.$el.querySelector(".menudf");

      var width = menuContent.offsetWidth;

      for (let item of menuContent.childNodes) {
        if (item.tagName == "LI") {
          if (!item.innerText.toLowerCase().includes(value.toLowerCase())) {
            item.style.display = "none";
          } else {
            item.style.display = "flex";
          }
        }
      }

      menuContent.style.width = width + "px";
    },
    updateTitle() {
      let selected = this.getSelected();

      var selection = [];
      var selectedItems = this.getSelectedItems();
      for (let item of this.items) {
        if (this.getSelectedItems().includes(item)) {
          selection.push(item);
        }
      }

      if (selection.length > 0) {
        if (selection.length == 1) {
          selected.innerText = this.getLabel(selection[0]);
        } else {
          selected.innerText =
            this.getLabel(selection[0]) + " + " + (selection.length - 1);
        }
      } else {
        selected.innerText = Lang.getI18N("no-selection");
      }
    },
    getSelected(): HTMLElement {
      return this.$el.querySelector(".selected");
    },
    getSelect(): HTMLElement {
      return this.$el.querySelector(".select");
    },
    getOptions(): HTMLElement[] {
      return this.$el.querySelectorAll(".menudf li") as HTMLElement[];
    },
    getCaret(): HTMLElement {
      return this.$el.querySelector(".caret");
    },
    getMenu(): HTMLElement {
      return this.$el.querySelector(".menudf");
    },
    onSelectClick() {
      let select = this.getSelect();
      let caret = this.getCaret();
      let menu = this.getMenu();

      select.classList.toggle("select-clicked");
      caret.classList.toggle("caret-rotate");
      menu.classList.toggle("menu-open");

      if (menu.offsetWidth < this.$el.offsetWidth) {
        menu.style.width = this.$el.offsetWidth + "px";
      }
    },
    getLabel(item: any) {
      if (this.labelFunction != null) {
        let result = this.labelFunction(item);
        return result;
      } else {
        return item;
      }
    },
    optionClick(item: any) {
      let index = this.items.indexOf(item);

      if (!this.selectedIndexes.includes(index)) {
        this.selectedIndexes.push(index);
      } else {
        let index2 = this.selectedIndexes.indexOf(index);
        this.selectedIndexes.splice(index2, 1);
      }

      let select = this.getSelect();

      this.updateTitle();

      select.classList.remove("selected-clicked");

      this.$forceUpdate();

      this.$emit("selectedIndexesChange", this.selectedIndexes);
    },
    setSelectedIndexes(indexes: number[]) {
      this.selectedIndexes = indexes;

      this.updateTitle();

      this.$forceUpdate();
    },
    setSelectedItems(selected: any[]) {
      let indexes = [] as number[];

      for (let i = 0; i < this.items.length; i++) {
        var item = this.items[i];

        if (selected.includes(item)) {
          indexes.push(i);
        }
      }

      this.setSelectedIndexes(indexes);
    },
    getSelectedIndexes() {
      return this.selectedIndexes;
    },
    getSelectedItems() {
      let result = [];

      for (let index of this.selectedIndexes) {
        result.push(this.items[index]);
      }

      return result;
    },
  },
  watch: {
    selectedIndexes: {
      deep: true,
      handler() {
        this.$emit("selectedIndexesChange", this.selectedIndexes);
      },
    },
  },
});
