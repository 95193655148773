import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('no-mobile task-status ' + _ctx.status)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass('text ' + _ctx.status)
    }, _toDisplayString(_ctx.getStatusText()), 3)
  ], 2))
}