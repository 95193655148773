import { defineStore } from "pinia";
import { Role } from "@/model/User";
import { getStoreManager } from "@/firebase/StoreManager";
import Distributor from "@/model/Distributor";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import { getDistributorManager } from "@/firebase/DistributorManager";
import Probe from "@/model/Probe";

interface ProbeState {
  probe: Probe | null;
  postingIntervalMinutes: number;
}

export const getProbeState = defineStore("ProbeState", {
  state: (): ProbeState => {
    return {
      postingIntervalMinutes: 0,
      probe: null,
    };
  },
  actions: {
    async set(probe: Probe) {
      this.probe = probe;
      this.postingIntervalMinutes = probe.postingInterval / 60;
    },
  },
});
