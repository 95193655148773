import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";

export default class Quiz {
  clone(): Quiz | null {
    return new Quiz(this.questions.map((x) => x.clone()));
  }
  questions: QuizQuestion[];

  constructor(questions: QuizQuestion[]) {
    this.questions = questions;
  }

  public static fromFirestore(x: any): Quiz {
    return new Quiz(x.questions.map((x: any) => QuizQuestion.fromFirestore(x)));
  }

  public toFirestore() {
    return {
      questions: this.questions.map((x) => x.toFirestore()),
    };
  }
}

export class QuizQuestion {
  clone(): any {
    return new QuizQuestion(
      this.question,
      this.answers.map((x) => x.clone())
    );
  }
  question: string;
  answers: QuizAnswer[];

  constructor(question: string, answers: QuizAnswer[]) {
    this.question = question;
    this.answers = answers;
  }
  toFirestore(): any {
    return {
      question: this.question,
      answers: this.answers.map((x) => x.toFirestore()),
    };
  }
  static fromFirestore(x: any) {
    return new QuizQuestion(
      x.question,
      x.answers.map((x: any) => QuizAnswer.fromFirestore(x))
    );
  }
}

export class QuizAnswer {
  clone(): any {
    return new QuizAnswer(this.answer, this.correct);
  }
  answer: string;
  correct: boolean;

  constructor(answer: string, correct: boolean) {
    this.answer = answer;
    this.correct = correct;
  }

  toFirestore(): any {
    return {
      answer: this.answer,
      correct: this.correct,
    };
  }
  static fromFirestore(x: any) {
    return new QuizAnswer(x.answer, x.correct);
  }
}
