import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-65175362"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", {
    style: {"letter-spacing":"normal","resize":"none"},
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
    class: "se-textarea",
    placeholder: _ctx.placeholder,
    type: "text"
  }, _toDisplayString(_ctx.defaultValue), 41, _hoisted_1))
}