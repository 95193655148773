export function moveBackward(element: any, container: any[]) {
  if (container == null) {
    return;
  }
  var index = container.indexOf(element)!;

  if (index <= 0 || index >= container!.length) {
    return;
  }

  let temp = container![index];
  container[index] = container[index - 1];
  container[index - 1] = temp;
}

export function moveForward(element: any, container: any[]) {
  if (container == null) {
    return;
  }
  var index = container.indexOf(element)!;

  if (index < 0 || index >= container.length - 1) {
    return;
  }

  let temp = container[index];
  container[index] = container[index + 1];
  container[index + 1] = temp;
}
