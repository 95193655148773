import { defineStore } from "pinia";
import FirebaseConfig from "../firebase/FirebaseConfig";
import User from "@/model/User";
import { useRouter } from "vue-router";
import { Router } from "vue-router";
import { ModuleEnum } from "@/model/ModuleEnum";
import * as Index from "@/router/index";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

interface NavigationState {
  router: Router;
}

export const getState = defineStore("navigationState", {
  state: (): NavigationState => {
    const router = useRouter();

    let moduleLength = Object.keys(ModuleEnum).length;
    let selectedModules = [];
    for (let i = 0; i < moduleLength - 1; i++) {
      selectedModules.push(i);
    }

    return {
      router: router,
    };
  },

  actions: {
    navigate(page: string, replace: boolean = false) {
      if (replace) {
        this.router.replace({ name: page });
      } else {
        this.router.push({ name: page });
      }
    },
    goBack() {
      return this.router.go(-1);
    },
  },
});
