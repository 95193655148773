
import { defineComponent, nextTick } from "@vue/runtime-core";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  props: ["onchange", "search"],
  expose: [
    "getValue",
    "getIndex",
    "setValue",
    "setIndex",
    "getLength",
    "update",
  ],

  setup(props) {
    return {
      onchange: props.onchange,
    };
  },
  methods: {
    getLength(): number {
      return this.getOptions().length;
    },

    getSelect(): HTMLElement {
      return this.$el.querySelector(".select");
    },
    getOptions(): HTMLElement[] {
      return this.$el.querySelectorAll(".menu li") as HTMLElement[];
    },

    getMenu(): HTMLElement {
      return this.$el.querySelector(".menu");
    },

    onSelectClick() {
      let select = this.getSelect();
      let menu = this.getMenu();

      if (!menu.classList.contains("menu-open")) {
        var items = document.body.querySelectorAll(".menu-open");

        for (let item of items) {
          // close previously open ones
          item.classList.remove("menu-open");
        }
      }

      select.classList.toggle("select-clicked");
      menu.classList.toggle("menu-open");
    },

    onOptionClick($event: any) {
      const select = this.getSelect();
      const menu = this.getMenu();

      select.classList.remove("selected-clicked");
      menu.classList.remove("menu-open");

      if (this.onchange != undefined) {
        this.onchange();
      }
    },
    createHooks() {
      const options = this.getOptions();

      for (let option of options) {
        option.removeEventListener("click", this.onOptionClick, true);
        option.addEventListener("click", () => {
          this.onOptionClick(option);
        });
      }
    },

    update() {
      this.$forceUpdate();

      nextTick().then(() => {
        this.createHooks();
      });
    },
  },
  mounted() {
    this.createHooks();
    let options = this.getOptions();

    let select = this.getSelect();
    let menu = this.getMenu();

    document.addEventListener("click", (event: MouseEvent) => {
      let target = <HTMLElement>event.target;

      if (!DOMUtils.isDescendantOf(target, this.$el)) {
        select.classList.remove("selected-clicked");
        menu.classList.remove("menu-open");
      }
    });
  },
});
