import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import Tag from "@/model/Tag";
import EmployeeRole from "@/model/EmployeeRole";

interface DatabaseManager {
  listener: SnapshotListener<Database> | null;
}

export const getDatabaseManager = defineStore("DatabaseManager", {
  state: (): DatabaseManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async initialize() {
      if (this.listener != null) {
        return;
      }
      let user = getState().userData!;

      let databaseQuery: Query<DocumentData> | null = null;
      if (user.role == Role.Admin) {
        databaseQuery = query(collection(Firebase.firestore, "databases"));
      } else if (user.role == Role.Distributor) {
        databaseQuery = query(
          collection(Firebase.firestore, "databases"),
          where("distributor", "==", user.distributor)
        );
      } else if (user.role == Role.Manager) {
        databaseQuery = query(
          collection(Firebase.firestore, "databases"),
          where("managers", "array-contains", user.ref)
        );
      } else if (user.role == Role.Customer) {
        let storeManager = getStoreManager();

        await storeManager.initialize();

        let dbIds = storeManager.getStores(true).map((x) => x.database?.id!);

        if (dbIds.length > 0) {
          databaseQuery = query(
            collection(Firebase.firestore, "databases"),
            where(documentId(), "in", dbIds)
          );
        }
      }

      let listener = new SnapshotListener<Database>(
        Database.fromFirestore,
        databaseQuery!
      );

      this.listener = listener;

      await this.listener.ensureInit();
    },
    getDatabases(): Database[] {
      return this.listener?.items!;
    },

    async getDistributorDatabases(
      distributorRef: DocumentReference
    ): Promise<Database[]> {
      const q = query(
        collection(Firebase.firestore, "databases"),
        where("distributor", "==", distributorRef)
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Database.fromFirestore(x));
    },

    getDatabaseByReferenceFromCache(ref: DocumentReference): Database | null {
      var result = this.listener?.items.find((x) => x.ref.id == ref.id);
      return result ? result : null;
    },
    async getDatabaseByReference(ref: DocumentReference): Promise<Database> {
      let state = getState();
      const snapshot = await getDoc(ref);
      let database: Database = Database.fromFirestore(snapshot);
      return database;
    },

    async getDatabaseTags(database: Database): Promise<Tag[]> {
      const q = query(
        collection(Firebase.firestore, database.ref.path + "/store_tags")
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Tag.fromFirestore(x));
    },
    async getEmployeeRole(ref: DocumentReference) {
      const snapshot = await getDoc(ref);
      let role: EmployeeRole = EmployeeRole.fromFirestore(snapshot);
      return role;
    },
    async getEmployeeRoles(database: Database) {
      const q = query(
        collection(Firebase.firestore, database.ref.path + "/roles")
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => EmployeeRole.fromFirestore(x));
    },
  },
});
