import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "flex grow p24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_DocumentExplorer = _resolveComponent("DocumentExplorer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('documents')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DocumentExplorer, {
        ref: "documentExplorer",
        rootDocuments: _ctx.rootDocuments,
        title: "Documents"
      }, null, 8, ["rootDocuments"])
    ])
  ]))
}