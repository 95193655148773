import { defineStore } from "pinia";
import StoreElement from "@/model/Elements/StoreElement";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import { ProfilingChartType } from "@/components/home/ProfilingChartType";

interface AdminState {}

export const getAdminState = defineStore("adminState", {
  state: (): AdminState => {
    return {};
  },
  actions: {},
});
