import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import Probe from "@/model/Probe";

interface ProbeManager {}

export const getProbesManager = defineStore("ProbeManager", {
  state: (): ProbeManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async editProbe(probe: Probe) {
      await SecureatServerApi.editProbe(probe);
    },
    async getProbes(): Promise<Probe[]> {
      return await SecureatServerApi.getProbes();
    },
    async removeOwnership(probe: Probe): Promise<boolean> {
      return await SecureatServerApi.removeOwnership(probe);
    },
    async getProbeOfStores(storeId: string): Promise<Probe[]> {
      return await SecureatServerApi.getProbesOfStore(storeId);
    },
  },
});
