import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";

export default class Tag extends SecureatSnapshot {
  name: string = "";

  constructor(ref: DocumentReference, name: string) {
    super(ref);
    this.name = name;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Tag {
    const data = snapshot.data()!;

    let tag = new Tag(snapshot.ref, data.name);
    return tag;
  }

  public toFirestore() {
    return {
      name: this.name,
    };
  }
}
