import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import Audit from "@/model/Auditing/Audit";
import * as StringUtils from "@/utils/StringUtils";
import { Role } from "@/model/User";
import { getAuditManager } from "@/firebase/AuditManager";
import Communication from "@/model/Communication/Communication";
import { getCommunicationManager } from "@/firebase/CommunicationManager";
import CommunicationTarget from "@/model/Communication/CommunicationTarget";
import { DateTime } from "rrule/dist/esm/datetime";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import Quiz, { QuizQuestion } from "@/model/Communication/Quiz";
import CommunicationResult from "@/model/Communication/CommunicationResult";
import * as DateUtils from "@/utils/DateUtils";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Employee from "@/model/Employee";

interface CommunicationStatsState {
  communication: Communication | null;
  results: SnapshotListener<CommunicationResult> | null;
  pageIndex: number;
  visibleResults: CommunicationResult[];
  employees: Employee[];
}

export const getCommunicationStatsState = defineStore(
  "CommunicationStatsState",
  {
    state: (): CommunicationStatsState => {
      return {
        communication: null,
        results: null,
        pageIndex: 0,
        visibleResults: [],
        employees: [],
      };
    },
    actions: {
      set(communication: Communication | null = null) {
        this.communication = communication;
      },
      async increasePageIndex() {
        var maxIndex = this.results.items.length / 20 - 1;

        if (this.pageIndex >= maxIndex) {
          return;
        }
        this.pageIndex++;
        await this.updateVisibleResults();
      },
      async decreasePageIndex() {
        if (this.pageIndex == 0) {
          return;
        }
        this.pageIndex--;
        await this.updateVisibleResults();
      },

      async updateVisibleResults() {
        var res = this.getResults().map((x) => x);

        res.sort(
          (a, b) =>
            b.completed_timestamp.getDate() - a.completed_timestamp.getDate()
        );

        this.visibleResults = res.slice(
          this.pageIndex * 20,
          this.pageIndex * 20 + 20
        );
        this.loadEmployees();
      },

      loadEmployees(): void {
        for (let result of this.visibleResults) {
          if (this.employees.some((x) => x.ref.id == result.employee.id)) {
            continue;
          }
          getEmployeeManager()
            .getEmployee(result.employee)
            .then((employee: Employee) => {
              this.employees.push(employee);
            });
        }
      },

      async setup() {
        await getStoreManager().initialize();

        this.results = await getCommunicationManager().getCommunicationResults(
          this.communication!
        );

        await this.results.ensureInit();

        this.updateVisibleResults();
      },

      getResults() {
        let myStores = getStoreManager()
          .getStores(true)
          .map((x) => x.ref.id!);

        let results = this.results?.items.filter(
          (x) => myStores.includes(x.store.id) && x.completed_timestamp != null
        );

        return results!;
      },

      getTotalViews() {
        return this.getResults().filter((x) => x.completed_timestamp != null)
          .length;
      },
      getTotalViewsToday() {
        var date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);

        var dateEnd = new Date();
        dateEnd.setHours(23);
        dateEnd.setMinutes(59);
        dateEnd.setSeconds(59);

        return this.getResults().filter(
          (x) =>
            x.completed_timestamp != null &&
            x.completed_timestamp >= date &&
            x.completed_timestamp <= dateEnd
        ).length;
      },
      getMedianTimespent() {
        var items = this.getResults().filter((x) => x.time_spent != null)!;

        if (items.length == 0) {
          return DateUtils.formatTimeWithSecondsFromSeconds(0);
        }

        var timeSpentArray = items
          .map((item) => item.time_spent!)
          .sort((a, b) => a - b);

        var median;
        var midIndex = Math.floor(timeSpentArray.length / 2);

        if (timeSpentArray.length % 2 === 0) {
          // If even, average the two middle values
          median =
            (timeSpentArray[midIndex - 1] + timeSpentArray[midIndex]) / 2;
        } else {
          // If odd, take the middle value
          median = timeSpentArray[midIndex];
        }
        return DateUtils.formatTimeWithSecondsFromSeconds(median);
      },
    },
  }
);
