
import { defineComponent, PropType } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

export default defineComponent({
  components: { Carousel, Slide, Pagination, Navigation },
  props: {
    pictures: {
      type: Array as PropType<string[]>,
      default: [],
      required: true,
    },
  },
  methods: {
    close() {
      var element = this.$el;

      console.log(element.parentNode);

      element.parentNode.parentNode.removeChild(element.parentNode);
    },
  },
});
