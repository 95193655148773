
import { defineComponent, nextTick } from "vue";
import WideCard from "../../global/WideCard.vue";
import StoreElement from "@/model/Elements/StoreElement";
import * as Lang from "@/i18n/lang";
import * as LabelsManager from "@/firebase/LabelsManager";
import * as ModuleManager from "@/firebase/ModuleManager";

import { LabelType } from "@/model/Labels/Label";
import StoreLabel from "@/model/Labels/StoreLabel";
import Store from "@/model/Store";
import { doc, DocumentReference, collection } from "firebase/firestore";
import Module from "@/model/Module";
import { ModuleEnum } from "@/model/ModuleEnum";
import { AllergenEnum } from "@/model/AllergensEnum";
import Attachment from "@/model/Filesystem/Attachment";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import { getElementManager } from "@/firebase/ElementManager";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Snackbars from "@/utils/Snackbars";
import { AttachmentType } from "@/model/Filesystem/AttachmentType";
import * as StringUtils from "@/utils/StringUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as Firebase from "@/firebase/Firebase";
import * as CollectionUtils from "@/utils/CollectionUtils";
import Probe from "@/model/Probe";
import { getProbesManager } from "@/firebase/ProbeManager";
import { getStoreElementState } from "@/pinia/elements/StoreElementState";

export default defineComponent({
  expose: ["labels", "cloneLabels"],
  data() {
    return {
      attachmentName: "",
      labels: [] as StoreLabel[],
      currentLabel: new StoreLabel(
        doc(collection(Firebase.firestore, this.store.ref.path, "labels"))
      ),
      labelModalMode: "create",
      Lang: Lang,
      LabelType: LabelType,
      ModuleEnum: ModuleEnum,
      probes: [] as Probe[],
      storeElementState: getStoreElementState(),
    };
  },
  async setup(props: any) {
    const hidden_modulesRefs: DocumentReference[] = props.store?.hidden_modules;
    let hidden_modules: (Module | null)[] = [];
    if (hidden_modulesRefs && hidden_modulesRefs.length > 0) {
      hidden_modulesRefs.map((hidden_moduleRef) => {
        hidden_modules.push(ModuleManager.getModuleFromRef(hidden_moduleRef));
      });
    }

    const hiddenModuleValues = [];

    for (const [key, value] of Object.entries(ModuleEnum)) {
      const isModuleAvailable = hidden_modules.some(
        (module) => module?.key === value
      );
      if (isModuleAvailable) {
        hiddenModuleValues.push(value);
      }
    }

    return {
      store: props.store,
      hiddenModules: hiddenModuleValues,
    };
  },
  async mounted() {
    this.labels = await LabelsManager.getStoreLabelsByElement(
      this.store,
      this.element
    );

    this.probes = await getProbesManager().getProbeOfStores(this.store.ref!.id);

    if (this.element.probe_id != null) {
      let probe = this.probes.find((x) => x.probeUID == this.element.probe_id);

      if (probe != undefined) {
        this.storeElementState.selectedProbe = probe;
      } else {
        this.storeElementState.selectedProbe = null;
      }
    }
  },
  computed: {
    allergenValues() {
      return Object.values(AllergenEnum);
    },
  },
  methods: {
    onFreeformInputChange(value: any) {
      var lineCount = value.getLineCount();

      if (value.getValue().trim() == "") {
        return;
      }
      var refs = <any>this.$refs;

      if (lineCount >= 5) {
        refs.freeformErrorMsg.style.display = "";
      } else {
        refs.freeformErrorMsg.style.display = "none";
      }
    },
    moveUpLabel(label: StoreLabel) {
      CollectionUtils.moveForward(label, this.labels);
    },
    moveDownLabel(label: StoreLabel) {
      CollectionUtils.moveBackward(label, this.labels);
    },
    moveDownAttachement(attachment: Attachment) {
      var index = this.element.attachments?.indexOf(attachment)!;

      if (index <= 0 || index >= this.element.attachments!.length) {
        return;
      }

      // Swap the element at the given index with the previous one
      let temp = this.element.attachments![index];
      this.element.attachments![index] = this.element.attachments![index - 1];
      this.element.attachments![index - 1] = temp;
    },
    moveUpAttachement(attachment: Attachment) {
      var index = this.element.attachments?.indexOf(attachment)!;

      // Check if the index is within the bounds of the array
      if (index < 0 || index >= this.element.attachments!.length - 1) {
        return;
      }

      // Swap the element at the given index with the next one
      let temp = this.element.attachments![index];
      this.element.attachments![index] = this.element.attachments![index + 1];
      this.element.attachments![index + 1] = temp;
    },
    editAttachment() {
      var refs = <any>this.$refs;
      let attachment = refs.attachmentModal.context;

      attachment.name = refs.attachmentName.getValue();

      refs.attachmentModal.close();
    },
    openEditAttachmentModal(attachment: Attachment) {
      this.attachmentName = attachment.name.split(".")[0];
      var refs = <any>this.$refs;
      refs.attachmentModal.open(attachment);
    },

    uploadAttachment() {
      DOMUtils.attachementFileDialog(this.onAttachmentUploaded);
    },
    async downloadAttachment(attachment: Attachment) {
      await attachment.download();
    },
    async deleteAttachment(attachment: Attachment) {
      let index = this.element.attachments?.indexOf(attachment);

      if (index != undefined) {
        this.element.attachments!.splice(index, 1);
      }

      await UploadManager.addFileToDelete(attachment);
    },
    async onAttachmentUploaded(e: any) {
      let file = e.target.files[0];
      var path =
        "/stores/" +
        this.store.ref.id! +
        "/element_attachments/" +
        this.element.ref?.id;

      var strategy = await UploadManager.createStrategy(file, path);

      UploadManager.addFileToUpload(strategy);

      this.element.attachments!.push(strategy.generateAttachment()!);
    },

    onTabChanged(tabIndex: number) {
      if (tabIndex === 5) {
        nextTick(() => {
          this.displayAssignedAllergens();
        });
      }
    },
    displayAssignedAllergens() {
      var refs = <any>this.$refs;
      let indexes: number[] = [];

      if (
        this.element.getAllergens() != null &&
        this.element?.getAllergens()!.length > 0
      ) {
        this.allergenValues.forEach((allergenValue, index) => {
          if (
            this.element
              .getAllergens()!
              .find((allergen) => allergen === allergenValue)
          ) {
            indexes.push(index);
          }
        });
      }
      let selectAllergensRef = refs.selectAllergens;
      if (selectAllergensRef) {
        selectAllergensRef.setSelectedIndexes(indexes);
      }
    },
    setSelectedAllergens(selectedIndexes: []) {
      let newSelectedAllergens: AllergenEnum[] = [];
      selectedIndexes.forEach((selectedIndex) => {
        newSelectedAllergens.push(this.allergenValues[selectedIndex]);
      });
      this.element.allergens = newSelectedAllergens;
    },
    openCreateLabelModal() {
      let refs = <any>this.$refs;

      this.currentLabel = new StoreLabel(
        doc(collection(Firebase.firestore, this.store.ref.path + "/labels")),
        true
      );
      this.currentLabel.type = LabelType.Defrosting;
      this.labelModalMode = "create";

      refs.submitForm.reset();

      refs.labelModal.open();

      refs.expirationDurationDays.setValue("0");
      refs.expirationDurationHours.setValue("0");
      refs.defrostingDurationDays.setValue("0");
      refs.defrostingDurationHours.setValue("0");
    },

    openEditLabelModal(label: StoreLabel) {
      console.log(label);
      this.currentLabel = label;

      let refs = <any>this.$refs;
      refs.submitForm.reset();

      if (label.getExpirationDuration(this.getLabelLock()) != null) {
        let exp = label.getExpirationDuration(this.getLabelLock())!;

        let hours = exp % 24;
        let days = Math.trunc(exp / 24);

        refs.expirationDurationDays.setValue(days);
        if (refs.expirationDurationHours != undefined)
          refs.expirationDurationHours.setValue(hours);
      }

      if (label.getDefrostingDuration(this.getLabelLock()) != null) {
        let exp = label.getDefrostingDuration(this.getLabelLock())!;

        let hours = exp % 24;
        let days = Math.trunc(exp / 24);

        if (refs.defrostingDurationDays != undefined)
          refs.defrostingDurationDays.setValue(days);
        if (refs.defrostingDurationHours != undefined)
          refs.defrostingDurationHours.setValue(hours);
      }

      refs.cumulateExprSwitch.toggle(
        label.getCumulateExpirationDuration(this.getLabelLock())
      );

      refs.expirationText.setValue(
        label.getExpirationText(this.getLabelLock())
      );

      this.labelModalMode = "edit";

      refs.labelModal.open();

      nextTick(() => {
        refs.labelName.setValue(label.getName(this.getLabelLock()));

        refs.labelTypeSelect.setValue(label.getType(this.getLabelLock()));
      });
    },
    getLabelLock() {
      return this.element.originValue != null
        ? this.element.originValue?.labels_lock
        : false;
    },
    async createLabel() {
      let refs = <any>this.$refs;

      if (!refs.submitForm.verify()) {
        return;
      }

      this.applyLabelProperties();
      refs.labelModal.close();

      this.labels.push(this.currentLabel!);

      this.$forceUpdate();
    },

    async editLabel() {
      let refs = <any>this.$refs;

      if (!refs.submitForm.verify()) {
        return;
      }

      this.applyLabelProperties();
      this.$forceUpdate();
      refs.labelModal.close();
    },

    applyLabelProperties() {
      let refs = <any>this.$refs;

      let expirationDurationDays = 0;
      let expirationDurationHours = 0;

      let defrostingDurationDays = 0;
      let defrostingDurationHours = 0;

      if (refs.expirationDurationDays != undefined) {
        expirationDurationDays = parseInt(
          refs.expirationDurationDays.getValue()
        );
      }
      if (refs.expirationDurationHours != undefined) {
        expirationDurationHours = parseInt(
          refs.expirationDurationHours.getValue()
        );
      }
      if (refs.defrostingDurationDays != undefined) {
        defrostingDurationDays = parseInt(
          refs.defrostingDurationDays.getValue()
        );
      }
      if (refs.defrostingDurationHours != undefined) {
        defrostingDurationHours = parseInt(
          refs.defrostingDurationHours.getValue()
        );
      }

      this.currentLabel.expiration_duration = Math.round(
        expirationDurationDays * 24 + expirationDurationHours
      );
      this.currentLabel.defrosting_duration = Math.round(
        defrostingDurationDays * 24 + defrostingDurationHours
      );

      this.currentLabel.expiration_text = refs.expirationText.getValue();
    },
  },
  props: {
    store: { type: Store, required: true },
    element: { type: StoreElement, required: true },
  },
  components: { WideCard },
});
