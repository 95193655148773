import { defineStore } from "pinia";
import User from "@/model/User";
import Store from "@/model/Store";
import Task from "@/model/Task";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import Database from "@/model/Database";
import Element from "@/model/Elements/Element";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import { getState } from "../AppState";
import { getDoc, getDocFromCache } from "firebase/firestore";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getElementManager } from "@/firebase/ElementManager";
import { getStoreManager } from "@/firebase/StoreManager";

interface StoreDatabaseState {
  store: Store | null;
  tabIndex: number;
  database: Database | null;
  elements: Element[] | null;
  tasks: TaskConfiguration[] | null;
  elementSearch: string;
  taskSearch: string;
  scrollY: number;
  displayUnactives: boolean;
}

export const getStoreDatabaseState = defineStore("storeDatabaseState", {
  state: (): StoreDatabaseState => {
    return {
      store: null,
      tabIndex: 0,
      database: null,
      elements: [],
      tasks: [],
      elementSearch: "",
      taskSearch: "",
      scrollY: 0,
      displayUnactives: false,
    };
  },
  actions: {
    set(store: Store) {
      this.store = store;
    },
    async setup() {
      let appState = getState();

      if (appState.customStore != null) {
        this.store = appState.customStore;
      }

      await getStoreManager().initialize();

      this.database = await getDatabaseManager().getDatabaseByReference(
        this.store!.database!
      );

      let taskConfigurationManager = getTaskConfigurationManager();

      await taskConfigurationManager.ensureDatabaseTaskConfigurationListener(
        this.store?.database?.id!
      );

      await taskConfigurationManager.ensureStoreTaskConfigurationListener(
        this.store?.ref?.id!
      );

      let elementManager = getElementManager();

      await elementManager.ensureDatabaseElementListener(
        this.store?.database?.id!
      );

      await elementManager.ensureStoreElementListener(this.store?.ref?.id!);

      this.elements = elementManager.getElements(this.store!);

      this.tasks = taskConfigurationManager.getTaskConfigurations(this.store!);

      elementManager.getDatabaseElementListener(
        this.store?.database?.id!
      ).updated = () => {
        this.elements = elementManager.getElements(this.store!);
      };

      elementManager.getStoreElementListener(this.store?.ref?.id!).updated =
        () => {
          this.elements = elementManager.getElements(this.store!);
        };
    },
  },
});
