import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import { getShopManager } from "@/firebase/ShopManager";

interface DistributorState {
  initialDistributor: Distributor | null;
  distributor: Distributor | null;
  kooveaPasswordClear: string;
}

export const getDistributorState = defineStore("distributorState", {
  state: (): DistributorState => {
    return {
      distributor: null,
      initialDistributor: null,
      kooveaPasswordClear: null,
    };
  },
  actions: {
    set(distributor: Distributor) {
      this.initialDistributor = distributor;
      this.distributor = Distributor.clone(distributor);
      this.kooveaPasswordClear = "";
    },
  },
});
