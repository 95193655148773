import { generateUniqueId } from "../utils/uniqueIdGenerator";
import {
  QuestionOptions,
  TextQuestionOptions,
  MultipleQuestionOptions,
  RadioQuestionOptions,
  RatingQuestionOptions,
  UploadQuestionOptions,
  NumberQuestionOptions,
  DateQuestionOptions,
} from "./QuestionOptions";
import { QuestionCondition } from "./QuestionCondition";
import { Answer } from "./Answer";
import { Choice } from "./Choice";

export enum QuestionType {
  TextInput = "text_input",
  MultipleChoice = "multiple_choice",
  YesOrNo = "yes_or_no",
  FileUpload = "file_upload",
  Rating = "rating",
  Number = "number",
  Date = "date",
}

export class Question {
  id: string;
  text: string;
  type: QuestionType;
  score: number | null;
  options: QuestionOptions;
  conditions: QuestionCondition[];
  required: boolean;
  archived_at: Date | null;
  answers: Answer[] | null;

  constructor(
    required: boolean = false,
    text: string = "",
    type: QuestionType = QuestionType.TextInput,
    score: number | null = null,
    options?: QuestionOptions,
    conditions: QuestionCondition[] = [],
    id: string = generateUniqueId(),
    archived_at: Date | null = null,
    answers: Answer[] | null = []
  ) {
    this.required = required;
    this.id = id;
    this.text = text;
    this.type = type;
    this.score = score;
    this.options = options ?? this.getOptionsClass(type);
    this.conditions = conditions;
    this.archived_at = archived_at;
    this.answers = answers;
  }

  toMap() {
    return {
      // serialize properties
      id: this.id,
      text: this.text,
      type: this.type.toString(),
      score: this.score,
      options: this.options.toMap(),
      conditions: this.conditions.map((condition) => condition.toMap()),
      required: this.required,
      archived_at: this.archived_at,
    };
  }

  clone(exact: boolean = false): Question {
    if (exact) {
      return new Question(
        this.required,
        this.text,
        this.type,
        this.score,
        this.options.clone(),
        this.conditions.map((condition) => condition.clone()),
        this.id,
        this.archived_at
      );
    } else {
      return new Question(
        this.required,
        this.text,
        this.type,
        this.score,
        this.options.clone(),
        this.conditions.map((condition) => condition.clone()),
        generateUniqueId(),
        null
      );
    }
  }

  setQuestionOptions(questionOptions: QuestionOptions): void {
    this.options = questionOptions;
  }

  changeQuestionOptions(questionType: QuestionType): QuestionOptions {
    return this.getOptionsClass(questionType);
  }

  hasConditions(): boolean {
    if (this.conditions && this.conditions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  addCondition(newKey: string, value: any): QuestionCondition {
    let newCondition = new QuestionCondition(newKey, value);
    this.conditions.push(newCondition);
    return newCondition;
  }

  editCondition(newKey: string, value: any): void {
    let newConditions: QuestionCondition[] = [];
    let newCondition = new QuestionCondition(newKey, value);
    newConditions.push(newCondition);
    this.conditions = newConditions;
  }

  deleteCondition(conditionId: string) {
    const index = this.conditions.findIndex(
      (condition) => condition.id === conditionId
    );
    if (index !== -1) {
      this.conditions.splice(index, 1);
    }
  }

  countProvidedAnswers(): Number {
    if (!this.answers) return 0;
    let countAnswers = 0;
    this.answers.map((answer) => {
      if (answer.toPDF() !== "") {
        countAnswers++;
      }
    });
    return countAnswers;
  }

  public getOptionsClass(type: QuestionType): QuestionOptions {
    switch (type) {
      case "text_input":
        return new TextQuestionOptions();
      case "multiple_choice":
        return new MultipleQuestionOptions();
      case "file_upload":
        return new UploadQuestionOptions();
      case "rating":
        return new RatingQuestionOptions();
      case "yes_or_no":
        let choices: Choice[] = [new Choice("true"), new Choice("false")];
        return new MultipleQuestionOptions(false, choices);
      case "number":
        return new NumberQuestionOptions();
      case "date":
        return new DateQuestionOptions();
      default:
        return new RadioQuestionOptions();
    }
  }

  static createQuestionOptions(
    type: QuestionType,
    optionsData: any
  ): QuestionOptions {
    switch (type) {
      case "text_input":
        return new TextQuestionOptions(optionsData.max_characters);
      case "multiple_choice":
        return new MultipleQuestionOptions(
          optionsData.multiple_selection,
          optionsData.choices
        );
      case "file_upload":
        return new UploadQuestionOptions(optionsData.file_extensions);
      case "rating":
        return new RatingQuestionOptions(
          optionsData.min_rating,
          optionsData.max_rating,
          optionsData.precision
        );
      case "yes_or_no":
        return new MultipleQuestionOptions(false, optionsData.choices);
      case "number":
        return new NumberQuestionOptions(
          optionsData.min_number,
          optionsData.max_number
        );
      case "date":
        return new DateQuestionOptions(optionsData.date_limit);
      default:
        return new RadioQuestionOptions();
    }
  }
}
