import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6ef84723"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document-card" }
const _hoisted_2 = {
  class: "flex",
  style: {"width":"100px"}
}
const _hoisted_3 = { class: "document-icon-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  style: {"font-size":"14px","text-align":"center"},
  class: "text-blue bold"
}
const _hoisted_6 = {
  class: "flex justify-end",
  style: {"width":"100px"}
}
const _hoisted_7 = {
  key: 0,
  class: "document-footer footer-text"
}
const _hoisted_8 = {
  key: 0,
  style: {"font-size":"14px","white-space":"nowrap"},
  class: "flex align-center g1"
}
const _hoisted_9 = {
  key: 1,
  class: "center-row",
  style: {"gap":"6px"}
}
const _hoisted_10 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeImage = _resolveComponent("SeImage")!
  const _component_SeIconDropdown = _resolveComponent("SeIconDropdown")!
  const _component_ReadonlyField = _resolveComponent("ReadonlyField")!

  return (_openBlock(), _createBlock(_component_ReadonlyField, { locked: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              style: {"border-radius":"6px"},
              width: "30",
              height: "30",
              src: _ctx.getIcon()
            }, null, 8, _hoisted_4)
          ])
        ]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.item.name), 1),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.hasWriteRights() || _ctx.isFile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.item.locked)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _createVNode(_component_SeImage, { src: "/ico/lock.svg" })
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.item.forceReadonly)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_SeIconDropdown, {
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isFile)
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 0,
                                onClick: _cache[0] || (_cache[0] = () => _ctx.downloadClick!(_ctx.item!))
                              }, _toDisplayString(_ctx.Lang.getI18N("download")), 1))
                            : _createCommentVNode("", true),
                          (_ctx.hasWriteRights())
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 1,
                                onClick: _cache[1] || (_cache[1] = () => _ctx.renameClick!(_ctx.item!))
                              }, _toDisplayString(_ctx.Lang.getI18N("rename")), 1))
                            : _createCommentVNode("", true),
                          (_ctx.hasWriteRights())
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 2,
                                onClick: _cache[2] || (_cache[2] = () => _ctx.lockClick!(_ctx.item!))
                              }, _toDisplayString(_ctx.item.locked ? _ctx.Lang.getI18N("unlock") : _ctx.Lang.getI18N("lock")), 1))
                            : _createCommentVNode("", true),
                          false
                            ? (_openBlock(), _createElementBlock("li", _hoisted_10, _toDisplayString(_ctx.Lang.getI18N("share")), 1))
                            : _createCommentVNode("", true),
                          (_ctx.hasWriteRights())
                            ? (_openBlock(), _createElementBlock("li", {
                                key: 4,
                                onClick: _cache[3] || (_cache[3] = () => _ctx.deleteClick!(_ctx.item!))
                              }, _toDisplayString(_ctx.Lang.getI18N("delete")), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}