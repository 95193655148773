
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import Task from "@/model/Task";
import Store from "@/model/Store";
import TraceabilityResultRow from "./TraceabilityResultRow.vue";
import * as Lang from "@/i18n/lang";
import TaskStatus from "../TaskStatus.vue";

export default defineComponent({
  props: {
    task: {
      type: Task,
    },
    store: {
      type: Store,
    },
  },

  methods: {},
  async setup(props) {
    let results = await TaskManager.getTaskResults(props.task!, props.store!);

    return {
      results: results,
    };
  },
  data() {
    return {
      Lang: Lang,
      TaskManager: TaskManager,
    };
  },
  components: {
    TaskStatus,
    TraceabilityResultRow,
  },
});
