
import { doc, updateDoc } from "firebase/firestore";
import NavPanel from "../nav/NavPanel.vue";
import NavHeader from "../nav/NavHeader.vue";
import SearchBar from "../components/global/SearchBar.vue";
import ToggleSwitch from "../components/global/ToggleSwitch.vue";
import SeInput from "../components/global/SeInput.vue";
import { getDistributorManager } from "../firebase/DistributorManager";
import User, { Role } from "@/model/User";
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import Distributor from "@/model/Distributor";
import AccountList from "@/components/accounts/AccountList.vue";
import WideCard from "@/components/global/WideCard.vue";
import { defineComponent, nextTick } from "vue";
import * as Lang from "@/i18n/lang";
import SeInputFile from "@/components/global/SeInputFile.vue";
import * as Storage from "@/firebase/Storage";
import SeCountryDropdown from "@/components/global/SeCountryDropdown.vue";
import * as Snackbars from "@/utils/Snackbars";
import { getAccountState } from "@/pinia/accounts/AccountState";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import { getUserManager } from "@/firebase/UserManager";
import * as UploadManager from "@/model/Uploads/UploadManager";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import * as Crypto from "@/utils/Crypto";

export default defineComponent({
  components: {
    AccountList,
    SeInputFile,
    SeCountryDropdown,
  },
  mounted() {
    var refs = <any>this.$refs;

    nextTick(() => {
      if (this.state.distributor!.countries.length > 0) {
        refs.countrySelect.selectCountryCode(
          this.state.distributor!.countries[0]
        );
      }
    });
  },
  async setup() {
    var state = getDistributorState();

    let contacts: User[] = await getUserManager().getDistributorContacts(
      state.distributor!
    );

    return {
      Lang: Lang,
      Role: Role,
      role: getAppState().userData.role,
      state: state,
      contacts: contacts,
    };
  },
  methods: {
    openKooveaModal() {
      var refs = <any>this.$refs;
      refs.kooveaModal.open();
    },
    closeKooveaModal() {
      var refs = <any>this.$refs;
      refs.kooveaModal.close();
    },
    async pickIllustration(event: ProgressEvent, file: File) {
      await Storage.uploadDistributorLogo(this.state.distributor!, file);

      this.state.distributor!.logo = await Storage.getDistributorLogoUrl(
        this.state.distributor!
      );

      await this.state.distributor?.set();
    },
    openAccount(account: User) {
      getAccountState().selectedAccount = account;
      getState().navigate("account");
    },
    async updateDistributor() {
      let refs = <any>this.$refs;
      refs.modifyBtn.toggleLoading(true);
      this.state.distributor!.countries = [] as string[];

      if (
        this.state.kooveaPasswordClear != null &&
        this.state.kooveaPasswordClear.trim() != ""
      ) {
        var result = (
          await Crypto.hashSHA256(this.state.kooveaPasswordClear)
        ).toLowerCase();

        this.state.distributor.koovea_password = result;

        console.log(result);
      }

      var country = refs.countrySelect.getCountry();

      if (country != null) {
        this.state.distributor!.countries.push(country.code);
      }

      await UploadManager.applyUploads();

      await this.state.distributor!.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
});
