
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  props: {
    modelValue: {},
    change: {
      type: Function,
      required: false,
      default: null,
    },
  },
  expose: ["toggle", "toggled"],
  emits: ["update:modelValue"],

  methods: {
    onClick() {
      this.locked = !this.locked;
      this.updateIcon();
      this.$emit("update:modelValue", this.locked);

      if (this.change != null) {
        this.change(this.locked);
      }
    },

    updateIcon() {
      let refs = <any>this.$refs;
      let icon: any = refs.lockedIcon;
      icon.src = this.locked ? "ico/lock.svg" : "ico/unlock.svg";
    },
  },
  mounted() {
    this.updateIcon();
  },
  data() {
    return {
      Lang: Lang,
      locked: this.modelValue,
    };
  },
  watch: {
    modelValue(value: boolean, old: boolean) {
      this.locked = value;
      this.updateIcon();
    },
  },
});
