
import { defineComponent, PropType, ref } from "vue";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import TaskAction from "@/model/Tasks/TaskAction";
import * as Lang from "@/i18n/lang";
import SeFadeButton from "../global/SeFadeButton.vue";

export default defineComponent({
  props: {
    actions: Array as PropType<TaskAction[]>,
    deleteClick: null,
    editClick: null,
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  methods: {
    cardClick(item: TaskAction) {
      if (this.editClick != null) {
        this.editClick(item);
      }
    },
    toggleRequirePicture(item: TaskAction) {
      item.requires_picture = !item.requires_picture;
    },
  },
});
