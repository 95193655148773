import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import * as Lang from "@/i18n/lang";

export enum LabelType {
  Expiration = "expiration",
  Defrosting = "defrosting",
  Name = "name",
  CustomerExpiration = "customer_expiration",
  HourlessExpiration = "hourless_expiration",
  FreeformExpiration = "freeform_expiration",
  Opening = "opening",
  Frozen = "frozen",
}

export default class Label extends SecureatSnapshot {
  public toFirestore() {
    throw new Error("Method not implemented.");
  }
  active: boolean | null;
  cumulate_expiration_duration: boolean | null;
  defrosting_duration: number | null;
  element: DocumentReference | null;
  expiration_duration: number | null;
  expiration_text: string | null;
  name: string | null;
  type: LabelType | null;
  index: number | null = null;

  constructor(
    ref: DocumentReference,
    active: boolean | null = false,
    cumulate_expiration_duration: boolean | null = false,
    defrosting_duration: number | null = 0,
    element: DocumentReference | null = null,
    expiration_duration: number | null = 0,
    expiration_text: string | null = null,
    name: string | null = null,
    type: LabelType | null = null,
    index: number | null = null
  ) {
    super(ref);
    this.active = active;
    this.cumulate_expiration_duration = cumulate_expiration_duration;
    this.defrosting_duration = defrosting_duration;
    this.element = element;
    this.expiration_duration = expiration_duration;
    this.expiration_text = expiration_text;
    this.name = name;
    this.type = type;
    this.index = index;
  }

  public getActive() {
    return this.active;
  }
  public getCumulateExpirationDuration(labels_lock: boolean) {
    return this.cumulate_expiration_duration;
  }
  public getExpirationText(labels_lock: boolean) {
    return this.expiration_text;
  }
  public getDefrostingDuration(labels_lock: boolean) {
    return this.defrosting_duration;
  }
  public getExpirationDuration(labels_lock: boolean) {
    return this.expiration_duration;
  }
  public getType(labels_lock: boolean) {
    return this.type;
  }
  public getName(labels_lock: boolean) {
    return this.name;
  }
  public getDescription(labels_lock: boolean) {
    let type = <LabelType>this.getType(labels_lock);

    switch (type) {
      case LabelType.FreeformExpiration:
        let text = this.expiration_text!;

        const maxLength = 30;

        if (text.length > maxLength) {
          text = text.substring(0, maxLength) + "...";
        }
        return Lang.getI18N("freeform_expiration") + " : " + text;
      case LabelType.HourlessExpiration:
        return (
          Lang.getI18N("hourless_expiration") +
          " : " +
          this.getExpirationDuration(labels_lock) +
          "h"
        );
      case LabelType.CustomerExpiration:
        return (
          Lang.getI18N("customer_expiration") +
          " : " +
          this.getExpirationDuration(labels_lock) +
          "h"
        );
      case LabelType.Defrosting:
        return (
          Lang.getI18N("defrosting") +
          " : " +
          this.getDefrostingDuration(labels_lock) +
          "h, " +
          Lang.getI18N("expiration") +
          " : " +
          this.getExpirationDuration(labels_lock) +
          "h"
        );
      case LabelType.Expiration:
        return (
          Lang.getI18N("expiration") +
          " : " +
          this.getExpirationDuration(labels_lock) +
          "h"
        );
      case LabelType.Name:
        return Lang.getI18N("simple-name");
      case LabelType.Opening:
        return Lang.getI18N("opening");
      case LabelType.Frozen:
        return Lang.getI18N("freezing");
    }

    return "";
  }

  public toJson(): Record<string, any> {
    return {
      active: this.active,
      cumulate_expiration_duration: this.cumulate_expiration_duration,
      defrosting_duration: this.defrosting_duration,
      element: this.element ? this.element.path : null,
      expiration_duration: this.expiration_duration,
      expiration_text: this.expiration_text,
      name: this.name,
      type: this.type,
    };
  }
}
