
import NavHeader from "@/nav/NavHeader.vue";
import SearchBar from "@/components/global/SearchBar.vue";
import ProbeList from "@/components/probes/ProbeList.vue";
import { getStoreManager } from "@/firebase/StoreManager";
import Probe from "@/model/Probe";
import { defineComponent } from "vue";
import { getProbesState } from "@/pinia/probes/ProbesState";
import * as Lang from "@/i18n/lang";
import { nextTick } from "vue";

export default defineComponent({
  methods: {
    async onFilterSwitch(index: number) {
      var refs = <any>this.$refs;

      this.state.filterIndex = index;

      if (index == 1) {
        this.state.visibleProbes = this.state.probes.filter((x: Probe) =>
          x.isProbeWorking()
        );
      } else if (index == 2) {
        this.state.visibleProbes = this.state.probes.filter(
          (x) => !x.isProbeWorking()
        );
      } else {
        this.state.visibleProbes = this.state.probes;
      }

      nextTick(() => {
        if (refs.searchBar) refs.searchBar.applySearch();
      });
    },
  },

  async setup() {
    var state = getProbesState();

    await getStoreManager().initialize();

    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  mounted() {
    var refs = <any>this.$refs;
    refs.filters.setIndex(this.state.filterIndex);
  },
  components: {
    ProbeList,
  },
});
