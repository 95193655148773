import { generateUniqueId } from "../utils/uniqueIdGenerator";
import { Question } from "./Question";

export class Section {
  id: string;
  title: string;
  isVisible: boolean;
  questions: Question[];
  archived_at: Date | null;

  constructor(
    title: string,
    isVisible: boolean,
    questions: Question[] = [],
    id: string = generateUniqueId(),
    archived_at: Date | null = null
  ) {
    this.id = id;
    this.title = title;
    this.isVisible = isVisible;
    this.questions = questions;
    this.archived_at = archived_at;
  }

  addQuestion(question: Question): void {
    this.questions.push(question);
  }

  toMap() {
    return {
      // serialize properties
      id: this.id,
      title: this.title,
      questions: this.questions.map((question) => question.toMap()),
      archived_at: this.archived_at,
    };
  }

  clone(exact: boolean = false): Section {
    const clonedQuestions: Question[] = this.questions.map((question) =>
      question.clone(exact)
    );

    if (exact) {
      return new Section(
        this.title,
        this.isVisible,
        clonedQuestions,
        this.id,
        this.archived_at
      );
    } else {
      return new Section(
        this.title,
        this.isVisible,
        clonedQuestions,
        generateUniqueId(),
        null
      );
    }
  }

  calculateTotalScore(): number {
    let totalScore = 0;
    this.questions.forEach((question) => {
      if (question.score) totalScore += question.score;
    });
    return totalScore;
  }

  getQuestions(): Question[] {
    return this.questions.filter((question) => question.archived_at === null);
  }
}
