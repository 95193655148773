export enum Role {
  Customer = "customer",
  Manager = "manager",
  Distributor = "distributor",
  Admin = "admin",
}

import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import * as ImageKit from "@/utils/ImageUtils";

export default class User extends SecureatSnapshot {
  active: boolean = false;
  email: string = "";
  name: string = "";
  notification_tokens: string[] = [];
  phone_number: string = "";
  role: string = "";
  stores: DocumentReference[] = [];
  surname: string = "";
  distributor: DocumentReference | null = null;
  illustration_url: string | null = null;
  store_distributors: DocumentReference[];
  new_password_required: boolean = false;
  manager_databases: DocumentReference[];
  store_databases: DocumentReference[];

  constructor(
    ref: DocumentReference,
    active: boolean = false,
    email: string = "",
    name: string = "",
    notification_tokens: string[] = [],
    phone_number: string = "",
    role: string = "customer",
    stores: DocumentReference[] = [],
    surname: string = "",
    distributor: DocumentReference | null = null,
    illustration_url: string | null,
    store_distributors: DocumentReference[],
    new_password_required: boolean,
    manager_databases: DocumentReference[],
    store_databases: DocumentReference[]
  ) {
    super(ref);
    this.active = active;
    this.email = email;
    this.name = name;
    this.notification_tokens = notification_tokens;
    this.phone_number = phone_number;
    this.role = role;
    this.stores = stores;
    this.surname = surname;
    this.distributor = distributor;
    this.illustration_url = illustration_url;
    this.store_distributors = store_distributors;
    this.new_password_required = new_password_required;
    this.manager_databases = manager_databases;
    this.store_databases = store_databases;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): User {
    const data = snapshot.data()!;

    let result = new User(
      snapshot.ref,
      data.active,
      data.email,
      data.name,
      data.notification_tokens,
      data.phone_number,
      data.role,
      data.stores,
      data.surname,
      data.distributor,
      data.illustration_url == undefined ? null : data.illustration_url,
      data.store_distributors == undefined ? [] : data.store_distributors,
      data.new_password_required == undefined
        ? false
        : data.new_password_required,
      data.manager_databases == undefined ? [] : data.manager_databases,
      data.store_databases == undefined ? [] : data.store_databases
    );

    return result;
  }

  public override toFirestore() {
    return {
      active: this.active,
      email: this.email,
      name: this.name,
      notification_tokens: this.notification_tokens,
      phone_number: this.phone_number,
      role: this.role,
      stores: this.stores,
      surname: this.surname,
      distributor: this.distributor,
      illustration_url: this.illustration_url,
      store_distributors: this.store_distributors,
      new_password_required: this.new_password_required,
      manager_databases: this.manager_databases,
      store_databases: this.store_databases,
    };
  }

  public static clone(user: User): User {
    return new User(
      user.ref,
      user.active,
      user.email,
      user.name,
      user.notification_tokens.map((x) => x),
      user.phone_number,
      user.role,
      user.stores.map((x) => x),
      user.surname,
      user.distributor,
      user.illustration_url,
      user.store_distributors.map((x) => x),
      user.new_password_required,
      user.manager_databases,
      user.store_databases
    );
  }
}
