import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import Database from "./Database";

export default class Employee extends SecureatSnapshot {
  active: boolean = true;
  illustration_url: string | null = null;
  name: string = "";
  surname: string = "";
  role: DocumentReference | null = null;
  completed_communications: DocumentReference[] = [];
  stores: DocumentReference[];
  databases: DocumentReference[];

  old_ref: DocumentReference | null = null;

  constructor(
    ref: DocumentReference,
    name: string,
    surname: string,
    active: boolean,
    illustration_url: string | null,
    role: DocumentReference | null,
    completed_communications: DocumentReference[],
    stores: DocumentReference[],
    databases: DocumentReference[],
    old_ref: DocumentReference | null
  ) {
    super(ref);
    this.name = name;
    this.surname = surname;
    this.active = active;
    this.illustration_url = illustration_url;
    this.role = role;
    this.completed_communications = completed_communications;
    this.stores = stores;
    this.databases = databases;
    this.old_ref = old_ref;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Employee {
    const data = snapshot.data()!;

    let result = new Employee(
      snapshot.ref,
      data.name,
      data.surname,
      data.active == undefined ? true : data.active,
      data.illustration_url,
      data.role == undefined ? null : data.role,
      data.completed_communications == undefined
        ? []
        : data.completed_communications,
      data.stores == undefined ? [] : data.stores,
      data.databases == undefined ? [] : data.databases,
      data.old_ref == undefined ? null : data.old_ref
    );

    return result;
  }

  public toFirestore() {
    throw new Error(
      "Employee serialization is not supported by the dashboard rn."
    );
  }
}
