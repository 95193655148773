import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import Attachment from "@/model/Filesystem/Attachment";
import Store from "@/model/Store";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import User from "@/model/User";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export async function uploadDistributorLogo(
  distributor: Distributor,
  file: File
) {
  let path = "distributors/" + distributor.ref?.id!;

  const storage = getStorage();
  const storageRef = ref(storage, path);
  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, file);
}

export async function getDistributorLogoUrl(
  distributor: Distributor
): Promise<string> {
  let path = "distributors/" + distributor.ref?.id!;
  const storage = getStorage();
  try {
    return await getDownloadURL(ref(storage, path));
  } catch {
    console.log("err");
    return "";
  }
}

export async function uploadStoreIllustration(store: Store, file: File) {
  let path = "stores/" + store.ref?.id!;

  const storage = getStorage();
  const storageRef = ref(storage, path);
  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, file);
}

export async function getStoreIllustrationUrl(store: Store): Promise<string> {
  let path = "stores/" + store.ref?.id!;
  const storage = getStorage();
  try {
    return await getDownloadURL(ref(storage, path));
  } catch {
    console.log("err");
    return "";
  }
}

export async function uploadAccountIllustration(account: User, file: File) {
  let path = "accounts/" + account.ref?.id!;

  const storage = getStorage();
  const storageRef = ref(storage, path);
  // 'file' comes from the Blob or File API
  await uploadBytes(storageRef, file);
}

export async function getAccountIllustrationUrl(
  account: User
): Promise<string> {
  let path = "accounts/" + account.ref?.id!;
  const storage = getStorage();
  try {
    return await getDownloadURL(ref(storage, path));
  } catch {
    console.log("err");
    return "";
  }
}

export async function getDownloadUrl(path: string) {
  const storage = getStorage();
  return await getDownloadURL(ref(storage, path));
}
