
import { defineComponent } from "@vue/runtime-core";
import { getUserManager } from "../../firebase/UserManager";
import User from "@/model/User";
import { LoginViewType } from "@/views/LoginViewType";
import SeCheckBox from "../global/SeCheckBox.vue";
import { getState } from "@/pinia/AppState";
import * as Lang from "@/i18n/lang";
import { doc, collection } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";

export default defineComponent({
  data() {
    return {
      Lang: Lang,
    };
  },
  methods: {
    openLogin() {
      (this.$parent as any).switchView(LoginViewType.Login);
    },
    displayWarning(msg: string) {
      let label: HTMLElement = this.$refs.stateLabel as HTMLElement;
      label.style.display = "";
      label.innerHTML = msg;
    },
    async register() {
      var refs = <any>this.$refs;

      let firstname = refs.firstname.getValue();
      let surname = refs.surname.getValue();
      let email = refs.email.getValue().toLowerCase();
      let phone = refs.phone.getValue();
      let password = refs.password.getValue();
      let passwordConf = refs.passwordConf.getValue();

      if (refs.legalTerms.getValue() == false) {
        this.displayWarning("Please accept legal terms");
        return;
      }
      if (password != passwordConf) {
        this.displayWarning("Passwords do not match");
        return;
      }
      if (
        firstname == "" ||
        surname == "" ||
        email == "" ||
        phone == "" ||
        password == ""
      ) {
        this.displayWarning("Please fill all fields.");
        return;
      }

      refs.registerBtn.toggleLoading(true);

      let result = await getUserManager().signup(
        firstname,
        surname,
        email,
        password,
        phone
      );
      if (result.status != "ok") {
        this.displayWarning(result.status);
        refs.registerBtn.toggleLoading(false);
      } else {
        let auth = getAuth();
        let userCredential = await signInWithCustomToken(auth, result.token!);
        refs.registerBtn.toggleLoading(false);

        const user = userCredential.user;
        getUserManager().performLogin(user);
      }
    },
  },
  mounted() {
    let userEmail = getState().initialEmail;
    if (userEmail !== null) {
      (this.$refs.email as any).setValue(userEmail);
    }
  },
  components: { SeCheckBox },
});
