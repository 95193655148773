import { defineStore } from "pinia";
import User from "@/model/User";
import Store from "@/model/Store";
import Task from "@/model/Task";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import Database from "@/model/Database";
import TaskType from "@/model/Tasks/TaskType";
import Module from "@/model/Module";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getElementManager } from "@/firebase/ElementManager";
import { data } from "@/components/home/ProfilingChartConfig";
import { child } from "firebase/database";
import { doc } from "@firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { collection } from "firebase/firestore";

interface DatabaseTaskConfigurationState {
  taskConfiguration: DatabaseTaskConfiguration | null;
  initialTaskConfiguration: DatabaseTaskConfiguration | null;
  database: Database | null;
  creation: boolean;
  selectedModule: ModuleEnum | null;
  selectedTaskType: TaskType | null;
  taskTypes: TaskType[];
  changeSaved: boolean;
}

export const getDatabaseTaskConfigurationState = defineStore(
  "databaseTaskConfigurationState",
  {
    state: (): DatabaseTaskConfigurationState => {
      return {
        initialTaskConfiguration: null,
        taskConfiguration: null,
        database: null,
        creation: false,
        selectedModule: ModuleEnum.Temperatures,
        changeSaved: false,
        selectedTaskType: null,
        taskTypes: [],
      };
    },
    actions: {
      async setup() {
        await getElementManager().ensureDatabaseElementListener(
          this.getDatabaseId()
        );
      },
      getDatabaseId() {
        return this.database?.ref!.id!.toString()!;
      },
      set(
        taskConfiguration: DatabaseTaskConfiguration | null,
        database: Database
      ) {
        this.initialTaskConfiguration = taskConfiguration;
        this.database = database;
        this.creation = this.initialTaskConfiguration == null;
        this.taskConfiguration = null;

        this.selectedTaskType = null;
        this.selectedModule = ModuleEnum.Action;
        this.changeSaved = false;

        if (this.creation) {
          this.taskConfiguration = new DatabaseTaskConfiguration(
            doc(
              collection(
                Firebase.firestore,
                this.database.ref.path,
                "task_configurations"
              )
            )
          );

          this.taskConfiguration.recurrence = "FREQ=DAILY;INTERVAL=1";
        } else if (taskConfiguration instanceof DatabaseTaskConfiguration) {
          this.initialTaskConfiguration = taskConfiguration;
          this.taskConfiguration = DatabaseTaskConfiguration.clone(
            this.initialTaskConfiguration
          );
          this.taskConfiguration.ref = this.initialTaskConfiguration.ref;
        }
      },
    },
  }
);
