import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-41e359a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeButton = _resolveComponent("SeButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['se-picker-container', _ctx.variantClassName])
  }, [
    _createVNode(_component_SeButton, {
      class: "icon-button",
      icon: "/ico/minus.svg",
      iconSize: "16",
      onClick: _ctx.decrement
    }, null, 8, ["onClick"]),
    _withDirectives(_createElementVNode("input", {
      style: _normalizeStyle({ width: _ctx.inputWidth }),
      class: "subtitle",
      type: "number",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      min: _ctx.min,
      max: _ctx.max,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 44, _hoisted_1), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createVNode(_component_SeButton, {
      class: "icon-button",
      icon: "/ico/plus.svg",
      onClick: _ctx.increment
    }, null, 8, ["onClick"])
  ], 2))
}