import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import TaskResult from "./TaskResults/TaskResult";
import TemperatureResult from "./TaskResults/TemperatureResult";
import Store from "./Store";
import Employee from "./Employee";
import { ModuleEnum } from "./ModuleEnum";
import * as ModuleManager from "@/firebase/ModuleManager";

export default class Task extends SecureatSnapshot {
  assigned_employee: DocumentReference | null = null;
  creation_timestamp: string = "";
  completed_timestamp: Timestamp | null = null;
  completed_employee: DocumentReference | null = null;
  description: string = "";
  duration: number = 0;
  configuration: [] = [];
  module: ModuleEnum;
  name: string = "";
  origin: DocumentReference | null = null;
  result: any = [];
  start: Timestamp;

  // Not in firebase structure
  store: Store | null = null;
  employee: Employee | null = null;

  postponed_task: DocumentReference | null = null;
  original_task: DocumentReference | null = null;

  constructor(
    ref: DocumentReference,
    assigned_employee: DocumentReference | null,
    creation_timestamp: string,
    completed_timestamp: Timestamp,
    completed_employee: DocumentReference | null,
    description: string,
    duration: number,
    configuration: [],
    module: ModuleEnum,
    name: string,
    origin: DocumentReference,
    result: any,
    start: Timestamp,
    postponed_task: DocumentReference | null,
    original_task: DocumentReference | null
  ) {
    super(ref);
    this.assigned_employee = assigned_employee;
    this.creation_timestamp = creation_timestamp;
    this.completed_timestamp = completed_timestamp;
    this.completed_employee = completed_employee;
    this.description = description;
    this.duration = duration;
    this.configuration = configuration;
    this.module = module;
    this.name = name;
    this.origin = origin;
    this.result = result;
    this.start = start;
    this.postponed_task = postponed_task;
    this.original_task = original_task;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Task {
    const data = snapshot.data()!;

    var module = ModuleManager.getModuleFromRef(data.module);

    let task = new Task(
      snapshot.ref,
      data.assigned_employee,
      data.creation_timestamp,
      data.completed_timestamp == undefined ? null : data.completed_timestamp,
      data.completed_employee,
      data.description,
      data.duration,
      data.configuration,
      module?.toEnum()!,
      data.name,
      data.origin,
      data.result,
      data.start,
      data.postponed_task == undefined ? null : data.postponed_task,
      data.original_task == undefined ? null : data.original_task
    );

    return task;
  }

  public toFirestore() {
    var moduleRef = ModuleManager.getModuleFromEnum(this.module);
    return {
      assigned_employee: this.assigned_employee,
      creation_timestamp: this.creation_timestamp,
      completed_timestamp: this.completed_timestamp,
      completed_employee: this.completed_employee,
      description: this.description,
      duration: this.duration,
      configuration: this.configuration,
      module: moduleRef,
      name: this.name,
      origin: this.origin,
      result: this.result,
      start: this.start,
      postponed_task: this.postponed_task,
      original_task: this.original_task,
    };
  }
}
