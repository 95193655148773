
import { defineComponent } from "vue";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  props: {},
  data() {
    return {
      callback: Function,
      placeholder: "name",
      Lang: Lang,
      editMode: false,
      context: "",
    };
  },
  emits: ["close", "action"],
  expose: ["openModal"],
  methods: {
    handleAction() {
      let refs = <any>this.$refs;
      const inputValue = refs.input.getValue();

      if (!refs.submitForm.verify()) return;

      if (this.editMode) {
        this.callback(this.context, inputValue);
      } else {
        this.callback(inputValue);
      }
      refs.inputModal.close();
    },
    openModal(
      context: any,
      callbackFunction: any,
      placeholder: string,
      prevText: string
    ) {
      if (context) {
        this.context = context;
        this.editMode = true;
      } else {
        this.editMode = false;
      }

      let refs = <any>this.$refs;
      refs.input.setValue("");
      refs.submitForm.reset();
      this.placeholder = placeholder;
      this.callback = callbackFunction;
      if (prevText) {
        refs.input.setValue(prevText);
        refs.inputModal.open(prevText);
      } else {
        refs.inputModal.open(context);
      }
    },
  },
});
