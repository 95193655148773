
import Card from "../global/Card.vue";
import dateFormat from "dateformat";
import Probe from "../../model/Probe";
import { PropType, defineComponent } from "vue";
import { getState } from "@/pinia/NavigationState";
import { getProbeState } from "@/pinia/probes/ProbeState";
import { getStoreManager } from "@/firebase/StoreManager";
import * as Firebase from "@/firebase/Firebase";
import { doc } from "@firebase/firestore";
import * as DateUtils from "@/utils/DateUtils";

export default defineComponent({
  props: {
    probes: Array as PropType<Probe[]>,
    unactives: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dateFormat: dateFormat,
    };
  },
  methods: {
    getStoreName(probe: Probe) {
      if (probe.storeId == null) {
        return "Pas d'établissement.";
      }
      let store = getStoreManager().getStoreFromCacheOnly(probe.storeId)!;

      return store.name;
    },

    onProbeClick(probe: Probe) {
      getProbeState().set(probe);
      getState().navigate("probe");
    },
  },
});
