
import { defineComponent, PropType, ref } from "vue";
import { languageMappings } from "@/i18n/lang";

export default defineComponent({
  props: {
    labels: Array as PropType<String[]>,
    changed: {
      type: Function,
      required: false,
    },
  },

  expose: ["setSelectedIndex"],

  data() {
    return {
      selection: "",
    };
  },

  methods: {
    setSelectedIndex(index: number) {
      this.selection = this.labels[index];
    },
    selectChip(selection: String) {
      let index = this.labels.indexOf(selection);

      if (index == undefined || index == -1) {
        return;
      }

      this.selection = selection;

      if (this.changed != null) {
        this.changed(index);
      }
    },
  },
});
