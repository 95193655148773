import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getModuleFromEnum, getModuleFromRef } from "@/firebase/ModuleManager";
import { ModuleEnum } from "@/model/ModuleEnum";
import Store from "@/model/Store";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import { getStoreManager } from "@/firebase/StoreManager";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import { getAllTaskTypes } from "@/firebase/TaskTypeManager";
import { DocumentReference } from "firebase/firestore";
import TaskType from "@/model/Tasks/TaskType";
import * as ObjectUtils from "@/utils/ObjectUtils";

interface StoreTaskState {
  store: Store | null;
  targetStore: Store | null;
  stores: Store[];
  tasks: StoreTaskConfiguration[];
  baseTasks: StoreTaskConfiguration[];
  filter: number;
  selectedModule: ModuleEnum;
  taskTypes: TaskType[];
  search: String;
  preventLoad: boolean;
  displayUnactives: boolean;

  database: Database | null;
}

export const Filters = {
  General: 0,
  Actions: 1,
  Temperatures: 2,
  Oils: 3,
};

export const getBulkStoreTaskState = defineStore("BulkStoresTaskState", {
  state: (): StoreTaskState => {
    return {
      database: null,
      targetStore: null,
      store: null as Store | null,
      stores: [],
      tasks: [] as StoreTaskConfiguration[],
      baseTasks: [],
      filter: Filters.General,
      selectedModule: ModuleEnum.Action,
      taskTypes: [],
      search: "",
      preventLoad: false,
      displayUnactives: false,
    };
  },
  actions: {
    hasChanges() {
      for (let i = 0; i < this.tasks.length; i++) {
        if (i >= this.baseTasks.length) {
          break;
        }
        this.baseTasks[i].ref = this.tasks[i].ref;
        if (!ObjectUtils.compareObjects(this.tasks[i], this.baseTasks[i])) {
          return true;
        }
      }

      return false;
    },

    async setup() {
      if (this.preventReload) {
        this.preventReload = false;
        return;
      }

      this.database = getDatabaseManager().getDatabaseByReferenceFromCache(
        this.store.database
      );

      console.log("Bulk store setup.");

      this.tasks = [];
      this.baseTasks = [];

      await getTaskConfigurationManager().ensureDatabaseTaskConfigurationListener(
        this.store.database.id!
      );
      await getTaskConfigurationManager().ensureStoreTaskConfigurationListener(
        this.store.ref.id
      );

      let allTaskConfigurations =
        getTaskConfigurationManager().getTaskConfigurations(this.store);

      for (let taskConfiguration of allTaskConfigurations) {
        if (taskConfiguration instanceof StoreTaskConfiguration) {
          this.tasks.push(taskConfiguration);
        } else {
          this.tasks.push(
            StoreTaskConfiguration.fromOrigin(
              <DatabaseTaskConfiguration>taskConfiguration,
              this.store
            )
          );
        }
      }

      this.tasks = this.tasks.map((x) => StoreTaskConfiguration.clone(x));

      this.baseTasks = this.tasks.map((x: StoreTaskConfiguration) =>
        StoreTaskConfiguration.clone(x)
      );

      this.taskTypes = await getAllTaskTypes(this.store);

      await getStoreManager().initialize();

      this.stores = getStoreManager().getStores(false);
    },
    getTypeName(ref: DocumentReference) {
      let type = this.taskTypes.find((x) => x.ref.id == ref.id);

      if (type) {
        return type.name;
      }
      return "?";
    },
    getTaskType(ref: DocumentReference) {
      return this.taskTypes.find((x) => x.ref.id == ref.id);
    },
    getTypeColor(ref: DocumentReference) {
      let type = this.taskTypes.find((x) => x.ref.id == ref.id);

      if (type) {
        return type.color;
      }
      return "black";
    },
    set(store: Store) {
      this.store = store;
    },
    setFilter(filter: number) {
      this.filter = filter;
    },
  },
  getters: {
    displayTasks(): StoreTaskConfiguration[] {
      let results: StoreTaskConfiguration[] = [];

      switch (this.filter) {
        case Filters.General:
          results = this.tasks.filter(
            (x) =>
              this.search == "" ||
              x.getName().toLowerCase().includes(this.search.toLowerCase())
          );
          break;
        case Filters.Actions:
          results = this.tasks.filter(
            (x: StoreTaskConfiguration) =>
              getModuleFromRef(x.getModule()) ===
                getModuleFromEnum(ModuleEnum.Action) &&
              (this.search == "" ||
                x.getName().toLowerCase().includes(this.search.toLowerCase()))
          );
          break;
        case Filters.Temperatures:
          results = this.tasks.filter(
            (x: StoreTaskConfiguration) =>
              getModuleFromRef(x.getModule()) ===
                getModuleFromEnum(ModuleEnum.Temperatures) &&
              (this.search == "" ||
                x.getName().toLowerCase().includes(this.search.toLowerCase()))
          );
          break;
        case Filters.Oils:
          results = this.tasks.filter(
            (x: StoreTaskConfiguration) =>
              getModuleFromRef(x.getModule()) ===
                getModuleFromEnum(ModuleEnum.Oils) &&
              (this.search == "" ||
                x.getName().toLowerCase().includes(this.search.toLowerCase()))
          );
          break;
      }

      return results.filter((x) => x.active || this.displayUnactives);
    },
    isGeneral() {
      return this.filter === Filters.General;
    },
    isActions() {
      return this.filter === Filters.Actions;
    },
    isTemperatures() {
      return this.filter === Filters.Temperatures;
    },
    isOils() {
      return this.filter === Filters.Oils;
    },
  },
});
