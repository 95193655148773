
import { Component, defineComponent } from "@vue/runtime-core";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import LoginView from "../../views/LoginView.vue";
import { ref } from "vue";
import * as Lang from "../../i18n/lang";
import { getState as getAppState } from "../../pinia/AppState";
import { getState as getNavigationState } from "../../pinia/NavigationState";
import * as UserManager from "../../firebase/UserManager";
import User from "../../model/User";
import { FirebaseError } from "firebase/app";
import { LoginViewType } from "@/views/LoginViewType";
import SeModal from "../global/SeModal.vue";

export default defineComponent({
  data() {
    return { Lang: Lang, modalMessage: "" };
  },
  methods: {
    displayMessage(message: string) {
      var refs = <any>this.$refs;
      this.modalMessage = message;
      refs.modal.open();
    },
    async recover() {
      var refs = <any>this.$refs;
      var email = refs.email.getValue();

      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          this.displayMessage(Lang.getI18N("login.recovery.success"));
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
          this.displayMessage(Lang.getI18N("login.recovery.error"));
          // ..
        });
    },
  },
  components: { SeModal },
});
