import { getAttachmentManager } from "@/firebase/AttachmentManager";
import Attachment from "../Filesystem/Attachment";
import UploadStrategy from "./UploadStategy";
import * as Filesystem from "@/firebase/Filesystem";
import * as Snackbars from "@/utils/Snackbars";

export default class FirebaseUploadStrategy extends UploadStrategy {
  constructor(file: File, path: string) {
    super(file);
    this.path = path;
  }
  public path: string;
  public override async prepare(): Promise<void> {}
  public override generateAttachment(): Attachment | null {
    return new Attachment(
      this.getFileName()!,
      getAttachmentManager().resolveType(this.file),
      this.path + "/" + this.file.name,
      null,
      null
    );
  }
  public override getFileName(): string | null {
    return this.file.name;
  }
  public override upload(done: Function): void {
    var fileReader = new FileReader();

    var instance = this;

    var snackbar = Snackbars.uploader(this);

    fileReader.addEventListener("load", async function (evt: any) {
      let result = await Filesystem.uploadFile(
        instance.path,
        instance.file,
        evt.target.result,
        (value: number) => {
          console.log(value);
          instance.progress.value = value;
        }
      );

      done(instance.file, result);

      Snackbars.remove(snackbar.el.parentNode);
    });

    fileReader.readAsArrayBuffer(this.file);
  }
}
