import * as tus from "tus-js-client";

export function uploadFile(
  file: File,
  endpoint: string,
  progress: Function,
  done: Function,
  client_id: string,
  video_id: string,
  token: string
) {
  var upload = new tus.Upload(file, {
    endpoint: endpoint,

    retryDelays: [0, 3000, 5000, 10000, 20000],

    metadata: {
      filename: file.name,
      filetype: file.type,
      client_id: client_id,
      video_id: video_id,
      token: token,
    },
    onError: function (error) {
      console.log("Failed because: " + error);
    },
    onProgress: function (bytesUploaded, bytesTotal) {
      var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
      progress(percentage);
    },
    onSuccess: function () {
      done();
    },
  });

  upload.start();
}
