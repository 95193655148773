
import Distributor from "@/model/Distributor";
import Card from "../global/Card.vue";
import WideCard from "../global/WideCard.vue";
import * as Lang from "@/i18n/lang";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import { getDistributorsState } from "@/pinia/distributors/DistributorsState";
import { getState } from "@/pinia/NavigationState";

export default defineComponent({
  props: {
    distributors: Array as PropType<Distributor[]>,
    unactives: Boolean,
  },
  methods: {
    onDistributorClick(distributor: Distributor) {
      getDistributorState().set(distributor);
      getState().navigate("distributor");
    },
  },
});
