import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";
import { CommunicationState } from "./CommunicationState";
import CommunicationTarget from "./CommunicationTarget";
import Quiz from "./Quiz";
import * as DateUtils from "@/utils/DateUtils";

export default class Communication extends SecureatSnapshot {
  active: boolean;
  user: DocumentReference;
  name: string;
  target: CommunicationTarget;
  quiz: Quiz | null;
  attachment: Attachment | null;
  start: Date;
  end: Date;

  /* Transcient */

  constructor(
    ref: DocumentReference,
    active: boolean,
    user: DocumentReference,
    name: string,
    target: CommunicationTarget,
    quiz: Quiz | null,
    attachment: Attachment | null,
    start: Date,
    end: Date
  ) {
    super(ref);
    this.active = active;
    this.user = user;
    this.name = name;
    this.target = target;
    this.quiz = quiz;
    this.attachment = attachment;
    this.start = start;
    this.end = end;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Communication {
    const data = snapshot.data()!;

    let result = new Communication(
      snapshot.ref,
      data.active,
      data.user,
      data.name,
      CommunicationTarget.fromFirestore(data.target),
      data.quiz != undefined && data.quiz != null
        ? Quiz.fromFirestore(data.quiz)
        : null,
      data.attachment != null && data.attachment != undefined
        ? Attachment.fromFirestore(data.attachment)
        : null,
      DateUtils.getDateFromSeconds(data.start.seconds),
      DateUtils.getDateFromSeconds(data.end.seconds)
    );
    return result;
  }

  public getState(): CommunicationState {
    var now = new Date();
    const nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      )
    );

    if (now >= this.end) {
      return CommunicationState.Passed;
    } else if (now <= this.start) {
      return CommunicationState.Coming;
    }

    return CommunicationState.Running;
  }
  public toFirestore() {
    return {
      active: this.active,
      user: this.user,
      name: this.name,
      target: this.target.toFirestore(),
      quiz: this.quiz == null ? null : this.quiz?.toFirestore(),
      attachment:
        this.attachment == null ? null : this.attachment?.toFirestore(),
      start: this.start,
      end: this.end,
    };
  }
  public clone() {
    return new Communication(
      this.ref,
      this.active,
      this.user,
      this.name,
      this.target.clone(),
      this.quiz == null ? null : this.quiz.clone(),
      this.attachment == null ? null : this.attachment.clone(),
      this.start,
      this.end
    );
  }
}
