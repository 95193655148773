import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";
import * as DateUtils from "@/utils/DateUtils";
import DistributorShopItem from "./DistributorShopItem";

export default class DatabaseShopitem extends SecureatSnapshot {
  active: boolean | null;
  name: string | null;
  description: string | null;
  price: number | null;
  illustration: string | null;
  // transcient
  illustration_path: string | null = null;
  origin: DocumentReference | null = null;

  originValue: DistributorShopItem | null = null;

  constructor(
    ref: DocumentReference,
    active: boolean | null,
    name: string | null,
    description: string | null,
    price: number | null,
    illustration: string | null,
    origin: DocumentReference | null
  ) {
    super(ref);
    this.active = active;
    this.name = name;
    this.description = description;
    this.price = price;
    this.illustration = illustration;
    this.origin = origin;
  }

  public getActive(): boolean {
    if (this.originValue == null) return this.active!;

    return this.active == null ? this.originValue?.active! : this.active;
  }
  public getName(): string {
    if (this.originValue == null) return this.name!;

    return this.name == null ? this.originValue?.name! : this.name;
  }
  public getDescription(): string {
    if (this.originValue == null) return this.description!;

    return this.description == null
      ? this.originValue!.description
      : this.description;
  }
  public getPrice(): number {
    if (this.originValue == null) return this.price!;

    return this.price == null ? this.originValue?.price! : this.price;
  }
  public getIllustration(): string {
    if (this.originValue == null) return this.illustration!;

    return this.illustration == null
      ? this.originValue!.illustration!
      : this.illustration;
  }
  public setOriginValue(origin: DistributorShopItem) {
    this.originValue = origin;
  }
  public static fromFirestore(snapshot: DocumentSnapshot): DatabaseShopitem {
    const data = snapshot.data()!;

    let result = new DatabaseShopitem(
      snapshot.ref,
      data.active,
      data.name,
      data.description,
      data.price,
      data.illustration,
      data.origin == undefined ? null : data.origin
    );
    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      name: this.name,
      description: this.description,
      price: this.price,
      illustration: this.illustration,
      origin: this.origin,
    };
  }
}
