
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import DocumentRow from "@/components/documents/DocumentRow.vue";
import * as Filesystem from "@/firebase/Filesystem";
import CloudItem from "@/model/Filesystem/CloudItem";
import { HalfCircleSpinner } from "epic-spinners";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";
import { CloudItemType } from "@/model/Filesystem/CloudItemType";
import * as DOMUtils from "@/utils/DOMUtils";
import { Console } from "console";
import ReadonlyField from "../global/ReadonlyField.vue";
import { getState } from "@/pinia/AppState";
import { Role } from "@/model/User";
import * as Modals from "@/utils/Modals";
import * as Snackbars from "@/utils/Snackbars";
import { UploadResult, getMetadata, getStorage, ref } from "firebase/storage";
import * as ImageUtils from "@/utils/ImageUtils";
import UploadStrategy from "@/model/Uploads/UploadStategy";
import * as UploadManager from "@/model/Uploads/UploadManager";
import FirebaseUploadStrategy from "@/model/Uploads/FirebaseUploadStrategy";
import GCoreUploadStrategy from "@/model/Uploads/GCoreUploadStrategy";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

export default defineComponent({
  props: ["title", "rootDocuments"],

  expose: [
    "setDocuments",
    "getPath",
    "setPath",
    "setLoading",
    "updateDocuments",
    "back",
  ],

  data() {
    return {
      Role: Role,
      user: getState().userData,
      cloudItems: [] as CloudItem[],
      path: "/",
      pathHistory: [] as string[],
      loading: false,
      displayPath: "",
      Lang: Lang,
      imgUrl: "",
    };
  },

  methods: {
    async uploadFile() {
      let upload = <any>this.$refs.upload;
      let file = upload.getFiles()[0];

      if (file == undefined) {
        return;
      }

      if (file.size / 1000000 > 500) {
        Modals.open(Lang.getI18N("documents.file-too-big"), "Infos");
        return;
      }

      var exist = this.cloudItems.some((x) => x.name == file.name);

      if (exist) {
        Modals.open(Lang.getI18N("documents.file-exists"), "Infos");
        return;
      }

      let strategy = await UploadManager.createStrategy(file, this.path);

      strategy.upload(() => this.onUploaded(strategy));

      upload.reset();
    },

    async onUploaded(strategy: UploadStrategy) {
      if (strategy instanceof FirebaseUploadStrategy) {
        var firebaseUpload = strategy as FirebaseUploadStrategy;
        var item = new CloudItem(
          strategy.file.name,
          CloudItemType.File,
          "",
          "/ico/file.svg"
        );

        item.path = firebaseUpload.path + "/" + strategy.file.name;

        this.cloudItems.push(item);
      } else if (strategy instanceof GCoreUploadStrategy) {
        var gcoreUpload = strategy as GCoreUploadStrategy;

        await Filesystem.createVideoLinkfile(
          this.path,
          strategy.file.name,
          gcoreUpload.getUrl(),
          gcoreUpload.getVideoId()
        );

        var item = new CloudItem(
          strategy.file.name,
          CloudItemType.File,
          this.path + "/" + strategy.file.name + ".vid",
          "/ico/file.svg"
        );

        item.isVideo = true;

        this.cloudItems.push(item);
      }
    },

    openCreateFolderModal() {
      if (this.path == "/") {
        return;
      }
      let refs = <any>this.$refs;

      refs.folderName.setValue("");

      refs.createFolderModal.open();
    },
    async updateDocuments() {
      this.setLoading(true);

      let path = this.getPath();

      if (path == "/") {
        this.cloudItems = this.rootDocuments;
      } else {
        this.cloudItems = await Filesystem.getDocuments(path);

        var folders = this.cloudItems
          .filter((x) => x.type == CloudItemType.Folder)
          .sort((a, b) => a.name.localeCompare(b.name));

        var files = this.cloudItems
          .filter((x) => x.type == CloudItemType.File)
          .sort((a, b) => a.name.localeCompare(b.name));

        this.cloudItems = folders.concat(files);

        for (let item of this.rootDocuments) {
          if (item.path == path) {
            for (let additional of item.additionalItems) {
              this.cloudItems.unshift(additional);
            }
          }
        }
      }

      this.setLoading(false);
    },
    getPath() {
      return this.path;
    },
    async lockClick(item: CloudItem) {
      item.locked = !item.locked;
      await Filesystem.applyLock(item);
    },
    renameClick(item: CloudItem) {
      let refs = <any>this.$refs;

      refs.itemName.setValue(item.name);
      refs.renameModal.open(item);
    },
    async applyRename() {
      let refs = <any>this.$refs;
      let item = refs.renameModal.context;

      let newName = refs.itemName.getValue();

      await Filesystem.rename(item.path, item.type, newName);
      item.name = newName;

      refs.renameModal.close();
    },
    async downloadClick(item: CloudItem) {
      if (item.isVideo) {
        let storage = getStorage();
        let metadata = await getMetadata(ref(storage, item.path));

        var link = await SecureatServerApi.getVideoDownloadLink(
          metadata.customMetadata.videoId
        );
        DOMUtils.forceDownloadFromUrl(link, item.name);
      } else {
        let link = await Filesystem.getDownloadLink(item.path);
        window.open(link, "_blank");
      }
    },
    deleteClick(item: CloudItem) {
      var refs = <any>this.$refs;
      refs.deletionModal.open(item);
    },
    cancelDeletion() {
      var refs = <any>this.$refs;
      refs.deletionModal.close();
    },
    async performDeletion() {
      var refs = <any>this.$refs;
      var item = refs.deletionModal.context;

      let path = item.path;
      try {
        if (item.isVideo) {
          var videoRef = ref(getStorage(), path);
          var metadata = await getMetadata(videoRef);

          var videoId = metadata.customMetadata!.videoId;

          await SecureatServerApi.deleteGCoreVideo(videoId);
        }

        await Filesystem.deleteRef(path, item.type);
        const index = this.cloudItems.indexOf(item);
        const x = this.cloudItems.splice(index, 1);
      } catch {
        console.log("Cannot delete element.");
      }
      refs.deletionModal.close();
    },
    async itemClick($event: any, item: CloudItem) {
      let refs = <any>this.$refs;

      if (item.type == CloudItemType.Folder) {
        refs.searchBar.setSearch("");
        this.path = item.path;
        this.pathHistory.push(this.path);
        await this.updateDocuments();
        this.displayPath += "/" + item.name;
      } else {
        let extension = item.name.match(/\.[0-9a-z]+$/i)![0].toLowerCase();

        if (
          extension == ".png" ||
          extension == ".jpg" ||
          extension == ".jpeg" ||
          extension == ".gif"
        ) {
          this.imgUrl = await Filesystem.getDownloadLink(item.path);
          refs.imageModal.open();
        } else {
          this.downloadClick(item);
        }
      }
    },

    setLoading(loading: boolean) {
      this.loading = loading;
    },

    async createFolder() {
      let refs = <any>this.$refs;

      let name = refs.folderName.getValue();

      let path = this.getPath() + "/" + name;

      refs.folderName.setValue("");

      refs.createFolderModal.close();

      await Filesystem.createFolder(path);

      this.cloudItems.push(
        new CloudItem(name, CloudItemType.Folder, path, "/ico/folder2.svg", 0)
      );
    },
    cropDisplayPath() {
      let index = this.displayPath.lastIndexOf("/");
      if (index !== -1) {
        this.displayPath = this.displayPath.slice(0, index);
      }
    },
    back() {
      let refs = <any>this.$refs;

      if (this.pathHistory.length == 0) {
        return;
      }
      if (this.pathHistory.length == 1) {
        this.path = this.pathHistory[0];
        this.cropDisplayPath();
        this.cloudItems = this.rootDocuments;
        this.pathHistory = [];
        this.pathHistory.push("/");
        return;
      }
      var backPath = this.pathHistory[this.pathHistory.length - 2];

      refs.searchBar.setSearch("");

      this.path = backPath;

      this.cropDisplayPath();

      this.pathHistory.pop();

      this.updateDocuments();
    },
  },
  components: {
    DocumentRow,
    HalfCircleSpinner,
    SeLoadingIndicator,
    ReadonlyField,
  },
});
