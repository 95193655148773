
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import * as Device from "@/utils/Device";
export default defineComponent({
  props: ["result"],
  data() {
    return {
      employeeName: "",
    };
  },
  methods: {
    displayPicture() {
      if (Device.isMobile()) {
        window.open(this.result.url, "_blank");
        return;
      }

      let refs = <any>this.$refs;
      refs.pictureModal.open();
    },
  },
  async mounted() {
    if (this.result.employee != null) {
      let employee = await TaskManager.getEmployee(this.result.employee);
      this.employeeName = employee.name + " " + employee.surname;
    }
  },
});
