
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Answer } from "@/model/Auditing/Answer";
import { Question } from "@/model/Auditing/Question";
import { truncateText } from "@/utils/StringUtils";
import { getAuditResultsState } from "@/pinia/audits/AuditResultsState";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  props: {
    question: {
      type: Question,
      required: true,
    },
    answers: {
      type: Array as () => Answer[],
      required: true,
    },
  },

  async setup() {
    var state = getAuditResultsState();

    return {
      state: state,
    };
  },

  methods: {
    showComment(answer: Answer) {
      Modals.open(answer.comment!, Lang.getI18N("comment"));
    },
  },

  data() {
    return {
      Lang: Lang,
      truncateText: truncateText,
    };
  },
});
