import { defineStore } from "pinia";
import User from "@/model/User";
import Store from "@/model/Store";
import Task from "@/model/Task";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";

interface TaskState {
  task: Task | null;
  store: Store | null;
}

export const getTaskState = defineStore("state", {
  state: (): TaskState => {
    return {
      task: null,
      store: null,
    };
  },
  actions: {},
});
