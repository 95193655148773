import { defineStore } from "pinia";
import StoreElement from "@/model/Elements/StoreElement";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import Store from "@/model/Store";
import Database from "@/model/Database";
import ElementType from "@/model/ElementType";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";

interface AllergensState {
  elements: StoreElement[] | DatabaseElement[] | null;
  store: Store | null;
  database: Database | null;
  elementTypes: ElementType[];
}

export const getAllergensState = defineStore("allergensState", {
  state: (): AllergensState => {
    return {
      elements: [],
      store: null,
      database: null,
      elementTypes: [],
    };
  },
  actions: {
    async setup() {
      console.log(this.store);
      console.log(this.database);
      if (this.store && this.database) {
        this.elementTypes = await ElementTypeManager.getAllElementsTypes(
          this.store,
          this.database.ref!.id
        );
      } else if (this.database) {
        this.elementTypes = await ElementTypeManager.getDatabaseElementTypes(
          this.database.ref!.id
        );
      } else if (this.store) {
        this.elementTypes = await ElementTypeManager.getStoreElementTypes(
          this.store
        );
      } else {
        return [];
      }
      console.log(this.elementTypes);
    },

    set(elements: any, store: Store | null, database: Database | null) {
      const filteredElements = elements.filter(
        (element: any) => element.allergens && element.allergens.length > 0
      );
      this.elements = filteredElements;
      this.store = store;
      this.database = database;
    },
  },
});
