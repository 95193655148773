import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "text-white",
  style: {"font-weight":"500","font-size":"12px"}
}
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeSnackbar = _resolveComponent("SeSnackbar")!

  return (_openBlock(), _createBlock(_component_SeSnackbar, null, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.uploadStrategy.getFileName()), 1),
      _createElementVNode("progress", {
        class: "file-loading-bar",
        value: _ctx.progress
      }, null, 8, _hoisted_2)
    ]),
    _: 1
  }))
}