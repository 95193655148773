
import * as StoreManager from "../../firebase/StoreManager";
import { getStoreState } from "@/pinia/stores/StoreState";
import { useRouter } from "vue-router";
import { getState } from "@/pinia/NavigationState";
import { defineComponent, nextTick } from "vue";
import Store from "@/model/Store";
import ToggleSwitch from "../global/ToggleSwitch.vue";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import * as Firebase from "@/firebase/Firebase";
import { collection, doc } from "firebase/firestore";
import * as DOMUtils from "@/utils/DOMUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as ImageUtils from "@/utils/ImageUtils";
import DatabaseShopItem from "@/model/Shop/DatabaseShopItem";

export default defineComponent({
  props: {
    shopItem: {
      type: DatabaseShopItem,
      required: true,
    },
  },
  data() {
    console.log(this.shopItem);
    return {
      hasIllustration: this.shopItem.getIllustration() != null,
    };
  },
  setup() {},
  methods: {
    pickIllustration(shopItem: DatabaseShopItem) {
      DOMUtils.selectFileDialog(
        (e: any) => this.onFileSelect(e, shopItem),
        " image/*"
      );
    },
    onFileSelect(e: any, shopItem: DatabaseShopItem) {
      let picture: any = this.$refs.picture;
      let file = e.target.files[0];
      const fileReader = new FileReader();

      var refs = <any>this.$refs;
      var instance = this;
      fileReader.addEventListener("load", async function (evt: any) {
        instance.hasIllustration = true;

        refs.illustration.src = evt.target.result;

        let path =
          "distributors/" +
          getDistributorState().distributor?.ref.id +
          "/shops/";

        shopItem.illustration_path = path + file.name;

        var strategy = await UploadManager.createStrategy(file, path);

        UploadManager.addFileToUpload(strategy);
      });

      fileReader.readAsDataURL(file);
    },
  },
});
