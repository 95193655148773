
import { defineComponent } from "@vue/runtime-core";
import dateFormat from "dateformat";

export default defineComponent({
  expose: ["getStart", "getEnd", "setStart", "setEnd"],

  data() {
    return {
      dateFormat: dateFormat,
      range: {
        start: new Date(),
        end: new Date(),
      },
    };
  },

  methods: {
    dateSelected(e: any, range: any, toggle: any) {
      toggle({ ref: e.target });
    },
    getStart() {
      return this.range.start;
    },
    getEnd() {
      return this.range.end;
    },

    setStart(date: Date) {
      this.range.start = date;
    },
    setEnd(date: Date) {
      this.range.end = date;
    },
  },
});
