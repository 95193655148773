
import * as StoreManager from "../../firebase/StoreManager";
import { getStoreState } from "@/pinia/stores/StoreState";
import { useRouter } from "vue-router";
import { getState } from "@/pinia/NavigationState";
import { defineComponent } from "vue";
import Store from "@/model/Store";
import ToggleSwitch from "../global/ToggleSwitch.vue";
import { getDistributorState } from "@/pinia/distributors/DistributorState";
import DistributorShopItem from "@/model/Shop/DistributorShopItem";
import * as Firebase from "@/firebase/Firebase";
import { collection, doc } from "firebase/firestore";
import * as DOMUtils from "@/utils/DOMUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import DatabaseShopitem from "@/model/Shop/DatabaseShopItem";
import { getDatabaseState } from "@/pinia/databases/DatabaseState";
import DatabaseShopItemCard from "./DatabaseShopItemCard.vue";

export default defineComponent({
  props: [],
  setup() {
    return {
      state: getDatabaseState(),
    };
  },
  methods: {
    addShopItem() {
      this.state.shopItems.push(
        new DatabaseShopitem(
          doc(
            collection(
              Firebase.firestore,
              this.state.database!.ref.path,
              "shop"
            )
          ),
          true,
          "",
          "",
          100,
          null,
          null
        )
      );
    },
  },
  components: { DatabaseShopItemCard },
});
