import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "main-layout",
  style: {"overflow":"hidden"}
}
const _hoisted_2 = {
  class: "container scrollbars flex col grow",
  style: {"overflow":"scroll"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_BulkDatabaseTaskTable = _resolveComponent("BulkDatabaseTaskTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      backButton: "true",
      title: _ctx.state.database.name
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BulkDatabaseTaskTable)
    ])
  ]))
}