
import NavContent from "./NavContent.vue";
import { useRoute } from "vue-router";
import { getState } from "@/pinia/AppState";
import * as DOMUtils from "@/utils/DOMUtils";

export default {
  components: { NavContent },

  methods: {
    onClick(e: any) {
      if (!DOMUtils.isDescendantOf(e.target!, this.$el)) {
        if (e.target.id != "navDisplay") {
          getState().hideNavPanel();
        }
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.onClick);
  },
};
