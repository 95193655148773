import { FirebaseApp, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import FirebaseConfig from "./FirebaseConfig";

const firebaseOptions = require("./../firebase_options.json");

export let firebaseConfig: FirebaseConfig;

export let firebase: FirebaseApp;

export let firestore: Firestore;

export function initalize() {
  console.log(
    "Welcome to Secureat dashboard (" + firebaseOptions.projectId + ")"
  );

  firebaseConfig = firebaseOptions;

  firebase = initializeApp(firebaseConfig);
  firestore = getFirestore(firebase);
}
