import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a86e4dd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "se-input-container" }
const _hoisted_2 = ["readonly", "placeholder", "value", "type", "required"]
const _hoisted_3 = { class: "text-muted floating-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      size: "5",
      readonly: _ctx.readonly,
      style: _normalizeStyle('background-color:' + _ctx.background),
      placeholder: _ctx.placeholderBase,
      value: _ctx.value == undefined ? _ctx.modelValue : _ctx.value,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
      class: "se-input",
      type: _ctx.type,
      required: _ctx.required
    }, null, 44, _hoisted_2),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.placeholder), 1)
  ]))
}