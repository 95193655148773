
import NavPanel from "@/nav/NavPanel.vue";
import NavHeader from "@/nav/NavHeader.vue";
import BulkElementTable from "@/components/bulk/BulkElementTable.vue";

import { defineComponent } from "vue";
import * as Lang from "@/i18n/lang";
import BulkStoreElementTable from "@/components/bulk/BulkStoreElementTable.vue";
import { getBulkStoreElementState } from "@/pinia/elements/BulkStoreElementState";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  async setup() {
    let state = getBulkStoreElementState();

    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },
  async mounted() {},
  methods: {},

  async beforeRouteLeave(to, from, next) {
    if (to.name == "storeElement") {
      next();
      return;
    }
    let changes = getBulkStoreElementState().hasChanges();

    if (!changes) {
      next();
    } else {
      const confirmLeave = await Modals.openYesNo(
        Lang.getI18N("unsaved-changes"),
        "Secureat",
        "Oui",
        "Non"
      );

      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    }
  },

  components: { BulkStoreElementTable },
});
