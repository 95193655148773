
import * as Lang from "@/i18n/lang";
import { defineComponent, nextTick } from "@vue/runtime-core";

import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  methods: {
    reset() {
      let inputs = this.$el.querySelectorAll(".se-input");

      for (let input of inputs) {
        if (input != undefined) {
          if (input.attributes.required != undefined) {
            let span = input.parentNode.querySelector(".text-red");
            this.removeErrorSpan(span);
          }
        }
      }
    },

    verify(): boolean {
      let result = true;
      const inputs = Array.from(
        this.$el.querySelectorAll(".se-input")
      ) as HTMLInputElement[];

      for (let input of inputs) {
        if (DOMUtils.isElementVisible(input)) {
          const isRequired = input.hasAttribute("required");
          const span = input.parentNode!.querySelector(".text-red");

          if (isRequired && this.isInvalidInput(input)) {
            result = this.handleInvalidInput(input, span);
          } else {
            this.removeErrorSpan(span);
          }
        }
      }

      return result;
    },

    isInvalidInput(input: HTMLInputElement): boolean {
      return input.value === "" || /^\s*$/.test(input.value);
    },

    handleInvalidInput(input: HTMLElement, span: Element | null): boolean {
      if (!span) {
        span = this.createErrorSpan();
        input.parentNode!.appendChild(span);
      }

      return false;
    },

    createErrorSpan(): HTMLElement {
      const span = document.createElement("span");
      span.innerHTML = Lang.getI18N("required-field");
      span.classList.add("text-red");
      return span;
    },

    removeErrorSpan(span: Element | null): void {
      if (span) {
        span.parentNode!.removeChild(span);
      }
    },
  },
});
