
import StoreElement from "@/model/Elements/StoreElement";
import Element from "@/model/Elements/Element";
import Card from "../global/Card.vue";
import WideCard from "../global/WideCard.vue";
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { getStoreElementState } from "@/pinia/elements/StoreElementState";
import { getDatabaseElementState } from "@/pinia/elements/DatabaseElementState";
import { getState } from "@/pinia/NavigationState";
import DatabaseElement from "@/model/Elements/DatabaseElement";
export default defineComponent({
  props: ["elements", "database", "storeDatabase", "store", "unactives"],
  data() {
    return { Lang: Lang };
  },
  setup(props) {},
  methods: {
    getDatabaseId() {
      if (this.storeDatabase) {
        if (this.store.database != null) {
          return this.store.database.id!;
        } else {
          return null;
        }
      } else {
        return this.database.ref.id!;
      }
    },
    onElementClick(element: Element) {
      if (this.storeDatabase) {
        var storeElementState = getStoreElementState();

        storeElementState.set(element, this.store);
        getState().navigate("storeElement");
      } else {
        getDatabaseElementState().set(<DatabaseElement>element, this.database);
        getState().navigate("databaseElement");
      }
    },
  },
});
