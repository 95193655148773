
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  expose: ["getValue", "setValue"],
  methods: {
    getValue(): boolean {
      let input = this.$el.childNodes[this.$el.childNodes.length - 2];
      return input.checked;
    },
    setValue(value: boolean) {
      let input = this.$el.childNodes[this.$el.childNodes.length - 2];
      input.checked = value;
    },
  },
});
