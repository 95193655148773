
import { defineComponent, nextTick, PropType, ref } from "vue";
import { Database } from "firebase/database";
import RRuleInput from "@/components/databases/task_configurations/RRuleInput.vue";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";
import { collection, doc, DocumentReference } from "firebase/firestore";
import * as Lang from "@/i18n/lang";
import * as Snackbars from "@/utils/Snackbars";
import * as ObjectUtils from "@/utils/ObjectUtils";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import SeCheckBox from "../global/SeCheckBox.vue";
import * as Firebase from "@/firebase/Firebase";
import { getDatabaseTaskConfigurationState } from "@/pinia/tasks/DatabaseTaskConfigurationState";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { RRule, rrulestr } from "rrule";
import TaskModuleConfigurationAction from "@/model/Tasks/config/TaskModuleConfigurationAction";
import TaskModuleConfigurationTemperature from "@/model/Tasks/config/TaskModuleConfigurationTemperature";
import TaskModuleConfigurationOils from "@/model/Tasks/config/TaskModuleConfigurationOils";
import TaskActionList from "../databases/TaskActionList.vue";
import SeFadeButton from "../global/SeFadeButton.vue";
import * as DateUtils from "@/utils/DateUtils";
import { getBulkStoreTaskState } from "@/pinia/tasks/BulkStoreTaskState";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import { getStoreTaskConfigurationState } from "@/pinia/tasks/StoreTaskConfigurationState";
import ReadonlyField from "../global/ReadonlyField.vue";
import { ModuleEnum } from "@/model/ModuleEnum";
import * as ModuleManager from "@/firebase/ModuleManager";
import * as TaskTypeManager from "@/firebase/TaskTypeManager";
import TaskType from "@/model/Tasks/TaskType";

export default defineComponent({
  components: {
    RRuleInput,
    TaskActionList,
  },
  methods: {
    openEditTaskTypeModal(taskTypeRef: DocumentReference) {
      let taskType = this.state.getTaskType(taskTypeRef);
      var refs = <any>this.$refs;

      refs.taskTypeName.setValue(taskType.name);
      refs.colorPicker.setValue(taskType.color);
      refs.taskTypeModal.open(taskType);
    },
    onSearchChange(value: string) {
      this.state.search = value;
    },
    async modifyOrCreateTaskType() {
      var refs = <any>this.$refs;

      var name = refs.taskTypeName.getValue();

      var color = refs.colorPicker.getValue();

      if (name.trim() == "") {
        return;
      }
      if (refs.taskTypeModal.context == null) {
        var type = new TaskType(
          doc(
            collection(
              Firebase.firestore,
              this.state.store!.ref.path,
              "task_types"
            )
          ),
          name,
          color,
          true
        );

        await TaskTypeManager.createStoreTaskType(this.state.store!, type);

        this.state.taskTypes.push(type);
        this.state.selectedTaskType = type;
      } else {
        var taskType = <TaskType>refs.taskTypeModal.context;

        taskType.name = name;
        taskType.color = color;
        await TaskTypeManager.modifyTaskType(taskType);
      }

      refs.taskTypeModal.close();
    },
    openTaskTypeModal() {
      var refs = <any>this.$refs;
      refs.taskTypeName.setValue("");
      refs.colorPicker.setValue("#454eff");
      refs.taskTypeModal.open();
    },
    openExportModal() {
      var refs = <any>this.$refs;

      refs.exportModal.open();
    },
    async exportTasks() {
      var refs = <any>this.$refs;

      if (this.state.targetDatabase == null) {
        Snackbars.display("Aucune base de donnée selectionnée.");
        return;
      }

      if (this.checkedTasks.length == 0) {
        Snackbars.display("Aucune tâche selectionnée.");
        return;
      }

      getTaskConfigurationManager().exportTasks(
        this.checkedTasks,
        this.state.database,
        this.state.targetDatabase
      );

      refs.exportModal.close();
    },

    openNewTaskModal() {
      var refs = <any>this.$refs;

      refs.newTaskModal.open();
    },
    createNewTask() {
      let configuration: any = null;

      switch (this.state.selectedModule) {
        case ModuleEnum.Action:
          configuration = new TaskModuleConfigurationAction([]);
          break;

        case ModuleEnum.Temperatures:
          configuration = new TaskModuleConfigurationTemperature(
            [],
            false,
            false
          );
          break;

        case ModuleEnum.Oils:
          configuration = new TaskModuleConfigurationOils([]);
          break;
      }

      var task = new StoreTaskConfiguration(
        doc(
          collection(
            Firebase.firestore,
            this.state.store.ref.path,
            "task_configurations"
          )
        ),
        true,
        configuration,
        "",
        [],
        ModuleManager.getModuleFromEnum(this.state.selectedModule).ref,
        null,
        Lang.getI18N("new-task"),
        "FREQ=DAILY;INTERVAL=1",
        [],
        false,
        null,
        false
      );

      this.state.tasks.push(task);

      var refs = <any>this.$refs;

      refs.newTaskModal.close();

      this.scrollBottom();
    },
    revertActive() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].active = baseTask.active;
      }
    },
    revertManager() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].requires_manager = baseTask.requires_manager;
      }
    },
    revertName() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].name = baseTask.name;
      }
    },
    revertRecurrence() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].recurrence = baseTask.recurrence;
      }
    },
    revertType() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].type = baseTask.type;
      }
    },
    revertSchedule() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].starts = baseTask.starts;
        this.state.tasks[i].durations = baseTask.durations;
      }
    },
    applyGlobalCheck() {
      var refs = <any>this.$refs;

      let value = !refs.globalCheck.getValue();

      if (value) this.checkedTasks = this.state.tasks.map((x) => x);
      else this.checkedTasks = [];
    },

    onNameChange(value: string) {
      for (let task of this.checkedTasks) {
        task.name = value;
      }
    },
    onTypeChange(type: DocumentReference) {
      for (let element of this.checkedTasks) {
        element.type = type;
      }
    },
    onActiveChange(value: boolean) {
      for (let task of this.checkedTasks) {
        task.active = value;
      }
    },
    onManagerChange(value: boolean) {
      for (let task of this.checkedTasks) {
        task.requires_manager = value;
      }
    },

    onFilterChange(index: number) {
      this.checkedTasks = [];
      this.state.setFilter(index);
    },
    openTask(task: StoreTaskConfiguration) {
      this.state.preventReload = true;
      getStoreTaskConfigurationState().set(task, this.state.store);
      getNavigationState().navigate("storeTaskConf");
    },
    duplicate(task: StoreTaskConfiguration) {
      let cloned = StoreTaskConfiguration.clone(task);

      cloned.name += " (Copy)";

      this.state.tasks.push(cloned);

      this.scrollBottom();
    },
    scrollBottom() {
      var refs = <any>this.$refs;

      nextTick(() => {
        let container = refs.container.parentNode;
        container.scroll(0, container.scrollHeight);

        container = refs.container;
        container.scroll(0, container.scrollHeight);
      });
    },
    async save() {
      var refs = <any>this.$refs;

      refs.saveBtn.toggleLoading(true);

      let updatedTasks = [] as StoreTaskConfiguration[];

      for (let i = 0; i < this.state.tasks.length; i++) {
        if (i >= this.state.baseTasks.length) {
          updatedTasks.push(this.state.elements[i]);
          continue;
        }
        this.state.baseTasks[i].ref = this.state.tasks[i].ref;
        if (
          !ObjectUtils.compareObjects(
            this.state.tasks[i],
            this.state.baseTasks[i]
          )
        ) {
          updatedTasks.push(this.state.tasks[i]);
        }
      }
      for (let t of updatedTasks) {
        await t.set();
      }

      this.state.baseTasks = this.state.tasks.map((x) =>
        StoreTaskConfiguration.clone(x)
      );

      Snackbars.display(updatedTasks.length + " tâches ont été mis a jour.");
      refs.saveBtn.toggleLoading(false);
    },
    onRowClick(task: StoreTaskConfiguration) {
      if (this.checkedTasks.includes(task)) {
        let index = this.checkedTasks.indexOf(task);
        this.checkedTasks.splice(index, 1);
      } else {
        this.checkedTasks.push(task);
      }
    },
    openRRuleModal(task: StoreTaskConfiguration) {
      let refs = <any>this.$refs;
      refs.rruleModal.open();

      let rrule = rrulestr(task.getRecurrence());
      refs.rruleInput.fromRRule(rrule);
    },
    closeRRuleModal() {
      let refs = <any>this.$refs;
      refs.rruleModal.close();
      let rule = refs.rruleInput.computeRRule();
      let ruleString = rule.toString();
      for (let task of this.checkedTasks) {
        task.recurrence = ruleString;
      }
    },

    recurrenceTitle(task: StoreTaskConfiguration): String {
      let rrule = rrulestr(task.getRecurrence());
      return DateUtils.getFrequenceText(rrule.options.freq);
    },
    revertConfigurations() {
      for (let i = 0; i < this.state.tasks.length; i++) {
        let baseTask = this.state.baseTasks[i];

        this.state.tasks[i].configurations = baseTask.configurations;
      }
    },

    configurationTitle(task: StoreTaskConfiguration): String {
      let module = ModuleManager.getModuleFromRef(task.getModule()).toEnum();

      switch (module) {
        case ModuleEnum.Action:
          return (
            (task.configuration as TaskModuleConfigurationAction).actions
              .length + " sous-tâches"
          );
        case ModuleEnum.Temperatures:
          return (
            (task.configuration as TaskModuleConfigurationTemperature)
              .temperature_elements.length + " éléments"
          );
        case ModuleEnum.Oils:
          return (
            (task.configuration as TaskModuleConfigurationOils).oil_elements
              .length + " éléments"
          );
      }
    },

    openScheduleModal(task: StoreTaskConfiguration, index: number) {
      if (index != null) {
        this.scheduleStart = new Date(
          0,
          0,
          0,
          0,
          task.getStarts()[index],
          0,
          0
        );
        this.scheduleEnd = new Date(
          0,
          0,
          0,
          0,
          task.getStarts()[index] + task.getDurations()[index],
          0,
          0
        );
      }

      let refs = <any>this.$refs;
      refs.scheduleModal.open({
        task: task,
        index: index,
      });
    },
    closeScheduleModal() {
      let refs = <any>this.$refs;
      refs.scheduleModal.close();

      let task = refs.scheduleModal.context.task;
      let index = refs.scheduleModal.context.index;
      let start =
        this.scheduleStart.getHours() * 60 + this.scheduleStart.getMinutes();
      let duration =
        this.scheduleEnd.getHours() * 60 +
        this.scheduleEnd.getMinutes() -
        start;

      if (index != null) {
        task.getStarts()[index] = start;
        task.getDurations()[index] = duration;
      } else {
        for (let t of this.checkedTasks) {
          if (
            !(
              t.getStarts().includes(start) &&
              t.getDurations().includes(duration)
            )
          )
            t.getStarts().push(start);
          t.getDurations().push(duration);
        }

        if (!this.checkedTasks.includes(task)) {
          task.getStarts().push(start);
          task.getDurations().push(duration);
        }
      }
    },
    formatDate(start: number, end: number) {
      let startDate = new Date(0, 0, 0, 0, start, 0, 0);
      let endDate = new Date(0, 0, 0, 0, end, 0, 0);
      return `${startDate.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      })} - ${endDate.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    },
    onScroll() {
      var refs = <any>this.$refs;

      var parent = refs.container;

      if (parent == null) {
        return;
      }

      if (this.oldParentScrollTop - parent.scrollTop < 0) {
        refs.stickContainer.style.top = this.distanceFromTop - 400 + "px";

        refs.tbody.style.top = this.distanceFromTop - 85 + "px";
      } else {
        refs.stickContainer.style.top = this.distanceFromTop - 83 + "px";
        refs.tbody.style.top = this.distanceFromTop + 47 + "px";
      }

      this.oldParentScrollTop = parent.scrollTop;
    },
  },
  mounted() {
    var refs = <any>this.$refs;

    var rect = refs.container.getBoundingClientRect();
    this.distanceFromTop = rect.top - window.scrollY;

    refs.container.addEventListener("scroll", this.onScroll);

    refs.chips.setSelectedIndex(this.state.filter);
    this.onFilterChange(this.state.filter);
  },
  data() {
    return {
      Database: Database,
      Lang: Lang,
      oldParentScrollTop: 0,
      distanceFromTop: 0,
      ModuleEnum: ModuleEnum,
      state: getBulkStoreTaskState(),
      checkedTasks: [] as StoreTaskConfiguration[],
      scheduleStart: new Date(0, 0, 0, 0, 0, 0, 0),
      scheduleEnd: new Date(0, 0, 0, 0, 0, 0, 0),
    };
  },
  setup() {},
});
