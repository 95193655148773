
import { defineComponent, nextTick, watch } from "vue";

export default defineComponent({
  expose: [
    "getSelectedItem",
    "changeTab",
    "getSelectedIndex",
    "changeTabIndex",
    "refresh",
  ],
  props: ["tabs", "icons", "onTabChange", "btnSize"],
  methods: {
    getParent() {
      return this.$el.parentNode;
    },
    getSelectedIndex(): number {
      return this.selectedIndex;
    },
    getSelectedItem(): string {
      return this.tabs[this.selectedIndex];
    },
    changeTabIndex(index: number) {
      var label = this.tabs[index];
      this.changeTab(label);
    },
    changeTab(label: string) {
      var refs = <any>this.$refs;

      let index = this.tabs.indexOf(label);
      this.selectedIndex = index;

      const tabs = document.querySelectorAll(".tab");

      let element: any = tabs[index];
      const tabBar = refs.tabBar;
      tabs.forEach((tab: any) => tab.classList.remove("active"));
      element.classList.add("active");

      this.resizeIndicator();

      const containerWidth = refs.tabContainer.offsetWidth;
      const elementWidth = element.offsetWidth;
      const elementOffsetLeft = element.offsetLeft;

      const scrollPosition =
        elementOffsetLeft - containerWidth / 2 + elementWidth / 2;

      refs.tabContainer.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      if (this.onTabChange != undefined) {
        this.onTabChange(index);
      }

      this.init = true;
    },

    onResize() {
      this.resizeIndicator();
    },

    hide(index: number) {},

    resizeIndicator() {
      const tabs = document.querySelectorAll(".tab");
      let element: any = tabs[this.selectedIndex];
      const indicator: HTMLElement = document.querySelector(".indicator");
      indicator.style.width = `${element.offsetWidth}px`;
      indicator.style.left = `${element.offsetLeft}px`;
    },
    onScroll(a: any) {
      var refs = <any>this.$refs;

      if (!refs.stickContainer) {
        return;
      }
      var parent = this.getParent();

      if (parent == null) {
        return;
      }
      if (this.oldParentScrollTop - parent.scrollTop < 0) {
        refs.stickContainer.style.top = this.distanceFromTop - 80 + "px";
      } else {
        refs.stickContainer.style.top = this.distanceFromTop + "px";
      }

      this.oldParentScrollTop = parent.scrollTop;
    },
  },

  data() {
    return {
      selectedIndex: 0,
      oldParentScrollTop: 0,
      distanceFromTop: 0,
      init: false,
    };
  },

  mounted() {
    window.addEventListener("resize", this.resizeIndicator);

    this.getParent().addEventListener("scroll", this.onScroll);
    var rect = this.$el.getBoundingClientRect();
    this.distanceFromTop = rect.top - window.scrollY;

    nextTick(() => {
      if (!this.init) {
        this.changeTabIndex(0);
      }
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.resizeIndicator);
    this.getParent().removeEventListener("scroll", this.onScroll);
  },
});
