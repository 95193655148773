
import * as Lang from "@/i18n/lang";
import Audit from "@/model/Auditing/Audit";
import draggable from "vuedraggable";
import { defineComponent } from "@vue/runtime-core";
import { Question, QuestionType } from "@/model/Auditing/Question";
import { Section } from "@/model/Auditing/Section";
import { Choice } from "@/model/Auditing/Choice";
import { CloudSupportedExtensions } from "@/model/Filesystem/CloudSupportedExtensions";
import { truncateText } from "@/utils/StringUtils";
import {
  UploadQuestionOptions,
  MultipleQuestionOptions,
  TextQuestionOptions,
  RatingQuestionOptions,
  NumberQuestionOptions,
  DateLimitConfiguration,
  DateQuestionOptions,
} from "@/model/Auditing/QuestionOptions";
import ConditionsModal from "./ConditionsModal.vue";

export default defineComponent({
  props: {
    audit: {
      type: Audit,
      required: true,
    },
  },
  emits: ["updateAudit"],
  data() {
    return {
      Lang: Lang,
      localAudit: this.audit as Audit,
      selectedQuestion: {} as Question,
      selectedType: QuestionType.TextInput,
      toggleLimitCharacters: false,
      toggleQuestionScore: false,
      QuestionType: QuestionType,
      truncateText: truncateText,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.selectFirstQuestion();
    });
  },
  computed: {
    dateConfigurationLimits() {
      return Object.values(DateLimitConfiguration);
    },
    questionTypes() {
      return Object.values(QuestionType);
    },
    supportedExtensions() {
      return Object.values(CloudSupportedExtensions);
    },
    findFirstQuestion(): any {
      return this.localAudit.sections
        .flatMap((section) => section.questions)
        .find((question) => question);
    },
    selectedQuestionChoices() {
      if (
        this.selectedQuestion.type === QuestionType.MultipleChoice ||
        this.selectedQuestion.type === QuestionType.YesOrNo
      ) {
        let multipleOptions = this.selectedQuestion
          .options as MultipleQuestionOptions;
        if (multipleOptions) {
          let choices = multipleOptions.getChoices();
          if (
            this.selectedQuestion.type === QuestionType.YesOrNo &&
            choices.length <= 0
          ) {
            let choices: Choice[] = [new Choice("true"), new Choice("false")];
            this.selectedQuestion.options = new MultipleQuestionOptions(
              false,
              choices
            );
            let newMultipleOptions = this.selectedQuestion
              .options as MultipleQuestionOptions;
            return newMultipleOptions.getChoices();
          }
          return choices;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    selectFirstQuestion() {
      const question = this.findFirstQuestion;
      if (question) {
        this.selectedQuestion = question;
      }
    },
    onQuestionTypeChange(selectedType: QuestionType) {
      this.selectedQuestion.options =
        this.selectedQuestion.changeQuestionOptions(selectedType);
    },
    onQuestionSelect(question: Question) {
      let refs = <any>this.$refs;

      // Saves selected extensions state
      if (this.selectedQuestion.type == QuestionType.FileUpload) {
        let selectedExtension = refs.supportedExtensions.getSelectedItems();
        let questionOptions = new UploadQuestionOptions(selectedExtension);
        this.selectedQuestion.setQuestionOptions(questionOptions);
      }

      this.selectedQuestion = question;
    },

    // CRUD Questions, Sections, Choices
    addQuestion(sectionId: string) {
      let newQuestion = new Question();
      this.localAudit.sections.map((section: Section) => {
        if (section.id === sectionId) {
          section.questions.push(newQuestion);
        }
      });
      this.selectedQuestion = newQuestion;
    },
    addSection(sectionText: string) {
      let newSection = new Section(sectionText, true);
      this.localAudit.addSection(newSection);
      this.addQuestion(newSection.id);
    },
    addChoice(choiceText: string) {
      let newChoice = new Choice(choiceText);
      let questionOptions = this.selectedQuestion
        .options as MultipleQuestionOptions;
      questionOptions.choices.push(newChoice);
    },
    editSection() {
      let refs = <any>this.$refs;
      if (!refs.editSectionForm.verify()) return;

      let section = refs.editSectionModal.context;
      section.title = refs.sectionTextEdit.getValue();

      refs.editSectionModal.close();
      this.$forceUpdate();
    },
    editChoice(choice: Choice, newText: string) {
      const multipleOptions = this.selectedQuestion
        .options as MultipleQuestionOptions;
      const choices = multipleOptions.choices;

      const indexToEdit = choices.findIndex((c) => c.id === choice.id);

      if (indexToEdit !== -1) {
        const updatedChoices = [...choices];
        const editedChoice = new Choice(
          newText,
          updatedChoices[indexToEdit].score,
          updatedChoices[indexToEdit].id,
          updatedChoices[indexToEdit].archived_at
        );
        updatedChoices[indexToEdit] = editedChoice;

        multipleOptions.choices = updatedChoices;
      }
    },
    deleteQuestion(questionId: any) {
      this.localAudit.sections.forEach((section) => {
        const questionIndex = section.questions.findIndex(
          (question) => question.id === questionId
        );
        if (questionIndex !== -1) {
          if (this.audit.publishing_date || this.audit.published) {
            section.questions[questionIndex].archived_at = new Date();
          } else {
            section.questions.splice(questionIndex, 1);
            this.selectFirstQuestion();
          }
        }
      });
    },
    deleteSection(sectionId: string) {
      const sectionIndex = this.localAudit.sections.findIndex(
        (section) => section.id === sectionId
      );
      if (this.audit.publishing_date || this.audit.published) {
        this.localAudit.sections[sectionIndex].archived_at = new Date();
      } else {
        if (sectionIndex !== -1) {
          this.localAudit.sections.splice(sectionIndex, 1);
          this.selectFirstQuestion();
        }
      }
    },
    deleteChoice(choiceId: string) {
      const multipleOptions = this.selectedQuestion
        .options as MultipleQuestionOptions;

      if (multipleOptions.choices.length === 0) {
        return;
      }

      if (this.audit.publishing_date || this.audit.published) {
        const foundChoice = multipleOptions.choices.find(
          (choice) => choice.id === choiceId
        );

        if (foundChoice) {
          foundChoice.archived_at = new Date();
        }
      } else {
        multipleOptions.choices = multipleOptions.choices.filter(
          (choice) => choice.id !== choiceId
        );
      }

      this.selectedQuestion.options = multipleOptions;
    },

    // Modals view controlling
    openCreateSectionModal(modalRef: any) {
      modalRef.openModal("", this.addSection, "name");
    },
    openEditSectionModal(section: Section) {
      let refs = <any>this.$refs;
      refs.sectionTextEdit.setValue(section.title);
      refs.editSectionForm.reset();
      refs.editSectionModal.open(section);
    },
    openCreateChoiceModal(modalRef: any): any {
      modalRef.openModal("", this.addChoice, "name");
    },
    openEditChoiceModal(modalRef: any, choice: Choice) {
      modalRef.openModal(choice, this.editChoice, "name", choice.text);
    },
    openConditionsModal(modalRef: any) {
      modalRef.openModal();
    },
    openRangePickerModal(modalRef: any) {
      let minNumber = 0;
      let maxNumber = 0;
      let minRange = 0;

      if (this.selectedQuestion.type === "rating") {
        let questionOptions = this.selectedQuestion
          .options as RatingQuestionOptions;
        minNumber = questionOptions.min_rating;
        maxNumber = questionOptions.max_rating;
      }
      if (this.selectedQuestion.type === "number") {
        let questionOptions = this.selectedQuestion
          .options as NumberQuestionOptions;
        minNumber = questionOptions.min_number;
        maxNumber = questionOptions.max_number;
        minRange = -999999999;
      }
      modalRef.openModal(
        this.saveRangePickerState,
        minNumber,
        maxNumber,
        this.selectedQuestion.id,
        minRange
      );
    },

    // Delete confirmation modals
    confirmDeleteSection() {
      let refs = <any>this.$refs;
      let sectionId = refs.editSectionModal.context.id;
      refs.editSectionModal.close();
      refs.deleteConfirmModal.openModal(this.deleteSection, sectionId);
    },
    confirmDeleteQuestion(modalRef: any, questionId: string) {
      modalRef.openModal(this.deleteQuestion, questionId);
    },

    // Utils
    closeModal(modalRef: any) {
      modalRef.close();
    },
    toggleSection(sectionId: string) {
      const section = this.localAudit.sections.find(
        (section: Section) => section.id === sectionId
      );
      if (section) {
        section.isVisible = !section.isVisible;
      }
    },
    toggleValue(
      option: any,
      property: any,
      defaultValue: any,
      newVal: boolean
    ) {
      if (newVal) {
        if (option[property] === null) {
          option[property] = defaultValue;
        }
      } else {
        option[property] = null;
      }
    },
    updateLocalAuditAndEmit() {
      this.$emit("updateAudit", this.localAudit);
    },
    calculateScore(newState: boolean) {
      if (
        this.selectedQuestion.score === null ||
        this.toggleQuestionScore === false
      ) {
        return;
      }

      if (
        this.selectedQuestion.type === QuestionType.MultipleChoice ||
        this.selectedQuestion.type === QuestionType.YesOrNo
      ) {
        let choices = this.selectedQuestionChoices;

        if (choices && choices.length <= 0) {
          this.selectedQuestion.score = 0;
          return;
        }
        if (newState === true) {
          this.selectedQuestion.score = choices.reduce(
            (sum, choice) => sum + choice.score,
            0
          );
        } else if (newState === false) {
          const highestScore = choices.reduce(
            (max, choice) => Math.max(max, choice.score),
            Number.MIN_SAFE_INTEGER
          );
          this.selectedQuestion.score = highestScore;
        }
      }
    },
    saveRangePickerState(minNumberString: string, maxNumberString: string) {
      let minNumber = Number(minNumberString);
      let maxNumber = Number(maxNumberString);

      if (this.selectedQuestion.type === "rating") {
        let questionOptions = this.selectedQuestion
          .options as RatingQuestionOptions;

        questionOptions.min_rating = minNumber;
        questionOptions.max_rating = maxNumber;
      }
      if (this.selectedQuestion.type === "number") {
        let questionOptions = this.selectedQuestion
          .options as NumberQuestionOptions;
        questionOptions.min_number = minNumber;
        questionOptions.max_number = maxNumber;
      }
    },
  },
  watch: {
    localAudit: {
      deep: true,
      handler() {
        this.updateLocalAuditAndEmit();
      },
    },
    "selectedQuestion.options.choices": {
      deep: true,
      handler() {
        this.calculateScore(
          (this.selectedQuestion.options as MultipleQuestionOptions)
            .multiple_selection
        );
      },
    },
    "selectedQuestion.options.multiple_selection": function (newState) {
      this.calculateScore(newState);
    },
    toggleLimitCharacters(newVal) {
      const textQuestionOptions = this.selectedQuestion
        .options as TextQuestionOptions;
      this.toggleValue(textQuestionOptions, "max_characters", 200, newVal);
    },
    toggleQuestionScore(newVal) {
      if (
        this.selectedQuestion.type === QuestionType.MultipleChoice ||
        this.selectedQuestion.type === QuestionType.YesOrNo
      ) {
        if (newVal === true) {
          this.selectedQuestion.score = 0;
          this.calculateScore(
            (this.selectedQuestion.options as MultipleQuestionOptions)
              .multiple_selection
          );
        } else {
          this.selectedQuestion.score = null;
        }
      } else {
        this.toggleValue(this.selectedQuestion, "score", 1, newVal);
      }
    },
    selectedQuestion(question: Question) {
      let refs = <any>this.$refs;
      this.toggleLimitCharacters = false;

      // Checks if the question has score enabled
      if (this.selectedQuestion.score === null) {
        this.toggleQuestionScore = false;
      } else {
        this.toggleQuestionScore = true;
      }
      if (refs.toggleQuestionScore) {
        refs.toggleQuestionScore.toggle(this.toggleQuestionScore);
      }

      if (this.selectedQuestion.type == QuestionType.TextInput) {
        if (
          (this.selectedQuestion.options as TextQuestionOptions)
            .max_characters === null
        ) {
          this.toggleLimitCharacters = false;
        } else {
          this.toggleLimitCharacters = true;
        }
      }

      this.$nextTick(() => {
        // Checks if max_characters option exists
        if (this.selectedQuestion.type == QuestionType.TextInput) {
          refs.limitCharacters.toggle(this.toggleLimitCharacters);
        }

        // Sets state of the multiple_selection toggle
        if (this.selectedQuestion.type === QuestionType.MultipleChoice) {
          refs.multipleSelection.toggle(
            (this.selectedQuestion.options as MultipleQuestionOptions)
              .multiple_selection
          );
        }

        // Sets state of the required toggle
        refs.questionRequired.toggle(this.selectedQuestion.required);

        // Fetches saved extensions state
        if (this.selectedQuestion.type === QuestionType.FileUpload) {
          let selectedQuestionOptions = this.selectedQuestion
            .options as UploadQuestionOptions;
          let selectedExtensions = selectedQuestionOptions.file_extensions;
          if (!selectedExtensions) return;
          let indexesTable: number[] = [];
          selectedExtensions.forEach((value) => {
            let index = Object.keys(CloudSupportedExtensions).indexOf(value);
            indexesTable.push(index);
          });
          refs.supportedExtensions.setSelectedIndexes(indexesTable);
        }
      });
    },
  },
  components: { draggable, ConditionsModal },
});
