export function parseRGBA(rgbaString: string) {
  // Regular expression to match rgba color strings
  const rgbaRegex =
    /^rgba?\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*(?:,\s*(0|0?\.\d+|1(\.0)?)\s*)?\)$/;

  // Match the string against the regex
  const match = rgbaString.match(rgbaRegex);

  // If the match is null, the input string is not a valid rgba string
  if (!match) {
    throw new Error("Invalid RGBA string");
  }

  // Extract the components and convert them to numbers
  const red = parseInt(match[1], 10);
  const green = parseInt(match[2], 10);
  const blue = parseInt(match[3], 10);
  const alpha = match[4] !== undefined ? parseFloat(match[4]) : 1; // Default alpha to 1 if not provided

  return { red, green, blue, alpha };
}

export function rgbToHex(r: number, g: number, b: number) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}
