import { h, render } from "vue";
import SeModal from "@/components/global/SeModal.vue";
import { random } from "lodash";
import SeButton from "@/components/global/SeButton.vue";
import SeCarousel from "@/components/global/SeCarousel.vue";

export function openCarousel(pictures: string[]) {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);
  const snackbarComponent = h(SeCarousel, { pictures: pictures });
  render(snackbarComponent, snackbarContainer);
  let cmp = <any>snackbarComponent.component!;
  return cmp.proxy!;
}

export function open(text: string, title: string = "Infos") {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeModal, { title: title });

  render(snackbarComponent, snackbarContainer);

  let cmp = <any>snackbarComponent.component!;

  let span = document.createElement("span");
  span.classList.add("text-muted");
  span.innerText = text;

  cmp.proxy.$el.querySelector(".modal-body").appendChild(span);

  cmp.proxy!.open();

  return cmp.proxy!;
}
export async function openYesNo(
  text: string,
  title: string,
  yesText: string,
  noText: string
): Promise<boolean> {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeModal, { title: title });

  render(snackbarComponent, snackbarContainer);

  let cmp = <any>snackbarComponent.component!;

  var body = cmp.proxy.$el.querySelector(".modal-body");

  body.style.width = "80vw";
  body.style.maxWidth = "400px";
  body.style.padding = "24px";
  let span = document.createElement("span");
  span.classList.add("text-muted");
  span.innerText = text;
  body.appendChild(span);

  var btnsContainer = document.createElement("div");

  btnsContainer.classList.add("flex", "align-center");

  const yesBtn = h(SeButton);
  let spanYes = document.createElement("span");
  spanYes.innerText = yesText;
  render(yesBtn, document.createElement("div")); // render to a temporary container
  yesBtn.component.proxy.$el.classList.add("w100");
  yesBtn.component.proxy.$el.appendChild(spanYes);

  const noBtn = h(SeButton);
  let spanNo = document.createElement("span");
  spanNo.innerText = noText;
  render(noBtn, document.createElement("div"));
  noBtn.component.proxy.$el.classList.add("w100");
  noBtn.component.proxy.$el.appendChild(spanNo);

  btnsContainer.appendChild(yesBtn.component.proxy.$el);
  btnsContainer.appendChild(noBtn.component.proxy.$el);

  body.appendChild(btnsContainer);

  cmp.proxy!.open();

  return new Promise<boolean>((resolve, reject) => {
    yesBtn.component.proxy.$el.addEventListener("click", () => {
      cmp.proxy.close();
      return resolve(true);
    });

    noBtn.component.proxy.$el.addEventListener("click", () => {
      cmp.proxy.close();
      return resolve(false);
    });
  });
}
