import { DocumentReference, deleteDoc, setDoc } from "firebase/firestore";
import { SetOptions } from "firebase/firestore";

export default abstract class SecureatSnapshot {
  ref: DocumentReference;
  constructor(ref: DocumentReference) {
    this.ref = ref;
  }

  public static getValue<T>(
    data: any,
    name: string,
    defaultValue: T | null = null
  ): T {
    let value = data[name];

    let result = value as T;

    if (result == null) {
      if (defaultValue !== null) {
        return defaultValue;
      }
      throw new Error("Invalid type for field " + name + " : " + value);
    } else {
      return value;
    }
  }
  public async set() {
    const data = this.toFirestore();
    await setDoc(this.ref, data, { merge: true });
  }
  public async delete() {
    await deleteDoc(this.ref!);
  }
  public abstract toFirestore(): any;

  public static getNullableValue<T>(
    data: any,
    name: string,
    type: any
  ): T | null {
    let value = data[name];

    if (value == undefined) {
      return null;
    }

    if (value == null) {
      return value;
    } else {
      if (value.constructor === type) {
        return value as T;
      } else {
        throw new Error(
          "Invalid nullable type for field " + name + " value : " + value
        );
      }
    }
  }
}
