import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    class: "language-select text-muted",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleLanguageChange && _ctx.handleLanguageChange(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageMappings, (value, key) => {
      return (_openBlock(), _createElementBlock("option", {
        class: "text-muted",
        key: key,
        value: key
      }, _toDisplayString(key), 9, _hoisted_1))
    }), 128))
  ], 544)), [
    [_vModelSelect, _ctx.selectedLanguage]
  ])
}