import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import { ModuleEnum } from "./ModuleEnum";

export default class Module extends SecureatSnapshot {
  name: string = "";
  key: string = "";
  active: boolean = false;

  constructor(
    ref: DocumentReference,
    name: string,
    key: string,
    active: boolean
  ) {
    super(ref);
    this.name = name;
    this.key = key;
    this.active = active;
  }

  public toEnum() {
    const values = Object.values(ModuleEnum);

    for (const key in Object.keys(ModuleEnum)) {
      let value = values[key];

      if (value == this.key) {
        return value;
      }
    }

    console.log("Missing enum: " + this.key);
    throw Error("Invalid module reference. Unable to get module enum");
  }
  public static fromFirestore(snapshot: DocumentSnapshot): Module {
    const data = snapshot.data()!;

    let result = new Module(snapshot.ref, data.name, data.key, data.active);

    return result;
  }

  public toFirestore() {
    return {
      name: this.name,
      key: this.key,
      active: this.active,
    };
  }
}
