
import { defineComponent, ref } from "vue";
import { languageMappings } from "@/i18n/lang";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
      default: "text",
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
});
