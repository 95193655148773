import TaskResult from "./TaskResult";
import Element from "@/model/Elements/Element";

export default class OilResult extends TaskResult {
  cleaned: boolean | null;
  element: Element | null;
  oil_changed: boolean | null;
  oil_filtered: boolean | null;
  filter_changed: boolean | null;
  polarity: number | null;
  temperature: number | null;

  constructor(
    cleaned: boolean | null,
    element: Element | null,
    oil_changed: boolean | null,
    oil_filtered: boolean | null,
    filter_changed: boolean | null,
    polarity: number | null,
    temperature: number | null
  ) {
    super(null, null, null);
    this.cleaned = cleaned;
    this.element = element;
    this.oil_changed = oil_changed;
    this.oil_filtered = oil_filtered;
    this.filter_changed = filter_changed;
    this.polarity = polarity;
    this.temperature = temperature;
  }
}
