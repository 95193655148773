import { generateUniqueId } from "../utils/uniqueIdGenerator";

export class QuestionCondition {
  id: string;
  question_id: string;
  value: any;

  constructor(
    question_id: string,
    value: any,
    id: string = generateUniqueId()
  ) {
    this.question_id = question_id;
    this.value = value;
    this.id = id;
  }

  toMap(): Record<string, any> {
    return {
      question_id: this.question_id,
      value: this.value,
    };
  }

  clone(): QuestionCondition {
    return new QuestionCondition(this.question_id, this.value);
  }
}
