
import { Frequency, RRule } from "rrule";
import { defineComponent } from "vue";
import { nextTick } from "vue";
import * as Lang from "@/i18n/lang";
import SeNumberPicker from "../../global/SeNumberPicker.vue";

export default defineComponent({
  data() {
    return {
      Lang: Lang,
      dailyInterval: 1,
      weeklyInterval: 1,
      monthlyInterval: 1,
      yearlyInterval: 1,
      monthlyDay: 1,
      monthlyRecurrencePosition: 0,
      monthlyRecurrenceDay: 0,
      yearlyMonth: 0,
      yearlyDay: 1,
      yearlyRecurrencePosition: 0,
      yearlyRecurrenceDay: 0,
      yearlyRecurrenceMonth: 0,
      weekDayIndexes: [] as number[],
    };
  },
  expose: ["computeRRule", "fromRRule"],
  methods: {
    onTabChange(index: number) {
      var refs = <any>this.$refs;

      if (index == 1) {
        nextTick(() => {
          for (let index of this.weekDayIndexes) {
            refs.days.selectIndex(index);
          }
        });
      }

      if (refs.days != undefined) {
        this.weekDayIndexes = refs.days.getSelectedIndexes();
      }
    },
    getMonths(): string[] {
      return [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];
    },
    getDays(): string[] {
      return [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
    },
    getRecurrences(): string[] {
      return ["first", "second", "third", "fourth", "last"];
    },
    fromRRule(rule: RRule) {
      let refs = <any>this.$refs;

      switch (rule.options.freq) {
        case Frequency.DAILY:
          refs.tab.changeTabIndex(0);

          nextTick(() => {
            this.dailyInterval = rule.options.interval;
          });

          break;
        case Frequency.WEEKLY:
          refs.tab.changeTabIndex(1);

          nextTick(() => {
            refs.days.reset();

            this.weekDayIndexes = rule.options.byweekday;

            for (let dayIndex of this.weekDayIndexes) {
              refs.days.selectIndex(dayIndex);
            }
            this.weeklyInterval = rule.options.interval;
          });

          break;
        case Frequency.MONTHLY:
          refs.tab.changeTabIndex(2);

          nextTick(() => {
            this.monthlyInterval = rule.options.interval;

            if (rule.options.bymonthday.length > 0) {
              refs.monthlyByDay.checked = true;
              this.monthlyDay = rule.options.bymonthday[0];
            }

            if (
              rule.options.byweekday != null &&
              rule.options.byweekday.length > 0
            ) {
              refs.monthlyByRecurrence.checked = true;
              this.monthlyRecurrencePosition = rule.options.bysetpos[0] - 1;
              this.monthlyRecurrenceDay = rule.options.byweekday[0];
            }
          });

          break;

        case Frequency.YEARLY:
          refs.tab.changeTabIndex(3);

          nextTick(() => {
            this.yearlyInterval = rule.options.interval;

            if (rule.options.bysetpos === null) {
              refs.yearlyByMonth.checked = true;
              this.yearlyDay = rule.options.bymonthday[0];
              this.yearlyMonth = rule.options.bymonth[0] - 1;
            } else {
              refs.yearlyByRecurrence.checked = true;
              this.yearlyRecurrencePosition = rule.options.bysetpos[0] - 1;
              this.yearlyRecurrenceDay = rule.options.byweekday[0];
              this.yearlyRecurrenceMonth = rule.options.bymonth[0] - 1;
            }
          });

          break;
      }
    },
    computeRRule(): RRule {
      let refs = <any>this.$refs;

      let frequency: Frequency = Frequency.DAILY;
      let interval: number | undefined = undefined;
      let byweekday: any[] | null = null;
      let bymonthday: number | undefined = undefined;
      let bymonth: number | undefined = undefined;

      let bysetPos: number | undefined = undefined;

      switch (refs.tab.getSelectedIndex()) {
        case 0:
          frequency = Frequency.DAILY;
          break;
        case 1:
          frequency = Frequency.WEEKLY;
          break;
        case 2:
          frequency = Frequency.MONTHLY;
          break;
        case 3:
          frequency = Frequency.YEARLY;
          break;
      }

      switch (frequency) {
        case Frequency.DAILY:
          interval = this.dailyInterval;
          break;

        case Frequency.WEEKLY:
          byweekday = refs.days.getSelectedIndexes();

          if (byweekday?.length == 0) {
            byweekday = [0];
          }

          byweekday.filter(
            (value, index, self) => self.indexOf(value) === index
          );

          interval = this.weeklyInterval;
          break;

        case Frequency.MONTHLY:
          interval = this.monthlyInterval;
          if (refs.monthlyByDay.checked) {
            bymonthday = this.monthlyDay;
          } else {
            bysetPos = this.monthlyRecurrencePosition + 1;
            byweekday = [this.monthlyRecurrenceDay];
          }
          break;

        case Frequency.YEARLY:
          interval = this.yearlyInterval;

          if (refs.yearlyByMonth.checked) {
            bymonth = this.yearlyMonth + 1;
            bymonthday = this.yearlyDay;
          } else {
            bysetPos = this.yearlyRecurrencePosition + 1;
            byweekday = [this.yearlyRecurrenceDay];
            bymonth = this.yearlyRecurrenceMonth + 1;
          }

          break;
      }

      const rule = new RRule({
        freq: frequency,
        interval: interval,
        byweekday: byweekday,
        bymonthday: bymonthday,
        bysetpos: bysetPos,
        bymonth: bymonth,
      });

      return rule;
    },
  },
  components: { SeNumberPicker },
});
