
import { defineComponent } from "@vue/runtime-core";
import { nextTick } from "vue";

export default defineComponent({
  expose: ["getValue", "setValue"],
  emits: ["update:modelValue"],
  name: "SeInput",

  props: {
    inputChange: null,

    placeholder: {
      type: String,
      default: "",
    },
    placeholderBase: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "white",
    },
    modelValue: {},
    value: {},
    numberOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
  },

  mounted() {
    let val = <any>this.modelValue;

    if (this.modelValue == null || val.toString() == "") {
      let floatingLabel = this.$el.getElementsByClassName("floating-label")[0];
      floatingLabel.classList.add("fixed");
    }
  },
  methods: {
    onInput($event: any) {
      let res: number = 0;

      if (this.numberOnly) {
        let input = $event.target.value;

        res = Number(input);

        if (isNaN(res) && input != "-") {
          $event.target.value = $event.target.value.substring(
            0,
            $event.target.value.length - 1
          );
        }
      }
      if ($event.target.value.trim() == "") {
        if (!this.numberOnly) {
          this.$emit("update:modelValue", "");
        } else {
          this.$emit("update:modelValue", 0);
        }
      } else {
        var value = $event.target.value;

        if (this.numberOnly) {
          if (isNaN(res)) {
            return;
          }
          value = res;
        }
        this.$emit("update:modelValue", value);
      }

      if (this.inputChange != null) {
        this.inputChange(this.getValue());
      }
    },
    getValue(): any {
      let val = this.$el.childNodes[0].value;

      if (val.trim() == "" && this.numberOnly) {
        return 0;
      } else {
        return val;
      }
    },
    setValue(value: string): void {
      this.$el.childNodes[0].value = value;
    },
  },
});
