import { defineStore } from "pinia";
import User, { Role } from "@/model/User";
import Store from "@/model/Store";
import { getUserManager } from "@/firebase/UserManager";
import { getState } from "../AppState";
import Distributor from "@/model/Distributor";
import { getDistributorManager } from "@/firebase/DistributorManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Database from "@/model/Database";

interface AccountsState {
  search: string;
  distributors: Distributor[];
  stores: Store[];
  databases: Database[];
  role: Role;

  selectedRole: Role | null;
  selectedDistributor: Distributor | null;
  selectedDatabase: Database | null;
  displayUnactives: boolean;
  selectedStore: Store | null;
}

export const getAccountsState = defineStore("accountsState", {
  state: (): AccountsState => {
    return {
      search: "",
      selectedRole: null,
      selectedDistributor: null,
      selectedDatabase: null,
      displayUnactives: false,
      distributors: [],
      role: <Role>getState().userData?.role!,
      databases: [],
      stores: [],
      selectedStore: null,
    };
  },
  actions: {
    async setup() {
      await getUserManager().initialize();

      this.distributors = getDistributorManager().getDistributors();
    },
  },
});
