import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import * as Lang from "@/i18n/lang";
import Label, { LabelType } from "@/model/Labels/Label";

export default class DatabaseLabel extends Label {
  constructor(
    ref: DocumentReference,
    active: boolean | null = false,
    cumulate_expiration_duration: boolean | null = false,
    defrosting_duration: number | null = 0,
    element: DocumentReference | null = null,
    expiration_duration: number | null = 0,
    expiration_text: string | null = null,
    name: string | null = "",
    type: LabelType | null = null,
    index: number | null = null
  ) {
    super(
      ref,
      active,
      cumulate_expiration_duration,
      defrosting_duration,
      element,
      expiration_duration,
      expiration_text,
      name,
      type,
      index
    );
  }

  public static fromFirestore(snapshot: DocumentSnapshot): DatabaseLabel {
    const data = snapshot.data()!;

    let result = new DatabaseLabel(
      snapshot.ref,
      data.active,
      data.cumulate_expiration_duration,
      data.defrosting_duration,
      data.element,
      data.expiration_duration,
      data.expiration_text,
      data.name,
      data.type,
      data.index == undefined ? null : data.index
    );

    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      cumulate_expiration_duration: this.cumulate_expiration_duration,
      defrosting_duration: this.defrosting_duration,
      element: this.element,
      expiration_duration: this.expiration_duration,
      expiration_text: this.expiration_text,
      name: this.name,
      type: this.type,
      index: this.index,
    };
  }
}
