
import { useRoute } from "vue-router";
import { defineComponent } from "vue";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { getAccountState } from "@/pinia/accounts/AccountState";
import { getStoreManager } from "@/firebase/StoreManager";
import * as Lang from "@/i18n/lang";
import { useRouter } from "vue-router";
import { getAuth } from "firebase/auth";
import { getState } from "../../pinia/AppState";
import * as UserManager from "../../firebase/UserManager";
import User, { Role } from "../../model/User";
import { getDatabaseManager } from "@/firebase/DatabaseManager";

export default defineComponent({
  data() {
    let state = getState();

    return {
      Role: Role,
      Lang: Lang,
      userData: state.userData,
      user: state.user,
      state: state,
      disconnect: false,
    };
  },
  methods: {
    async logout() {
      let auth = getAuth();
      this.disconnect = true;
      this.state.logoutRequest = true;
      getAppState().token = null;
      await auth.signOut();
      this.state.logoutRequest = false;
      location.reload();
    },
    async openUserAccount() {
      var appState = getAppState();
      var state = getNavigationState();
      var accountState = getAccountState();

      accountState.set(User.clone(appState.userData!));

      await getStoreManager().initialize();

      state.navigate("account");
    },
    navigate(destination: string, event: any) {
      let targetCard = event.target.closest(".nav-card");

      let cards = this.$el.parentElement.querySelectorAll(".nav-card");

      for (let i = 0; i < cards.length; i++) {
        let navCard = cards[i];

        navCard.className = "nav-card";
      }

      targetCard.className = "nav-card selected";

      if (window.innerWidth < 750) {
        getAppState().hideNavPanel();
      }

      getNavigationState().navigate(destination, true);
    },
  },
});
