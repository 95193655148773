export const I18N_PL = new Map([
  ["register", "Potrzebujesz konta? Skontaktuj się z nami"],
  ["login", "Zaloguj się"],
  ["password", "Hasło"],
  ["home", "Strona główna"],
  ["distributors", "Dystrybutorzy"],
  ["databases", "Bazy danych"],
  ["database", "Baza danych"],
  ["tasks", "Zadania"],
  ["stores", "Sklepy"],
  ["probes", "Sondy"],
  ["search", "Szukaj"],
  ["new", "Nowy"],
  ["today", "Dziś"],
  ["yesterday", "Wczoraj"],
  ["this-week", "W tym tygodniu"],
  ["done", "Zrobione"],
  ["on-time", "Na czas"],
  ["postponed", "Przełożone"],
  ["late", "Spóźnione"],
  ["address", "Adres"],
  ["postal-code", "Kod pocztowy"],
  ["city", "Miasto"],
  ["country", "Kraj"],
  ["open", "Otwórz"],
  ["schedule", "Harmonogram"],
  ["yearly", "Rocznie"],
  ["monthly", "Miesięcznie"],
  ["weekly", "Tygodniowo"],
  ["daily", "Codziennie"],
  ["apply", "Zastosuj"],
  ["name", "Nazwa"],
  ["surname", "Nazwisko"],
  ["owners", "Właściciele"],
  ["add", "Dodaj"],
  ["modify", "Modyfikuj"],
  ["rooms", "Pokoje"],
  ["equipment", "Wyposażenie"],
  ["all", "Wszystko"],
  ["working", "Pracuje"],
  ["not-working", "Nie działa"],
  ["phone-number", "Numer telefonu"],
  ["customer", "Klient"],
  ["distributor", "Dystrybutor"],
  ["admin", "Administrator"],
  ["manager", "Menadżer"],
  ["managers", "Menadżerowie"],
  ["accounts", "Konta"],
  ["billing", "Rozliczenia"],
  ["support", "Wsparcie"],
  ["add", "Dodaj"],
  ["apply", "Zastosuj"],
  ["temperatures", "Temperatury"],
  ["labels", "Etykiety"],
  ["traceability", "Śledzenie"],
  ["edit", "Edytuj"],
  ["task-schedule", "Harmonogram zadania"],
  ["task-configuration", "Konfiguracja zadania"],
  ["new-room", "Nowy pokój"],
  ["active", "Aktywny"],
  ["inactive", "Nieaktywny"],
  ["start", "Rozpocznij"],
  ["end", "Zakończ"],
  ["enter-the-email-of-the-user", "Wprowadź adres e-mail użytkownika"],
  ["add-owner", "Dodaj właściciela"],
  ["manage", "Zarządzaj"],
  ["assigned-to", "Przypisane do"],
  ["activate-module", "Aktywuj moduł"],
  [
    "content-not-available",
    "Zawartość niedostępna w chwili obecnej! Wróć później",
  ],
  ["logout", "Wyloguj się"],
  ["requires-picture", "Wymaga zdjęcia"],
  ["requires-manager", "Wymaga managera"],
  ["oils", "Oleje"],
  ["elements", "Elementy"],
  ["new-element", "Nowy element"],
  ["new-task", "Nowe zadanie"],
  ["field-locked", "To pole jest zablokowane"],
  ["field-unlocked", "To pole jest odblokowane"],
  ["minimum-temperature", "Minimalna temperatura"],
  ["maximum-temperature", "Maksymalna temperatura"],
  ["probe-url", "Adres sondy"],
  ["picture", "Wymaga zdjęcia"],
  ["delivery_item", "Dostawa towaru"],
  ["maximum-polarity", "Maksymalna polaryzacja"],
  ["polarity", "Polaryzacja"],
  ["action-name", "Nazwa działania"],
  ["every", "Każde"],
  ["day(s)", "dzień/dni"],
  ["week(s)", "tydzień/tygodnie"],
  ["month(s)", "miesiąc/miesiące"],
  ["year(s)", "rok/lata"],
  ["monday", "Poniedziałek"],
  ["tuesday", "Wtorek"],
  ["wednesday", "Środa"],
  ["thursday", "Czwartek"],
  ["friday", "Piątek"],
  ["saturday", "Sobota"],
  ["sunday", "Niedziela"],
  ["first", "Pierwszy"],
  ["second", "Drugi"],
  ["third", "Trzeci"],
  ["fourth", "Czwarty"],
  ["on-day", "W dniu"],
  ["on-the", "W"],
  ["defrosting", "Odmrażanie"],
  ["expiration", "Termin ważności"],
  ["simple-name", "Prosta nazwa"],
  ["expected-date", "Oczekiwana data"],
  ["submission-date", "Data dostarczenia"],
  ["employee", "Pracownik"],
  ["cleaned", "Wyczyszczone"],
  ["oil-changed", "Zmiana oleju"],
  ["task-done", "Zadanie wykonane"],
  ["task-late", "Zadanie spóźnione"],
  ["task-future", "Przyszłe zadanie"],
  ["no-task-today", "Brak zadań na dziś"],
  ["store-database", "Baza danych sklepu"],
  ["task-action-required", "Proszę dodać co najmniej jedno zadanie"],
  ["password-conf", "Potwierdzenie hasła"],
  ["countries", "Kraje"],
  ["new-label", "Nowa etykieta"],
  ["edit-label", "Edytuj etykietę"],
  ["history", "Historia"],
  ["expend", "Rozwiń"],
  ["reduce", "Zmniejsz"],
  ["cumulative-expirations", "Kumulacyjne wygaśnięcia"],
  ["expiration-duration", "Czas trwania wygaśnięcia"],
  ["defrosting-duration", "Czas trwania odmrażania"],
  ["singleton-name", "Jedna nazwa"],
  [
    "invalid-temperature-input",
    "Maksymalna temperatura dla modułu temperatury musi być większa lub równa minimalnej temperaturze.",
  ],
  ["comment", "Komentarz"],
  ["temperature", "Temperatura"],
  ["refrigeration", "Chłodzenie"],
  ["documents", "Dokumenty"],
  ["save", "Zapisz"],
  ["secureat-licence", "Licencja Secureat"],
  ["no-elements", "Brak elementów"],
  ["schedule-incoherent", "Niekompletne harmonogramy"],
  ["hourless_expiration", "Bezgodzinne wygaśnięcie"],
  ["customer_expiration", "Wygaśnięcie klienta"],
  ["download", "Pobierz"],
  ["rename", "Zmień nazwę"],
  ["delete", "Usuń"],
  ["share", "Udostępnij"],
  ["subtasks", "Podzadania"],
  ["close", "Zamknij"],
  ["count", "Ilość"],
  ["new-folder", "Nowy folder"],
  ["back", "Powrót"],
  [
    "unsaved-changes",
    "Strona zawiera niezapisane zmiany. Czy na pewno chcesz wrócić?",
  ],
  ["user-not-found", "Nie znaleziono użytkownika"],
  ["form-invalid", "Proszę wypełnić wszystkie pola"],
  ["required-field", "Proszę wypełnić to pole"],
  ["cancel", "Anuluj"],
  ["yes", "Tak"],
  ["no", "Nie"],
  ["duplicate", "Duplikuj"],
  ["freeform_expiration", "DLC (Freeform)"],
  ["auditing", "Audyt"],
  ["published", "Opublikowane"],
  ["structure", "Struktura"],
  ["new-section", "Nowa sekcja"],
  ["new-question", "Nowe pytanie"],
  ["question", "Pytanie"],
  ["question.multiple_choice", "Wielokrotne opcje"],
  ["question.text_input", "Tekst"],
  ["question.yes_or_no", "Tak/Nie"],
  ["question.file_upload", "Dokument"],
  ["question.rating", "Ocena"],
  ["question.number", "Liczba"],
  ["question.date", "Data"],
  ["options", "Opcje"],
  ["limit-characters", "Ograniczenie znaków"],
  ["max-characters", "Maksymalna liczba znaków"],
  ["score", "Punktacja"],
  ["preview", "Podgląd"],
  ["flutter-embedding", "Osadzanie Flutter"],
  ["min-rating", "Minimalna ocena"],
  ["max-rating", "Maksymalna ocena"],
  ["min-number", "Minimalna liczba"],
  ["max-number", "Maksymalna liczba"],
  ["no-options", "Brak opcji dla wybranego typu."],
  ["allowed-extensions", "Dozwolone rozszerzenia"],
  ["limit-current-date", "Ograniczenie daty"],
  ["date.no_limit", "Brak ograniczeń"],
  ["date.only_future", "Tylko przyszłość"],
  ["date.only_past", "Tylko przeszłość"],
  ["results", "Wyniki"],
  ["general", "Ogólne"],
  ["time-target-temperature", "Czas do temperatury docelowej"],
  ["target-temperature", "Temperatura docelowa"],
  ["postpone-date", "Przełożona data"],
  ["select-all", "Zaznacz wszystko"],
  ["ask-domhygiene", "Nie znaleziono żadnego zadania w tym okresie"],
  ["lost-password", "Zapomniane hasło? Kliknij tutaj"],
  [
    "login.recovery.help",
    "Proszę podać adres e-mail podany podczas tworzenia konta",
  ],
  ["login.recovery.send-mail", "Wyślij e-mail z odzyskiwaniem"],
  [
    "login.recovery.error",
    "Nie można wysłać wiadomości z odzyskiwaniem. Proszę podać poprawny adres e-mail",
  ],
  [
    "login.recovery.success",
    "Wiadomość z odzyskiwaniem została wysłana na podany adres e-mail",
  ],
  ["multiple-selection", "Wielokrotny wybór"],
  ["choices", "Opcje"],
  ["new-choice", "Nowa opcja"],
  ["choice-name", "Nazwa opcji"],
  ["section-name", "Nazwa sekcji"],
  ["delete-dialog", "Potwierdzenie usuwania"],
  [
    "delete-confirm",
    "Ostrzeżenie: Masz zamiar usunąć ten element. Tej operacji nie można cofnąć. Czy na pewno chcesz kontynuować?",
  ],
  ["total-score", "Całkowita liczba punktów"],
  ["questions-empty", "Nie dodano jeszcze żadnych sekcji"],
  ["add-new-section", "Dodaj nową sekcję"],
  ["already-manager", "To konto już zarządza tą bazą danych"],
  ["opening", "Otwieranie"],
  ["temperature_control", "Kontrola temperatury"],
  [
    "target-require-customer",
    "Rola docelowego konta musi być klientem i nie może posiadać żadnych sklepów. Proszę utworzyć nowe konto",
  ],
  ["welcome-to-secureat", "Witamy w panelu Secureat"],
  ["download-pdf", "Pobierz PDF"],
  ["sections", "Sekcje"],
  ["questions", "Pytania"],
  ["reheating-temperature", "Temperatura podgrzewania"],
  ["cooling-temperature", "Temperatura chłodzenia"],
  ["cooling", "Chłodzenie"],
  ["reheating", "Podgrzewanie"],
  [
    "invalid-database",
    "Nieprawidłowa baza danych. Baza danych nie posiada dystrybutora.",
  ],
  ["preparations", "Przygotowania"],
  ["saved-successfully", "Zapisano pomyślnie"],
  ["start-temperature", "Temperatura początkowa"],
  ["end-temperature", "Temperatura końcowa"],
  ["temperature-control-duration", "Czas trwania"],
  ["attachment", "Załącznik"],
  ["period", "Okres"],
  ["add-condition", "Dodaj warunek"],
  ["edit-condition", "Edytuj warunek"],
  ["choose-question", "Wybierz pytanie warunkowe"],
  ["question-condition", "Wybierz odpowiedź na pytanie dla tego warunku"],
  ["create", "Utwórz"],
  ["no-parameters", "Moduł nie posiada żadnych parametrów."],
  ["import", "Import"],
  ["export", "Eksport"],
  ["imported-successfully", "Dane pomyślnie zaimportowane"],
  ["import-error", "Wystąpił błąd podczas importowania"],

  ["user-already-owns-store", "Użytkownik już posiada to miejsce"],
  [
    "unable-admin-assign-store",
    "Nie można przypisać miejsca do konta administratora",
  ],
  [
    "unable-manager-assign-store",
    "Nie można przypisać miejsca do konta menadżera",
  ],
  ["email.invitation.subject", "Zaproszenie do zostania właścicielem sklepu"],
  ["email.invitation.body", "Zostałeś zaproszony do objęcia własnością sklepu"],
  ["email.invitation.body.login", "Zaloguj się teraz do SecurEat"],
  ["email.invitation.body.register", "Załóż teraz konto w SecurEat"],
  ["email.invitation.snackbar.sent", "Zaproszenie zostało wysłane do"],
  ["form.store.invalid.exists", "Sklep o tej nazwie lub adresie już istnieje."],
  ["rating-precision", "Dokładność"],
  ["question-required", "Wymagane pytanie"],
  ["warning-dialog", "Ostrzeżenie"],
  [
    "audit-published-warning",
    "Uwaga: Audyt został już opublikowany. Proszę być ostrożnym, ponieważ modyfikacje w audycie mogą potencjalnie zakłócić istniejące odpowiedzi.",
  ],
  ["audit-publish-confirmation", "Czy na pewno chcesz opublikować audyt?"],
  ["publish", "Opublikuj"],
  ["duplicated-successfully", "Skopiowano pomyślnie."],
  ["store.select-database", "Wybierz bazę danych"],
  ["lock", "Zablokuj"],
  ["unlock", "Odblokuj"],
  ["user-store-limit", "Użytkownik nie może posiadać więcej niż 30 sklepów."],
  ["answer", "Odpowiedź"],
  ["score", "Wynik"],
  ["percentage", "Procent"],
  ["audit-no-results", "Brak dodanych jeszcze wyników dla tej oceny."],
  ["open-pdf", "Otwórz PDF"],
  ["open-csv", "Otwórz CSV"],
  ["required", "Wymagane"],
  ["select-results", "Proszę wybrać wyniki, które chcesz zobaczyć."],
  ["email", "Email"],
  ["last", "Ostatni"],
  ["january", "Styczeń"],
  ["february", "Luty"],
  ["march", "Marzec"],
  ["april", "Kwiecień"],
  ["may", "Maj"],
  ["june", "Czerwiec"],
  ["july", "Lipiec"],
  ["august", "Sierpień"],
  ["september", "Wrzesień"],
  ["october", "Październik"],
  ["november", "Listopad"],
  ["december", "Grudzień"],
  ["no-results", "Brak wyników"],
  ["of", "z"],
  ["question-missing", "Nie podano tekstu pytania"],
  ["answer-missing", "Nie podano odpowiedzi"],
  ["range", "Zakres"],
  ["min", "Min"],
  ["max", "Maks"],
  ["range-picker-invalid", "Wartość minimalna musi być niższa niż maksymalna."],
  ["from", "Od"],
  ["to", "do"],
  ["true", "Prawda"],
  ["false", "Fałsz"],
  ["not-taken", "Nie wzięte"],
  ["settings", "Ustawienia"],
  ["allergens", "Alergeny"],
  ["select-allergens", "Wybierz alergeny"],
  ["allergen.A", "Zboża zawierające gluten"],
  ["allergen.B", "Skorupiaki"],
  ["allergen.C", "Jajko"],
  ["allergen.D", "Ryby"],
  ["allergen.E", "Orzechy ziemne"],
  ["allergen.F", "Soja"],
  ["allergen.G", "Mleko lub laktoza"],
  ["allergen.H", "Jadalne orzechy"],
  ["allergen.L", "Seler"],
  ["allergen.M", "Musztarda"],
  ["allergen.N", "Sezam"],
  ["allergen.O", "Siarczyny"],
  ["allergen.P", "Strączki"],
  ["allergen.R", "Mięczaki"],
]);
