import { DocumentReference } from "firebase/firestore";
import TaskResult from "./TaskResult";
import Element from "@/model/Elements/Element";
import PictureResult from "./PictureResult";

export default class TemperatureResult extends TaskResult {
  temperature: number = 0;
  element: Element | null = null;
  firstTemperature: number | null = null;
  comment: string | null = null;
  pictures: PictureResult[] = [];

  constructor(
    details: string,
    status: string,
    timestamp: Date | null,
    element: Element | null,
    temperature: number,
    firstTemperature: number | null,
    comment: string | null,
    pictures: PictureResult[]
  ) {
    super(details, status, timestamp);
    this.element = element;
    this.temperature = temperature;
    this.firstTemperature = firstTemperature;
    this.comment = comment;
    this.pictures = pictures;

    console.log(this.pictures);
  }

  public isTemperatureRangeValid() {
    if (this.temperature != null) {
      if (
        this.temperature < this.element?.getMinimumTemperature()! ||
        this.temperature > this.element?.getMaximumTemperature()!
      ) {
        return false;
      }
    }
    return true;
  }
}
