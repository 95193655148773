
import { defineComponent } from "vue";
import SeSnackbar from "./SeSnackbar.vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  components: {
    SeSnackbar,
  },
});
