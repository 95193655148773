import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";

import * as StringUtils from "@/utils/StringUtils";
import { getState } from "@/pinia/AppState";

interface DatabaseState {
  search: string | null;
}

export const getDatabasesState = defineStore("databasesState", {
  state: (): DatabaseState => {
    return {
      search: null,
    };
  },
  actions: {
    async setup() {},
  },
});
