
import { defineComponent } from "vue";
import * as DOMUtils from "@/utils/DOMUtils";

export default defineComponent({
  data() {
    return {
      imageFile: null as File | null,
    };
  },
  async setup() {},

  props: {
    onFilePick: {
      type: Function,
      default: null,
    },
    src: {
      type: String,
      default: "",
      required: true,
    },
  },
  methods: {
    pickIllustration() {
      DOMUtils.selectFileDialog(this.modifyIllustration);
    },

    async modifyIllustration(e: any) {
      let picture: any = this.$refs.picture;
      let file = e.target.files[0];
      const fileReader = new FileReader();

      var onFilePick = this.onFilePick;

      fileReader.addEventListener("load", function (evt: any) {
        if (onFilePick != null) {
          onFilePick(evt, file);
        }
        picture.src = evt.target.result;
      });

      fileReader.readAsDataURL(file);
      this.imageFile = file;
    },
  },
});
