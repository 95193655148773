export default class PictureResult {
  details: string | null = null;
  firebase_path: string | null;
  local_path: string | null;
  status: string | null;
  timestamp: Date | null;
  upload_timestamp: Date | null;
  url: string | null;

  constructor(
    details: string | null,
    status: string | null,
    timestamp: Date | null,
    firebase_path: string | null,
    local_path: string | null,
    upload_timestamp: Date | null,
    url: string | null
  ) {
    this.details = details;
    this.status = status;
    this.timestamp = timestamp;
    this.firebase_path = firebase_path;
    this.local_path = local_path;
    this.upload_timestamp = upload_timestamp;
    this.url = url;
  }

  public static fromFirebase(x: any) {
    return new PictureResult(
      x.details,
      x.status,
      x.timestamp,
      x.firebase_path,
      x.local_path,
      x.upload_timestamp,
      x.url
    );
  }
}
