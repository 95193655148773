import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "flex justify-between center" }
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_AuditingList = _resolveComponent("AuditingList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('auditing')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeDropdown, {
        titleFunc: (database) => database.name,
        style: {"width":"33%","margin":"24px"},
        modelValue: _ctx.state.selectedDatabase,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.selectedDatabase) = $event)),
        ref: "databaseSelect",
        nullable: true,
        onSelectionChanged: _ctx.fetchAudits
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.databases, (database) => {
            return (_openBlock(), _createBlock(_component_SeDropdownItem, {
              value: database,
              key: database.ref?.id
            }, null, 8, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["titleFunc", "modelValue", "onSelectionChanged"]),
      _createVNode(_component_SearchBar, {
        ref: "searchBar",
        newCallback: _ctx.openAuditingConfigurationPage,
        btnText: _ctx.isDistributor() ? _ctx.Lang.getI18N('new') : undefined,
        style: {"width":"66%","margin-top":"12px","margin-right":"24px"},
        calm: true
      }, null, 8, ["newCallback", "btnText"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AuditingList, {
        audits: _ctx.state.audits,
        ref: "auditingList"
      }, null, 8, ["audits"])
    ])
  ]))
}