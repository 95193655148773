import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1fe082c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex align-center justify-start",
  style: {"gap":"3px"}
}
const _hoisted_2 = { class: "tooltip" }
const _hoisted_3 = { class: "tooltiptext text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        ref: "lockedIcon",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
        width: "28",
        height: "28",
        src: "/ico/lock.svg"
      }, null, 512),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.locked ? _ctx.Lang.getI18N("field-locked") : _ctx.Lang.getI18N("field-unlocked")), 1)
    ])
  ]))
}