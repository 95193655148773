import { deleteObject, getStorage, ref } from "firebase/storage";
import UploadStrategy from "./UploadStategy";
import FirebaseUploadStrategy from "./FirebaseUploadStrategy";
import GCoreUploadStrategy from "./GCoreUploadStrategy";
import DeletionStrategy from "./DeletionStrategy";
import Attachment from "../Filesystem/Attachment";
import { StrategyEnum } from "./StrategyEnum";
import { AttachmentType } from "../Filesystem/AttachmentType";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
export let uploadCache = [] as UploadStrategy[];
export let deletionCache = [] as DeletionStrategy[];

export async function createStrategy(
  file: File,
  path: string | null
): Promise<UploadStrategy> {
  var strategy = null as UploadStrategy | null;

  if (file.type.includes("video")) {
    strategy = new GCoreUploadStrategy(file);
  } else {
    strategy = new FirebaseUploadStrategy(file, path!);
  }

  await strategy.prepare();

  return strategy;
}
export function addFileToUpload(strategy: UploadStrategy): void {
  uploadCache.push(strategy);
}

export async function applyUploads() {
  const uploadPromises = [];

  for (let cached of uploadCache) {
    const uploadPromise = new Promise<void>((resolve) => {
      cached.upload(() => {
        resolve();
      });
    });

    uploadPromises.push(uploadPromise);
  }
  await Promise.all(uploadPromises);

  console.log(uploadCache.length + " file uploaded.");

  uploadCache = [];
}

export function addFileToDelete(attachment: Attachment): void {
  let index = -1;

  for (let cached of uploadCache) {
    if (cached instanceof FirebaseUploadStrategy) {
      var path = (<FirebaseUploadStrategy>cached).path;

      if (path == path) {
        break;
      }
    }
    index++;
  }

  if (index != -1) {
    uploadCache.splice(index, 1);
    return;
  }

  let deletionStrategy = new DeletionStrategy();

  if (attachment.type == AttachmentType.Video) {
    deletionStrategy.video_id = attachment.video_id;
    deletionStrategy.strategyEnum = StrategyEnum.GCore;
  } else {
    deletionStrategy.path = attachment.path;
    deletionStrategy.strategyEnum = StrategyEnum.FirebaseStorage;
  }
  deletionCache.push(deletionStrategy);
}

export async function applyDeletions() {
  for (let deletion of deletionCache) {
    if (deletion.strategyEnum == StrategyEnum.FirebaseStorage) {
      try {
        const storage = getStorage();
        var reference = ref(storage, deletion.path!);
        await deleteObject(reference);
      } catch {
        console.warn("Unable to delete object " + deletion.path);
      }
    } else if (deletion.strategyEnum == StrategyEnum.GCore) {
      await SecureatServerApi.deleteGCoreVideo(deletion.video_id!);
    }
  }

  console.log(deletionCache.length + " file deleted.");

  deletionCache = [];
}
