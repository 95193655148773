
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  expose: ["getFiles", "reset"],
  props: ["icon", "accept"],

  methods: {
    getFiles(): File[] {
      return this.$el.firstChild.files;
    },
    reset() {
      this.$el.firstChild.value = "";
    },
  },
});
