import * as DateUtils from "@/utils/DateUtils";

export var data = {
  labels: [],
  datasets: [
    {
      label: "Temperature",
      backgroundColor: "rgb(69, 78, 255,0.3)",
      data: [],
      fill: {
        target: "origin",
        above: "rgb(69, 78, 255,0.3)", // Area will be red above the origin
      },
      tension: 0.1,
      hoverBackgroundColor: "white",
      pointRadius: 1,
      borderWidth: 0,
      borderColor: "rgb(69, 78, 255,0.8)",
    },
  ],
};
export const options = {
  scales: {
    x: {
      type: "time",
      time: {
        unit: "minute",
        offsetAfterAutoskip: false,
        displayFormats: {
          minute: "DD/MM/YYYY HH:mm", // Format for display on the axis
        },
        tooltipFormat: "DD/MM/YYYY HH:mm", // Format for tooltip
      },
      ticks: {
        source: "labels",
        autoSkip: false,
        stepSize: 1,
        // For a category axis, the val is the index so the lookup via getLabelForValue is neededf
        callback: function (val: number, index: number): any {
          var date = new Date(val);

          let instance = <any>this;

          let flag =
            index % Math.round(3 * (1 / instance.chart.getZoomLevel())) == 0;

          if (instance.chart.getZoomLevel() > 4) {
            flag = true;
          }
          return flag ? DateUtils.formatDateWithTime(date) : "";
        },
      },
    },
  },
  plugins: {
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
        modifierKey: "ctrl",
      },
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        drag: {
          enabled: true,
        },
        mode: "x",
        onZoomStart: function (param: any) {
          var chart = param.chart;

          var zoomLevel = chart.getZoomLevel();

          var event = param.event;

          var point = param.point;

          if (zoomLevel > 21 && event.deltaY < 0) {
            return false;
          }

          var chartWidth = chart.chartArea.right - chart.chartArea.left;

          // Calculate the minimum zoom level based on chart width
          var minZoomLevel = chartWidth / 3000; // Adjust divisor as needed

          // Prevent zooming out beyond the calculated minimum zoom level
          if (zoomLevel <= minZoomLevel && event.deltaY > 0) {
            return true; // false
          }

          return true;
          //  chart.resetZoom();
        },
      },
    },
    annotation: {
      annotations: {
        line1: {
          type: "line",
          mode: "horizontal",
          scaleID: "y",
          value: 29,
          borderColor: "#f05454",
          borderWidth: 2,
          label: {
            content: "test2",
            enabled: true,
            position: "center",
          },
        },

        line2: {
          type: "line",
          mode: "horizontal",
          scaleID: "y",
          value: 29,
          borderColor: "#f05454",
          borderWidth: 2,
          label: {
            content: "test",
            enabled: true,
            position: "center",
          },
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  interaction: {
    intersect: false,
    mode: "x",
  },

  type: "line",
};
