import TaskAction from "../TaskAction";
import TaskModuleConfiguration from "./TaskModuleConfiguration";

export default class TaskModuleConfigurationAction extends TaskModuleConfiguration {
  public clone() {
    return new TaskModuleConfigurationAction(this.actions.map((x) => x));
  }
  actions: TaskAction[];

  constructor(actions: TaskAction[]) {
    super();
    this.actions = actions;
  }
  public static fromFirebase(x: any): TaskModuleConfigurationAction {
    var actions = x.actions.map((x: any) => TaskAction.fromFirestore(x));
    return new TaskModuleConfigurationAction(actions);
  }
  public override toFirebase() {
    return {
      actions: this.actions.map((x) => x.toFirestore()),
    };
  }
}
