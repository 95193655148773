import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-51c1f7c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-between w100 align-center" }
const _hoisted_2 = { style: {"width":"70%"} }
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  class: "flex justify-between",
  style: {"width":"25%"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "table-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuditResultTable = _resolveComponent("AuditResultTable")!
  const _component_SeExpendable = _resolveComponent("SeExpendable")!

  return (_openBlock(), _createBlock(_component_SeExpendable, {
    headerSize: 20,
    headerBackgroundColor: '#515151',
    headerTextColor: '#ffffff'
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            title: _ctx.section.title,
            class: "section-name"
          }, _toDisplayString(_ctx.truncateText(_ctx.section.title, 150)), 9, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.section.calculateTotalScore() > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(`${_ctx.Lang.getI18N("total-score")}: ${_ctx.section.calculateTotalScore()}`), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_6)),
          _createElementVNode("span", null, _toDisplayString(`${_ctx.Lang.getI18N("questions")}: ${_ctx.section.getQuestions().length}`), 1)
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.section.getQuestions(), (question) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_AuditResultTable, { question: question }, null, 8, ["question"])
          ]))
        }), 256))
      ])
    ]),
    _: 1
  }))
}