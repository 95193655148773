
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Answer } from "@/model/Auditing/Answer";
import { Question } from "@/model/Auditing/Question";
import { MultipleQuestionOptions } from "@/model/Auditing/QuestionOptions";
import { Choice } from "@/model/Auditing/Choice";
import { getAuditResultsState } from "@/pinia/audits/AuditResultsState";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  props: {
    question: {
      type: Question,
      required: true,
    },
    answers: {
      type: Array as () => Answer[],
      required: true,
    },
    isPercentageShown: Boolean,
  },

  async setup() {
    var state = getAuditResultsState();

    return {
      state: state,
    };
  },

  methods: {
    showComment(answer: Answer) {
      Modals.open(answer.comment!, Lang.getI18N("comment"));
    },
  },
  computed: {
    getAnswerPercentage(): (choice: Choice) => string {
      return (choice: Choice) => {
        if (!this.answers || !this.answers.length || !choice) return "0";

        let multipleQuestionOptions = this.question
          .options as MultipleQuestionOptions;

        const totalAnswers = this.answers.length;
        const choiceOccurrences = this.answers.filter(
          (answer) => answer.choices && answer.choices.includes(choice.text)
        ).length;

        let percentage = (choiceOccurrences / totalAnswers) * 100;

        if (multipleQuestionOptions.multiple_selection) {
          percentage = (choiceOccurrences / totalAnswers) * 100;
        }

        let percentageString =
          percentage % 1 !== 0
            ? percentage.toFixed(2)
            : Math.round(percentage).toString();

        return percentageString.toString();
      };
    },
    getEmptyPercentage() {
      let totalPercentage = 0;
      (this.question.options as MultipleQuestionOptions).choices.map(
        (choice) => {
          let multipleQuestionOptions = this.question
            .options as MultipleQuestionOptions;

          const totalAnswers = this.answers.length;
          const choiceOccurrences = this.answers.filter(
            (answer) => answer.choices && answer.choices.includes(choice.text)
          ).length;

          let percentage = (choiceOccurrences / totalAnswers) * 100;

          if (multipleQuestionOptions.multiple_selection) {
            percentage =
              (choiceOccurrences /
                multipleQuestionOptions.choices.length /
                totalAnswers) *
              100;
          }

          totalPercentage += percentage;
        }
      );
      return 100 - totalPercentage;
    },
    hasEmptyQuestions() {
      return this.answers.find((answer) => answer.toPDF() === "");
    },
  },
  data() {
    return {
      Lang: Lang,
    };
  },
});
