
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import CloudItem from "@/model/Filesystem/CloudItem";
import * as Filesystem from "@/firebase/Filesystem";
import { CloudItemType } from "@/model/Filesystem/CloudItemType";
import { getState } from "@/pinia/AppState";
import { Role } from "@/model/User";
import SeLoadingIndicator from "../global/SeLoadingIndicator.vue";

export default defineComponent({
  props: {
    item: {
      required: false,
      type: CloudItem,
      default: null,
    },
    downloadClick: Function,
    deleteClick: Function,
    renameClick: Function,
    lockClick: Function,
  },

  methods: {
    getIcon() {
      return this.item.icon;
    },
    hasWriteRights() {
      var role = getState().userData!.role;

      if (role == Role.Customer && this.isDatabase) {
        return false;
      }

      if (
        (role == Role.Customer || role == Role.Manager) &&
        this.isDistributor
      ) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      Lang: Lang,
    };
  },

  setup(props) {
    let isFile = props.item.name.includes(".");

    let isDatabase = props.item.path.includes("database");

    let isDistributor = props.item.path.includes("distributors");

    return {
      isDistributor: isDistributor,
      isDatabase: isDatabase,
      isFile: isFile,
    };
  },

  components: { SeLoadingIndicator },
});
