
import { defineComponent } from "vue";
import NavHeader from "../../components/nav/NavHeader.vue";
import * as Lang from "@/i18n/lang";
import AllergensTable from "@/components/allergens/AllergensTable.vue";
import { getAllergensState } from "@/pinia/allergens/AllergensState";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default defineComponent({
  components: {
    NavHeader,
    AllergensTable,
  },
  async setup() {
    var state = getAllergensState();
    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return { Lang: Lang };
  },
  methods: {
    async takeScreenshotAndGeneratePDF() {
      const element = document.getElementById("allergens-table");

      html2canvas(element!).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let position = 0;
        const pageHeight = 297;
        let leftHeight = imgHeight;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;

        while (leftHeight > 0) {
          position -= pageHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
        }

        const pdfData = pdf.output("bloburl");
        window.open(pdfData, "_blank");
      });
    },
  },
});
