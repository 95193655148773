
import { defineComponent } from "vue";
import * as ImageUtils from "@/utils/ImageUtils";

export default defineComponent({
  props: {
    src: {
      type: String,
    },
    maxWidth: {
      default: null,
    },
    maxHeight: {
      default: null,
    },
    class: {
      type: String,
      default: "",
    },
    click: {
      type: Function,
      default: null,
    },
    cdn: {
      type: Boolean,
      default: false,
    },
  },
  expose: ["updateSource"],
  data: () => ({
    ImageUtils: ImageUtils,
    ready: false,
  }),

  watch: {
    src() {
      this.ready = false;
    },
  },

  methods: {
    onClick() {
      if (this.click != null) {
        this.click(this.src);
      }
    },
    updateSource(src: string) {
      if (this.cdn) {
        src = ImageUtils.toImageKit(src)!;
      }
      this.$el.childNodes[0].src = src;
      this.$forceUpdate();
    },
  },
  computed: {
    cssPropsContainer(): any {
      if (this.$props["maxWidth"] != null && this.$props["maxHeight"] != null) {
        return {
          "max-width": this.maxWidth + "px",
          "max-height": this.maxHeight + "px",
        };
      } else {
        return {};
      }
    },
    cssProps(): any {
      return {
        "max-width": this.maxWidth + "px",
        "max-height": this.maxHeight + "px",
        "object-fit": "cover",
        "border-radius": "6px",
        "aspect-ratio": 1,
      };
    },
  },
});
