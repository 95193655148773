import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4cf81d54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frame" }
const _hoisted_2 = { class: "flex col align-start justify-start g0" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SePlaceholder = _resolveComponent("SePlaceholder")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
    class: _normalizeClass({
      card: true,
      unactive: !_ctx.active,
      highlight: _ctx.isHighlighted,
      selected: _ctx.isSelected,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SeImage, {
        maxWidth: "50",
        maxHeight: "50",
        src: _ctx.icon
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_SePlaceholder, {
                key: 0,
                style: {"width":"38px","height":"38px"}
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["src"]),
      _renderSlot(_ctx.$slots, "pretitle", {}, undefined, true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_4, [
          _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "body", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ], 2))
}