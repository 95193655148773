export function getIllustrationUrlWithWatermark(
  path: string,
  width?: number,
  height?: number,
  databaseWatermark?: string
): string {
  path = path.replace("https://ik.imagekit.io/secureat", "");
  path = path.replace("https://firebasestorage.googleapis.com", "");
  let result: string = `${"https://ik.imagekit.io/secureat"}${path}`;

  if (
    width !== undefined ||
    height !== undefined ||
    databaseWatermark !== undefined
  ) {
    result += "&tr=";

    if (width !== undefined) {
      result += `w-${width}`;
    }

    if (height !== undefined) {
      result += `${width !== undefined ? "," : ""}h-${height}`;
    }

    if (databaseWatermark !== undefined) {
      result +=
        ",l-image,i-" +
        databaseWatermark +
        ",w-32,h-32,lx-60,ly-10,r-6,t-false,l-end";
    }
  }

  return result;
}

export function toImageKit(url: string) {
  return url == null
    ? null
    : url.replaceAll(
        "https://firebasestorage.googleapis.com",
        "https://ik.imagekit.io/secureat/tr:w-128"
      );
}
export function fromImageKit(url: string) {
  return url == null
    ? null
    : url.replaceAll(
        "https://ik.imagekit.io/secureat/tr:w-128",
        "https://firebasestorage.googleapis.com"
      );
}
export async function loadImageAsArrayBuffer(url: string) {
  try {
    // Use the Fetch API to fetch the image
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to load image (status ${response.status})`);
    }

    // Convert the response to ArrayBuffer
    const arrayBuffer = await response.arrayBuffer();

    // Return the ArrayBuffer
    return arrayBuffer;
  } catch (error) {
    // Handle any errors during the fetch
    console.error("Error loading image:", error);
    throw error; // Re-throw the error to indicate failure
  }
}

export async function encodeFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Event listener for when the file is successfully read
    reader.onloadend = () => {
      // The result is a Base64 string starting with 'data:' (data URL scheme)
      const base64String = reader.result as string;
      // Remove the data URL scheme part if you only need the Base64 data
      const base64Data = base64String.split(",")[1];
      resolve(base64Data);
    };

    // Event listener for errors
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  });
}
