
import { defineComponent } from "vue";
import * as Lang from "@/i18n/lang";

export default defineComponent({
  props: {},
  data() {
    return {
      callback: Function,
      Lang: Lang,
      numberMin: 0,
      numberMax: 0,
      rangeMin: 0,
      showWarning: false,
      componentKey: 0,
    };
  },
  emits: ["close", "action"],
  expose: ["openModal"],
  methods: {
    handleAction() {
      let refs = <any>this.$refs;

      if (this.numberMax <= this.numberMin) {
        this.showWarning = true;
        return;
      } else {
        this.showWarning = false;
        this.callback(this.numberMin.toString(), this.numberMax.toString());
        refs.rangePickerModal.close();
      }
    },
    openModal(
      callbackFunction: any,
      numberMin: number,
      numberMax: number,
      componentKey: number,
      rangeMin: number = 0
    ) {
      this.callback = callbackFunction;
      this.numberMin = numberMin;
      this.numberMax = numberMax;
      this.rangeMin = rangeMin;

      if (this.componentKey === componentKey) {
        this.componentKey += 1;
      } else {
        this.componentKey = componentKey;
      }

      if (this.numberMax <= this.numberMin) {
        this.showWarning = true;
      } else {
        this.showWarning = false;
      }

      let refs = <any>this.$refs;
      refs.rangePickerModal.open();
      return;
    },
  },
});
