import { doc, collection } from "firebase/firestore";
import { getState } from "../../pinia/AppState";
import * as Firebase from "@/firebase/Firebase";

export function generateUniqueId(): string {
  const state = getState();

  const collectionRef = collection(Firebase.firestore, "test");

  const newDocRef = doc(collectionRef);

  return newDocRef.id;
}
