
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Info",
      required: true,
    },
    noHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  expose: ["open", "close", "context", "onClose"],
  data() {
    return {
      context: null,
      displayed: false,
    };
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(event: any) {
      var body = <any>this.$refs.body;

      if (this.isOpen()) {
        if (event.key == "Escape") {
          this.close();
        }
        if (event.key == "Enter") {
          var element = body.querySelector(".modal-valid");

          if (element != undefined) {
            element.click();
          }
        }
      }
    },
    isOpen() {
      return this.displayed;
    },
    close() {
      this.$el.classList.remove("anim");
      this.$el.querySelector(".modal-content").classList.remove("anim-content");
      this.$el.style.display = "none";
      this.$emit("onClose");
      this.displayed = false;
    },
    open(context: any = null) {
      this.context = context;
      this.$el.style.display = "flex";
      this.$el.style.visibility = "visible";
      this.$el.classList.add("anim");
      this.$el.querySelector(".modal-content").classList.add("anim-content");
      this.displayed = true;
    },
  },
});
