import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "container flex col" }
const _hoisted_3 = { class: "flex align-center" }
const _hoisted_4 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_CommunicationList = _resolveComponent("CommunicationList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('communication')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SeDropdown, {
          search: true,
          titleFunc: (database) => database.name,
          modelValue: _ctx.state.selectedDatabase,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.selectedDatabase) = $event)),
          onSelectionChanged: _ctx.onDatabaseChanged
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.databases, (database) => {
              return (_openBlock(), _createBlock(_component_SeDropdownItem, {
                key: database,
                value: database
              }, null, 8, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["titleFunc", "modelValue", "onSelectionChanged"]),
        _createVNode(_component_SearchBar, {
          class: "grow",
          ref: "searchBar",
          modelValue: _ctx.state.displayUnactives,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.displayUnactives) = $event)),
          newCallback: _ctx.addCommunication,
          btnText: _ctx.userRole != _ctx.Role.Customer ? _ctx.Lang.getI18N('new') : undefined
        }, null, 8, ["modelValue", "newCallback", "btnText"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_CommunicationList, {
          database: _ctx.state.selectedDatabase!
        }, null, 8, ["database"])
      ])
    ])
  ]))
}