import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";

export default class CommunicationTarget {
  clone(): CommunicationTarget {
    return new CommunicationTarget(
      this.stores.map((x) => x),
      this.store_tags.map((x) => x),
      this.employee_roles.map((x) => x),
      this.store_tags_union
    );
  }
  stores: DocumentReference[];
  store_tags: DocumentReference[];
  employee_roles: DocumentReference[];
  store_tags_union: boolean;
  /* Transcient */

  constructor(
    stores: DocumentReference[] = [],
    store_tags: DocumentReference[] = [],
    employee_roles: DocumentReference[] = [],
    store_tags_union: boolean = true
  ) {
    this.stores = stores;
    this.store_tags = store_tags;
    this.employee_roles = employee_roles;
    this.store_tags_union = store_tags_union;
  }

  public static fromFirestore(x: any): CommunicationTarget {
    return new CommunicationTarget(
      x.stores,
      x.store_tags,
      x.employee_roles,
      x.store_tags_union
    );
  }

  public toFirestore() {
    return {
      stores: this.stores,
      store_tags: this.store_tags,
      employee_roles: this.employee_roles,
      store_tags_union: this.store_tags_union,
    };
  }
}
