
import { defineComponent } from "@vue/runtime-core";
import * as Device from "@/utils/Device";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  props: ["result"],
  methods: {
    displayPictures() {
      Modals.openCarousel(this.result.pictures.map((x) => x.url));
    },
  },
});
