import { getState } from "../pinia/AppState";

import { getFunctions, httpsCallable } from "firebase/functions";
import * as Firebase from "@/firebase/Firebase";

export async function sendEmail(
  recipients: string[],
  subject: string,
  body: string
) {
  let state = getState();

  let functions = getFunctions(Firebase.firebase);

  const sendMailFunction = httpsCallable(functions, "sendEmail");
  sendMailFunction({
    sender: "Secureat",
    recipients: recipients,
    subject: subject,
    body: body,
  })
    .then((res) => {
      return "ok";
    })
    .catch((e) => {
      return e.details.code;
    });
}
