
import { PropType, defineComponent, nextTick } from "vue";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import Element from "@/model/Elements/Element";
import * as Lang from "@/i18n/lang";
import StoreElement from "@/model/Elements/StoreElement";
import { ModuleEnum } from "@/model/ModuleEnum";

export default defineComponent({
  props: {
    validElements: Array as PropType<Element[]>,
    selectedElements: Array as PropType<Element[]>,
    module: {
      default: ModuleEnum.Temperatures,
      required: true,
    },
    store: {
      type: Boolean,
      default: false,
    },
  },

  expose: ["automatic", "getSelectedElements", "update"],
  data() {
    return {
      displayedElements: [] as Element[],
      automatic: false,
      Lang: Lang,
    };
  },

  methods: {
    displayAutomatic() {
      return this.store && this.module == ModuleEnum.Temperatures;
    },
    alreadySelected(item: Element): boolean {
      return this.selectedElements!.some(
        (element: Element) => element.getName() == item.getName()
      );
    },
    onToggleClick(value: boolean) {
      var refs = <any>this.$refs;
    },

    toggleSelectAll() {
      var refs = <any>this.$refs;

      if (refs.items == undefined) {
        return;
      }
      var flag = refs.selectAll.getValue();

      for (let i = 0; i < refs.items.length; i++) {
        let item = refs.items[i];

        if (item == refs.selectAll) {
          continue;
        }
        let check = item.$el.getElementsByClassName("se-checkbox")[0];

        // should use refs here, (but cannot access $refs in child slots....)
        check.childNodes[check.childNodes.length - 2].checked = !flag;
      }
    },
    unselectAll() {
      var refs = <any>this.$refs;

      if (refs.items == undefined) {
        return;
      }
      var flag = refs.selectAll.getValue();

      for (let i = 0; i < refs.items.length; i++) {
        let item = refs.items[i];

        if (item == refs.selectAll) {
          continue;
        }
        let check = item.$el.getElementsByClassName("se-checkbox")[0];

        // should use refs here, (but cannot access $refs in child slots....)
        check.childNodes[check.childNodes.length - 2].checked = false;
      }
    },

    toggleCheckBox(item: Element) {
      const refs = <any>this.$refs;
      const index = this.displayedElements!.findIndex(
        (el: any) => el.ref.id === item.ref!.id
      );
      if (index !== -1) {
        const check =
          refs.items[index].$el.getElementsByClassName("se-checkbox")[0];
        check.childNodes[check.childNodes.length - 2].checked =
          !check.childNodes[check.childNodes.length - 2].checked;
      }
    },
    getSelectedElements() {
      let results: any = [];

      let refs = <any>this.$refs;

      if (refs.items == undefined) {
        return results;
      }
      for (let i = 0; i < refs.items.length; i++) {
        let item = refs.items[i];

        if (item == refs.selectAll) {
          continue;
        }
        let check = item.$el.getElementsByClassName("se-checkbox")[0];

        let checked = check.childNodes[check.childNodes.length - 2].checked;

        if (checked) {
          results.push(item.obj);
        }
      }

      return results;
    },

    update() {
      this.onAutomaticChange(this.automatic);
    },
    onAutomaticChange(val: boolean) {
      var refs = <any>this.$refs;

      if (refs.automaticSwitch == undefined) {
        this.displayedElements = this.validElements!;
        return;
      }

      if (this.store) {
        if (val) {
          this.displayedElements = this.validElements?.filter(
            (x) => (<StoreElement>x).probe_id != null
          )!;
        } else {
          this.displayedElements = this.validElements!;
        }

        refs.automaticSwitch.toggle(val, false);

        refs.selectAll.setValue(false);
        this.unselectAll();
      }
    },
  },
  watch: {
    validElements: {
      handler(val: Element[]) {
        this.displayedElements = val;
      },
    },
    automatic: {
      handler(val: boolean) {
        this.onAutomaticChange(val);
      },
    },
  },
});
