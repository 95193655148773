
import { getState } from "@/pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getAuth } from "firebase/auth";
import * as Lang from "@/i18n/lang";
import { defineComponent } from "vue";
import LanguageSelect from "../global/LanguageSelect.vue";

export default defineComponent({
  mounted() {},
  props: {
    title: String,
    backButton: String,
    subtitle: String,
  },
  data() {
    let appState = getAppState();
    return {
      appState: appState,
      Lang: Lang,
    };
  },
  methods: {
    displayNavBar() {
      getAppState().toggleNavPanel();
    },
    goBack() {
      getNavigationState().goBack();
    },
  },
  components: { LanguageSelect },
});
