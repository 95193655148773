
import { defineComponent } from "vue";
import * as ct from "countries-and-timezones";
import { nextTick } from "vue";
import SeDropdown from "./dropdown/SeDropdown.vue";
export default defineComponent({
  expose: ["getCountry", "selectCountryCode", "setIndex", "getTimezone"],
  data() {
    return {
      europeanCountries: [] as any[],
    };
  },
  mounted() {
    const allCountries = <any>ct.getAllCountries();
    this.europeanCountries = <any>Object.keys(allCountries)
      .filter((countryCode) =>
        allCountries[countryCode].timezones.some((timezone: string) =>
          timezone.startsWith("Europe/")
        )
      )
      .map((countryCode) => ({
        timezone: allCountries[countryCode].timezones[0],
        code: countryCode,
        name: (<any>allCountries[countryCode]).name.toString(),
      }));
  },
  methods: {
    toUtcFormat(timezone: string): number {
      const timezoneData = ct.getTimezone(timezone);
      let integerUTC = timezoneData?.utcOffset;
      let result = 0;
      if (integerUTC && integerUTC !== 0) {
        result = integerUTC / 60;
      }
      return result;
    },

    selectCountryCode(code: string) {
      var refs = <any>this.$refs;

      var results = this.europeanCountries.filter((x) => x.code == code);
      if (results.length == 0) {
        refs.country.clear();
        return;
      }
      refs.country.setValue(results[0]);
    },
    getTimezone() {
      let country = this.getCountry();
      if (country == null) return null;
      return this.toUtcFormat(country.timezone);
    },
    getCountry() {
      var refs = <any>this.$refs;
      return refs.country.getValue();
    },
  },
  components: { SeDropdown },
});
