import { defineStore } from "pinia";
import Audit from "@/model/Auditing/Audit";
import { getAuditsState } from "@/pinia/audits/AuditsState";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";

interface AuditConfigurationState {
  initialAudit: Audit | null;
  audit: Audit | null;
  selectedDatabaseId: string | null;
  creation: boolean;
  changeSaved: boolean;
}

export const getAuditConfigurationState = defineStore(
  "auditConfigurationState",
  {
    state: (): AuditConfigurationState => {
      return {
        initialAudit: null,
        audit: null,
        selectedDatabaseId: null,
        creation: false,
        changeSaved: false,
      };
    },
    actions: {
      set(audit: Audit | null, databaseId: string | null) {
        this.creation = audit == null;
        this.audit = null;

        this.changeSaved = false;

        if (this.creation) {
          this.audit = new Audit(
            doc(
              collection(
                Firebase.firestore,
                "databases/" + databaseId + "/",
                "audits"
              )
            ),
            "",
            false,
            false,
            new Date()
          );
        } else if (audit instanceof Audit) {
          this.initialAudit = audit;

          if (
            this.initialAudit!.sections &&
            this.initialAudit!.sections.length > 0
          ) {
            this.initialAudit!.sections.forEach((section, index) => {
              section.isVisible = index < 3;
            });
          }

          this.audit = Audit.clone(this.initialAudit, true);
        }

        if (databaseId) {
          this.selectedDatabaseId = databaseId;
        } else {
          let auditsState = getAuditsState();
          if (auditsState.selectedDatabase?.ref?.id) {
            this.selectedDatabaseId = auditsState.selectedDatabase?.ref?.id;
          }
        }
      },
    },
  }
);
