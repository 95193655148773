
import LoginPanel from "../components/login/LoginPanel.vue";
import RegisterPanel from "../components/login/RegisterPanel.vue";
import { getState } from "../pinia/AppState";
import { defineComponent } from "vue";
import { LoginViewType } from "@/views/LoginViewType";
import LostPassword from "@/components/login/LostPassword.vue";
import { getUserManager } from "@/firebase/UserManager";

export default defineComponent({
  components: {
    LoginPanel,
    RegisterPanel,
    LostPassword,
  },
  expose: ["switchView"],

  methods: {
    switchView(type: LoginViewType) {
      this.type = type;
    },
  },

  data() {
    return {
      LoginViewType: LoginViewType,
      type: LoginViewType.Login,
      initialEmail: "",
    };
  },

  async mounted() {
    let userEmail = getState().initialEmail;
    if (userEmail !== null) {
      let userData = await getUserManager().getUserByEmail(userEmail);
      if (userData === null) {
        this.switchView(LoginViewType.Registration);
      }
    }
  },
});
