import Attachment from "../Filesystem/Attachment";

export default class TaskAction {
  action: string = "";
  requires_picture: boolean = false;
  attachments: Attachment[] = [];

  constructor(
    action: string = "",
    requires_picture: boolean = false,
    attachment: Attachment[] = []
  ) {
    this.action = action;
    this.requires_picture = requires_picture;
    this.attachments = attachment;
  }

  public toFirestore() {
    return {
      action: this.action,
      requires_picture: this.requires_picture,
      attachments: this.attachments.map((x) => x.toFirestore()),
    };
  }
  public static fromFirestore(x: any) {
    return new TaskAction(
      x.action,
      x.requires_picture,
      x.attachments == undefined
        ? []
        : x.attachments.map((x: any) => Attachment.fromFirestore(x))
    );
  }
}
