
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import TemperatureResultRow from "@/components/tasks/table/TemperatureResultRow.vue";
import Task from "@/model/Task";
import Store from "@/model/Store";
import TemperatureResult from "@/model/TaskResults/TemperatureResult";
import * as Lang from "@/i18n/lang";
import TaskStatus from "../TaskStatus.vue";

export default defineComponent({
  props: {
    task: {
      type: Task,
    },
    store: {
      type: Store,
    },
  },

  expose: ["expendable"],

  methods: {},

  async mounted() {
    this.results = (await TaskManager.getTaskResults(
      this.task!,
      this.store!
    )) as TemperatureResult[];

    this.notFilledCount = this.results.filter(
      (x) => x.temperature == null
    ).length;

    this.invalidRangeCount = this.results.filter(
      (x) => !x.isTemperatureRangeValid()
    ).length;
  },

  data() {
    return {
      TaskManager: TaskManager,
      Lang: Lang,
      invalidRangeCount: 0,
      notFilledCount: 0,
      results: [] as TemperatureResult[],
    };
  },
  components: {
    TemperatureResultRow,
    TaskStatus,
  },
});
