import { defineStore } from "pinia";
import User from "@/model/User";

interface AccountState {
  selectedAccount: User | null;
  initialAccount: User | null;
}

export const getAccountState = defineStore("accountState", {
  state: (): AccountState => {
    return {
      selectedAccount: null,
      initialAccount: null,
    };
  },
  actions: {
    set(user: User) {
      this.initialAccount = user;
      this.selectedAccount = User.clone(user);
    },
  },
});
