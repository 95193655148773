
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import * as chartConfig from "./ProfilingChartConfig";
import { defineComponent, nextTick } from "vue";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { getAdminState } from "@/pinia/admin/AdminState";
import { getState as getAppState } from "@/pinia/AppState";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default defineComponent({
  name: "App",
  components: {
    Line,
  },
  setup() {
    return {
      intervalId: null as any,
    };
  },
  data() {
    return chartConfig;
  },
  methods: {
    computeAverage(data: any) {
      let results = [];

      let gap = 5;

      for (let i = 0; i < data.length; i += gap) {
        let averageCpu = 0;

        let averageMemory = 0;

        if (data[i] == null) {
          console.warn("snapshot in profiling is null at index " + i);
          continue;
        }

        let timestamp = data[i].timestamp;

        if (i + gap > data.length - 1) {
          break;
        }

        for (let j = i; j < i + gap; j++) {
          var cpu = data[j].cpuUsagePercent;
          averageCpu += cpu;
          averageMemory += data[j].memoryUsagePercent;
        }

        averageCpu /= gap;
        averageMemory /= gap;

        averageCpu = Math.max(0, averageCpu);
        averageMemory = Math.max(0, averageMemory);

        results.push({
          cpuUsagePercent: averageCpu,
          memoryUsagePercent: averageMemory,
          timestamp: timestamp,
        });
      }

      return results;
    },
    async updateGraph() {
      var refs = <any>this.$refs;

      let data: any = null;

      try {
        data = await SecureatServerApi.getProfilingData();
      } catch {
        clearInterval(this.intervalId);
        return;
      }
      if (refs.graph == null || data == null) {
        clearInterval(this.intervalId);
        return;
      }
      data = this.computeAverage(data);

      const ctx = refs.graph.chart.getContext("2d");

      let timestamps: string[] = [];
      let cpuUsage = [];
      let memoryUsage = [];

      for (let snapshot of data) {
        cpuUsage.push(snapshot.cpuUsagePercent);
        memoryUsage.push(snapshot.memoryUsagePercent);

        var date = new Date(snapshot.timestamp);

        var label = date.toLocaleString("default", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
        timestamps.push(label);
      }

      chartConfig.data.datasets[0].data = cpuUsage;

      chartConfig.data.datasets[1].data = memoryUsage;

      chartConfig.data.labels = timestamps;

      refs.graph.chart.data = chartConfig.data;
      ctx.chart.update();
    },
  },
  async mounted() {
    if (getAppState().connectedToApi) {
      this.intervalId = setInterval(this.updateGraph, 30 * 1000);
      await this.updateGraph();
    }
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
});
