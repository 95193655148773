import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"white-space":"nowrap","font-size":"13px"},
  class: "bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('se-chip ' + (_ctx.selected ? 'selected' : ''))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ], 2))
}