import Store from "../Store";
import { CloudItemType } from "./CloudItemType";
export default class CloudItem {
  name: string = "";
  type: CloudItemType = CloudItemType.Unknown;
  path: string = "";
  length: number = 0;
  icon: string = "";
  additionalItems: CloudItem[] = [];
  locked: boolean = false;
  forceReadonly: boolean = false;
  isVideo = false;

  constructor(
    name: string,
    type: CloudItemType,
    path: string,
    icon: string,
    length: number = 0,
    additionalItems: CloudItem[] = []
  ) {
    this.name = name;
    this.type = type;
    this.path = path;
    this.length = length;
    this.icon = icon;
    this.additionalItems = additionalItems;
  }
}
