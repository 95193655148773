
import User, { Role } from "@/model/User";
import { PropType, defineComponent } from "vue";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as Lang from "@/i18n/lang";
import { getAccountState } from "@/pinia/accounts/AccountState";
import Communication from "@/model/Communication/Communication";
import { CommunicationState } from "@/model/Communication/CommunicationState";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import Database from "@/model/Database";
import Quiz, { QuizAnswer, QuizQuestion } from "@/model/Communication/Quiz";
import ToggleSwitch from "../global/ToggleSwitch.vue";
import SeTextarea from "../global/SeTextarea.vue";
export default defineComponent({
  data() {
    return {
      Role: Role,
      Lang: Lang,
    };
  },
  props: {
    question: {
      type: QuizQuestion,
      required: true,
    },
  },

  methods: {
    addAnswer() {
      this.question?.answers.push(new QuizAnswer("", false));
    },
    removeAnswer(answer: QuizAnswer) {
      var index = this.question!.answers.indexOf(answer);
      this.question?.answers.splice(index, 1);
    },
  },
});
