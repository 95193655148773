import { RRule } from "rrule/dist/esm/rrule";
import * as Lang from "@/i18n/lang";
import dateFormat from "dateformat";

export function formatDateFromSeconds(secs: number) {
  return dateFormat(getDateFromSeconds(secs), "dd/mm/yyyy HH:MM");
}
export function formatDateWithTime(date: Date) {
  return dateFormat(date, "dd/mm/yyyy HH:MM");
}
export function formatTimeFromSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return formattedHours + ":" + formattedMinutes;
}
export function formatTimeWithSecondsFromSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
}
export function formatTime(date: Date) {
  const formattedHours = String(date.getHours()).padStart(2, "0");
  const formattedMinutes = String(date.getMinutes()).padStart(2, "0");

  return formattedHours + ":" + formattedMinutes;
}

export function getDateFromSecondsLocal(secs: number) {
  let d = new Date(0);
  d.setSeconds(secs);
  return d;
}

export function getDateFromSeconds(secs: number) {
  let d = new Date(0);
  d.setUTCSeconds(secs);
  return d;
}
export function getDateFromMinutes(mins: number) {
  let d = new Date(0);
  d.setUTCMinutes(mins);
  return d;
}
export function getSecondsFromDate(date: Date) {
  return date.getTime() / 1000;
}
export function formatDate(d: Date) {
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getRRuleFrequenceText(rule: RRule): string {
  return getFrequenceText(rule.options.freq);
}

export function getFrequenceText(frequency: number): string {
  switch (frequency) {
    case 1:
      return Lang.getI18N("monthly");
    case 2:
      return Lang.getI18N("weekly");
    case 3:
      return Lang.getI18N("daily");
    case 0:
      return Lang.getI18N("yearly");
  }

  return "??";
}
