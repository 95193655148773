import { defineStore } from "pinia";
import FirebaseConfig from "../firebase/FirebaseConfig";
import User from "@/model/User";
import Store from "@/model/Store";
import { getStoreManager } from "@/firebase/StoreManager";
import { getDistributorState } from "./distributors/DistributorState";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getUserManager } from "@/firebase/UserManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getElementManager } from "@/firebase/ElementManager";
import { getAuditManager } from "@/firebase/AuditManager";
import { getDistributorManager } from "@/firebase/DistributorManager";

interface AppState {
  user: any;
  userData: User | null;
  lang: string;
  token: string | null;
  customStoreId: string | null;
  customStore: Store | null;
  initialPage: string | null;
  initialEmail: string | null;
  newAccount: boolean;
  connectedToApi: boolean;
  logoutRequest: boolean;
}

export const getState = defineStore("state", {
  state: (): AppState => {
    let listView = false;
    if (window.innerWidth < 750) {
      listView = true;
    }
    return {
      user: null,
      userData: null,
      lang: "EN",
      token: null,
      customStoreId: null,
      initialPage: null,
      customStore: null,
      initialEmail: null,
      newAccount: false,
      connectedToApi: false,
      logoutRequest: false,
    };
  },
  actions: {
    resetManagers() {
      getStoreManager().$reset();
      getDistributorManager().$reset();
      getDatabaseManager().$reset();
      getUserManager().$reset();
      getTaskConfigurationManager().$reset();
      getElementManager().$reset();
      getAuditManager().$reset();
    },
    hideNavPanel() {
      let element: HTMLElement = document.getElementsByClassName(
        "nav-panel"
      )[0] as HTMLElement;

      element.classList.add("hidden");
      element.classList.remove("visible");
    },
    toggleNavPanel() {
      let element: HTMLElement = document.getElementsByClassName(
        "nav-panel"
      )[0] as HTMLElement;

      let res = element.classList.toggle("hidden");

      if (res) {
        element.classList.remove("visible");
      } else {
        element.classList.add("visible");
      }
    },
  },
});
