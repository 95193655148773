import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex align-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!

  return (_openBlock(), _createBlock(_component_SeDropdown, {
    titleFunc: (country) => country.name,
    ref: "country"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.europeanCountries, (country) => {
        return (_openBlock(), _createBlock(_component_SeDropdownItem, {
          displayTitle: false,
          key: country.code,
          value: country
        }, {
          heading: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", {
                width: "30",
                height: "30",
                src: 
              'https://purecatamphetamine.github.io/country-flag-icons/3x2/' +
              country.code +
              '.svg'
            
              }, null, 8, _hoisted_2),
              _createElementVNode("span", _hoisted_3, _toDisplayString(country.name), 1)
            ])
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["titleFunc"]))
}