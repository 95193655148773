import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08250eb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "se-fade-button",
  type: "button"
}
const _hoisted_2 = ["src", "width", "height"]
const _hoisted_3 = {
  key: 2,
  class: "flex align-center"
}
const _hoisted_4 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    (_ctx.icon != null && _ctx.iconRight)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.icon,
          width: _ctx.iconSize,
          height: _ctx.iconSize
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_HalfCircleSpinner, {
            color: "#454eff",
            style: {"width":"30px","height":"30px"}
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.icon != null && !_ctx.iconRight && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 3,
          src: _ctx.icon,
          width: _ctx.iconSize,
          height: _ctx.iconSize
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}