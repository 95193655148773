import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c54d939c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { cellspacing: "0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
          return (_openBlock(), _createElementBlock("th", {
            style: _normalizeStyle('width:' + _ctx.widths[index] + '%')
          }, _toDisplayString(column), 5))
        }), 256))
      ])
    ]),
    _createElementVNode("tbody", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}