
import { defineComponent } from "@vue/runtime-core";
import * as DateUtils from "@/utils/DateUtils";

export default defineComponent({
  props: ["result"],
  methods: {
    computeDuration() {
      let start = this.result.start_timestamp;
      let end = this.result.end_timestamp;

      let seconds = end - start;

      seconds = seconds < 60 ? 60 : Math.round(seconds);

      let res = DateUtils.formatTimeFromSeconds(seconds);
      return res.replace(":", "h");
    },
    valid() {
      if (this.result.mode == "cooling") {
        if (this.result.end_temperature! > this.result.target_temperature!) {
          return false;
        }
      } else if (this.result.mode == "reheating") {
        if (this.result.end_temperature! < this.result.target_temperature!) {
          return false;
        }
      }
      return true;
    },
  },
});
