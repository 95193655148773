
import { defineComponent } from "vue";

export default defineComponent({
  expose: ["loading"],
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
});
