
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    icon: String,
    bottomText: String,
    active: {
      type: Boolean,
      default: true,
    },
    isHighlighted: {
      type: Boolean,
      default: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    click: {
      type: Function,
      default: null,
    },
    obj: null,
  },
  methods: {
    onClick() {
      if (this.click != null) {
        this.click();
      }
    },
  },
});
