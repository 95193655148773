import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "global-layout"
}
const _hoisted_2 = { class: "main-layout center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavPanel = _resolveComponent("NavPanel")!
  const _component_SeLoadingIndicator = _resolveComponent("SeLoadingIndicator")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createBlock(_component_RouterView, null, {
    default: _withCtx(({ Component }) => [
      Component
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (
            _ctx.state.initialPage == null &&
            _ctx.route.path != '/login' &&
            _ctx.route.path != '/' &&
            _ctx.route.path != '/changePassword'
          )
              ? (_openBlock(), _createBlock(_component_NavPanel, { key: 0 }))
              : _createCommentVNode("", true),
            (_openBlock(), _createBlock(_Suspense, { timeout: "0" }, {
              fallback: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_SeLoadingIndicator)
                ])
              ]),
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}