import UploadStrategy from "./UploadStategy";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import * as GCoreClient from "@/utils/GCoreClient";
import Attachment from "../Filesystem/Attachment";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import * as Snackbars from "@/utils/Snackbars";

export default class GCoreUploadStrategy extends UploadStrategy {
  uploadMeta: any = null;

  public override async prepare(): Promise<void> {
    this.uploadMeta = await SecureatServerApi.createGCoreUpload(this.file);

    console.log(this.uploadMeta);
  }

  public override upload(done: Function): void {
    var hosts = JSON.parse(this.uploadMeta.upload_host);

    var snackbar = Snackbars.uploader(this);

    GCoreClient.uploadFile(
      this.file,
      "https://" + hosts[0].hostname + "/upload/",
      (value: number) => {
        this.progress.value = value;
        console.log(value);
      },

      () => {
        Snackbars.remove(snackbar.el.parentNode);
        done();
      },
      this.uploadMeta.client_id,
      this.uploadMeta.video_id,
      this.uploadMeta.upload_token
    );
  }
  public override getFileName(): string | null {
    return this.file.name;
  }
  public getUrl() {
    return this.uploadMeta.video_url;
  }
  public getVideoId() {
    return this.uploadMeta.video_id;
  }
  public generateAttachment(): Attachment | null {
    return new Attachment(
      this.getFileName()!,
      getAttachmentManager().resolveType(this.file),
      null,
      this.uploadMeta.video_url,
      this.uploadMeta.video_id
    );
  }
}
