
import { defineComponent, nextTick, PropType, ref } from "vue";
import { getState } from "../../pinia/AppState";
import { Database } from "firebase/database";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import SeDropdownFilter from "../global/SeDropdownFilter.vue";
import { getBulkElementState } from "@/pinia/elements/BulkDatabaseElementState";
import ElementType from "@/model/ElementType";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";
import { collection, doc, DocumentReference } from "firebase/firestore";
import * as Lang from "@/i18n/lang";
import * as Snackbars from "@/utils/Snackbars";
import * as ObjectUtils from "@/utils/ObjectUtils";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import { getDatabaseElementState } from "@/pinia/elements/DatabaseElementState";
import { getElementManager } from "@/firebase/ElementManager";
import SeCheckBox from "../global/SeCheckBox.vue";
import * as Firebase from "@/firebase/Firebase";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import * as Modals from "@/utils/Modals";
import * as fs from "fs";
import * as DOMUtils from "@/utils/DOMUtils";
import * as ImageUtils from "@/utils/ImageUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import * as Storage from "@/firebase/Storage";
import * as LabelsManager from "@/firebase/LabelsManager";

export default defineComponent({
  methods: {
    onSearchChange(value: string) {
      this.state.search = value;

      this.state.displayedElements = this.state.elements.filter(
        (x: DatabaseElement) =>
          x.name.toLowerCase().includes(this.state.search.toLowerCase())
      );

      this.state.displayedElements = this.state.displayedElements.filter(
        (x) => x.active || this.state.displayUnactives
      );
    },
    pickIllustration($event: any, element: DatabaseElement) {
      DOMUtils.selectFileDialog((e: any) =>
        this.pickIllustrationFromFile(e.target.files[0], $event.target, element)
      );
    },
    async pickIllustrationFromFile(
      file: File,
      target: HTMLImageElement,
      element: DatabaseElement
    ) {
      let base64string = await ImageUtils.encodeFileToBase64(file);
      const dataUrl = `data:image/jpeg;base64,${base64string}`;
      target.src = dataUrl;

      let path =
        "databases/" +
        this.state.database.ref.id +
        "/element_pictures/" +
        element?.ref.id! +
        "/";

      element.illustration_path = path + file.name;
      console.log(path);

      var strategy = await UploadManager.createStrategy(file, path);
      UploadManager.addFileToUpload(strategy);
    },
    async createOrEditType() {
      let refs = <any>this.$refs;

      if (!refs.typeForm.verify()) {
        return;
      }

      let typeName = refs.typeName.getValue();

      if (refs.typeModal.context == null) {
        let newType = await ElementTypeManager.createDatabaseElementType(
          this.state.database.ref.id,
          typeName
        );

        this.state.types.push(newType);
        this.state.selectedType = newType;
      } else {
        let type = <ElementType>refs.typeModal.context;
        type.name = typeName;
        await ElementTypeManager.updateElementType(type);
      }

      refs.typeModal.close();
    },
    openTypeModalCreation() {
      let refs = <any>this.$refs;
      refs.typeName.setValue("");
      refs.typeModal.open();
    },
    openEditTaskTypeModal(type: ElementType) {
      var refs = <any>this.$refs;

      refs.typeName.setValue(type.name);
      refs.typeModal.open(type);
    },

    openExportModal() {
      var refs = <any>this.$refs;

      refs.exportModal.open();
    },
    async exportElements() {
      var refs = <any>this.$refs;

      if (this.state.targetDatabase == null) {
        Snackbars.display("Aucune base de donnée selectionnée.");
        return;
      }

      if (this.state.targetDatabase == this.state.database) {
        Snackbars.display(
          "Impossible d'exporter les élements vers la base de donnée en cours d'edition."
        );
        return;
      }

      if (this.checkedElements.length == 0) {
        Snackbars.display("Aucun élement selectionné.");
        return;
      }

      refs.exportModal.close();

      let res = await Modals.openYesNo(
        "Vous allez exporter " +
          this.checkedElements.length +
          " élements sur " +
          this.state.targetDatabase.name +
          ". Les pièces jointes ne sont pas prises en compte. Voulez vous continuer ?",
        "Confirmation",
        "Continuer",
        "Annuler"
      );

      if (res) {
        this.applyExport();
      }
    },
    async applyExport() {
      await getElementManager().exportElements(
        this.checkedElements,
        this.state.database,
        this.state.targetDatabase
      );
    },
    newElement() {
      var element = new DatabaseElement(
        doc(
          collection(
            Firebase.firestore,
            this.state.database.ref.path,
            "elements"
          )
        )
      );

      element.name = Lang.getI18N("new-element");
      this.state.elements.push(element);

      this.scrollBottom();
    },
    revertCoolingTemperature() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].cooling_temperature =
          baseElement.cooling_temperature;
        this.state.elements[i].cooling_temperature_lock =
          baseElement.cooling_temperature_lock;
      }
    },
    revertReheatingTemperature() {
      for (let i = 0; i < this.statestate.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].reheating_temperature =
          baseElement.reheating_temperature;
        this.state.elements[i].reheating_temperature_lock =
          baseElement.reheating_temperature_lock;
      }
    },
    revertTemperatureControlModule() {
      for (let i = 0; i < this.statestate.elements.length; i++) {
        let baseElement = this.statestate.baseElements[i];

        this.statestate.elements[i].temperature_control_module_active =
          baseElement.temperature_control_module_active;
        this.statestate.elements[i].temperature_control_module_active_lock =
          baseElement.temperature_control_module_active_lock;
      }
    },
    revertMaximumPolarity() {
      for (let i = 0; i < this.statestate.elements.length; i++) {
        let baseElement = this.statestate.baseElements[i];

        this.statestate.elements[i].maximum_polarity =
          baseElement.maximum_polarity;
        this.statestate.elements[i].maximum_polarity_lock =
          baseElement.maximum_polarity_lock;
      }
    },

    revertOilModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].oils_module_active =
          baseElement.oils_module_active;
        this.state.elements[i].oils_module_active_lock =
          baseElement.oils_module_active_lock;
      }
    },
    revertTraceabilityModule() {
      let state = getBulkElementState();

      for (let i = 0; i < state.elements.length; i++) {
        let baseElement = state.baseElements[i];

        state.elements[i].traceability_module_active =
          baseElement.traceability_module_active;
        state.elements[i].traceability_module_active_lock =
          baseElement.traceability_module_active_lock;
      }
    },
    revertLabelModule() {
      for (let i = 0; i < this.statestate.elements.length; i++) {
        let baseElement = this.statestate.baseElements[i];

        this.statestate.elements[i].labels_module_active =
          baseElement.labels_module_active;
        this.state.elements[i].labels_module_active_lock =
          baseElement.labels_module_active_lock;
      }
    },
    revertMaximumTemperature() {
      for (let i = 0; i < this.statestate.elements.length; i++) {
        let baseElement = this.statestate.baseElements[i];

        this.statestate.elements[i].maximum_temperature =
          baseElement.maximum_temperature;
        this.statestate.elements[i].maximum_temperature_lock =
          baseElement.maximum_temperature_lock;
      }
    },
    revertMinimumTemperature() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].minimum_temperature =
          baseElement.minimum_temperature;
        this.state.elements[i].minimum_temperature_lock =
          baseElement.minimum_temperature_lock;
      }
    },
    revertTemperatureModule() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].temperature_control_module_active =
          baseElement.temperature_control_module_active;
        this.state.elements[i].temperature_control_module_active_lock =
          baseElement.temperature_control_module_active_lock;
      }
    },
    revertActive() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].active = baseElement.active;
        this.state.elements[i].active_lock = baseElement.active_lock;
      }
    },
    revertType() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].type = baseElement.type;
        this.state.elements[i].type_lock = baseElement.type_lock;
      }
    },
    revertImage() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].illustration_url = baseElement.illustration_url;
        this.state.elements[i].illustration_lock =
          baseElement.illustration_lock;
      }
    },
    revertName() {
      for (let i = 0; i < this.state.elements.length; i++) {
        let baseElement = this.state.baseElements[i];

        this.state.elements[i].name = baseElement.name;
        this.state.elements[i].name_lock = baseElement.name_lock;
      }
    },
    onCoolingTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        element.cooling_temperature = value;
      }
    },
    onCoolingTemperatureLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.cooling_temperature_lock = value;
      }
    },
    onReheatingTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        element.reheating_temperature = value;
      }
    },
    onReheatingTemperatureLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.reheating_temperature_lock = value;
      }
    },
    onTemperatureControlModuleChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.temperature_control_module_active = value;
      }
    },
    onTemperatureControlModuleLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.temperature_control_module_active_lock = value;
      }
    },
    onMaximumPolarityChange(value: number) {
      for (let element of this.checkedElements) {
        element.maximum_polarity = value;
      }
    },
    onMaximumPolarityLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.maximum_polarity_lock = value;
      }
    },
    onOilModuleActiveChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.oils_module_active = value;
      }
    },
    onOilModuleActiveLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.oils_module_active_lock = value;
      }
    },
    onTraceabilityModuleActiveChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.traceability_module_active = value;
      }
    },
    onTraceabilityModuleActiveLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.traceability_module_active_lock = value;
      }
    },
    onIllustrationLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.illustration_lock = value;
      }
    },
    onTypeLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.type_lock = value;
      }
    },
    onLabelsModuleActiveChange(value: number) {
      for (let element of this.checkedElements) {
        element.labels_module_active = value;
      }
    },
    onLabelsModuleActiveLockChange(value: number) {
      for (let element of this.checkedElements) {
        element.labels_module_active_lock = value;
      }
    },
    onMaximumTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        element.maximum_temperature = value;
      }
    },
    onMaximumTemperatureLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.maximum_temperature_lock = value;
      }
    },
    onMinimumTemperatureChange(value: number) {
      for (let element of this.checkedElements) {
        element.minimum_temperature = value;
      }
    },
    onMinimumTemperatureLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.minimum_temperature_lock = value;
      }
    },
    applyGlobalCheck() {
      var refs = <any>this.$refs;

      let value = !refs.globalCheck.getValue();

      if (value) this.checkedElements = this.state.elements.map((x) => x);
      else this.checkedElements = [];
    },
    onTemperatureModuleChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.temperatures_module_active = value;
      }
    },
    onTemperatureModuleLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.temperatures_module_active_lock = value;
      }
    },
    onActiveLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.active_lock = value;
      }
    },
    onNameLockChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.name_lock = value;
      }
    },
    onTypeChange(type: DocumentReference) {
      for (let element of this.checkedElements) {
        element.type = type;
      }
    },
    onNameChange(value: string) {
      for (let element of this.checkedElements) {
        element.name = value;
      }
    },
    onActiveChange(value: boolean) {
      for (let element of this.checkedElements) {
        element.active = value;
      }
    },
    onFilterChange(index: number) {
      if (index == 0) {
        this.state.displayColumns = ["type", "active"];
      } else if (index == 1) {
        this.state.displayColumns = [
          "temperatures",
          "minimum-temperature",
          "maximum-temperature",
        ];
      } else if (index == 2) {
        this.state.displayColumns = ["labels"];
      } else if (index == 3) {
        this.state.displayColumns = ["traceability"];
      } else if (index == 4) {
        this.state.displayColumns = ["oils", "maximum-polarity"];
      } else if (index == 5) {
        this.state.displayColumns = [
          "temperature_control",
          "reheating-temperature",
          "cooling-temperature",
        ];
      }
    },

    openElement(element: DatabaseElement) {
      this.state.preventReload = true;
      getDatabaseElementState().set(element, this.state.database);
      getNavigationState().navigate("databaseElement");
    },
    async duplicate(element: DatabaseElement) {
      let cloned = DatabaseElement.clone(element, false);

      cloned.name += " (Copy)";

      let labels = await LabelsManager.getDatabaseLabelsByElement(
        this.state.database.ref.id!,
        element
      );

      for (let label of labels) {
        label.ref = doc(
          collection(
            Firebase.firestore,
            this.state.database!.ref.path,
            "labels"
          )
        );
      }

      await LabelsManager.setLabels(cloned, labels);

      this.state.elements.push(cloned);

      this.scrollBottom();
    },
    scrollBottom() {
      var refs = <any>this.$refs;

      nextTick(() => {
        let container = refs.container;
        container.scroll(0, container.scrollHeight);
      });
    },
    async save() {
      var refs = <any>this.$refs;

      refs.saveBtn.toggleLoading(true);

      let updatedElements = [] as DatabaseElement[];

      for (let i = 0; i < this.state.elements.length; i++) {
        if (i >= this.state.baseElements.length) {
          updatedElements.push(this.state.elements[i]);
          continue;
        }
        this.state.baseElements[i].ref = this.state.elements[i].ref;
        if (
          !ObjectUtils.compareObjects(
            this.state.elements[i],
            this.state.baseElements[i]
          )
        ) {
          updatedElements.push(this.state.elements[i]);
        }
      }

      await UploadManager.applyUploads();

      for (let ele of updatedElements) {
        if (ele.illustration_path != null) {
          ele.illustration_url = await Storage.getDownloadUrl(
            ele.illustration_path!
          );

          console.log(ele.illustration_url);
        }

        await ele.set();
      }

      this.state.baseElements = this.state.elements.map((x) =>
        DatabaseElement.clone(x, true)
      );

      Snackbars.display(
        updatedElements.length + " élements ont été mis a jour."
      );
      refs.saveBtn.toggleLoading(false);
    },
    onRowClick(element: DatabaseElement) {
      if (this.checkedElements.includes(element)) {
        let index = this.checkedElements.indexOf(element);
        this.checkedElements.splice(index, 1);
      } else {
        this.checkedElements.push(element);
      }
    },
    onScroll() {
      var refs = <any>this.$refs;

      var parent = refs.container;

      if (parent == null) {
        return;
      }
      if (this.oldParentScrollTop - parent.scrollTop < 0) {
        refs.stickContainer.style.top = this.distanceFromTop - 400 + "px";

        refs.tbody.style.top = this.distanceFromTop - 85 + "px";
      } else {
        refs.stickContainer.style.top = this.distanceFromTop - 83 + "px";
        refs.tbody.style.top = this.distanceFromTop + 48 + "px";
      }

      this.oldParentScrollTop = parent.scrollTop;
    },
  },
  mounted() {
    var refs = <any>this.$refs;

    refs.chips.setSelectedIndex(0);
    this.onFilterChange(0);

    refs.container.addEventListener("scroll", this.onScroll);

    var rect = refs.container.getBoundingClientRect();

    this.distanceFromTop = rect.top - window.scrollY;
  },
  data() {
    return {
      Database: Database,
      Lang: Lang,
      state: getBulkElementState(),
      checkedElements: [] as DatabaseElement[],
      oldParentScrollTop: 0,
      distanceFromTop: 0,
    };
  },
  setup() {},
});
