import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-32be2574"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex col",
  style: {"padding-top":"24px","max-height":"400px","overflow-y":"scroll"}
}
const _hoisted_2 = { class: "answer-card flex align-center" }
const _hoisted_3 = { class: "text-muted no-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question!.answers, (answer) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SeInput, {
            placeholderBase: "Entrez votre réponse",
            modelValue: answer.answer,
            "onUpdate:modelValue": ($event: any) => ((answer.answer) = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_ToggleSwitch, {
            modelValue: answer.correct,
            "onUpdate:modelValue": ($event: any) => ((answer.correct) = $event)
          }, {
            default: _withCtx(() => [
              _createTextVNode("Correct")
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_SeFadeButton, {
            onClick: () => _ctx.removeAnswer(answer),
            icon: "/ico/trash.svg"
          }, null, 8, ["onClick"])
        ]))
      }), 256))
    ]),
    _createVNode(_component_WideCard, {
      onClick: _ctx.addAnswer,
      icon: "/ico/plus-blue.svg",
      title: _ctx.Lang.getI18N('add')
    }, {
      subtitle: _withCtx(() => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("communication.new_answer")), 1)
      ]),
      _: 1
    }, 8, ["onClick", "title"])
  ], 64))
}