export function isDescendantOf(
  childElement: HTMLElement,
  parentElement: HTMLElement
): boolean {
  let currentNode: Node | null = childElement.parentNode;

  while (currentNode !== null) {
    if (currentNode === parentElement) {
      return true;
    }

    currentNode = currentNode.parentNode;
  }

  return false;
}

export function isElementVisible(element: HTMLElement) {
  const style = window.getComputedStyle(element);

  if (style.display === "none" || style.visibility === "hidden") {
    return false;
  }

  if (element.offsetWidth === 0 && element.offsetHeight === 0) {
    return false;
  }

  return true;
}

export function getAbsolutePosition(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
  const scrollTop = window.scrollY || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
  };
}

export function attachementFileDialog(
  onFileSelect: (this: GlobalEventHandlers, ev: Event) => any
) {
  selectFileDialog(onFileSelect, ".pdf, image/*, video/*");
}
export function selectFileDialog(
  onFileSelect: (this: GlobalEventHandlers, ev: Event) => any,
  filter: string | null = ".pdf, image/*, video/*"
) {
  let input = document.createElement("input");
  input.type = "file";
  input.style.display = "none";

  if (filter != null) {
    input.accept = filter;
  }

  document.body.appendChild(input);

  input.onchange = onFileSelect;
  input.click();
}

export function downloadFile(fileData: Blob | MediaSource, fileName: string) {
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(fileData);

  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadLink.href);
}
export function forceDownloadFromUrl(url: string, fileName: string) {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;

  downloadLink.target = "_blank";
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadLink.href);
}
export function downloadFileFromUrl(url: string, filename: string): void {
  const fileExtension = url.split(".").pop()?.toLowerCase().split("?")[0];

  console.log(fileExtension);

  const supportedExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

  if (supportedExtensions.includes(fileExtension || "")) {
    var image = new Image();
    image.src = url;
    image.style.maxWidth = "100%";
    image.style.maxHeight = "100%";
    var w = window.open("", "_blank")!;
    w.document.write(image.outerHTML);
    w.document.close();
  } else {
    window.open(url, "_blank");
  }
}
