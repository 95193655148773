
import { PropType, defineComponent } from "vue";
import ToggleSwitch from "./ToggleSwitch.vue";

export default defineComponent({
  expose: [
    "getSelectedItem",
    "changeTab",
    "getSelectedIndex",
    "changeTabIndex",
  ],
  props: {
    tabs: {
      type: Array as PropType<string[]>,
      required: true,
    },
    hiddenTabs: {
      type: Array as PropType<string[]>,
      required: true,
    },
    labels: {
      type: Array as PropType<string[]>,
      required: true,
    },
    icons: {
      type: Array as PropType<string[]>,
      required: true,
    },
    onTabChange: {
      type: Function as PropType<(tab: number) => void>,
    },
  },
  methods: {
    expend() {
      var refs = <any>this.$refs;

      refs.tabs.classList.remove("hidden");
    },
    getSelectedIndex(): number {
      return this.selectedIndex;
    },
    getSelectedItem(): string {
      return this.tabs[this.selectedIndex];
    },
    changeTabIndex(index: number) {
      this.selectedIndex = index;
      if (this.onTabChange != undefined) {
        this.onTabChange(this.selectedIndex);
      }
      this.$emit("tabChanged", this.selectedIndex);

      var refs = <any>this.$refs;

      refs.tabs.classList.add("hidden");
    },
    changeTab(tab: string) {
      this.changeTabIndex(this.tabs.indexOf(tab));
    },
    getIndicator(): HTMLElement {
      return <HTMLElement>(
        document.getElementsByClassName("indicator-container")[0]
      );
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  mounted() {},
  components: { ToggleSwitch },
});
