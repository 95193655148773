import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
  orderBy,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import { defineStore } from "pinia";
import {
  getStorage,
  deleteObject,
  ref,
  getDownloadURL,
} from "firebase/storage";

import { SnapshotListener } from "@/utils/SnapshotListener";
import Employee from "@/model/Employee";
import * as Firebase from "@/firebase/Firebase";

interface EmployeeManager {
  cache: Map<string, Employee>;
}

export const getEmployeeManager = defineStore("EmployeesManager", {
  state: (): EmployeeManager => {
    return {
      cache: new Map<string, Employee>(),
    };
  },
  actions: {
    async getEmployee(ref: DocumentReference): Promise<Employee> {
      var result = this.cache.get(ref.id!);

      if (result) {
        return result;
      }

      let doc = await getDoc(ref);
      var employee = Employee.fromFirestore(doc);
      this.cache.set(ref.id, employee);
      return employee;
    },
  },
});
