
import { defineComponent, render } from "vue";

export default defineComponent({
  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {},
  },
});
