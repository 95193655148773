import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";

export default class TaskType extends SecureatSnapshot {
  name: string = "";
  color: string = "";
  /*
  transcient
  */
  storeSide: boolean = false;

  constructor(
    ref: DocumentReference,
    name: string,
    color: string,
    storeSide: boolean
  ) {
    super(ref);
    this.name = name;
    this.color = color;
    this.storeSide = storeSide;
  }

  public static fromFirestore(
    snapshot: DocumentSnapshot,
    storeSide: boolean
  ): TaskType {
    const data = snapshot.data()!;
    let result = new TaskType(snapshot.ref, data.name, data.color, storeSide);

    return result;
  }

  public toFirestore() {
    return {
      name: this.name,
      color: this.color,
    };
  }
}
