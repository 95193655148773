import { Choice } from "./Choice";

export enum DateLimitConfiguration {
  NoLimit = "no_limit",
  OnlyFuture = "only_future",
  OnlyPast = "only_past",
}

export abstract class QuestionOptions {
  abstract toMap(): Record<string, any>;
  abstract clone(): QuestionOptions;
}

export class TextQuestionOptions extends QuestionOptions {
  max_characters: number | null;

  constructor(max_characters: number | null = null) {
    super();
    this.max_characters = max_characters;
  }

  toMap(): Record<string, any> {
    return {
      max_characters: this.max_characters,
    };
  }

  clone(): TextQuestionOptions {
    return new TextQuestionOptions(this.max_characters);
  }
}

export class MultipleQuestionOptions extends QuestionOptions {
  multiple_selection: boolean;
  choices: Choice[];

  constructor(multiple_selection: boolean = true, choices: Choice[] = []) {
    super();
    this.multiple_selection = multiple_selection;
    this.choices = this.ensureChoiceInstances(choices);
  }

  private ensureChoiceInstances(
    choices: (Choice | Record<string, any>)[]
  ): Choice[] {
    return choices.map((choice) =>
      choice instanceof Choice
        ? choice
        : new Choice(choice.text, choice.score, choice.id, choice.archived_at)
    );
  }

  toMap(): Record<string, any> {
    return {
      multiple_selection: this.multiple_selection,
      choices: this.choices.map((choice) => choice.toMap()),
    };
  }

  clone(): MultipleQuestionOptions {
    return new MultipleQuestionOptions(
      this.multiple_selection,
      this.choices.map((choice) => choice.clone())
    );
  }

  getChoices(): Choice[] {
    return this.choices.filter((choice) => choice.archived_at === null);
  }
}

export class UploadQuestionOptions extends QuestionOptions {
  file_extensions: string[];

  constructor(file_extensions: string[] = []) {
    super();
    this.file_extensions = file_extensions;
  }

  toMap(): Record<string, any> {
    return {
      file_extensions: this.file_extensions,
    };
  }

  clone(): UploadQuestionOptions {
    return new UploadQuestionOptions(this.file_extensions);
  }
}

export class RatingQuestionOptions extends QuestionOptions {
  min_rating: number;
  max_rating: number;
  precision: number;

  constructor(
    min_rating: number = 0,
    max_rating: number = 10,
    precision: number = 1
  ) {
    super();
    this.min_rating = min_rating;
    this.max_rating = max_rating;
    this.precision = precision;
  }

  toMap(): Record<string, any> {
    return {
      min_rating: this.min_rating,
      max_rating: this.max_rating,
      precision: this.precision,
    };
  }

  clone(): RatingQuestionOptions {
    return new RatingQuestionOptions(
      this.min_rating,
      this.max_rating,
      this.precision
    );
  }
}

export class RadioQuestionOptions extends QuestionOptions {
  toMap(): Record<string, any> {
    return {};
  }

  clone(): RadioQuestionOptions {
    return new RadioQuestionOptions();
  }
}

export class NumberQuestionOptions extends QuestionOptions {
  min_number: number;
  max_number: number;

  constructor(min_number: number = 0, max_number: number = 10) {
    super();
    this.min_number = min_number;
    this.max_number = max_number;
  }

  toMap(): Record<string, any> {
    return {
      min_number: this.min_number,
      max_number: this.max_number,
    };
  }

  clone(): NumberQuestionOptions {
    return new NumberQuestionOptions(this.min_number, this.max_number);
  }
}

export class DateQuestionOptions extends QuestionOptions {
  date_limit: DateLimitConfiguration;

  constructor(
    date_limit: DateLimitConfiguration = DateLimitConfiguration.NoLimit
  ) {
    super();
    this.date_limit = date_limit;
  }

  toMap(): Record<string, any> {
    return {
      date_limit: this.date_limit,
    };
  }

  clone(): DateQuestionOptions {
    return new DateQuestionOptions(this.date_limit);
  }
}
