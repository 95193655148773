
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    headerSize: {
      type: Number,
      default: -1,
      required: false,
    },
    headerBackgroundColor: {
      type: String,
      default: "#f3f3f3",
    },
    headerTextColor: {
      type: String,
      default: "#808080",
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    isTextColorWhite(): boolean {
      return this.headerTextColor.toLowerCase() === "#ffffff";
    },
  },
  methods: {
    getHeaderStyle() {
      return this.headerSize === -1
        ? ""
        : `height: ${this.headerSize}px; background-color: ${this.headerBackgroundColor}; color: ${this.headerTextColor};`;
    },
    expendClick() {
      const zone = this.$el.querySelector(".expendable-zone");
      const btn = this.$el.querySelector(".expend-btn");

      if (zone.classList.contains("close")) {
        this.open = true;
      } else {
        this.open = false;
      }

      zone.classList.toggle("close");
      btn.classList.toggle("rotate");
    },
  },
});
