import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  orderBy,
  limitToLast,
  DocumentReference,
  limit,
  runTransaction,
  writeBatch,
  Query,
  DocumentData,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Store from "../model/Store";
import User from "../model/User";
import Room from "../model/Room";
import { Role } from "../model/User";
import Probe from "@/model/Probe";
import Employee from "@/model/Employee";
import Task from "@/model/Task";
import { getElementManager } from "@/firebase/ElementManager";
import * as TaskManager from "@/firebase/TaskManager";
import Database from "@/model/Database";
import StoreElement from "@/model/Elements/StoreElement";
import StoreLabel from "@/model/Labels/StoreLabel";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import * as LabelsManager from "@/firebase/LabelsManager";
import ElementType from "@/model/ElementType";
import { generateUniqueId } from "@/model/utils/uniqueIdGenerator";
import { formatDate } from "@/utils/DateUtils";
import { downloadFile } from "@/utils/DOMUtils";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import Element from "@/model/Elements/Element";
import * as Firebase from "@/firebase/Firebase";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { defineStore } from "pinia";
import { getDatabaseManager } from "./DatabaseManager";

interface ImportedData {
  database: string | undefined;
  elementTypes: ElementType[];
  elements: StoreElement[];
  labels: StoreLabel[];
  tasks: StoreTaskConfiguration[];
  metadata: {
    export_date: String;
    database_name: String;
    database_reference: String;
    store_reference: String;
    store_name: String;
    user_email: String;
    user_uuid: String;
    dashboard_version: String;
  };
}

interface StoreManager {
  listener: SnapshotListener<Store> | null;
}

export const getStoreManager = defineStore("StoreManager", {
  state: (): StoreManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async initialize() {
      if (this.listener != null) {
        return;
      }

      let user = getState().userData!;

      if (user == null) {
        console.log("Unable to initialize store manager. User is null");
        return;
      }

      let storeQuery: Query<DocumentData> | null = null;

      if (user.role == Role.Distributor) {
        let distributorRef = user.distributor;

        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          where("distributor", "==", distributorRef),
          orderBy("name")
        );
      } else if (user.role == Role.Admin) {
        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          orderBy("name")
        );
      } else if (user.role == Role.Manager) {
        await getDatabaseManager().initialize();

        var databases = getDatabaseManager().getDatabases();

        if (databases.length == 0) {
          storeQuery = null;
        } else {
          storeQuery = query(
            collection(Firebase.firestore, "stores"),
            where(
              "database",
              "in",
              databases.map((x) => x.ref)
            )
          );
        }
      } else {
        if (user.stores.length > 0) {
          storeQuery = query(
            collection(Firebase.firestore, "stores"),
            where(
              documentId(),
              "in",
              user.stores.map((storeRef: DocumentReference) => storeRef.id)
            )
          );
        }
      }

      let listener = new SnapshotListener<Store>(
        Store.fromFirestore,
        storeQuery
      );

      this.listener = listener;
      await this.listener.ensureInit();
    },

    async getStore(id: string) {
      let cached = this.getStoreFromCacheOnly(id);

      if (cached != null) {
        return cached;
      }

      const docRef = doc(Firebase.firestore, "stores", id);
      const snapshot = await getDoc(docRef);
      let store: Store = Store.fromFirestore(snapshot);
      return store;
    },
    getStoreFromCacheOnly(id: string) {
      let cached = this.getStores(false).find((x) => x.ref?.id == id);

      if (cached) {
        return cached;
      } else {
        return null;
      }
    },
    async getStoresFromDatabase(database: Database) {
      return this.getStores(true).filter(
        (x) => x.database!.id == database.ref.id
      );
    },
    async getStoresOfUser(user: User) {
      if (
        user.stores.length == 0 &&
        user.role != Role.Distributor &&
        user.role != Role.Admin
      ) {
        return [];
      }

      let storeQuery: Query<DocumentData> | null = null;

      if (user.role == Role.Distributor) {
        let distributorRef = user.distributor;

        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          where("distributor", "==", distributorRef),
          orderBy("name")
        );
      } else if (user.role == Role.Admin) {
        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          orderBy("name")
        );
      } else {
        if (user.stores.length == 0) {
          return [];
        }
        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          where(
            documentId(),
            "in",
            user.stores.map((storeRef: DocumentReference) => storeRef.id)
          )
        );
      }
      let snapshot = await getDocs(storeQuery);
      return snapshot.docs.map((x) => Store.fromFirestore(x));
    },
    getAvailableStoreOnHistory() {
      var stores = this.getStores(true);

      let user = getState().userData!;
      if (user.role == Role.Manager) {
        var databaseManager = getDatabaseManager();
        var results = [];

        for (let store of stores) {
          var db = databaseManager.getDatabaseByReferenceFromCache(
            store.database!
          );

          if (db!.store_access == true) {
            results.push(store);
          }
        }
        return results;
      } else {
        return stores;
      }
    },
    getStores(mustHaveDatabase: boolean): Store[] {
      let user = getState().userData!;

      if (user == null) {
        return [];
      }

      return mustHaveDatabase
        ? this.listener?.items.filter((x) => x.database != null)!
        : this.listener?.items!;
    },

    async exportStoreDatabase(
      store: Store,
      database: Database,
      elements: Element[],
      tasks: TaskConfiguration[]
    ) {
      //Fetch store elementTypes
      const storeElementTypes = await ElementTypeManager.getStoreElementTypes(
        store
      );

      let jsonElementTypeResults: any[] = [];
      if (storeElementTypes.length > 0) {
        jsonElementTypeResults = storeElementTypes.map((elementType) =>
          elementType.toJson()
        );
      }

      //Fetch store elements

      let storeElements: any[] = [];

      storeElements = elements.filter(
        (element) => element instanceof StoreElement
      );
      let jsonElementResults: any[] = [];
      if (storeElements.length > 0) {
        jsonElementResults = storeElements.map((element) => element.toJson());
      }

      //Fetch store labels
      const labels = await LabelsManager.getStoreLabels(store);

      let storeLabels: StoreLabel[] = [];

      storeLabels = labels.filter((label) => label instanceof StoreLabel);
      let jsonLabelResults: any[] = [];
      if (storeLabels.length > 0) {
        jsonLabelResults = storeLabels.map((label) => label.toJson());
      }

      //Fetch store tasks
      let storeTasks: TaskConfiguration[] = [];

      storeTasks = tasks.filter(
        (task) => task instanceof StoreTaskConfiguration
      );
      let jsonTaskResults: any[] = [];
      if (storeTasks.length > 0) {
        jsonTaskResults = storeTasks.map((task) => task.toJson());
      }

      let jsonMetadata = {
        export_date: new Date(),
        database_name: database.name,
        database_reference: database.ref?.path,
        store_reference: store.ref?.path,
        store_name: store.name,
        user_email: getState().userData?.email,
        user_uuid: getState().userData?.ref?.id,
        dashboard_version: "1.0",
      };

      const jsonBlob = new Blob(
        [
          JSON.stringify(
            {
              elementTypes: jsonElementTypeResults,
              elements: jsonElementResults,
              labels: jsonLabelResults,
              tasks: jsonTaskResults,
              metadata: jsonMetadata,
            },
            null,
            2
          ),
        ],
        {
          type: "application/json",
        }
      );

      const currentDate = new Date();
      const formattedDate = formatDate(currentDate);
      const fileName = `${store.name}-${formattedDate}.secureat`;
      downloadFile(jsonBlob, fileName);
    },
    async importStoreDatabase(store: Store, database: Database, file: File) {
      try {
        // Read and process the file content
        const fileContent = await this.readFileContents(file);
        const importedData: ImportedData = JSON.parse(fileContent);

        if (database?.ref?.path !== importedData.metadata.database_reference) {
          throw Error("This store has assigned different database");
        }

        //Fetch the data from JSON and initialize as class objects
        const db = Firebase.firestore;

        let elementTypes: ElementType[] = [];
        importedData.elementTypes.map((elementType) => {
          elementTypes.push(ElementType.fromJson(elementType, db));
        });

        let elements: StoreElement[] = [];
        importedData.elements.map((element) => {
          elements.push(StoreElement.fromJson(element, db));
        });

        let labels: StoreLabel[] = [];
        importedData.labels.map((label) => {
          labels.push(StoreLabel.fromJson(label, db));
        });

        let tasks: StoreTaskConfiguration[] = [];
        importedData.tasks.map((task) => {
          tasks.push(StoreTaskConfiguration.fromJson(task, db));
        });

        const batch = writeBatch(db);

        //Imports ElementTypes to Store
        //Fetches existing ElementTypes to check for unique names
        const fetchedElementTypes =
          await ElementTypeManager.getStoreElementTypes(store);
        const newElementTypesMap = new Map<string | undefined, ElementType>();
        if (elementTypes.length > 0) {
          elementTypes.map(async (elementType) => {
            let newElementType: ElementType | undefined;

            const matchingElementType = fetchedElementTypes.find(
              (fetchedElementType) =>
                fetchedElementType.name === elementType.name
            );

            if (matchingElementType) {
              newElementType = matchingElementType;
            } else {
              newElementType = await ElementTypeManager.createStoreElementType(
                store,
                elementType.name,
                batch
              );
            }

            newElementTypesMap.set(elementType?.ref?.id, newElementType);
          });
        }

        //Imports Elements to Store
        //Links new IDs of ElementTypes to Elements
        const newElementsMap = new Map<string | undefined, StoreElement>();
        if (elements.length > 0) {
          elements.map(async (element) => {
            let oldElementRef = element.ref?.id;
            const newElementType = newElementTypesMap.get(element?.type?.id);
            if (newElementType) {
              element.type = newElementType.ref;
            }

            let newElement = await getElementManager().createStoreElement(
              store,
              element,
              batch
            );
            newElementsMap.set(oldElementRef, newElement);
          });
        }

        //Imports Labels to Store
        //Links new IDs of Elements to Labels
        if (labels.length > 0) {
          await labels.map(async (label) => {
            const newElement = newElementsMap.get(label.element?.id.toString());
            if (newElement) {
              label.element = newElement.ref;
            }
            await label.set();
          });
        }

        //Imports Tasks to Store
        if (tasks.length > 0) {
          await tasks.map(async (task) => {
            await task.set();
          });
        }

        await batch.commit();
      } catch (error) {
        console.error("Error:", error);
        return false;
      }
      return true;
    },
    async readFileContents(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target?.result as string;
          resolve(content);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsText(file);
      });
    },
  },
});
