import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeInput = _resolveComponent("SeInput")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SeForm = _resolveComponent("SeForm")!
  const _component_SeModal = _resolveComponent("SeModal")!

  return (_openBlock(), _createBlock(_component_SeModal, {
    title: _ctx.editMode ? _ctx.Lang.getI18N('edit') : _ctx.Lang.getI18N('add'),
    ref: "inputModal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SeForm, {
        ref: "submitForm",
        class: "flex col grow"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeInput, {
            ref: "input",
            placeholder: _ctx.Lang.getI18N(_ctx.placeholder),
            required: ""
          }, null, 8, ["placeholder"]),
          (!_ctx.editMode)
            ? (_openBlock(), _createBlock(_component_SeButton, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleAction())),
                class: "w100",
                icon: "ico/plus.svg"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("add")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.editMode)
            ? (_openBlock(), _createBlock(_component_SeButton, {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleAction())),
                class: "w100",
                icon: "ico/edit.svg"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("edit")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["title"]))
}