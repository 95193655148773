import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-12159cc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "text-muted p12",
  style: {"font-weight":"bold"}
}
const _hoisted_2 = { class: "text-muted" }
const _hoisted_3 = { class: "text-muted flex p12" }
const _hoisted_4 = {
  key: 0,
  class: "indicator green tooltip"
}
const _hoisted_5 = { class: "tooltiptext text-muted" }
const _hoisted_6 = {
  key: 1,
  class: "indicator red tooltip"
}
const _hoisted_7 = { class: "tooltiptext text-muted" }
const _hoisted_8 = { class: "text-muted p12" }
const _hoisted_9 = {
  key: 0,
  class: "indicator green tooltip"
}
const _hoisted_10 = { class: "tooltiptext text-muted" }
const _hoisted_11 = {
  key: 1,
  class: "indicator red tooltip"
}
const _hoisted_12 = { class: "tooltiptext text-muted" }
const _hoisted_13 = { class: "text-muted p12" }
const _hoisted_14 = {
  key: 0,
  class: "indicator green tooltip"
}
const _hoisted_15 = { class: "tooltiptext text-muted" }
const _hoisted_16 = {
  key: 1,
  class: "indicator red tooltip"
}
const _hoisted_17 = { class: "tooltiptext text-muted" }
const _hoisted_18 = { class: "text-muted p12" }
const _hoisted_19 = {
  key: 0,
  class: "indicator green tooltip"
}
const _hoisted_20 = { class: "tooltiptext text-muted" }
const _hoisted_21 = {
  key: 1,
  class: "indicator red tooltip"
}
const _hoisted_22 = { class: "tooltiptext text-muted" }
const _hoisted_23 = {
  key: 0,
  class: "text-muted p12 f16 bold"
}
const _hoisted_24 = {
  key: 1,
  class: "text-red"
}
const _hoisted_25 = {
  key: 2,
  class: "text-muted p12 f16 bold"
}
const _hoisted_26 = {
  key: 3,
  class: "text-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeLoadingIndicator = _resolveComponent("SeLoadingIndicator")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.result.element.getName()), 1),
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_SeImage, {
        maxWidth: "40",
        maxHeight: "40",
        src: 
          _ctx.result.element.getIllustrationUrl() == null
            ? '/ico/element-blue.svg'
            : _ctx.result.element.getIllustrationUrl()
        
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SeLoadingIndicator, { size: 40 })
        ]),
        _: 1
      }, 8, ["src"])
    ]),
    _createElementVNode("td", _hoisted_3, [
      _createElementVNode("div", null, [
        (_ctx.result.cleaned)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.Lang.getI18N("true")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Lang.getI18N("false")), 1)
            ]))
      ])
    ]),
    _createElementVNode("td", _hoisted_8, [
      _createElementVNode("div", null, [
        (_ctx.result.oil_changed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.Lang.getI18N("true")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.Lang.getI18N("false")), 1)
            ]))
      ])
    ]),
    _createElementVNode("td", _hoisted_13, [
      _createElementVNode("div", null, [
        (_ctx.result.oil_filtered)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.Lang.getI18N("true")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.Lang.getI18N("false")), 1)
            ]))
      ])
    ]),
    _createElementVNode("td", _hoisted_18, [
      _createElementVNode("div", null, [
        (_ctx.result.filter_changed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.Lang.getI18N("true")), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.Lang.getI18N("false")), 1)
            ]))
      ])
    ]),
    (_ctx.result.polarity != null)
      ? (_openBlock(), _createElementBlock("td", _hoisted_23, _toDisplayString(_ctx.result.polarity) + " % ", 1))
      : (_openBlock(), _createElementBlock("td", _hoisted_24, _toDisplayString(_ctx.Lang.getI18N("not-taken")), 1)),
    (_ctx.result.temperature != null)
      ? (_openBlock(), _createElementBlock("td", _hoisted_25, _toDisplayString(_ctx.result.temperature) + "°C ", 1))
      : (_openBlock(), _createElementBlock("td", _hoisted_26, _toDisplayString(_ctx.Lang.getI18N("not-taken")), 1))
  ]))
}