import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c0deacc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "allergens-layout" }
const _hoisted_3 = { class: "allergens-header" }
const _hoisted_4 = {
  class: "container",
  id: "allergens-table"
}
const _hoisted_5 = { class: "table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_AllergensTable = _resolveComponent("AllergensTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, {
      title: _ctx.Lang.getI18N('allergens'),
      backButton: "true"
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SeButton, {
          style: {"width":"150px"},
          class: "table-button",
          icon: "/ico/print.svg",
          onClick: _ctx.takeScreenshotAndGeneratePDF
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("print-pdf")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AllergensTable, {
            elements: _ctx.state.elements,
            elementTypes: _ctx.state.elementTypes
          }, null, 8, ["elements", "elementTypes"])
        ])
      ])
    ])
  ]))
}