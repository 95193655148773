import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import Tag from "@/model/Tag";
import Communication from "@/model/Communication/Communication";
import { getDatabaseManager } from "./DatabaseManager";
import CommunicationResult from "@/model/Communication/CommunicationResult";

interface CommunicationManager {}

export const getCommunicationManager = defineStore("CommunicationManager", {
  state: (): CommunicationManager => {
    return {};
  },
  actions: {
    async getCommunications(database: Database): Promise<Communication[]> {
      const q = query(
        collection(Firebase.firestore, database.ref.path + "/communications")
      );

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Communication.fromFirestore(x));
    },

    async getCommunicationResults(communication: Communication) {
      let path = communication.ref.path + "/results";

      const q = query(
        collection(Firebase.firestore, path),
        where("completed_timestamp", "!=", null)
      );

      return new SnapshotListener<CommunicationResult>(
        CommunicationResult.fromFirestore,
        q
      );
    },
  },
});
