export const I18N_EN = new Map([
  ["register", "Need an account? Get in touch with us"],
  ["login", "Login"],
  ["password", "Password"],
  ["home", "Home"],
  ["distributors", "Distributors"],
  ["databases", "Databases"],
  ["database", "Database"],
  ["tasks", "Tasks"],
  ["stores", "Stores"],
  ["probes", "Probes"],
  ["search", "Search"],
  ["new", "New"],
  ["today", "Today"],
  ["yesterday", "Yesterday"],
  ["this-week", "This week"],
  ["done", "Done"],
  ["on-time", "On time"],
  ["postponed", "Postponed"],
  ["late", "Late"],
  ["address", "Address"],
  ["postal-code", "Postal code"],
  ["city", "City"],
  ["country", "Country"],
  ["open", "Open"],
  ["schedule", "Schedule"],
  ["yearly", "Yearly"],
  ["monthly", "Monthly"],
  ["weekly", "Weekly"],
  ["daily", "Daily"],
  ["apply", "Apply"],
  ["name", "Name"],
  ["surname", "Surname"],
  ["owners", "Owners"],
  ["add", "Add"],
  ["modify", "Modify"],
  ["rooms", "Rooms"],
  ["equipement", "Equipement"],
  ["all", "All"],
  ["working", "Working"],
  ["not-working", "Not working"],
  ["phone-number", "Phone number"],
  ["customer", "Customer"],
  ["distributor", "Distributor"],
  ["admin", "Administrator"],
  ["manager", "Manager"],
  ["managers", "Managers"],
  ["accounts", "Accounts"],
  ["billing", "Billing"],
  ["support", "Support"],
  ["add", "Add"],
  ["apply", "Apply"],
  ["temperatures", "Temperatures"],
  ["labels", "Labels"],
  ["traceability", "Traceability"],
  ["edit", "Edit"],
  ["task-schedule", "Task Schedule"],
  ["task-configuration", "Task Configuration"],
  ["new-room", "New room"],
  ["active", "Active"],
  ["inactive", "Inactive"],
  ["start", "Start"],
  ["end", "End"],
  ["enter-the-email-of-the-user", "Enter the email of the user"],
  ["add-owner", "Add owner"],
  ["manage", "Manage"],
  ["assigned-to", "Assigned to"],
  ["activate-module", "Activate module"],
  [
    "content-not-available",
    "This content is not available at the moment! come back later",
  ],
  ["logout", "Logout"],
  ["actions", "Actions"],
  ["action", "Action"],
  ["requires-picture", "Requires picture"],
  ["requires-manager", "Requires manager"],
  ["oils", "Oils"],
  ["elements", "Elements"],
  ["new-element", "New element"],
  ["new-task", "New task"],
  ["field-locked", "This field is locked"],
  ["field-unlocked", "This field is unlocked"],
  ["minimum-temperature", "Minimum temperature"],
  ["maximum-temperature", "Maximum temperature"],
  ["probe-url", "Probe URL"],
  ["picture", "Require picture"],
  ["delivery_item", "Merchandise delivery"],
  ["maximum-polarity", "Maximum polarity"],
  ["polarity", "Polarity"],
  ["action-name", "Action name"],
  ["every", "Every"],
  ["day(s)", "day(s)"],
  ["week(s)", "week(s)"],
  ["month(s)", "month(s)"],
  ["year(s)", "year(s)"],

  ["monday", "Monday"],
  ["tuesday", "Tuesday"],
  ["wednesday", "Wednesday"],
  ["thursday", "Thursday"],
  ["friday", "Friday"],
  ["saturday", "Saturday"],
  ["sunday", "Sunday"],

  ["first", "First"],
  ["second", "Second"],
  ["third", "Third"],
  ["fourth", "Fourth"],

  ["on-day", "On day"],
  ["on-the", "On the"],

  ["defrosting", "Defrosting"],
  ["expiration", "Expiration"],
  ["simple-name", "Name"],

  ["expected-date", "Expected Date"],
  ["submission-date", "Submission Date"],
  ["employee", "Employee"],
  ["cleaned", "Cleaned"],
  ["oil-changed", "Oil changed"],
  ["task-done", "Done"],
  ["task-late", "Late"],
  ["task-future", "Futur"],
  ["no-task-today", "No task(s) today"],
  ["store-database", "Store database"],
  ["task-action-required", "Please add at least one task action"],
  ["password-conf", "Password confirmation"],
  ["countries", "Countries"],
  ["new-label", "New label"],
  ["edit-label", "Edit label"],
  ["history", "History"],
  ["expend", "Expand all"],
  ["reduce", "Hide all"],
  ["cumulative-expirations", "Cumulative expirations"],
  ["expiration-duration", "Expiration Duration"],
  ["defrosting-duration", "Defrosting duration"],
  ["singleton-name", "Single name"],
  [
    "invalid-temperature-input",
    "The maximum temperature for the temperature module must be greater than or equal to the minimum temperature.",
  ],
  ["comment", "Comment"],
  ["temperature", "Temperature"],
  ["refrigeration", "Refrigeration"],
  ["documents", "Documents"],
  ["save", "Save"],
  ["secureat-licence", "Secureat licence"],
  ["no-elements", "No elements"],
  ["schedule-incoherent", "Incoherent schedules"],
  ["hourless_expiration", "Hourless expiration"],
  ["customer_expiration", "Customer expiration"],
  ["download", "Download"],
  ["rename", "Rename"],
  ["delete", "Delete"],
  ["share", "Share"],
  ["subtasks", "Subtasks"],
  ["close", "Close"],
  ["count", "Count"],
  ["new-folder", "New Folder"],
  ["back", "Back"],
  [
    "unsaved-changes",
    "The page contains changes that have not been saved, are you sure you want to go back?",
  ],
  ["user-not-found", "User not found"],
  ["form-invalid", "Please fill all fields."],
  ["required-field", "Please fill the field"],
  ["cancel", "Cancel"],
  ["yes", "Yes"],
  ["no", "No"],
  ["duplicate", "Duplicate"],
  ["freeform_expiration", "DLC (Freeform)"],
  ["auditing", "Auditing"],
  ["published", "Published"],
  ["structure", "Structure"],
  ["new-section", "New section"],
  ["new-question", "New question"],
  ["question", "Question"],
  ["question.multiple_choice", "Multiple choice"],
  ["question.text_input", "Text input"],
  ["question.yes_or_no", "Yes/No"],
  ["question.file_upload", "Upload document"],
  ["question.rating", "Rating"],
  ["question.number", "Number"],
  ["question.date", "Date"],
  ["options", "Options"],
  ["limit-characters", "Limit characters"],
  ["max-characters", "Max characters"],
  ["score", "Score"],
  ["preview", "Preview"],
  ["flutter-embedding", "Flutter embedding"],
  ["min-rating", "Minimum rating"],
  ["max-rating", "Maximum rating"],
  ["min-number", "Minimum number"],
  ["max-number", "Maximum number"],
  ["no-options", "Nothing to set up here."],
  ["allowed-extensions", "Allowed extensions"],
  ["limit-current-date", "Date limitation"],
  ["date.no_limit", "No limit"],
  ["date.only_future", "Only future"],
  ["date.only_past", "Only past"],
  ["results", "Results"],
  ["general", "General"],
  ["time-target-temperature", "Time to target temperature"],
  ["target-temperature", "Target température"],
  ["postpone-date", "Postpone date"],
  ["select-all", "Select all"],
  ["ask-domhygiene", "No task found for this period."],
  ["lost-password", "Forgotten password ? Click here"],
  [
    "login.recovery.help",
    "Please specify the email address specified during account creation.",
  ],
  ["login.recovery.send-mail", "Send a recovery mail"],
  [
    "login.recovery.error",
    "Unable to send a recovery message. Please specify a valid mail address.",
  ],
  [
    "login.recovery.success",
    "A recovery mail was sent to the specified email address.",
  ],
  ["multiple-selection", "Multiple selection"],
  ["choices", "Choices"],
  ["new-choice", "New choice"],
  ["choice-name", "Choice name"],
  ["section-name", "Section name"],
  ["delete-dialog", "Delete confirmation"],
  [
    "delete-confirm",
    "Warning: You are about to delete this item. This action cannot be undone. Are you sure you want to proceed?",
  ],
  ["total-score", "Total score"],
  ["questions-empty", "No sections added yet"],
  ["add-new-section", "Add a new Section"],
  ["already-manager", "This account is already managing this database"],
  ["opening", "Opening"],
  ["temperature_control", "Temperature control"],
  [
    "target-require-customer",
    "The role of the targeted account must be customer and must not own any stores. Please create a new account.",
  ],
  ["welcome-to-secureat", "Welcome to Secureat dashboard."],
  ["download-pdf", "Download PDF"],
  ["sections", "Sections"],
  ["questions", "Questions"],
  ["reheating-temperature", "Reheating temperature"],
  ["cooling-temperature", "Cooling temperature"],
  ["cooling", "Cooling"],
  ["reheating", "Rheating"],
  [
    "invalid-database",
    "Invalid database. The database do not have a distributor.",
  ],
  ["preparations", "Preparations"],
  ["saved-successfully", "Saved successfully."],
  ["start-temperature", "Start temperature"],
  ["end-temperature", "End temperature"],
  ["temperature-control-duration", "Duration"],
  ["attachment", "Attachment"],

  ["period", "Period"],

  ["add-condition", "Condition"],
  ["edit-condition", "Edit condition"],
  ["choose-question", "Select conditional question"],
  ["question-condition", "Show if"],
  ["create", "Create"],
  ["no-parameters", "This module has no parameters."],
  ["import", "Import"],
  ["export", "Export"],
  [
    "documents.file-exists",
    "This file already exists in this folder, renaming it or remove it before moving your file",
  ],
  ["imported-successfully", "Data imported successfully"],
  ["import-error", "Error occurred while importing"],
  ["user-already-owns-store", "The user already owns this store"],
  [
    "unable-admin-assign-store",
    "Unable to assign a store to an administrator account",
  ],
  [
    "unable-manager-assign-store",
    "Unable to assign a store to a manager account",
  ],
  ["email.invitation.subject", "Invitation to become a store owner"],
  ["email.invitation.body", "You have been invited to own the store"],
  ["email.invitation.body.login", "Log in to SecurEat now"],
  ["email.invitation.body.register", "create your SecurEat account now"],
  ["email.invitation.snackbar.sent", "Invitation was sent to"],
  [
    "form.store.invalid.exists",
    "Store with this Name or Address already exists.",
  ],
  ["rating-precision", "Precision"],
  ["task-configuration.select-module", "Select a module"],
  ["firstname", "Firstname"],
  ["password-confirm", "Password confirm"],
  ["question-required", "Question required"],
  ["display-unactives", "Display inactives"],
  ["warning-dialog", "Warning"],
  [
    "audit-published-warning",
    "Caution: The audit has already been published. Please be cautious, as modifications to the audit may potentially disrupt existing answers.",
  ],
  [
    "audit-publish-confirmation",
    "Are you sure you want to publish the audit? ",
  ],
  ["publish", "Publish"],
  ["duplicated-successfully", "Duplicated successfully."],
  ["store.select-database", "Select database"],
  ["lock", "Lock"],
  ["unlock", "Unlock"],
  ["user-store-limit", "A user cannot own more than 30 stores."],
  ["answer", "Answer"],
  ["score", "Score"],
  ["percentage", "Percentage"],
  ["audit-no-results", "No results added yet for this audit."],
  ["open-pdf", "Open PDF"],
  ["open-csv", "Open CSV"],
  ["required", "Required"],
  ["select-results", "Please select the results you would like to see."],
  ["files", "Files"],
  ["column-action", "Action"],
  ["email", "Email"],
  ["last", "Last"],
  ["january", "January"],
  ["february", "February"],
  ["march", "March"],
  ["april", "April"],
  ["may", "May"],
  ["june", "June"],
  ["july", "July"],
  ["august", "August"],
  ["september", "September"],
  ["october", "October"],
  ["november", "November"],
  ["december", "December"],
  ["of", "Of"],
  ["question-missing", "Question text not provided"],
  ["answer-missing", "No answer was provided"],
  ["range", "Range"],
  ["min", "Min"],
  ["max", "Max"],
  ["range-picker-invalid", "Minimum value must be lower than the maximum."],
  ["from", "From"],
  ["to", "to"],
  ["no-results", "No results"],
  ["true", "True"],
  ["false", "False"],
  ["not-taken", "Not taken"],
  ["settings", "Settings"],
  ["allergens", "Allergens"],
  ["allergen.A", "Cereals (Gluten)"],
  ["allergen.B", "Crustaceans"],
  ["allergen.C", "Egg"],
  ["allergen.D", "Fish"],
  ["allergen.E", "Peanut"],
  ["allergen.F", "Soy"],
  ["allergen.G", "Milk"],
  ["allergen.H", "Nuts"],
  ["allergen.L", "Celery"],
  ["allergen.M", "Mustard"],
  ["allergen.N", "Sesame"],
  ["allergen.O", "Sulphites"],
  ["allergen.P", "Lupines"],
  ["allergen.R", "Molluscs"],
  ["select-allergens", "Select allergens"],
  ["button-allergens-open", "Open allergens table"],
  ["modules-select", "Select modules"],
  ["self-evaluation", "Self-evaluation"],
  ["print-pdf", "Print PDF"],
  ["audit.open-pdf-info", "PDF can be generated with only one result selected"],
  ["audit.open-csv-info", "Select at least one result"],
  ["no-condition-info", "Add a new question"],
  ["select-question", "Select a question"],
  ["element", "Element"],
  ["type", "Type"],
  ["comment", "Comment"],
  ["add-question", "Add question"],
  [
    "attachments-not-available",
    "Create the element to be able to upload attachments.",
  ],
  ["no-element", "No element"],
  ["probe", "Probe"],

  ["probe.upload_frequency", "Upload frequency"],
  ["probe.last_temperature", "Last temperature"],
  ["probe.send_push_notification", "Send push notification"],

  ["probes.all", "All"],
  ["probes.working", "Working"],
  ["probes.probes.not-working", "Not working"],

  ["documents.file-too-big", "File size is too big."],
  ["no-selection", "No selection"],
  ["communication", "Communication"],
  ["communication.passed", "Passed"],
  ["communication.coming", "Coming"],
  ["communication.running", "Running"],
  ["none", "None"],
  ["tag", "Tag"],
  ["role", "Role"],
  ["communication.new_answer", "New answer"],
  ["communication.store_filters", "Store filters"],
  ["communication.employee_filters", "Employee filters"],
  ["overview", "Overview"],
  ["table", "Table"],
  ["communication.question-list", "Question list"],
  [
    "communication.no_valid_answer",
    "Please select at least one correct answer for the question.",
  ],
  ["shop", "Shop"],
  [
    "freeform-line-limit",
    "Please note, a description of more than 5 lines may have consequences on the visibility of the message on the label.",
  ],
  ["account", "Account"],
  ["save-error", "An error occured during save."],
]);
