
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Section } from "@/model/Auditing/Section";
import AuditResultTable from "@/components/auditing/results/AuditResultTable.vue";
import { truncateText } from "@/utils/StringUtils";

export default defineComponent({
  props: {
    section: {
      type: Section,
      required: true,
    },
  },

  methods: {},
  data() {
    return {
      Lang: Lang,
      truncateText: truncateText,
    };
  },
  components: {
    AuditResultTable,
  },
});
