import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b61b5ade"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader-container" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "inner",
      width: _ctx.size,
      height: _ctx.size,
      src: "/ico/inner_logo.svg"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      class: "outer",
      width: _ctx.size,
      height: _ctx.size,
      src: "/ico/outer_logo.svg"
    }, null, 8, _hoisted_3)
  ]))
}