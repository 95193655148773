
import {
  createApp,
  createBaseVNode,
  createVNode,
  defineComponent,
  h,
  render,
} from "vue";
import * as Lang from "@/i18n/lang";
import SeSnackbar from "@/components/global/SeSnackbar.vue";
import { Vue } from "vue-class-component";
import * as Snackbars from "@/utils/Snackbars";
import * as Modals from "@/utils/Modals";
import { getState as getAppState, getState } from "@/pinia/AppState";
import SeProgressBar from "@/components/global/SeProgressBar.vue";
import * as StoreManager from "@/firebase/StoreManager";
import Store from "@/model/Store";
import SeCheckBox from "@/components/global/SeCheckBox.vue";
import { Role } from "@/model/User";
import AdminHome from "@/components/home/AdminHome.vue";

export default defineComponent({
  components: {
    SeProgressBar,
    SeCheckBox,
    AdminHome,
  },
  data() {
    var state = getState();
    return {
      Role: Role,
      role: state.userData?.role!,
      Lang: Lang,
    };
  },

  methods: {},
});
