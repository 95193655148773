
import { defineComponent } from "@vue/runtime-core";
import SeButton from "./SeButton.vue";
export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    variant: {
      type: String,
      default: "transparent",
      validator: (value: string) => ["transparent", "white"].includes(value),
    },
    min: {
      type: Number,
      default: -999999999999999,
    },
    max: {
      type: Number,
      default: 999999999999999,
    },
    inputWidth: {
      type: String,
      default: "35px",
    },
  },
  data() {
    return {
      inputValue: this.modelValue.toString(),
      previousValue: this.modelValue.toString(),
    };
  },
  emits: ["update:modelValue"],
  computed: {
    variantClassName() {
      return this.variant === "white" ? "white-variant" : "";
    },
  },
  methods: {
    increment() {
      this.updateModelValue(+this.inputValue + 1);
    },
    decrement() {
      this.updateModelValue(+this.inputValue - 1);
    },
    onInput() {
      const numericValue = +this.inputValue;
      if (!isNaN(numericValue)) {
        this.updateModelValue(numericValue);
      }
    },
    updateModelValue(newValue: number) {
      // if (newValue > this.max || newValue < this.min) {
      //   this.inputValue = this.previousValue;
      //   if (this.showWarning === true) {
      //     return;
      //   }
      //   this.showWarning = true;
      //   setTimeout(() => {
      //     this.showWarning = false;
      //   }, 2000);
      //   return;
      // }

      const clampedValue = Math.min(Math.max(newValue, this.min), this.max);
      this.previousValue = clampedValue.toString();
      this.inputValue = clampedValue.toString();
      this.$emit("update:modelValue", clampedValue);
    },
  },
  components: { SeButton },
});
