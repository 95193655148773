import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Element from "../model/Elements/Element";
import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { getStoreManager } from "@/firebase/StoreManager";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import {
  getStorage,
  deleteObject,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { AttachmentType } from "@/model/Filesystem/AttachmentType";
import Attachment from "@/model/Filesystem/Attachment";
import * as StringUtils from "@/utils/StringUtils";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

interface AttachmentManager {}

export const getAttachmentManager = defineStore("AttachmentManager", {
  state: (): AttachmentManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async deleteFileFromStorage(path: string) {
      const storage = getStorage();
      var reference = ref(storage, path);
      await deleteObject(reference);
    },

    resolveType(file: File): AttachmentType {
      let type = AttachmentType.Image;

      if (file.type.includes("video")) {
        type = AttachmentType.Video;
      } else if (file.type.includes("pdf")) {
        type = AttachmentType.PDF;
      }

      return type;
    },
    async getDownloadUrl(path: string) {
      const storage = getStorage();
      return await getDownloadURL(ref(storage, path));
    },
  },
});
