import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import * as ImageKit from "@/utils/ImageUtils";

export default class Distributor extends SecureatSnapshot {
  active: boolean = false;
  address: string = "";
  postal_code: number = 0;
  city: string = "";
  countries: string[] = [];
  name: string = "";
  logo: string | null;
  databases: DocumentReference[];
  shop_email: string | null = null;
  koovea_email: string | null = null;
  koovea_password: string | null = null;

  constructor(
    ref: DocumentReference,
    active: boolean,
    address: string,
    postal_code: number,
    city: string,
    countries: string[],
    name: string,
    logo: string | null,
    databases: DocumentReference[],
    shop_email: string | null = null,
    koovea_email: string | null = null,
    koovea_password: string | null = null
  ) {
    super(ref);
    this.active = active;
    this.address = address;
    this.postal_code = postal_code;
    this.city = city;
    this.countries = countries;
    this.name = name;
    this.logo = logo;
    this.databases = databases;
    this.shop_email = shop_email;
    this.koovea_email = koovea_email;
    this.koovea_password = koovea_password;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Distributor {
    const data = snapshot.data()!;

    let result = new Distributor(
      snapshot.ref,
      data.active,
      data.address,
      data.postal_code,
      data.city,
      data.countries,
      data.name,
      data.logo,
      data.databases != undefined ? data.databases : [],
      data.shop_email != undefined ? data.shop_email : null,
      data.koovea_email != undefined ? data.koovea_email : null,
      data.koovea_password != undefined ? data.koovea_password : null
    );

    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      address: this.address,
      postal_code: this.postal_code,
      city: this.city,
      countries: this.countries,
      name: this.name,
      logo: this.logo,
      databases: this.databases,
      shop_email: this.shop_email,
      koovea_email: this.koovea_email,
      koovea_password: this.koovea_password,
    };
  }

  public static clone(d: Distributor): Distributor {
    return new Distributor(
      d.ref,
      d.active,
      d.address,
      d.postal_code,
      d.city,
      d.countries.map((x) => x),
      d.name,
      d.logo,
      d.databases,
      d.shop_email,
      d.koovea_email,
      d.koovea_password
    );
  }
}
