import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import { getDistributorManager } from "@/firebase/DistributorManager";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { collection, query } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";

interface DistributorsState {
  search: string;
  displayUnactives: boolean;
  distributors: Distributor[];
}

export const getDistributorsState = defineStore("distributorsState", {
  state: (): DistributorsState => {
    return {
      distributors: [],
      displayUnactives: false,
      search: "",
    };
  },
  actions: {
    async setup() {
      await getDistributorManager().initialize();
      this.distributors = getDistributorManager().listener?.items!;
    },
  },
});
