export enum CloudSupportedExtensions {
	jpg = "jpg",
	png = "png",
	gif = "gif",
	svg = "svg",
	pdf = "pdf",
	txt = "txt",
	doc = "doc",
	docx = "docx",
	xls = "xls",
	xlsx = "xlsx",
	ppt = "ppt",
	pptx = "pptx",
	csv = "csv",
}
