import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid single" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (item) => {
      return (_openBlock(), _createBlock(_component_WideCard, {
        class: "shadow",
        key: item.action,
        obj: item,
        title: item.action,
        onClick: ($event: any) => (_ctx.cardClick(item)),
        icon: '/ico/action.svg'
      }, {
        body: _withCtx(() => []),
        footer: _withCtx(() => [
          _createVNode(_component_SeFadeButton, { icon: "/ico/link.svg" }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(item.attachments.length), 1)
            ]),
            _: 2
          }, 1024),
          (item.requires_picture)
            ? (_openBlock(), _createBlock(_component_SeFadeButton, {
                key: 0,
                onClick: [
                  () => _ctx.toggleRequirePicture(item),
                  _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                ],
                icon: "/ico/requires-picture-on.svg"
              }, null, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_SeFadeButton, {
                key: 1,
                onClick: [
                  () => _ctx.toggleRequirePicture(item),
                  _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                ],
                icon: "/ico/requires-picture-off.svg"
              }, null, 8, ["onClick"])),
          (_ctx.deleteClick != undefined)
            ? (_openBlock(), _createBlock(_component_SeFadeButton, {
                key: 2,
                onClick: [
                  _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                  ($event: any) => (_ctx.deleteClick(item))
                ],
                icon: "/ico/trash.svg"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["obj", "title", "onClick"]))
    }), 128))
  ]))
}