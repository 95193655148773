
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import * as chartConfig from "./ProbeChartConfig";
import { defineComponent } from "vue";
import { getState as getAppState } from "@/pinia/AppState";
import Probe from "@/model/Probe";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import { getProbeState } from "@/pinia/probes/ProbeState";
import "chartjs-adapter-moment";
import ProbeTemperature from "@/model/ProbeTemperature";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default defineComponent({
  name: "App",
  components: {
    Line,
  },
  expose: ["updateGraph"],
  props: {
    probe: Probe,
  },
  setup() {
    chartConfig.data.datasets[0].data = [];

    return {
      intervalId: null as any,
    };
  },
  data() {
    return chartConfig;
  },
  methods: {
    generateLabels(temperatures: ProbeTemperature[]): Date[] {
      if (temperatures.length == 0) {
        return [];
      }
      const start = temperatures.sort(
        (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
      )[0].timestamp;
      const end = temperatures
        .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
        .slice(-1)[0].timestamp;
      return this.getIntervals(start, end, 20);
    },
    getIntervals(start: Date, end: Date, gap: number): Date[] {
      const intervals: Date[] = [];

      // Round start time to the nearest 5-minute interval
      let current = new Date(start);
      current.setMinutes(start.getMinutes() - (start.getMinutes() % 5), 0);

      // Add intervals until we reach the end time
      while (current <= end) {
        intervals.push(new Date(current));
        current = new Date(current.getTime() + gap * 60000); // Adding minutes in milliseconds
      }

      return intervals;
    },

    async updateGraph(start: Date, end: Date) {
      var refs = <any>this.$refs;

      let data = await SecureatServerApi.getProbeGraph(
        this.probe?.probeUID!,
        start,
        end
      );

      const ctx = refs.graph.chart.getContext("2d");

      let temperatures = [];

      for (let snapshot of data) {
        temperatures.push({
          x: snapshot.timestamp,
          y: snapshot.temperature,
        });
      }

      chartConfig.data.datasets[0].data = <any>temperatures;

      chartConfig.data.labels = <any>this.generateLabels(data);

      chartConfig.options.plugins.annotation.annotations.line1.value =
        this.probe?.maximumTemperature!;

      chartConfig.options.plugins.annotation.annotations.line2.value =
        this.probe?.minimumTemperature!;

      refs.graph.chart.data = chartConfig.data;
      ctx.chart.update();
    },
  },
  async mounted() {
    var refs = <any>this.$refs;
  },
});
