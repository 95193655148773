import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4baf1c12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeCalendarRanged = _resolveComponent("SeCalendarRanged")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SearchBar = _resolveComponent("SearchBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SeCalendarRanged, {
      class: "grow",
      ref: "calendar"
    }, null, 512),
    _createVNode(_component_SeDropdown, {
      search: true,
      titleFunc: (store) => store.name,
      style: {"min-width":"300px"},
      ref: "storeSelect",
      onSelectionChanged: _ctx.storeChanged,
      class: "grow"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stores, (store) => {
          return (_openBlock(), _createBlock(_component_SeDropdownItem, { value: store }, null, 8, ["value"]))
        }), 256))
      ]),
      _: 1
    }, 8, ["titleFunc", "onSelectionChanged"]),
    _createVNode(_component_SeButton, {
      ref: "searchBtn",
      class: "grow",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchClick()))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Valider")
      ]),
      _: 1
    }, 512),
    _createVNode(_component_SearchBar, {
      ref: "searchBar",
      searchChange: _ctx.searchTextChanged,
      calm: true,
      class: "grow"
    }, null, 8, ["searchChange"])
  ]))
}