import { h, render } from "vue";
import SeSnackbarText from "@/components/global/snackbars/SeSnackbarText.vue";
import SeSnackbarUpload from "@/components/global/snackbars/SeSnackbarUpload.vue";
import { random } from "lodash";
import UploadStrategy from "@/model/Uploads/UploadStategy";

let snackbarCount = 1;

export function display(
  text: string,
  duration: number = 2500,
  type: "info" | "error" = "info"
) {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeSnackbarText, { text: text });

  render(snackbarComponent, snackbarContainer);

  if (type == "error") {
    snackbarComponent.el.style.backgroundColor = "#e3473b";
  }

  setTimeout(() => {
    snackbarContainer.classList.add("fade-anim");

    setTimeout(() => {
      remove(snackbarContainer);
    }, 900);
  }, duration);

  snackbarCount++;
}

export function remove(snackbarContainer: HTMLDivElement) {
  document.body.removeChild(snackbarContainer);
  snackbarCount--;
}

export function uploader(strategy: UploadStrategy): any {
  const snackbarContainer = document.createElement("div");
  document.body.appendChild(snackbarContainer);

  const snackbarComponent = h(SeSnackbarUpload, {
    uploadStrategy: strategy,
  });

  render(snackbarComponent, snackbarContainer);

  snackbarCount++;

  return snackbarComponent;
}
