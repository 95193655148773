import {
  DocumentData,
  Query,
  collection,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { Unsubscribe } from "firebase/auth";

export class SnapshotListener<T> {
  private fromFirestore: Function;

  public items: T[];

  private query: Query<DocumentData> | null;

  private unsubscribe: Unsubscribe | null;

  private initialized: boolean = false;

  /* Remove? */
  public updated: Function | null = null;

  constructor(
    fromFirestore: Function,
    q: Query<DocumentData> | null,
    updated: Function | null = null
  ) {
    this.items = [];
    this.fromFirestore = fromFirestore;
    this.query = q;
    this.unsubscribe = null;
    this.updated = updated;
  }

  public dispose() {
    if (this.query != null) {
      this.unsubscribe!();
    }

    this.items = [];
    this.query = null;
  }
  public async ensureInit() {
    if (!this.initialized) {
      await this.startListen();
      this.initialized = true;
    }
  }

  private startListen() {
    if (this.query == null) {
      return;
    }

    return new Promise((resolve, reject) => {
      this.unsubscribe = onSnapshot(
        this.query!,
        (snapshot) => {
          this.items = [];
          for (let doc of snapshot.docs) {
            this.items.push(this.fromFirestore(doc));
          }
          if (this.updated != null) {
            this.updated(this.items);
          }
          resolve(true);
        },
        reject
      );
    });
  }
}
