import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import TaskModuleConfiguration from "./config/TaskModuleConfiguration";

export default abstract class TaskConfiguration extends SecureatSnapshot {
  public active: boolean | null = true;
  public configuration: TaskModuleConfiguration | null = null;
  public description: string | null = null;
  public durations: number[] | null = null;
  public module: DocumentReference | null = null;
  public name: string | null = null;
  public recurrence: string | null = null;
  public starts: number[] | null = null;
  public requires_manager: boolean | null = null;
  public type: DocumentReference | null;
  public notify_late: boolean | null = null;

  public getActive() {
    return this.active;
  }
  public setActive(active: boolean) {
    this.active = active;
  }
  public getConfiguration() {
    return this.configuration;
  }
  public setConfiguration(config: TaskModuleConfiguration) {
    this.configuration = config;
  }
  public getDescription() {
    return this.description;
  }
  public setDescription(description: string) {
    this.description = description;
  }
  public getDurations() {
    return this.durations;
  }
  public setDurations(durations: number[]) {
    this.durations = durations;
  }
  public getModule(): DocumentReference | null {
    return this.module;
  }
  public setModule(module: DocumentReference) {
    this.module = module;
  }
  public getName() {
    return this.name;
  }
  public setName(name: string) {
    this.name = name;
  }
  public getRecurrence() {
    return this.recurrence;
  }
  public setRecurrence(recurrence: string) {
    this.recurrence = recurrence;
  }
  public getStarts() {
    return this.starts;
  }
  public setStarts(starts: []) {
    this.starts = starts;
  }
  public getNotifyLate() {
    return this.notify_late;
  }
  public getRequiresManager(): boolean | null {
    return this.requires_manager;
  }
  public getType(): DocumentReference | null {
    return this.type;
  }
  public setType(type: DocumentReference | null) {
    this.type = type;
  }

  public isLocal() {
    return false;
  }
  constructor(
    ref: DocumentReference,
    active: boolean | null,
    configuration: TaskModuleConfiguration | null,
    description: string | null,
    durations: number[] | null,
    module: DocumentReference | null,
    name: string | null,
    recurrence: string | null,
    starts: number[] | null,
    requires_manager: boolean | null,
    type: DocumentReference | null,
    notify_late: boolean
  ) {
    super(ref);
    this.active = active;
    this.configuration = configuration;
    this.description = description;
    this.durations = durations;
    this.module = module;
    this.name = name;
    this.recurrence = recurrence;
    this.starts = starts;
    this.requires_manager = requires_manager;
    this.type = type;
    this.notify_late = notify_late;
  }

  public toJson(): Record<string, any> {
    return {
      active: this.active,
      configuration: this.configuration,
      description: this.description,
      durations: this.durations,
      module: this.module ? this.module.path : null,
      name: this.name,
      recurrence: this.recurrence,
      starts: this.starts,
      requires_manager: this.requires_manager,
      type: this.type,
    };
  }
}
