
import { defineComponent, nextTick } from "vue";
import DatabaseLabel from "@/model/Labels/DatabaseLabel";
import WideCard from "../../global/WideCard.vue";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import * as Lang from "@/i18n/lang";
import ToggleSwitch from "@/components/global/ToggleSwitch.vue";
import { LabelType } from "@/model/Labels/Label";
import * as LabelsManager from "@/firebase/LabelsManager";
import LockedField from "@/components/global/LockedField.vue";
import { ModuleEnum } from "@/model/ModuleEnum";
import { AllergenEnum } from "@/model/AllergensEnum";
import * as DOMUtils from "@/utils/DOMUtils";
import * as Snackbars from "@/utils/Snackbars";
import Attachment from "@/model/Filesystem/Attachment";
import { AttachmentType } from "@/model/Filesystem/AttachmentType";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import { getElementManager } from "@/firebase/ElementManager";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import * as StringUtils from "@/utils/StringUtils";
import * as UploadManager from "@/model/Uploads/UploadManager";
import { doc, collection } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import * as CollectionUtils from "@/utils/CollectionUtils";

export default defineComponent({
  expose: ["labels", "updateLabels"],
  data() {
    return {
      attachmentName: "",
      labels: [] as DatabaseLabel[],
      Lang: Lang,
      currentLabel: new DatabaseLabel(
        doc(
          collection(
            Firebase.firestore,
            "databases/" + this.databaseId,
            "labels"
          )
        )
      ),
      labelModalMode: "create",
      LabelType: LabelType,
      ModuleEnum: ModuleEnum,
    };
  },
  async mounted() {
    this.labels = await LabelsManager.getDatabaseLabelsByElement(
      this.databaseId,
      this.element
    );
  },
  computed: {
    allergenValues() {
      return Object.values(AllergenEnum);
    },
  },
  methods: {
    onFreeformInputChange(value: any) {
      var lineCount = value.getLineCount();

      if (value.getValue().trim() == "") {
        return;
      }
      var refs = <any>this.$refs;

      if (lineCount >= 5 || value.getValue().length > 200) {
        refs.freeformErrorMsg.style.display = "";
      } else {
        refs.freeformErrorMsg.style.display = "none";
      }
    },
    moveUpLabel(label: DatabaseLabel) {
      CollectionUtils.moveForward(label, this.labels);
    },
    moveDownLabel(label: DatabaseLabel) {
      CollectionUtils.moveBackward(label, this.labels);
    },
    moveDownAttachement(attachment: Attachment) {
      CollectionUtils.moveBackward(attachment, this.element.attachments!);
    },
    moveUpAttachement(attachment: Attachment) {
      CollectionUtils.moveForward(attachment, this.element.attachments!);
    },
    editAttachment() {
      var refs = <any>this.$refs;
      let attachment = refs.attachmentModal.context;

      attachment.name = refs.attachmentName.getValue();

      refs.attachmentModal.close();
    },
    openEditAttachmentModal(attachment: Attachment) {
      this.attachmentName = attachment.name.split(".")[0];
      var refs = <any>this.$refs;
      refs.attachmentModal.open(attachment);
    },

    uploadAttachment() {
      DOMUtils.attachementFileDialog(this.onAttachmentUploaded);
    },
    async downloadAttachment(attachment: Attachment) {
      await attachment.download();
    },
    async deleteAttachment(attachment: Attachment) {
      let index = this.element.attachments?.indexOf(attachment);

      if (index != undefined) {
        this.element.attachments!.splice(index, 1);
      }

      await UploadManager.addFileToDelete(attachment);
    },
    async onAttachmentUploaded(e: any) {
      let file = e.target.files[0];

      var path =
        "/databases/" +
        this.databaseId +
        "/element_attachments/" +
        this.element.ref?.id;

      var strategy = await UploadManager.createStrategy(file, path);

      UploadManager.addFileToUpload(strategy);

      var attachment = await strategy.generateAttachment();

      this.element.attachments!.push(attachment!);
    },

    onTabChanged(tabIndex: number) {
      if (tabIndex === 5) {
        nextTick(() => {
          this.displayAssignedAllergens();
        });
      }
    },
    displayAssignedAllergens() {
      var refs = <any>this.$refs;
      let indexes: number[] = [];

      if (
        this.element.getAllergens() != null &&
        this.element.getAllergens()!.length > 0
      ) {
        this.allergenValues.forEach((allergenValue, index) => {
          if (
            this.element.allergens!.find(
              (allergen) => allergen === allergenValue
            )
          ) {
            indexes.push(index);
          }
        });
      }
      let selectAllergensRef = refs.selectAllergens;

      if (selectAllergensRef) {
        selectAllergensRef.setSelectedIndexes(indexes);
      }
    },
    setSelectedAllergens(selectedIndexes: []) {
      let newSelectedAllergens: AllergenEnum[] = [];
      selectedIndexes.forEach((selectedIndex) => {
        newSelectedAllergens.push(this.allergenValues[selectedIndex]);
      });
      this.element.allergens = newSelectedAllergens;
    },
    openCreateLabelModal() {
      let refs = <any>this.$refs;
      this.currentLabel = new DatabaseLabel(
        doc(
          collection(
            Firebase.firestore,
            "databases/" + this.databaseId,
            "labels"
          )
        ),

        true,
        false,
        0,
        null,
        0,
        "",
        "",
        LabelType.Defrosting
      );
      this.labelModalMode = "create";
      refs.submitForm.reset();

      refs.labelModal.open();

      if (refs.expirationDurationDays != undefined)
        refs.expirationDurationDays.setValue("0");
      if (refs.expirationDurationHours != undefined)
        refs.expirationDurationHours.setValue("0");

      if (refs.defrostingDurationDays != undefined)
        refs.defrostingDurationDays.setValue("0");

      if (refs.defrostingDurationHours != undefined)
        refs.defrostingDurationHours.setValue("0");
    },
    async openEditLabelModal(label: DatabaseLabel) {
      let refs = <any>this.$refs;

      refs.submitForm.reset();

      this.currentLabel = label;

      refs.labelModal.open();

      if (label.getExpirationDuration(false) != null) {
        let exp = label.getExpirationDuration(false)!;

        let hours = exp % 24;
        let days = Math.trunc(exp / 24);

        refs.expirationDurationDays.setValue(days);

        if (refs.expirationDurationHours != undefined)
          refs.expirationDurationHours.setValue(hours);
      }

      if (label.getDefrostingDuration(false) != null) {
        let exp = label.getDefrostingDuration(false)!;

        let hours = exp % 24;
        let days = Math.trunc(exp / 24);

        if (refs.defrostingDurationDays != undefined)
          refs.defrostingDurationDays.setValue(days);
        if (refs.defrostingDurationHours != undefined)
          refs.defrostingDurationHours.setValue(hours);
      }

      refs.expirationText.setValue(label.expiration_text);

      refs.cumulateExprSwitch.toggle(label.cumulate_expiration_duration);
      this.labelModalMode = "edit";
    },

    async createLabel() {
      let refs = <any>this.$refs;

      if (!refs.submitForm.verify()) {
        return;
      }

      this.applyLabelProperties();

      (<any>this.$refs).labelModal.close();

      this.labels.push(this.currentLabel!);
      this.$forceUpdate();
    },

    applyLabelProperties() {
      let refs = <any>this.$refs;

      let expirationDurationDays = 0;
      let expirationDurationHours = 0;

      let defrostingDurationDays = 0;
      let defrostingDurationHours = 0;

      if (refs.expirationDurationDays != undefined) {
        expirationDurationDays = parseInt(
          refs.expirationDurationDays.getValue()
        );
      }
      if (refs.expirationDurationHours != undefined) {
        expirationDurationHours = parseInt(
          refs.expirationDurationHours.getValue()
        );
      }
      if (refs.defrostingDurationDays != undefined) {
        defrostingDurationDays = parseInt(
          refs.defrostingDurationDays.getValue()
        );
      }
      if (refs.defrostingDurationHours != undefined) {
        defrostingDurationHours = parseInt(
          refs.defrostingDurationHours.getValue()
        );
      }

      this.currentLabel.expiration_duration = Math.round(
        expirationDurationDays * 24 + expirationDurationHours
      );

      this.currentLabel.defrosting_duration = Math.round(
        defrostingDurationDays * 24 + defrostingDurationHours
      );

      this.currentLabel.expiration_text = refs.expirationText.getValue();
    },

    async editLabel() {
      let refs = <any>this.$refs;

      if (!refs.submitForm.verify()) {
        return;
      }

      this.applyLabelProperties();

      this.$forceUpdate();
      refs.labelModal.close();
    },
  },
  props: {
    databaseId: { type: String, required: true },
    element: { type: DatabaseElement, required: true },
  },
  components: { WideCard, ToggleSwitch, LockedField },
});
