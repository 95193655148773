import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";

export default class EmployeeRole extends SecureatSnapshot {
  name: string = "";
  active: boolean = false;

  constructor(ref: DocumentReference, name: string, active: boolean) {
    super(ref);
    this.name = name;
    this.active = active;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): EmployeeRole {
    const data = snapshot.data()!;
    let result = new EmployeeRole(snapshot.ref, data.name, data.active);
    return result;
  }

  public toFirestore() {
    return {
      name: this.name,
      active: this.active,
    };
  }
}
