
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Answer } from "@/model/Auditing/Answer";
import { Question } from "@/model/Auditing/Question";
import { getAuditResultsState } from "@/pinia/audits/AuditResultsState";
import * as Modals from "@/utils/Modals";

export default defineComponent({
  props: {
    question: {
      type: Question,
      required: true,
    },
    answers: {
      type: Array as () => Answer[],
      required: true,
    },
    isPercentageShown: Boolean,
  },

  async setup() {
    var state = getAuditResultsState();

    return {
      state: state,
    };
  },

  methods: {
    showComment(answer: Answer) {
      Modals.open(answer.comment!, Lang.getI18N("comment"));
    },
  },
  computed: {
    getAnswerPercentage(): (booleanQuestion: boolean) => string {
      return (booleanQuestion: boolean) => {
        if (!this.answers || !this.answers.length) return "0.00";

        const totalAnswers = this.answers.length;
        const choiceOccurrences = this.answers.filter(
          (answer) => answer.answer_yes === booleanQuestion
        ).length;
        let percentage = (choiceOccurrences / totalAnswers) * 100;

        let percentageString =
          percentage % 1 !== 0
            ? percentage.toFixed(2)
            : Math.round(percentage).toString();

        return percentageString.toString();
      };
    },
    hasEmptyQuestions() {
      return this.answers.find((answer) => answer.toPDF() === "");
    },
  },
  data() {
    return {
      Lang: Lang,
    };
  },
});
