export var data = {
  labels: [] as string[],
  datasets: [
    {
      label: "CPU Consuption",
      backgroundColor: "#454eff",
      data: [40, 39, 10, 40, 39, 80, 40, 10, 10, 40, 45],
      fill: {
        target: "origin",
        above: "rgb(69, 78, 255,0.3)", // Area will be red above the origin
      },
      tension: 0,
      hoverBackgroundColor: "white",
      pointRadius: 0,
      borderWidth: 2,
      borderColor: "rgb(69, 78, 255,0.8)",
    },

    {
      label: "Memory Consuption",
      backgroundColor: "#f24f05",
      data: [4, 10, 15, 70, 30, 10, 10, 10, 15, 20, 10],
      fill: {
        target: "origin",
        above: "rgb(242, 79, 5,0.3)", // Area will be red above the origin
      },
      tension: 0,
      hoverBackgroundColor: "white",
      pointRadius: 0,
      borderWidth: 2,
      borderColor: "rgb(242, 79, 5,0.8)",
    },
  ],
};
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  interaction: {
    intersect: false,
    mode: "x",
  },
  scales: {
    y: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
  type: "line",
};
