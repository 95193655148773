
import { defineComponent } from "vue";
import NavHeader from "../components/nav/NavHeader.vue";
import * as Lang from "@/i18n/lang";
import { getState } from "@/pinia/AppState";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as Modals from "@/utils/Modals";
import * as Snackbars from "@/utils/Snackbars";
import { updatePassword } from "firebase/auth";

export default defineComponent({
  components: {},
  mounted() {},
  methods: {
    async apply() {
      var refs = <any>this.$refs;

      var state = getState();

      if (this.password.trim() == "" || this.passwordConf.trim() == "") {
        Snackbars.display(
          "Veuillez renseigner une valeur pour le mot de passe."
        );
        return;
      }
      if (this.password != this.passwordConf) {
        Snackbars.display("Les deux mot de passes ne correspondent pas.");
        return;
      }

      refs.applyBtn.toggleLoading(true);

      var result = await updatePassword(getState().user, this.password);

      state.userData!.new_password_required = false;

      await state.userData?.set();

      getNavigationState().navigate("home", true);
    },
  },
  async setup() {},
  data() {
    return {
      password: "",
      passwordConf: "",
      Lang: Lang,
    };
  },
});
