
import { defineComponent } from "vue";
import * as DOMUtils from "@/utils/DOMUtils";
import SePlaceholder from "./SePlaceholder.vue";
import { getElementManager } from "@/firebase/ElementManager";

export default defineComponent({
  props: {
    select: {
      type: Function,
      default: null,
    },
  },
  expose: ["search"],
  methods: {
    onImageClick(url: string) {
      if (this.select != null) {
        this.select(url);
      }
    },
    search(value: string) {
      if (!this.isOpen()) {
        this.open();
      }
      if (this.timeout != null) {
        this.searching = true;
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.requestPictures(value);
      }, 500);
    },
    async requestPictures(search: string) {
      if (search.trim() == "") {
        return;
      }

      var result: any = await getElementManager().getIllustrationProposals(
        search,
        this.count
      );

      this.images = <any>result.data.images;
      this.searching = false;
    },
    open() {
      this.getImagePicker().style.display = "flex";
    },
    close() {
      this.getImagePicker().style.display = "none";
    },
    isOpen() {
      return this.getImagePicker().style.display == "flex";
    },
    getImagePicker() {
      return this.$el.querySelector(".stock-image-picker");
    },
    onDocumentClick(event: any) {
      if (
        event.target == this.getImagePicker() ||
        DOMUtils.isDescendantOf(event.target, this.getImagePicker())
      ) {
        return;
      }
      this.close();
    },
  },
  data() {
    return {
      count: 4,
      searching: true,
      images: [],
      timeout: null as NodeJS.Timeout | null,
    };
  },
  mounted() {
    // document.onclick = this.onDocumentClick;
  },
  components: { SePlaceholder },
});
