import { DocumentReference } from "firebase/firestore";
import TaskModuleConfiguration from "./TaskModuleConfiguration";

export default class TaskModuleConfigurationOils extends TaskModuleConfiguration {
  public clone() {
    return new TaskModuleConfigurationOils(this.oil_elements.map((x) => x));
  }
  oil_elements: DocumentReference[];

  constructor(oil_elements: DocumentReference[]) {
    super();
    this.oil_elements = oil_elements;
  }
  public static fromFirebase(x: any): TaskModuleConfigurationOils {
    return new TaskModuleConfigurationOils(x.oil_elements);
  }
  public override toFirebase() {
    return {
      oil_elements: this.oil_elements,
    };
  }
}
