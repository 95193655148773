import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1336122a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex grow justify-between align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      style: _normalizeStyle(_ctx.getHeaderStyle()),
      class: _normalizeClass(['header', { 'text-white': _ctx.isTextColorWhite }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expendClick()))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ]),
      _createElementVNode("img", {
        class: "expend-btn no-mobile",
        style: _normalizeStyle({
          filter: _ctx.isTextColorWhite ? 'brightness(1)' : 'brightness(0.6)',
        }),
        src: "/ico/arrow-ex.svg"
      }, null, 4)
    ], 6),
    _createElementVNode("div", {
      class: _normalizeClass(["expendable-zone", { close: !_ctx.open }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}