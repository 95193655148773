
import { defineComponent } from "vue";
import * as Lang from "@/i18n/lang";
import StoreElement from "@/model/Elements/StoreElement";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import { AllergenEnum } from "@/model/AllergensEnum";
import ElementType from "@/model/ElementType";

export default defineComponent({
  props: {
    elements: {
      type: Array as () => DatabaseElement[] | StoreElement[],
      required: true,
    },
    elementTypes: {
      type: Array as () => ElementType[],
      required: true,
    },
  },
  computed: {
    allergenValues() {
      return Object.values(AllergenEnum);
    },
    groupedElements(): Record<string, (StoreElement | DatabaseElement)[]> {
      const grouped: Record<string, (StoreElement | DatabaseElement)[]> = {};
      this.elementTypes.forEach((elementType: ElementType) => {
        const elementsOfType: (StoreElement | DatabaseElement)[] = [];
        this.elements.forEach((element: StoreElement | DatabaseElement) => {
          const dbElement = element as DatabaseElement;
          if (dbElement.getType()?.id === elementType.ref?.id) {
            elementsOfType.push(dbElement);
          }
        });
         if (elementsOfType.length > 0) {
          grouped[elementType.name] = elementsOfType;
        }
      });
      return grouped;
    },
  },
  methods: {
    elementHasAllergen(
      element: StoreElement | DatabaseElement,
      allergen: AllergenEnum
    ): boolean {
      return element.getAllergens()!.includes(allergen);
    },
  },
  data() {
    return { Lang: Lang };
  },
});
