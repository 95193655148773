import Store from "@/model/Store";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import {
  DocumentReference,
  WriteBatch,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import * as StringUtils from "@/utils/StringUtils";
import { SnapshotListener } from "@/utils/SnapshotListener";
import Distributor from "@/model/Distributor";
import { defineStore } from "pinia";
import { ModuleEnum } from "@/model/ModuleEnum";
import TaskModuleConfigurationTemperature from "@/model/Tasks/config/TaskModuleConfigurationTemperature";
import TaskModuleConfigurationAction from "@/model/Tasks/config/TaskModuleConfigurationAction";
import TaskModuleConfigurationOils from "@/model/Tasks/config/TaskModuleConfigurationOils";
import Database from "@/model/Database";
import { getModuleFromRef } from "./ModuleManager";

interface TaskConfigurationManager {
  databaseTasks: Map<
    String,
    SnapshotListener<DatabaseTaskConfiguration>
  > | null;
  storeTasks: Map<String, SnapshotListener<StoreTaskConfiguration>> | null;
}

export const getTaskConfigurationManager = defineStore(
  "TaskConfigurationManager",
  {
    state: (): TaskConfigurationManager => {
      return {
        databaseTasks: new Map(),
        storeTasks: new Map(),
      };
    },
    actions: {
      exportTasks(
        tasks: DatabaseTaskConfiguration[],
        sourceDatabase: Database,
        targetDatabase: Database
      ) {
        for (let task of tasks) {
          let newTask = DatabaseTaskConfiguration.clone(task);
          newTask.ref = doc(
            collection(
              Firebase.firestore,
              targetDatabase.ref.path,
              "task_configurations"
            )
          );

          switch (getModuleFromRef(task.module).toEnum()) {
            case ModuleEnum.Temperatures: {
              let configuration =
                task.configuration as TaskModuleConfigurationTemperature;
              configuration.temperature_elements = [];
              break;
            }
            case ModuleEnum.Oils: {
              let configuration =
                task.configuration as TaskModuleConfigurationOils;
              configuration.oil_elements = [];
              break;
            }
            case ModuleEnum.Action: {
              let configuration =
                task.configuration as TaskModuleConfigurationAction;
              for (let action of configuration.actions) {
                action.attachments = [];
              }
            }
          }

          newTask.set();
        }
      },
      async ensureDatabaseTaskConfigurationListener(databaseId: string) {
        if (this.databaseTasks?.has(databaseId)) {
          return;
        }
        const q = query(
          collection(
            Firebase.firestore,
            "databases/" + databaseId + "/task_configurations"
          )
        );

        let listener = new SnapshotListener<DatabaseTaskConfiguration>(
          DatabaseTaskConfiguration.fromFirestore,
          q
        );

        await listener.ensureInit();

        this.databaseTasks?.set(databaseId, listener);
      },

      async ensureStoreTaskConfigurationListener(storeId: string) {
        if (this.storeTasks?.has(storeId)) {
          return;
        }
        const q = query(
          collection(
            Firebase.firestore,
            "stores/" + storeId + "/task_configurations"
          )
        );

        let listener = new SnapshotListener<StoreTaskConfiguration>(
          StoreTaskConfiguration.fromFirestore,
          q
        );

        await listener.ensureInit();

        this.storeTasks?.set(storeId, listener);
      },
      getDatabaseTaskListener(databaseId: string) {
        return this.databaseTasks?.get(databaseId)!;
      },
      async updateStoreTaskConfiguration(
        store: Store,
        task: StoreTaskConfiguration
      ) {},

      async createDatabaseTaskConfiguration(
        databaseId: string,
        task: DatabaseTaskConfiguration
      ) {
        let taskConfRef = await addDoc(
          collection(
            Firebase.firestore,
            "/databases/" + databaseId + "/task_configurations"
          ),
          task.toFirestore()
        );

        task.ref = taskConfRef;
      },

      getTaskConfigurations(store: Store): TaskConfiguration[] {
        let tasks: TaskConfiguration[] = [];

        let storeTasks: StoreTaskConfiguration[] = this.storeTasks?.get(
          store.ref?.id!
        )?.items!;

        let databaseTasks: DatabaseTaskConfiguration[] =
          this.getDatabaseTaskConfigurations(store.database?.id!);

        // Store Tasks without origins
        for (let storeTask of storeTasks) {
          if (storeTask.origin != null) {
            let originValue = databaseTasks.find(
              (x) => x.ref!.id == storeTask.origin!.id
            );
            storeTask.setOriginValue(originValue!);
          }
        }

        for (let databaseTask of databaseTasks) {
          let exist = storeTasks.some(
            (x) =>
              x.originValue != null &&
              x.originValue!.ref!.id == databaseTask.ref!.id
          );

          if (!exist) {
            tasks.push(databaseTask);
          }
        }

        tasks = tasks.concat(storeTasks);

        tasks = tasks.sort((a, b) =>
          StringUtils.removeEmojis(a.getName()!).localeCompare(
            StringUtils.removeEmojis(b.getName()!)
          )
        );
        return tasks;
      },

      deserializeConfiguration(configuration: any, module: ModuleEnum) {
        if (module == ModuleEnum.Temperatures) {
          return TaskModuleConfigurationTemperature.fromFirebase(configuration);
        } else if (module == ModuleEnum.Oils) {
          return TaskModuleConfigurationOils.fromFirebase(configuration);
        } else if (module == ModuleEnum.Action) {
          return TaskModuleConfigurationAction.fromFirebase(configuration);
        }
        console.log("Unknown configuration" + module);
        return null;
      },

      getDatabaseTaskConfigurations(databaseId: string) {
        let tasks: DatabaseTaskConfiguration[] = [];

        tasks = this.databaseTasks?.get(databaseId)?.items!;

        tasks = tasks.sort((a, b) =>
          StringUtils.removeEmojis(a.getName()!).localeCompare(
            StringUtils.removeEmojis(b.getName()!)
          )
        );

        return tasks;
      },
    },
  }
);
