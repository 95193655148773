
import User, { Role } from "@/model/User";
import { PropType, defineComponent } from "vue";
import { getState } from "@/pinia/NavigationState";
import * as Lang from "@/i18n/lang";
import { getAccountState } from "@/pinia/accounts/AccountState";

export default defineComponent({
  data() {
    return {
      Role: Role,
      Lang: Lang,
    };
  },
  props: {
    accounts: Array as PropType<User[]>,
    unactives: Boolean,
  },
  methods: {
    onAccountClick(account: User) {
      getAccountState().set(account);
      getState().navigate("account");
    },
    getDefaultIllustration(user: User) {
      if (user.role == Role.Distributor) {
        return "/ico/user_distributor.svg";
      } else if (user.role == Role.Manager) {
        return "/ico/user_manager.svg";
      }
      return "/ico/user_general.svg";
    },
  },
});
