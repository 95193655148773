import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import Audit from "@/model/Auditing/Audit";
import * as StringUtils from "@/utils/StringUtils";
import { Role } from "@/model/User";
import { getAuditManager } from "@/firebase/AuditManager";
import Communication from "@/model/Communication/Communication";
import { getCommunicationManager } from "@/firebase/CommunicationManager";
import CommunicationTarget from "@/model/Communication/CommunicationTarget";
import { DateTime } from "rrule/dist/esm/datetime";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import Quiz, { QuizQuestion } from "@/model/Communication/Quiz";

interface CommunicationState {
  communication: Communication | null;
  initialCommunication: Communication | null;
  database: Database | null;
  creation: boolean;
  selectedQuestion: QuizQuestion | null;
}

export const getCommunicationState = defineStore("CommunicationState", {
  state: (): CommunicationState => {
    return {
      database: null,
      communication: null,
      creation: false,
      selectedQuestion: null,
      initialCommunication: null,
    };
  },
  actions: {
    set(database: Database, communication: Communication | null = null) {
      this.database = database;

      var appState = getState();

      if (communication == null) {
        this.initialCommunication = new Communication(
          doc(
            collection(Firebase.firestore, database.ref.path, "communications")
          ),
          true,
          appState.userData?.ref!,
          "",
          new CommunicationTarget(),
          null,
          null,
          new Date(),
          new Date()
        );

        this.creation = true;
      } else {
        this.initialCommunication = communication;
        this.creation = false;
      }

      if (this.initialCommunication.quiz == null) {
        this.initialCommunication.quiz = new Quiz([]);
      }

      this.communication = this.initialCommunication.clone();
      this.selectedQuestion = null;
    },
    async setup() {},
  },
});
