<style scoped>
.shimmer {
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right / 400%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 1.5s infinite;
}

.bottomhalf {
  background-color: rgba(119, 119, 119, 0.3);
  border-radius: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
</style>

<template>
  <div class="shimmer bottomhalf"></div>
</template>
