
import { defineComponent } from "vue";
import { Vue3ColorPicker } from "@cyhnkckali/vue3-color-picker";
import * as ColorUtils from "@/utils/ColorUtils";

export default defineComponent({
  components: { Vue3ColorPicker },
  expose: ["getValue", "setValue"],
  computed: {
    colorIndicator(): any {
      return {
        width: "50px",
        height: "30px",
        backgroundColor: this.getValue(),
        boxShadow: "0px 0px 3px " + this.getValue(),
      };
    },
  },
  data() {
    return { pureColor: "" };
  },
  methods: {
    setValue(value: string) {
      this.pureColor = value;
    },
    getValue() {
      if (this.pureColor.startsWith("rgba")) {
        var parsed = ColorUtils.parseRGBA(this.pureColor);

        return ColorUtils.rgbToHex(parsed.red, parsed.green, parsed.blue);
      }
      return this.pureColor;
    },
  },
});
