import { getState } from "../pinia/AppState";
import { I18N_EN } from "../i18n/en";
import { I18N_FR } from "../i18n/fr";
import { I18N_PL } from "../i18n/pl";
import { I18N_DE } from "../i18n/de";

export const languageMappings: Record<string, Map<string, string>> = {
  FR: I18N_FR,
  EN: I18N_EN,
  DE: I18N_DE,
  PL: I18N_PL,
};

export function getI18N(keyword: string) {
  const state = getState();
  const target = languageMappings[state.lang] || I18N_EN;

  if (target.has(keyword)) {
    return target.get(keyword)!;
  } else {
    return "[" + keyword + "]";
  }
}
