
import User, { Role } from "@/model/User";
import { PropType, defineComponent } from "vue";
import { getState as getNavigationState } from "@/pinia/NavigationState";
import * as Lang from "@/i18n/lang";
import { getAccountState } from "@/pinia/accounts/AccountState";
import Communication from "@/model/Communication/Communication";
import { CommunicationState } from "@/model/Communication/CommunicationState";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import Database from "@/model/Database";
import { getCommunicationsState } from "@/pinia/communications/CommunicationsState";
import CommunicationStatus from "@/components/communication/CommunicationStatus.vue";
import { getCommunicationStatsState } from "@/pinia/communications/CommunicationStatsState";
import { getState } from "@/pinia/AppState";

export default defineComponent({
  components: {
    CommunicationStatus,
  },
  data() {
    return {
      Role: Role,
      myRole: getState().userData!.role,
      Lang: Lang,
      state: getCommunicationsState(),
      CommunicationState: CommunicationState,
    };
  },
  props: {
    database: {
      type: Database,
      required: true,
    },
  },
  methods: {
    viewCommunicationStats(communication: Communication) {
      getCommunicationStatsState().set(communication);
      getNavigationState().navigate("communicationStats");
    },
    editCommunication(communication: Communication) {
      getCommunicationState().set(this.database, communication);
      getNavigationState().navigate("communication");
    },
  },
});
