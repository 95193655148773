import { getFunctions, httpsCallable } from "firebase/functions";
import { DocumentReference } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";

export async function getPDFDownloadLink(resultRef: DocumentReference) {
  let functions = getFunctions(Firebase.firebase);

  const generateAndStorePDF = httpsCallable(functions, "generateAndStorePDF");
  console.log(resultRef.path);

  let functionResult = generateAndStorePDF({
    resultRef: resultRef.path,
  })
    .then((res) => {
      let data = res.data as { downloadURL: string };
      return data.downloadURL;
    })
    .catch((e) => {
      console.log(e);
      return "error";
    });
  return functionResult;
}
