import { DocumentReference } from "firebase/firestore";
import TaskResult from "./TaskResult";

export default class CleaningResult extends TaskResult {
  action: string | null = null;
  done: boolean | null = null;
  employee: DocumentReference | null = null;
  firebase_path: string | null = null;
  local_path: string | null = null;
  requires_picture: boolean | null = null;
  uploadTimestamp: Date | null = null;
  url: string | null = null;

  constructor(
    action: string | null,
    details: string | null,
    done: boolean | null,
    employee: DocumentReference | null,
    firebase_path: string | null,
    local_path: string | null,
    requires_picture: boolean,
    status: string | null,
    timestamp: Date | null,
    uploadTimestamp: Date | null,
    url: string | null
  ) {
    super(details, status, timestamp);
    this.action = action;
    this.done = done;
    this.employee = employee;
    this.firebase_path = firebase_path;
    this.local_path = local_path;
    this.requires_picture = requires_picture;
    this.uploadTimestamp = uploadTimestamp;
    this.url = url;
  }
}
