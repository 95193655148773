import { DocumentSnapshot, DocumentReference, doc } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import TaskConfiguration from "./TaskConfiguration";
import TaskModuleConfiguration from "./config/TaskModuleConfiguration";
import { getModuleFromRef } from "@/firebase/ModuleManager";
import { ModuleEnum } from "../ModuleEnum";
import TaskModuleConfigurationTemperature from "./config/TaskModuleConfigurationTemperature";
import TaskModuleConfigurationAction from "./config/TaskModuleConfigurationAction";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";

export default class DatabaseTaskConfiguration extends TaskConfiguration {
  active_lock: boolean;
  configuration_lock: boolean;
  description_lock: boolean;
  schedule_lock: boolean;
  name_lock: boolean;
  recurrence_lock: boolean;
  requires_manager_lock: boolean;
  type_lock: boolean;
  notify_late_lock: boolean;

  constructor(
    ref: DocumentReference,
    active: boolean = true,
    configuration: TaskModuleConfiguration | null = null,
    description: string = "",
    durations: number[] = [],
    module: DocumentReference | null = null,
    name: string = "",
    recurrence: string = "",
    starts: number[] = [],
    requires_manager: boolean = false,
    type: DocumentReference | null = null,
    notify_late: boolean = false,
    active_lock: boolean = true,
    configuration_lock: boolean = true,
    description_lock: boolean = true,
    schedule_lock: boolean = true,
    name_lock: boolean = true,
    recurrence_lock: boolean = true,
    requires_manager_lock: boolean = true,
    type_lock: boolean = true,
    notify_late_lock: boolean = true
  ) {
    super(
      ref,
      active,
      configuration,
      description,
      durations,
      module,
      name,
      recurrence,
      starts,
      requires_manager,
      type,
      notify_late
    );

    this.active_lock = active_lock;
    this.configuration_lock = configuration_lock;
    this.description_lock = description_lock;
    this.schedule_lock = schedule_lock;
    this.name_lock = name_lock;
    this.recurrence_lock = recurrence_lock;
    this.requires_manager_lock = requires_manager_lock;
    this.type_lock = type_lock;
    this.notify_late_lock = notify_late_lock;
  }

  public static fromFirestore(
    snapshot: DocumentSnapshot
  ): DatabaseTaskConfiguration {
    const data = snapshot.data()!;

    var module = getModuleFromRef(data.module)?.toEnum();

    let result = new DatabaseTaskConfiguration(
      snapshot.ref,
      data.active,
      getTaskConfigurationManager().deserializeConfiguration(
        data.configuration,
        module!
      ),

      data.description,
      data.durations,
      data.module,
      data.name,
      data.recurrence,
      data.starts,
      data.requires_manager,
      data.type,
      data.notify_late == undefined ? false : data.notify_late,
      data.active_lock,
      data.configuration_lock,
      data.description_lock,
      data.schedule_lock,
      data.name_lock,
      data.recurrence_lock,
      data.requires_manager_lock,
      data.type_lock,
      data.notify_late_lock == undefined ? false : data.notify_late_lock
    );

    if (result.starts == null) {
      result.starts = [];
    }
    if (result.durations == null) {
      result.durations = [];
    }

    /* Temporary sanitize */
    if (
      result.starts!.constructor != Array ||
      result.durations!.constructor != Array
    ) {
      result.starts = Array.from(<any>result.starts!, ([name, value]) => value);
      result.durations = Array.from(
        <any>result.durations!,
        ([name, value]) => value
      );
    }

    return result;
  }

  public toFirestore(): any {
    return {
      active: this.active,
      configuration:
        this.configuration == null ? null : this.configuration.toFirebase(),
      description: this.description,
      durations: this.durations,
      module: this.module,
      name: this.name,
      recurrence: this.recurrence,
      starts: this.starts,
      requires_manager: this.requires_manager,
      type: this.type,
      notify_late: this.notify_late,
      active_lock: this.active_lock,
      configuration_lock: this.configuration_lock,
      description_lock: this.description_lock,
      schedule_lock: this.schedule_lock,
      name_lock: this.name_lock,
      recurrence_lock: this.recurrence_lock,
      requires_manager_lock: this.requires_manager_lock,
      type_lock: this.type_lock,
      notify_late_lock: this.notify_late_lock,
    };
  }

  public static clone(obj: DatabaseTaskConfiguration) {
    let durations = obj.durations?.map((x) => x);
    let starts = obj.starts?.map((x) => x);

    return new DatabaseTaskConfiguration(
      obj.ref!,
      obj.active!,
      obj.configuration == null ? null : obj.configuration.clone(),
      obj.description!,
      durations,
      obj.module!,
      obj.name!,
      obj.recurrence!,
      starts,
      obj.requires_manager!,
      obj.type,
      obj.notify_late,
      obj.active_lock!,
      obj.configuration_lock,
      obj.description_lock,
      obj.schedule_lock,
      obj.name_lock,
      obj.recurrence_lock,
      obj.requires_manager_lock,
      obj.type_lock,
      obj.notify_late_lock
    );
  }

  public static duplicate(obj: DatabaseTaskConfiguration) {
    let durations = obj.durations?.map((x) => x);
    let starts = obj.starts?.map((x) => x);

    return new DatabaseTaskConfiguration(
      doc(obj.ref.parent),
      obj.active!,
      obj.configuration == null ? null : obj.configuration.clone(),
      obj.description!,
      durations,
      obj.module!,
      obj.name!,
      obj.recurrence!,
      starts,
      obj.requires_manager!,
      obj.type,
      obj.notify_late,
      obj.active_lock!,
      obj.configuration_lock,
      obj.description_lock,
      obj.schedule_lock,
      obj.name_lock,
      obj.recurrence_lock,
      obj.requires_manager_lock,
      obj.type_lock,
      obj.notify_late_lock
    );
  }
}
